import React, {FC, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {BigLoading} from '../../components/bigLoading';
import {Datepicker} from '../../components/DatePicker/DatePicker';
import '../../components/healthTable.css';
import {IVRsTableItem} from './ivrsTableItem';
import {HttpHealthIvrAnalytics} from '../../../../dataServices/app.generated';
import {HealthIVRFilter} from '../healthIVRs';
import {OptionSelect} from '../../components/OptionSelect/OptionSelect';

interface IVRsTableProps {
  ivrsList: HttpHealthIvrAnalytics[];
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  loading: boolean;
  selectedFilter: HealthIVRFilter;
  setSelectedFilter: (val: HealthIVRFilter) => void;
}

const options: {id: HealthIVRFilter; label: string}[] = [
  {id: '', label: 'All'},
  {id: 'forward-to-ucm', label: 'Forwarded to UCM'},
  {id: 'send-text', label: 'Sent Text'},
];

export const IVRsTable: FC<IVRsTableProps> = (props) => {
  const history = useHistory();

  const onNavIVR = (id: string) => {
    history.push('/health/ivr/' + id);
  };

  const ivrsList = useMemo(() => {
    return props.ivrsList.map((ivr) => <IVRsTableItem key={ivr.id} {...ivr} onNavIVR={() => onNavIVR(ivr.id)} />);
  }, [props.ivrsList]);

  const onChangeDate = (selectedDates: Date[]) => {
    props.setStartDate(selectedDates[0]);
    selectedDates.length === 1 ? props.setEndDate(null) : props.setEndDate(selectedDates[1]);
  };

  return (
    <div className={'HealthTable'}>
      <header className={'HealthTable__header'}>
        <h2>IVRs</h2>
        <OptionSelect
          selectedFilter={props.selectedFilter}
          setSelectedFilter={props.setSelectedFilter}
          options={options}
        />
        <Datepicker value={[props.startDate, props.endDate]} onChange={onChangeDate} />
      </header>
      <div className={'HealthTable__tableContainer'}>
        <table className={'HealthTable__table'}>
          <thead>
            <tr>
              <th style={{paddingLeft: '1.25rem'}}>
                <div>Time</div>
              </th>
              <th>
                <div>Type</div>
              </th>
              <th>
                <div>Phone Number</div>
              </th>
              <th style={{paddingRight: '1.25rem'}}>
                <div>Zip Code</div>
              </th>
            </tr>
          </thead>
          <tbody>{ivrsList}</tbody>
        </table>
        {ivrsList.length === 0 && !props.loading && (
          <p className={'HealthTable__empty'}>Sorry, no ivr analytics could be found.</p>
        )}
        {props.loading && (
          <div className={'HealthTable__loadingContainer'}>
            <BigLoading loading />
          </div>
        )}
      </div>
    </div>
  );
};
