import React, {FC} from 'react';
import {format} from 'date-fns';

interface IVRsTableItemProps {
  onNavIVR: () => void;
  date: string;
  type: string;
  phoneNumber: string;
  zipCode: string;
  id: string;
}

export const IVRsTableItem: FC<IVRsTableItemProps> = (props) => {
  return (
    <tr onClick={props.onNavIVR}>
      <td style={{paddingLeft: '1.25rem'}}>
        <div>{format(new Date(props.date), 'P')}</div>
      </td>
      <td>
        <div>{props.type}</div>
      </td>
      <td>
        <div>{props.phoneNumber}</div>
      </td>
      <td style={{paddingRight: '1.25rem'}}>
        <div>{props.zipCode}</div>
      </td>
    </tr>
  );
};
