import {faBusinessTime} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {Prompt, RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  CustomInput,
  DropdownMenu,
  DropdownToggle,
  Form,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {LInput, LSelect, LText} from '../../../components/lInput';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {HttpAccountExtended} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {FormatUtils} from '../../../utils/formatUtils';
import {PartnerUtils} from '../../../utils/partnerUtils';

type Props = RouteComponentProps<{accountId: string}> & {} & MainStoreProps;
type State = {
  account?: HttpAccountExtended;
  isModified: boolean;
};

@inject(MainStoreName)
@observer
export class AccountDetails extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {isModified: false};
  }

  async componentDidMount() {
    await this.getAccount();
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.match.params.accountId !== this.props.match.params.accountId) {
      await this.getAccount();
    }
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {this.state.account ? this.renderAccount() : <Loading />}
        <Prompt
          when={this.state.isModified}
          message={(location) => `You have unsaved changes, are you sure you want leave?`}
        />
      </ReactCSSTransitionGroup>
    );
  }

  private renderAccount() {
    const account = this.state.account;
    return (
      <>
        <PageTitle
          heading={account.accountOwner ? `${account.accountOwner} (${account.email})` : account.email}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={
            <UncontrolledDropdown className="d-inline-block">
              <DropdownToggle color="primary" className="btn-shadow" caret>
                <span className="btn-icon-wrapper pr-2 opacity-7">
                  <FontAwesomeIcon icon={faBusinessTime} />
                </span>
                Actions
              </DropdownToggle>
              <DropdownMenu right>
                <Nav vertical>
                  <NavItem>
                    <NavLink href="javascript:void(0);" onClick={() => alert('Coming soon...')}>
                      <span>Send HubSpot Templated Email</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="javascript:void(0);" onClick={this.activateAccount}>
                      <span>Activate Partner Portal</span>
                    </NavLink>
                  </NavItem>
                  {account.hubspotId && (
                    <NavItem>
                      <NavLink
                        href={`https://app.hubspot.com/contacts/5863116/contact/${account.hubspotId}/`}
                        onClick={() => alert('Coming soon...')}
                      >
                        <span> Open In Hubspot</span>
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        />
        <Row>
          <Col md="5">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Partners</CardTitle>
                {account.partners.length === 0 && 'None'}
                {account.partners.map((p) => (
                  <div key={p.id} style={{fontSize: '1.4em'}}>
                    <Link to={`/partner/details/${p.id}`}>
                      {p.startingGroupCode}-{p.startingMemberId} {p.partnerName}
                    </Link>
                    <span style={{paddingLeft: 5}} className={'money'}>
                      ({FormatUtils.formatMoney(p.commission.commissionAmount)})
                    </span>

                    {account.primaryPartnerId === p.id && <b style={{paddingLeft: 5}}>This is the Primary Partner</b>}
                  </div>
                ))}
              </CardBody>
            </Card>
            {account.cards.length > 0 && (
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Cards</CardTitle>
                  {account.cards.map((card) => (
                    <div key={card.id}>
                      <Link to={`/card/details/${card.id}`} style={{color: 'green'}}>
                        {card.groupCode} - {card.memberId} {card.companyName}
                      </Link>
                    </div>
                  ))}
                </CardBody>
              </Card>
            )}
          </Col>
          <Col md="7">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Details</CardTitle>
                <Form>
                  <Row>
                    <Col md={8}>
                      <LInput
                        label={'Account Owner'}
                        value={account.accountOwner}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, accountOwner: e.target.value},
                          })
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <LText style={{fontWeight: 'bold'}} label={'Account Status'}>
                        {!account.accountInvited ? 'Not Invited' : !account.accountSetUp ? 'Invited' : 'Set Up'}
                      </LText>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <LInput
                        label={'Email'}
                        value={account.email}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, email: e.target.value},
                          })
                        }
                      />
                    </Col>
                    <Col md={3} style={{height: '90px', display: 'flex', alignItems: 'center'}}>
                      <CustomInput
                        type="checkbox"
                        label="Allow Promotional Materials"
                        id={'allowPromoMaterials'}
                        checked={this.state.account.allowPromotionalCommunication}
                        onChange={(e) => {
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, allowPromotionalCommunication: e.target.checked},
                          });
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <LInput
                        label={'Phone Number'}
                        value={account.phoneNumber}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, phoneNumber: e.target.value},
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <LInput
                        label={'Address 1'}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, address1: e.target.value},
                          })
                        }
                        value={account.address1}
                      />
                    </Col>
                    <Col md={6}>
                      <LInput
                        label={'Address 2'}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, address2: e.target.value},
                          })
                        }
                        value={account.address2}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <LInput
                        value={account.city}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, city: e.target.value},
                          })
                        }
                        label={'City'}
                        type={'text'}
                        name={'city'}
                        placeholder="Optional"
                        autoComplete={'disabled'}
                      />
                    </Col>
                    <Col md={4}>
                      <Label for={'stateSelect'}>State</Label>
                      <Select
                        menuPosition={'fixed'}
                        id={'stateSelect'}
                        onChange={(v: any) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, state: v.value},
                          })
                        }
                        value={PartnerUtils.getStates().find((a) => a.value === account.state)}
                        options={PartnerUtils.getStates()}
                        autoComplete={'disabled'}
                      />
                    </Col>
                    <Col md={2}>
                      <LInput
                        value={account.zip}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, zip: e.target.value},
                          })
                        }
                        label={'Zip'}
                        type={'text'}
                        name={'zip'}
                        placeholder="Optional"
                        autoComplete={'disabled'}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <LSelect
                        menuPosition={'fixed'}
                        label={'Contact Type'}
                        value={PartnerUtils.getAccountContactsTypes().find((a) => a.value === account.contactType)}
                        options={PartnerUtils.getAccountContactsTypes()}
                        onChange={(e) =>
                          this.setState({
                            isModified: true,
                            account: {...this.state.account, contactType: (e as any).value},
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <PromiseButton
                      disabled={!this.state.isModified}
                      onClick={this.save}
                      className="btn-pill btn-shadow mr-3"
                      color="primary"
                    >
                      Save Changes
                    </PromiseButton>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  private async getAccount() {
    this.setState({account: null});
    const result = await AppService.adminAccountClient.getAccount(
      {
        accountId: this.props.match.params.accountId,
      },
      {
        ...MainStore.handleError(404, () => {
          this.props.history.push('/');
        }),
      }
    );
    if (result) {
      this.setState({account: result.account});
    }
  }

  private save = async () => {
    const result = await AppService.adminAccountClient.updateAccount(
      {
        accountId: this.state.account.id,
        email: this.state.account.email,
        phoneNumber: this.state.account.phoneNumber,
        accountOwner: this.state.account.accountOwner,
        allowPromotionalCommunication: this.state.account.allowPromotionalCommunication,
        address1: this.state.account.address1,
        address2: this.state.account.address2,
        city: this.state.account.city,
        state: this.state.account.state,
        zip: this.state.account.zip,
        contactType: this.state.account.contactType,
      },
      {
        400: (e) => {
          this.props.mainStore.setError(e.error);
        },
      }
    );
    if (result) {
      this.setState({isModified: false, account: result.account});
      toast('Account has been updated!', {type: 'success'});
    }
  };

  private activateAccount = async () => {
    // eslint-disable-next-line no-restricted-globals
    const j = confirm(`Are you sure you want to allow ${this.state.account.accountOwner} to log into Partner Portal?`);
    if (j) {
      const result = await AppService.adminAccountClient.activateAccount(
        {
          accountId: this.state.account.id,
        },
        {...MainStore.handleError(404)}
      );
      if (result) {
        toast('The activation email has been sent!', {type: 'success'});
        this.setState({account: result.account});
      }
    }
  };
}
