import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import Loading from '../../../components/loading';
import {SlugsByDayResponse} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {useEffectAsync} from '../../../hooks/useEffectAsync';
import {Utils} from '../../../utils/utils';

export const SlugsReport: FC = () => {
  const [gettingReport, setGettingReport] = useState(true);

  const [report, setReport] = useState<SlugsByDayResponse['slugs']>(undefined);

  useEffectAsync(async () => {
    const result = await AppService.adminDashboardClient.slugsByDay({}, {});
    if (result) {
      setGettingReport(false);
      setReport(result.slugs);
    }
  }, []);

  if (gettingReport) {
    return <Loading />;
  }

  return (
    <>
      <Card className="mb-3">
        <CardHeader className="card-header-tab z-index-7">
          <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
            Slug Report
          </div>
        </CardHeader>
      </Card>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <ReactTable
                data={
                  report
                    ? Utils.groupBy(report, (a) => a.slug)
                        .map((a) => ({
                          slug: a.key,
                          cardId: a.items[0].cardId,
                          count: Utils.sum(a.items, (c) => c.count),
                        }))
                        .sort((a, b) => b.count - a.count)
                    : []
                }
                showPagination={true}
                sortable={false}
                columns={[
                  {
                    Header: 'Slug',
                    accessor: 'slug',
                    Cell: (e) =>
                      e.original.cardId ? <Link to={`/card/details/${e.original.cardId}`}>{e.value}</Link> : e.value,
                  },
                  {Header: 'Hits', accessor: 'count'},
                ]}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
