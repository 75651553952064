import React, {useCallback, useEffect, useState} from 'react';
import {HealthContainer} from '../components/HealthContainer/healthContainer';
import {useHistory, useParams} from 'react-router';
import {AppService} from '../../../dataServices/appService';
import {BigLoading} from '../components/bigLoading';
import '../components/healthTable.css';
import {TextFormatter} from '../../../utils/textFormatter';
import {FullDateUtils} from '../../../utils/fullDateUtils';
import {format} from 'date-fns';
import {toast} from 'react-toastify';
import {DBEligibleMember} from '../../../dataServices/app.generated';
import {useEffectAsync} from '../../../hooks/useEffectAsync';
import './HealthEligibilityMember.css';

type MemberUpdateFields = {
  firstName: string;
  lastName: string;
  dob: string;
  zipCode: string;
};

const defaultErrorMessages = {
  firstName: '',
  lastName: '',
  dob: '',
  zipCode: '',
};

const FamilyTreeRow = React.memo(
  ({member, onNavMember}: {member: DBEligibleMember; onNavMember: (id: string) => void}) => (
    <tr onClick={() => onNavMember(member._id)} className={'cursor-pointer hover:bg-primary-200'}>
      <td>{member.employeeType}</td>
      <td>{member.firstName}</td>
      <td>{member.lastName}</td>
      <td>{format(FullDateUtils.toDate(member.dateOfBirth), 'P')}</td>
      <td>{member.zipcode}</td>
      <td>{format(FullDateUtils.toDate(member.activeDate), 'P')}</td>
      <td>{member.deactivatedDate ? format(FullDateUtils.toDate(member.deactivatedDate), 'P') : null}</td>
      <td>{member.uniqueId}</td>
    </tr>
  )
);

const tableHeaders = [
  'Employee Type',
  'First Name',
  'Last Name',
  'Date of Birth',
  'Zip Code',
  'Active Date',
  'Deactivated Date',
  'Unique ID',
];

const validateUpdateMemberFields = (fields: MemberUpdateFields) => {
  let hasError = false;
  const messages = {...defaultErrorMessages};
  if (!fields.firstName) {
    messages.firstName = 'Required Field';
    hasError = true;
  }
  if (!fields.lastName) {
    messages.lastName = 'Required Field';
    hasError = true;
  }
  if (!fields.dob) {
    messages.dob = 'Required Field';
    hasError = true;
  } else if (!TextFormatter.valid({format: '##/##/####', prefix: ''}, fields.dob)) {
    messages.dob = 'Please enter a valid date of birth.';
    hasError = true;
  }
  if (!fields.zipCode) {
    messages.zipCode = 'Required Field';
    hasError = true;
  } else if (!TextFormatter.valid({format: '#####', prefix: ''}, fields.zipCode)) {
    messages.zipCode = 'Please enter a valid zip code.';
    hasError = true;
  }

  return {
    hasError,
    messages,
  };
};

export const HealthEligibilityMember = () => {
  const history = useHistory();
  const params = useParams<{id: string}>();
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    zipCode: '',
  });
  const [errorMessages, setErrorMessages] = useState({...defaultErrorMessages});
  const [member, setMember] = useState<DBEligibleMember | null>(null);
  const [familyTree, setFamilyTree] = useState<DBEligibleMember[]>([]);

  useEffectAsync(async () => {
    const memberResponse = await AppService.adminPartnerHealthClient.getAdminEDIMember(
      {id: params.id},
      {
        404: () => {
          history.push('health/eligibility/members');
        },
      }
    );
    setLoading(false);
    if (!memberResponse?.member) {
      return;
    }
    setMember(memberResponse.member);
    setFields({
      firstName: memberResponse.member.firstName,
      lastName: memberResponse.member.lastName,
      zipCode: memberResponse.member.zipcode,
      dob: format(FullDateUtils.toDate(memberResponse.member.dateOfBirth), 'P'),
    });
    const familyTree = await AppService.adminPartnerHealthClient.getAdminEDIMemberFamilyTree(
      {externalMemberId: memberResponse.member.externalMemberId},
      {}
    );
    setFamilyTree(familyTree.members);
  }, [params]);

  const onUpdateMember = async () => {
    const {hasError, messages} = validateUpdateMemberFields(fields);
    if (hasError) {
      return setErrorMessages({...messages});
    }

    await AppService.adminPartnerHealthClient.updateAdminEDIMember(
      {
        id: params.id,
        firstName: fields.firstName,
        lastName: fields.lastName,
        zipCode: fields.zipCode,
        dob: FullDateUtils.fromDate(new Date(fields.dob)),
      },
      {404: () => history.push('health/eligibility/members'), 400: () => history.push('health/eligibility/members')}
    );

    toast('The member was successfully updated.', {type: 'success'});
  };

  const onChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({...fields, firstName: e.target.value});
    if (errorMessages.firstName) {
      setErrorMessages((messages) => ({...messages, firstName: ''}));
    }
  };

  const onChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({...fields, lastName: e.target.value});
    if (errorMessages.lastName) {
      setErrorMessages((messages) => ({...messages, lastName: ''}));
    }
  };

  const onChangeZipCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({...fields, zipCode: TextFormatter.format({format: '#####', prefix: ''}, e.target.value)});
    if (errorMessages.zipCode) {
      setErrorMessages((messages) => ({...messages, zipCode: ''}));
    }
  };

  const onChangeDOB = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFields({
      ...fields,
      dob: TextFormatter.format({format: '##/##/####', prefix: ''}, e.target.value),
    });
    if (errorMessages.dob) {
      setErrorMessages((messages) => ({...messages, dob: ''}));
    }
  };

  const onNavMember = useCallback((id: string) => {
    history.push('/health/eligibility/members/' + id);
  }, []);

  return (
    <HealthContainer title={'Clever Health Eligibility Member'}>
      {loading ? (
        <div className={'HealthTable__loadingContainer'}>
          <BigLoading loading />
        </div>
      ) : (
        <>
          <div className={'HealthTable HealthEligibilityCard'}>
            <div className={'HealthTable__headerRowTitle'}>
              <span>Update Member</span>
            </div>
            <div className={'HealthTable__headerRow'}>
              <label>
                First Name
                <input
                  className={`HealthTable__input ${errorMessages.firstName && 'HealthTable__inputError'}`}
                  value={fields.firstName}
                  onChange={onChangeFirstName}
                />
                {errorMessages.firstName && (
                  <div className={'HealthTable__inputErrorMsg'}>{errorMessages.firstName}</div>
                )}
              </label>
              <label>
                Last Name
                <input
                  className={`HealthTable__input ${errorMessages.lastName && 'HealthTable__inputError'}`}
                  value={fields.lastName}
                  onChange={onChangeLastName}
                />
                {errorMessages.lastName && <div className={'HealthTable__inputErrorMsg'}>{errorMessages.lastName}</div>}
              </label>
              <label>
                Zip Code
                <input
                  className={`HealthTable__input ${errorMessages.zipCode && 'HealthTable__inputError'}`}
                  value={fields.zipCode}
                  onChange={onChangeZipCode}
                />
                {errorMessages.zipCode && <div className={'HealthTable__inputErrorMsg'}>{errorMessages.zipCode}</div>}
              </label>
              <label>
                Date of Birth
                <input
                  className={`HealthTable__input ${errorMessages.dob && 'HealthTable__inputError'}`}
                  value={fields.dob}
                  onChange={onChangeDOB}
                  placeholder={'MM/DD/YYYY'}
                />
                {errorMessages.dob && <div className={'HealthTable__inputErrorMsg'}>{errorMessages.dob}</div>}
              </label>
            </div>
            <div className={'HealthTable__headerRow'}>
              <button className={'HealthTable__searchBtn'} onClick={onUpdateMember}>
                Save
              </button>
            </div>
          </div>
          {!!member && (
            <div className={'HealthTable HealthEligibilityCard'}>
              <div className={'HealthTable__headerRowTitle'}>
                <span>Member Details</span>
              </div>
              <div className={'HealthEligibilityMember__inputRow'}>
                <ReadOnlyField label={'ID'} value={member._id} />
                <ReadOnlyField label={'Unique ID'} value={member.uniqueId} />
                <ReadOnlyField label={'External Member ID'} value={member.externalMemberId} />
              </div>
              <div className={'HealthEligibilityMember__inputRow'}>
                <ReadOnlyField label={'Clever Partner ID'} value={member.cleverPartnerId} />
                <ReadOnlyField label={'Clever Card ID'} value={member.cleverCardId} />
                <ReadOnlyField label={'Partner Config ID'} value={member.partnerConfigId} />
              </div>
              <div className={'HealthEligibilityMember__inputRow'}>
                <ReadOnlyField label={'Clever Group Code'} value={member.cleverGroupCode} />
                <ReadOnlyField label={'Clever Member ID'} value={member.cleverMemberId} />
                <ReadOnlyField label={'Batch ID'} value={member.batchId} />
              </div>
              <div className={'HealthEligibilityMember__inputRow'}>
                <ReadOnlyField label={'Created Date'} value={format(new Date(member.createdDate), 'P')} />
                <ReadOnlyField label={'Active Date'} value={format(FullDateUtils.toDate(member.activeDate), 'P')} />
                <ReadOnlyField
                  label={'Deactivated Date'}
                  value={member.deactivatedDate ? format(FullDateUtils.toDate(member.activeDate), 'P') : 'N/A'}
                />
              </div>
              <div className={'HealthEligibilityMember__inputRow'}>
                <ReadOnlyField label={'First Name'} value={member.firstName} />
                <ReadOnlyField label={'Last Name'} value={member.lastName} />
                <ReadOnlyField label={'Date of Birth'} value={format(FullDateUtils.toDate(member.dateOfBirth), 'P')} />
              </div>
              <div className={'HealthEligibilityMember__inputRow'}>
                <ReadOnlyField label={'Zip Code'} value={member.zipcode} />
                <ReadOnlyField label={'State'} value={member.state} />
                <ReadOnlyField label={'Email'} value={member.email} />
              </div>
              <div className={'HealthEligibilityMember__inputRow'}>
                <ReadOnlyField label={'Phone Number'} value={member.phoneNumber} />
                <ReadOnlyField label={'Employee Type'} value={member.employeeType} />
                <ReadOnlyField label={'Updated Date'} value={format(new Date(member.updatedDate), 'P')} />
              </div>
            </div>
          )}
          {familyTree.length > 0 && (
            <div className={'HealthTable HealthEligibilityCard'}>
              <div className={'HealthTable__headerRowTitle'}>
                <span>Family Members</span>
              </div>
              <div className={'HealthTable__tableContainer'}>
                <table className={'HealthTable__table'}>
                  <thead>
                    <tr>
                      {tableHeaders.map((h) => (
                        <th key={h}>{h}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {familyTree.map((m) => (
                      <FamilyTreeRow key={m._id} member={m} onNavMember={onNavMember} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </HealthContainer>
  );
};

const ReadOnlyField = ({label, value}: {label: string; value: string}) => (
  <label className={'HealthEligibilityMember__readOnlyField'}>
    {label}
    <input className={'HealthTable__input'} value={value} readOnly onFocus={(e) => e.target.select()} />
  </label>
);
