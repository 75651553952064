import moment from 'moment';
import React, {Component} from 'react';
import CountUp from 'react-countup';
import {DateRangePicker, Range} from 'react-date-range';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {Card, CardHeader, Col, Row} from 'reactstrap';
import Loading from '../../../components/loading';
import {FullDate, MasterClaimReport} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {FullDateUtils} from '../../../utils/fullDateUtils';

type Props = RouteComponentProps<any> & {};
type State = {
  gettingReport: boolean;
  masters?: MasterClaimReport[];
  startDate: FullDate;
  endDate: FullDate;
  showDate: boolean;
};

export class MastersReport extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      gettingReport: true,
      startDate: FullDateUtils.fromDate(moment().startOf('month').toDate()),
      endDate: FullDateUtils.fromDate(moment().toDate()),
      showDate: false,
    };
  }

  async componentDidMount() {
    this.getReport();
  }

  private async getReport() {
    this.setState({gettingReport: true});
    const result = await AppService.adminReportsClient.getMasterClaimsReport(
      {
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      },
      {}
    );
    if (result) {
      console.log(result);
      this.setState({
        gettingReport: false,
        masters: result.masters,
      });
    }
  }

  render() {
    if (this.state.gettingReport) {
      return <Loading />;
    }

    return (
      <>
        <Card className="mb-3">
          <CardHeader className="card-header-tab z-index-7">
            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
              <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
              Masters Report
            </div>
            <div className="btn-actions-pane-right">
              <div onClick={() => this.setState({showDate: true})} style={{display: 'flex', alignItems: 'center'}}>
                <div style={{border: 'solid 1px black', margin: 5, padding: 5}}>
                  {FullDateUtils.getString(this.state.startDate)}
                </div>
                -
                <div style={{border: 'solid 1px black', margin: 5, padding: 5}}>
                  {FullDateUtils.getString(this.state.endDate)}
                </div>
              </div>
            </div>
          </CardHeader>
        </Card>
        {this.state.showDate && (
          <div style={{right: 0, position: 'absolute', zIndex: 100, border: 'solid'}}>
            <DateRangePicker
              ranges={[
                {
                  startDate: this.state.startDate ? FullDateUtils.toDate(this.state.startDate) : null,
                  endDate: this.state.endDate ? FullDateUtils.toDate(this.state.endDate) : null,
                },
              ]}
              onChange={(result) => {
                const range = (result as any).range1 as Range;
                if (!range) {
                  return;
                }
                this.setState(
                  {
                    startDate: range.startDate ? FullDateUtils.fromDate(range.startDate) : null,
                    endDate: range.endDate ? FullDateUtils.fromDate(range.endDate) : null,
                    showDate: false,
                  },
                  () => {
                    this.getReport();
                  }
                );
              }}
            />
          </div>
        )}

        {this.state.gettingReport ? (
          <div className={'pt-4 pb-4'}>
            <Loading />
          </div>
        ) : (
          this.state.masters
            .sort((a, b) => b.commissionEarned - a.commissionEarned)
            .map((m) => (
              <Card className="main-card mb-3" key={m.partnerId}>
                <CardHeader>
                  <h2 className={'pt-2 pb-2'}>
                    <Link to={`/partner/details/${m.partnerId}`}>{m.partnerName}</Link>
                  </h2>
                </CardHeader>
                <Row className="no-gutters">
                  <Col sm="6" md="4" lg="4" xl="4">
                    <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                      <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning" />
                        <i className="lnr-laptop-phone text-dark opacity-8" />
                      </div>
                      <div className="widget-chart-content">
                        <div className="widget-subheading">Approx. Commission Earned</div>
                        <div className="widget-numbers">
                          <CountUp
                            start={0}
                            end={m.commissionEarned}
                            separator=","
                            decimals={0}
                            decimal="."
                            prefix="$"
                            useEasing={true}
                            suffix=""
                            duration={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider m-0 d-md-none d-sm-block" />
                  </Col>
                  <Col sm="6" md="2" lg="4" xl="2">
                    <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                      <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning" />
                        <i className="lnr-laptop-phone text-dark opacity-8" />
                      </div>
                      <div className="widget-chart-content">
                        <div className="widget-subheading">Total Savings</div>
                        <div className="widget-numbers">
                          <CountUp
                            start={0}
                            end={m.totalSavings}
                            separator=","
                            decimals={0}
                            decimal="."
                            prefix="$"
                            useEasing={true}
                            suffix=""
                            duration={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider m-0 d-md-none d-sm-block" />
                  </Col>
                  <Col sm="6" md="2" lg="4" xl="2">
                    <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                      <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning" />
                        <i className="lnr-laptop-phone text-dark opacity-8" />
                      </div>
                      <div className="widget-chart-content">
                        <div className="widget-subheading">Average Percent Saved</div>
                        <div className="widget-numbers">
                          <CountUp
                            start={0}
                            end={Math.round(m.averageSavingPercent * 100)}
                            separator=","
                            decimals={0}
                            decimal="."
                            prefix=""
                            useEasing={true}
                            suffix="%"
                            duration={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider m-0 d-md-none d-sm-block" />
                  </Col>
                  <Col sm="6" md="2" lg="4" xl="2">
                    <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                      <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning" />
                        <i className="lnr-laptop-phone text-dark opacity-8" />
                      </div>
                      <div className="widget-chart-content">
                        <div className="widget-subheading">App Claims</div>
                        <div className="widget-numbers">
                          <CountUp
                            start={0}
                            end={m.appClaims}
                            separator=","
                            decimals={0}
                            decimal="."
                            prefix=""
                            useEasing={true}
                            suffix=""
                            duration={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider m-0 d-md-none d-sm-block" />
                  </Col>
                  <Col sm="6" md="2" lg="4" xl="2">
                    <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                      <div className="icon-wrapper rounded-circle">
                        <div className="icon-wrapper-bg opacity-10 bg-warning" />
                        <i className="lnr-laptop-phone text-dark opacity-8" />
                      </div>
                      <div className="widget-chart-content">
                        <div className="widget-subheading">Non App Claims</div>
                        <div className="widget-numbers">
                          <CountUp
                            start={0}
                            end={m.nonAppClaims}
                            separator=","
                            decimals={0}
                            decimal="."
                            prefix=""
                            useEasing={true}
                            suffix=""
                            duration={4}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="divider m-0 d-md-none d-sm-block" />
                  </Col>
                </Row>
              </Card>
            ))
        )}
      </>
    );
  }
}
