import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, {Component, useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';
import CountUp from 'react-countup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {RouteComponentProps} from 'react-router';
import Select from 'react-select';
import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';
import Loading from '../../../../components/loading';
import {
  GetConsultResultResponse,
  HttpCommissionResult,
  HttpPartnerExtended,
} from '../../../../dataServices/app.generated';
import {AppService} from '../../../../dataServices/appService';
import {useEffectAsync} from '../../../../hooks/useEffectAsync';
import {FormatUtils} from '../../../../utils/formatUtils';
import {Utils} from '../../../../utils/utils';

type Props = RouteComponentProps<any> & {partner?: HttpPartnerExtended};

export function ConsultReport(props: Props) {
  const [noData, setNoData] = useState(false);
  const [gettingDownloadReport, setGettingDownloadReport] = useState(false);
  const [consultResult, setConsultResult] = useState<GetConsultResultResponse>(null);
  const [month, setMonth] = useState(moment().format('YYYY-MM'));
  const [months, setMonths] = useState<string[]>([]);

  useEffectAsync(async () => {
    await startDownloadReport();
  }, []);

  useEffectAsync(() => getDownloadReport(), [month]);

  async function startDownloadReport() {
    setGettingDownloadReport(true);

    const monthsResult = await AppService.adminPartnerHealthClient.getConsultsMonths({partnerId: props.partner.id}, {});

    if (!monthsResult || monthsResult.months.length === 0) {
      setGettingDownloadReport(false);
      setNoData(true);
    } else {
      setMonth(monthsResult.months[monthsResult.months.length - 1]);
      setMonths(monthsResult.months);
    }
  }

  useEffect(() => {}, [months]);
  async function getDownloadReport() {
    setGettingDownloadReport(true);
    const result = await AppService.adminPartnerHealthClient.getConsultsResult(
      {month, partnerId: props.partner.id},
      {}
    );
    setGettingDownloadReport(false);
    if (result) {
      setConsultResult(result);
    } else {
      setNoData(true);
    }
  }
  if (noData) {
    return (
      <Row>
        <Col md="5">
          <Card className="mb-3">
            <CardHeader className="card-header-tab">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                Consult Data
              </div>
              <div className="btn-actions-pane-right" style={{width: 200}}>
                <Select
                  isSearchable={false}
                  placeholder={'Select Month'}
                  value={month}
                  getOptionLabel={(a) => moment(a + '-01').format('MMMM YYYY')}
                  options={months.reverse()}
                  onChange={(m: any) => setMonth(month)}
                />
              </div>
            </CardHeader>
            <CardBody className="pt-2 pb-0">
              <div className="scroll-area-md">
                <h3>Sorry, there were no consults found for this period</h3>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
  if (gettingDownloadReport || !consultResult) {
    return <Loading />;
  }
  const daysData: {name: string; Consults: number}[] = [];
  for (const ind of Utils.range(moment(month + '-01').daysInMonth())) {
    const key = moment(month + '-01')
      .set('date', ind + 1)
      .format('YYYY-MM-DD');
    const day = consultResult.byDay.find((a) => a.day === key);
    if (!day) {
      daysData.push({
        name: moment(month + '-01')
          .set('date', ind + 1)
          .format('Do'),
        Consults: 0,
      });
    } else {
      daysData.push({
        name: moment(month + '-01')
          .set('date', ind + 1)
          .format('Do'),
        Consults: day.total,
      });
    }
  }

  const dataset = {
    label: 'Consults',
    backgroundColor: '#ffa100',
    borderColor: '#ac6d00',
    borderWidth: 1,
    borderCapStyle: 'round' as const,
    data: daysData.map((a) => a.Consults),
  };

  /**/

  return (
    <>
      <Row>
        <Col>
          <Card className="mb-3">
            <CardHeader className="card-header-tab z-index-6">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
                Performance {moment(month + '-01').format('MMMM YYYY')}
              </div>
              <div className="btn-actions-pane-right" style={{width: 200, zIndex: 100000000}}>
                <Select
                  menuPosition={'absolute'}
                  value={month}
                  getOptionLabel={(a) => moment(a + '-01').format('MMMM YYYY')}
                  options={months.reverse()}
                  isSearchable={false}
                  placeholder={'Select Month'}
                  onChange={(m: any) => setMonth(m)}
                />
              </div>
            </CardHeader>
            <Row className="no-gutters">
              <Col sm="6" md="3" xl="3">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-warning" />
                    <i className="lnr-laptop-phone text-dark opacity-8" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Total Consults</div>
                    <div className="widget-numbers">
                      <CountUp
                        start={0}
                        end={consultResult.partner.total}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=""
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col sm="6" md="3" xl="3">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-9 bg-danger" />
                    <i className="lnr-graduation-hat text-white" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Total Not Started</div>
                    <div className="widget-numbers">
                      <CountUp
                        start={0}
                        end={consultResult.partner.totalNotStarted}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=""
                        duration={3}
                      />
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col sm="12" md="3" xl="3">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-9 bg-success" />
                    <i className="lnr-apartment text-white" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Total Payment Held</div>
                    <div className="widget-numbers text-success">
                      <CountUp
                        start={0}
                        end={consultResult.partner.totalHeld}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=""
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col sm="12" md="3" xl="3">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-9 bg-success" />
                    <i className="lnr-apartment text-white" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Total Charged</div>
                    <div className="widget-numbers text-success">
                      <CountUp
                        start={0}
                        end={consultResult.partner.totalCharged}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=""
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {/*
              <CardFooter className="text-center d-block p-3">
                <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                  <span className="mr-2 opacity-7">
                    <Ionicon color="#ffffff" icon="ios-analytics-outline" beat={true} />
                  </span>
                  <span className="mr-1">View Complete Report</span>
                </Button>
              </CardFooter>
*/}
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <Card className="mb-3">
            <CardHeader className="card-header-tab">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                Top Performing Cards
              </div>
            </CardHeader>
            <CardBody className="pt-2 pb-0">
              <div className="scroll-area-md shadow-overflow">
                <PerfectScrollbar>
                  <Table hover striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Group Code</th>
                        <th>Member Id</th>
                        <th>Total Consults</th>
                        <th>Total Charged Consults</th>
                        <th>Total Async</th>
                        <th>Total Non Async</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consultResult.byCard.map((item) => (
                        <tr key={item.groupCode + ' ' + item.memberId}>
                          <td>{item.groupCode}</td>
                          <td>{item.memberId}</td>
                          <td>{item.total}</td>
                          <td>{item.totalCharged}</td>
                          <td>{item.totalGroupedModality.filter((e) => e.modality === 'async').length}</td>
                          <td>{item.totalGroupedModality.filter((e) => e.modality !== 'async').length}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </PerfectScrollbar>
              </div>
            </CardBody>
          </Card>
          <Card className="mb-3">
            <CardHeader className="card-header-tab">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                Top Reason Codes
              </div>
            </CardHeader>
            <CardBody className="pt-2 pb-0">
              <div className="scroll-area-md shadow-overflow">
                <PerfectScrollbar>
                  <Table hover striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Reason</th>
                        <th>Total Consults</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consultResult.partner.totalGroupedReason.map((item) => (
                        <tr key={item.reasonCode}>
                          <td>{item.reasonCode}</td>
                          <td>{item.count}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </PerfectScrollbar>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md={'6'}>
          <Card className="main-card mb-3">
            <CardHeader className="card-header-tab">
              <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                <i className="header-icon lnr-briefcase mr-3 text-muted opacity-6" />
                Consults In {moment(month + '-01').format('MMMM')}
              </div>
            </CardHeader>
            <CardBody>
              <Bar
                data={{
                  labels: Utils.range(moment(month + '-01').daysInMonth()).map((a) => a + 1 + ''),
                  datasets: [dataset],
                }}
                width={100}
                height={50}
                options={{
                  tooltips: {mode: 'index', intersect: false},
                  legend: {display: false},
                  responsive: true,
                  scales: {yAxes: [{stacked: true}]},
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
