import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {StateSwitch} from '../../../components/stateSwitch';
import {HttpPartnerExtended} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore} from '../../../store/main/store';
import {PartnerAccountDetails, PartnerCreateAccountDetailsStep} from './steps/accountDetailsStep';
import {CardDetails, CardDetailsStep} from './steps/cardDetailsStep';

type Props = RouteComponentProps<{partnerId: string}> & {};
type State = {
  steps: {
    type: 'card' | 'account';
    title: string;
  }[];
  step: number;

  accountDetails?: PartnerAccountDetails;
  cardDetails?: CardDetails;
  parentPartner: HttpPartnerExtended;
};

export class CardCreate extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      step: 0,
      steps: [
        {
          type: 'card',
          title: 'Card',
        },
        {
          type: 'account',
          title: 'Account',
        },
      ],
      parentPartner: null,
    };
  }

  async componentDidMount() {
    await this.getPartner();
  }

  private async getPartner() {
    this.setState({parentPartner: null});
    const result = await AppService.adminPartnerClient.startCreateCard(
      {parentPartnerId: this.props.match.params.partnerId},
      {
        ...MainStore.handleError(404, () => {
          this.props.history.push('/');
        }),
      }
    );
    if (result) {
      this.setState({
        parentPartner: result.parentPartner,

        cardDetails: {
          companyName: '',
          urlSlug: '',
          groupCode: result.groupCode,
          memberId: result.memberId,
        },
        accountDetails: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          contactType: 'other',
          email: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
        },
      });
    }
  }

  private getWizardStep(index: number) {
    if (index === this.state.step) {
      return 'doing';
    }
    if (index < this.state.step) {
      return 'done';
    }
    return 'todo';
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle heading="Card Creation" subheading="Easily create new cards" icon="lnr-magic-wand text-primary" />
        <Row>
          <Col md="12">
            {this.state.parentPartner ? (
              <Card className="main-card mb-3">
                <CardBody>
                  <div className="forms-wizard-vertical">
                    <ol className="forms-wizard">
                      {this.state.steps.map((s, i) => (
                        <li className={`form-wizard-step-${this.getWizardStep(i)}`} key={i} value={i}>
                          <em>{i + 1}</em>
                          <span>{s.title}</span>
                        </li>
                      ))}
                    </ol>
                    <div className="form-wizard-content">
                      <StateSwitch state={() => this.state.step}>
                        {{
                          0: () => (
                            <CardDetailsStep
                              cardDetails={this.state.cardDetails}
                              setCardDetails={(cardDetails) => this.setState({cardDetails})}
                            />
                          ),
                          1: () => (
                            <PartnerCreateAccountDetailsStep
                              parentPartner={this.state.parentPartner}
                              next={this.validateStep}
                              accountDetails={this.state.accountDetails}
                              setAccountDetails={(accountDetails) => this.setState({accountDetails})}
                            />
                          ),
                        }}
                      </StateSwitch>
                    </div>
                    <div className="divider" />
                    <div className="clearfix">
                      <div>
                        <Button
                          color="secondary"
                          className="btn-shadow float-left btn-wide btn-pill"
                          outline
                          style={this.state.step > 0 ? {} : {display: 'none'}}
                          onClick={() => this.setState({step: this.state.step - 1})}
                        >
                          Previous
                        </Button>

                        <PromiseButton
                          color="primary"
                          className="btn-shadow btn-wide float-right btn-pill btn-hover-shine"
                          onClick={this.validateStep}
                        >
                          {this.state.step < this.state.steps.length - 1 ? 'Next' : 'Done'}
                        </PromiseButton>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
    );
  }

  private validateStep = async () => {
    switch (this.state.step) {
      case 0:
        if (this.state.cardDetails.urlSlug.length > 0 && this.state.cardDetails.urlSlugValid !== 1) {
          return;
        }
        break;
      case 1:
        if (!this.state.accountDetails.accountId) {
          if (!this.state.accountDetails.firstName) {
            return false;
          }
          if (!this.state.accountDetails.lastName) {
            return false;
          }
          if (!this.state.accountDetails.email) {
            return false;
          }
        }
        break;
    }
    if (this.state.step === 1) {
      const result = await AppService.adminPartnerClient.createCard(
        {
          parentPartnerId: this.state.parentPartner.id,
          groupCode: this.state.cardDetails.groupCode,
          memberId: this.state.cardDetails.memberId,
          accountDetails: this.state.accountDetails,
          card: {
            companyName: this.state.cardDetails.companyName,
            urlSlug: this.state.cardDetails.urlSlug,
          },
        },
        {
          '400': (e) => {
            alert(e.error);
          },
        }
      );
      if (result) {
        this.props.history.push(`/card/details/${result.card.id}`);
      }
    } else {
      this.setState({step: this.state.step + 1});
    }
  };
}
