import React, {Component} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {PromiseButton} from '../components/promiseButton';

type Props = {
  text: string;
  title: string;
  close: () => void;
  onSuccess: () => Promise<any>;
};

type State = {};

export class ConfirmModal extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};
  }

  render() {
    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={this.props.close}>{this.props.title}</ModalHeader>
        <ModalBody>
          <h4 style={{marginTop: 10}}>{this.props.text}</h4>
        </ModalBody>
        <ModalFooter>
          <PromiseButton onClick={this.props.onSuccess} color={'warning'}>
            Yes
          </PromiseButton>
        </ModalFooter>
      </Modal>
    );
  }
}
