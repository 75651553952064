import {action, observable} from 'mobx';
import {persist} from 'mobx-persist';
import {toast} from 'react-toastify';
import {HttpAdminUser} from '../../dataServices/app.generated';

export class MainStore {
  @persist
  @observable
  jwt?: string;

  @persist('object')
  @observable
  user?: HttpAdminUser;

  @observable
  error?: string;

  @action
  setJWT(jwt: string | undefined): void {
    this.jwt = jwt;
  }

  @action
  setUser(user: HttpAdminUser | undefined): void {
    this.user = user;
  }

  @action
  logout() {
    this.setJWT(undefined);
    this.setUser(undefined);
  }

  @action
  setError(error: string) {
    this.error = error;
    toast(error, {type: 'error'});
  }

  static handleError = <T extends number>(code: T, callback?: () => void) =>
    ({
      [code]: (response: {error: string}) => {
        mainStore.setError(response.error);
        callback && callback();
      },
    } as {[key in T]: (r: {error: string}) => void});
}

export const mainStore = new MainStore();
export type MainStoreProps = {mainStore?: MainStore};
export const mainStoreDefaultProps = {mainStore: (null as unknown) as MainStore};
export const MainStoreName = 'mainStore';
