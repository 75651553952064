import React, {Fragment} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {AccountDetails} from './details';
import {AccountSearch} from './search';

// Layout

// Theme Options

export const AccountPages = ({match}: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/search/:query?`} component={AccountSearch} />
          <Route path={`${match.url}/details/:accountId`} component={AccountDetails} />
        </div>
      </div>
    </div>
  </Fragment>
);
