import React, {FC, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {BigLoading} from '../../components/bigLoading';
import {Datepicker} from '../../components/DatePicker/DatePicker';
import '../../components/healthTable.css';
import {UsersTableItem} from './usersTableItem';
import {HttpAdminHealthUser} from '../../../../dataServices/app.generated';

interface UsersTableProps {
  usersList: HttpAdminHealthUser[];
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  onQuery: () => void;
  loading: boolean;
  memberId: string;
  setMemberId: (val: string) => void;
  groupCode: string;
  setGroupCode: (val: string) => void;
}

export const UsersTable: FC<UsersTableProps> = (props) => {
  const history = useHistory();

  const onNavUser = (id: string) => {
    history.push('/health/user/' + id);
  };

  const usersList = useMemo(() => {
    return props.usersList.map((user) => (
      <UsersTableItem key={user.id} {...user} onNavUser={() => onNavUser(user.id)} />
    ));
  }, [props.usersList]);

  const onChangeDate = (selectedDates: Date[]) => {
    props.setStartDate(selectedDates[0]);
    selectedDates.length === 1 ? props.setEndDate(null) : props.setEndDate(selectedDates[1]);
  };

  return (
    <div className={'HealthTable'}>
      <header className={'HealthTable__header'}>
        <h2>Users</h2>
        <input
          className={'HealthTable__input'}
          value={props.groupCode}
          onChange={(e) => props.setGroupCode(e.target.value)}
          placeholder={'Group Code'}
        />
        <input
          className={'HealthTable__input'}
          value={props.memberId}
          onChange={(e) => props.setMemberId(e.target.value)}
          placeholder={'Member ID'}
        />
        <Datepicker value={[props.startDate, props.endDate]} onChange={onChangeDate} />
        <button className={'HealthTable__searchBtn'} onClick={props.onQuery}>
          <svg fill="currentColor" viewBox="0 0 16 16">
            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
          </svg>
          Search
        </button>
      </header>
      <div className={'HealthTable__tableContainer'}>
        <table className={'HealthTable__table'}>
          <thead>
            <tr>
              <th style={{paddingLeft: '1.25rem'}}>
                <div>First Name</div>
              </th>
              <th>
                <div>Group Code</div>
              </th>
              <th>
                <div>Member ID</div>
              </th>
              <th>
                <div>Date Created</div>
              </th>
              <th style={{paddingRight: '1.25rem'}}>
                <div>Dependents</div>
              </th>
            </tr>
          </thead>
          <tbody>{usersList}</tbody>
        </table>
        {usersList.length === 0 && !props.loading && (
          <p className={'HealthTable__empty'}>Sorry, no users could be found.</p>
        )}
        {props.loading && (
          <div className={'HealthTable__loadingContainer'}>
            <BigLoading loading />
          </div>
        )}
      </div>
    </div>
  );
};
