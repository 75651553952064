import React, {FC} from 'react';
import {ZipnosisVisitState} from '../../../../dataServices/app.generated';
import {format} from 'date-fns';

interface VisitsTableItemProps {
  onNavVisit: () => void;
  zipVisitId: number;
  firstName: string;
  reasonCode: string;
  status: ZipnosisVisitState;
  dateCreated: string;
}

export const VisitsTableItem: FC<VisitsTableItemProps> = (props) => {
  return (
    <tr onClick={props.onNavVisit}>
      <td style={{paddingLeft: '1.25rem'}}>
        <div>{props.zipVisitId}</div>
      </td>
      <td>
        <div>{props.firstName}</div>
      </td>
      <td>
        <div>{props.reasonCode}</div>
      </td>
      <td>
        <div>{props.status}</div>
      </td>
      <td style={{paddingRight: '1.25rem'}}>
        <div>{format(new Date(props.dateCreated), 'P')}</div>
      </td>
    </tr>
  );
};
