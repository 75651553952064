/* This file was generated by https://github.com/dested/serverless-client-builder */
/* eslint-disable */
/* tslint:disable */
import {BaseClient, ControllerOptions} from './baseClient';
declare type ObjectId = string;

export class AdminAccountClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async updateAccount<TPromise = GetAccountResponse>(
    model: UpdateAccountsRequest,
    handle: {
      200?: (result: GetAccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchAccounts<TPromise = SearchAccountsResponse>(
    model: SearchAccountsRequest,
    handle: {
      200?: (result: SearchAccountsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAccount<TPromise = GetAccountResponse>(
    model: GetAccountRequest,
    handle: {
      200?: (result: GetAccountResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/:accountId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'accountId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':accountId', model.accountId).replace('{accountId}', model.accountId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async activateAccount<TPromise = GetAccountResponse>(
    model: GetAccountRequest,
    handle: {
      200?: (result: GetAccountResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/account/:accountId/activate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      url = url.replace(':accountId', model.accountId).replace('{accountId}', model.accountId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminClaimClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getUnfoundClaims<TPromise = GetUnfoundClaimsResponse>(
    model: GetUnfoundClaimsRequest,
    handle: {
      200?: (result: GetUnfoundClaimsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/unfound?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resolveClaim<TPromise = SuccessResponse>(
    model: ResolveClaimRequest,
    handle: {
      200?: (result: SuccessResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/resolve-unfound?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPayoutRequest<TPromise = ResolveClaimResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: ResolveClaimResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/payout/requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async fulfillPayoutRequest<TPromise = SuccessResponse>(
    model: FulfillPayoutRequestRequest,
    handle: {
      200?: (result: SuccessResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/payout/fulfill?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResultMonths<TPromise = AdminMonthReportMonthsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: AdminMonthReportMonthsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/reports/commission-result-months?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResult<TPromise = AdminMonthReportResponse>(
    model: AdminMonthReportRequest,
    handle: {
      200?: (result: AdminMonthReportResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/claim/reports/commission-result?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = AdminLoginResponse>(
    model: AdminLoginRequest,
    handle: {200?: (result: AdminLoginResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async report<TPromise = AdminReportResponse>(
    model: VoidRequest,
    handle: {200?: (result: AdminReportResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async generateBinPcnReport<TPromise = AdminStringReportResponse>(
    model: AdminGenerateBinPcnReportRequest,
    handle: {
      200?: (result: AdminStringReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/bin-pcn-report?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminDashboardClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getClaimsByDay<TPromise = GetClaimsByDayResponse>(
    model: GetClaimsByDayRequest,
    handle: {
      200?: (result: GetClaimsByDayResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/dashboard/claims-by-day?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async slugsByDay<TPromise = SlugsByDayResponse>(
    model: VoidRequest,
    handle: {200?: (result: SlugsByDayResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/dashboard/slugs-by-day?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminMarketingClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async allMaterials<TPromise = GetAllMarketingResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetAllMarketingResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/materials?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addMaterials<TPromise = AddMaterialResponse>(
    model: AddMaterialRequest,
    handle: {200?: (result: AddMaterialResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/material?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteMaterials<TPromise = VoidResponse>(
    model: MaterialRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/marketing/material?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminPartnerClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async createPartner<TPromise = GetPartnerResponse>(
    model: CreatePartnersRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async breakOffPartner<TPromise = GetPartnerResponse>(
    model: BreakPartnerOffRequestRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/break-off?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartner<TPromise = GetPartnerResponse>(
    model: UpdatePartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllAmountsDue<TPromise = GetAllAmountsDueResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetAllAmountsDueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/all-amounts-due?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async linkStripe<TPromise = LinkStripeResponse>(
    model: LinkStripeRequest,
    handle: {
      200?: (result: LinkStripeResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/link-stripe?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resendStripeLink<TPromise = VoidResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/resend-stripe-link?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateCard<TPromise = GetCardResponse>(
    model: UpdateCardRequest,
    handle: {
      200?: (result: GetCardResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addManualDebit<TPromise = GetLedgerResponse>(
    model: AddManualDebitRequest,
    handle: {
      200?: (result: GetLedgerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/manual-debit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addManualCredit<TPromise = GetLedgerResponse>(
    model: AddManualDebitRequest,
    handle: {
      200?: (result: GetLedgerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/manual-credit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteCard<TPromise = VoidResponse>(
    model: CardRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card/:cardId?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      url = url.replace(':cardId', model.cardId).replace('{cardId}', model.cardId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deletePartner<TPromise = VoidResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/partner/:partnerId?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      url = url.replace(':partnerId', model.partnerId).replace('{partnerId}', model.partnerId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendToDocusign<TPromise = GetPartnerResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/docusign?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createCard<TPromise = GetCardResponse>(
    model: CreateCardRequest,
    handle: {
      200?: (result: GetCardResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startCreatePartner<TPromise = StartCreatePartnersResponse>(
    model: StartCreatePartnersRequest,
    handle: {
      200?: (result: StartCreatePartnersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/start?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startCreateCard<TPromise = StartCreatePartnersResponse>(
    model: StartCreatePartnersRequest,
    handle: {
      200?: (result: StartCreatePartnersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner//card/start?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validatePartnerNameUnique<TPromise = ValidateUniqueResponse>(
    model: ValidatePartnerNameUniqueRequest,
    handle: {
      200?: (result: ValidateUniqueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/validate-partner-name?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validateUrlSlugUnique<TPromise = ValidateUniqueResponse>(
    model: ValidateUrlSlugUniqueRequest,
    handle: {
      200?: (result: ValidateUniqueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/validate-url-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchPartners<TPromise = SearchPartnersResponse>(
    model: SearchPartnersRequest,
    handle: {
      200?: (result: SearchPartnersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartner<TPromise = GetPartnerResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/:partnerId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'partnerId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':partnerId', model.partnerId).replace('{partnerId}', model.partnerId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCard<TPromise = GetCardResponse>(
    model: GetCardRequest,
    handle: {
      200?: (result: GetCardResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card/:cardId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'cardId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':cardId', model.cardId).replace('{cardId}', model.cardId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReferrals<TPromise = GetReferralPartnersResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetReferralPartnersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/referrals?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPayouts<TPromise = GetPayoutsResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPayoutsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/payouts?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllPartnerRequests<TPromise = AdminPartnerRequestsResponse>(
    model: PageRequest,
    handle: {
      200?: (result: AdminPartnerRequestsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/partner-requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async adjudicatePartnerRequest<TPromise = AdminAdjudicatePartnerResponse>(
    model: AdminAdjudicatePartnerRequest,
    handle: {
      200?: (result: AdminAdjudicatePartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/partner-requests?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async makePharmacist<TPromise = GetPartnerResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/make-pharmacist?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientUsers<TPromise = GetClientUsersResponse>(
    model: GetClientUsersRequest,
    handle: {
      200?: (result: GetClientUsersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client-users?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClient<TPromise = GetHealthClientResponse>(
    model: GetHealthClientRequest,
    handle: {
      200?: (result: GetHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createClient<TPromise = GetClientResponse>(
    model: CreateClientRequest,
    handle: {
      200?: (result: GetClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createClientUser<TPromise = GetClientUserResponse>(
    model: CreateClientUserRequest,
    handle: {
      200?: (result: GetClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async activateClientUser<TPromise = GetClientUserResponse>(
    model: ActivateClientUserRequest,
    handle: {
      200?: (result: GetClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/activate-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deactivateClientUser<TPromise = GetClientUserResponse>(
    model: ActivateClientUserRequest,
    handle: {
      200?: (result: GetClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/deactivate-client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setClaimReconcileRule<TPromise = VoidResponse>(
    model: SetClaimReconcileRuleRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/claim-reconcile-rule?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async autoSignDocument<TPromise = GetPartnerResponse>(
    model: AutoSignDocumentRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/auto-sign-document?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async cardOrderValidation<TPromise = CardOrderValidationResponse>(
    model: CardOrderValidationRequest,
    handle: {
      200?: (result: CardOrderValidationResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllCardOrders<TPromise = GetAllCardOrdersResponse>(
    model: GetAllCardOrdersRequest,
    handle: {
      200?: (result: GetAllCardOrdersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/all-orders?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerCardOrder<TPromise = GetPartnerCardOrderResponse>(
    model: GetPartnerCardOrderRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async markPartnerCardOrderFulfilled<TPromise = GetPartnerCardOrderResponse>(
    model: GetPartnerCardOrderRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/fulfill?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async uploadCardOrderProof<TPromise = GetPartnerCardOrderResponse>(
    model: UploadCardOrderProofRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/upload-proof?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCardOrderImage<TPromise = VoidResponse>(
    model: GetCardOrderImageRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/get-image?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerCardOrderTracking<TPromise = GetPartnerCardOrderResponse>(
    model: UpdatePartnerCardOrderTrackingRequest,
    handle: {
      200?: (result: GetPartnerCardOrderResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/card-order/tracking?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminPartnerHealthClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async makeHealthPartner<TPromise = GetPartnerResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/make-health-partner?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthConfig<TPromise = HealthConfigResponse>(
    model: GetCardRequest,
    handle: {
      200?: (result: HealthConfigResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health-config?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateHealthConfig<TPromise = HealthConfigResponse>(
    model: UpdateHealthConfigRequest,
    handle: {
      200?: (result: HealthConfigResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health-config?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateCommission<TPromise = GetPartnerResponse>(
    model: UpdatePartnerHealthCommissionRequest,
    handle: {
      200?: (result: GetPartnerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/update-commission?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReferrals<TPromise = GetReferralPartnersResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetReferralPartnersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/referrals?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getConsultsMonths<TPromise = AccountCommissionResultMonthsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: AccountCommissionResultMonthsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/consults-months?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getConsultsResult<TPromise = GetConsultResultResponse>(
    model: AccountMonthReportRequest,
    handle: {
      200?: (result: GetConsultResultResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/reports/consults?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthAdminClientUsers<TPromise = GetAdminHealthClientUsersResponse>(
    model: GetAdminHealthClientUsersRequest,
    handle: {
      200?: (result: GetAdminHealthClientUsersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/clientUsers?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthVisits<TPromise = GetHealthVisitsResponse>(
    model: GetHealthVisitsRequest,
    handle: {
      200?: (result: GetHealthVisitsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/visits?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthUsers<TPromise = GetHealthUsersResponse>(
    model: GetHealthUsersRequest,
    handle: {
      200?: (result: GetHealthUsersResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/users?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthIvrAnalytics<TPromise = GetHealthIvrAnalyticsResponse>(
    model: GetHealthIvrAnalyticsRequest,
    handle: {
      200?: (result: GetHealthIvrAnalyticsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/ivr-analytics?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReports<TPromise = GetHealthReportsResponse>(
    model: GetHealthReportsRequest,
    handle: {
      200?: (result: GetHealthReportsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsCompletedCalls<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/completedCalls?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsRedirectedCalls<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/redirectedCalls?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsUsersCreated<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/usersCreated?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsVisitsStarted<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/visitsStarted?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsVisitsCompleted<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/visitsCompleted?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsVisitsTimeCompleted<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/visitsTimeCompleted?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsPrescriptionsSent<TPromise = GetHealthReportsDataResponse>(
    model: GetHealthReportsDataRequest,
    handle: {
      200?: (result: GetHealthReportsDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/prescriptionsSent?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthVisitData<TPromise = GetHealthVisitDataResponse>(
    model: GetHealthVisitDataRequest,
    handle: {
      200?: (result: GetHealthVisitDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/visit?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAdminEDIMembers<TPromise = GetAdminEDIMembersResponse>(
    model: GetAdminEDIMembersRequest,
    handle: {
      200?: (result: GetAdminEDIMembersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/eligibility/members?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAdminEDIMember<TPromise = GetAdminEDIMemberResponse>(
    model: GetAdminEDIMemberRequest,
    handle: {
      200?: (result: GetAdminEDIMemberResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/eligibility/member?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateAdminEDIMember<TPromise = UpdateAdminEDIMemberResponse>(
    model: UpdateAdminEDIMemberRequest,
    handle: {
      200?: (result: UpdateAdminEDIMemberResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/eligibility/member?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAdminEDIMemberFamilyTree<TPromise = GetAdminEDIMemberTreeResponse>(
    model: GetAdminEDIMemberTreeRequest,
    handle: {
      200?: (result: GetAdminEDIMemberTreeResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/eligibility/family-tree?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsMedicineCabinet<TPromise = GetHealthReportsMedicineCabinetResponse>(
    model: GetHealthReportsMedicineCabinetRequest,
    handle: {
      200?: (result: GetHealthReportsMedicineCabinetResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/medicineCabinet?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsUsersByCard<TPromise = GetHealthReportsUsersByCardResponse>(
    model: GetHealthReportsUsersByCardRequest,
    handle: {
      200?: (result: GetHealthReportsUsersByCardResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/usersByCard?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsChargesByCard<TPromise = GetHealthReportsChargesByCardResponse>(
    model: GetHealthReportsUsersByCardRequest,
    handle: {
      200?: (result: GetHealthReportsChargesByCardResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/chargesByCard?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsTopPricedDrugs<TPromise = GetHealthReportsTopPricedDrugsResponse>(
    model: GetHealthReportsTopPricedDrugsRequest,
    handle: {
      200?: (result: GetHealthReportsTopPricedDrugsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/reports/topPricedDrugs?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientInvoices<TPromise = GetHealthClientInvoicesResponse>(
    model: GetHealthClientInvoicesRequest,
    handle: {
      200?: (result: GetHealthClientInvoicesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/invoices?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientInvoice<TPromise = GetHealthClientInvoiceResponse>(
    model: GetHealthClientInvoiceRequest,
    handle: {
      200?: (result: GetHealthClientInvoiceResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/invoice?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientRequests<TPromise = GetHealthClientRequestsResponse>(
    model: GetHealthClientRequestsRequest,
    handle: {
      200?: (result: GetHealthClientRequestsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/client-requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientRequest<TPromise = GetHealthClientRequestResponse>(
    model: GetHealthClientRequestRequest,
    handle: {
      200?: (result: GetHealthClientRequestResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/client-request?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateHealthClientRequest<TPromise = GetHealthClientRequestResponse>(
    model: UpdateHealthClientRequestRequest,
    handle: {
      200?: (result: GetHealthClientRequestResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/client-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async adjudicateHealthClientRequest<TPromise = GetHealthClientRequestResponse>(
    model: AdjudicateHealthClientRequestRequest,
    handle: {
      200?: (result: GetHealthClientRequestResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/health/adjudicate-client-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerKPIsPartners<TPromise = GetHealthPartnerKPIsPartnersResponse>(
    model: GetHealthPartnerKPIsPartnersRequest,
    handle: {
      200?: (result: GetHealthPartnerKPIsPartnersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/kpis/partners?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerKPIsPartner<TPromise = GetHealthPartnerKPIsPartnerResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetHealthPartnerKPIsPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/kpis/partner?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerKPIsReports<TPromise = GetHealthPartnerKPIsReportsResponse>(
    model: GetHealthPartnerKPIsReportsRequest,
    handle: {
      200?: (result: GetHealthPartnerKPIsReportsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/partner/health/kpis/reports?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AdminReportsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getDownloadsByPartner<TPromise = GetDownloadsByPartnerResponse>(
    model: GetDownloadsByPartnerRequest,
    handle: {
      200?: (result: GetDownloadsByPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/downloads-by-partner?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClaimsReport<TPromise = ClaimsReportResponse>(
    model: GetClaimsReportRequest,
    handle: {
      200?: (result: ClaimsReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/claims?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMasterClaimsReport<TPromise = MasterClaimReportResponse>(
    model: GetMasterClaimReportRequest,
    handle: {
      200?: (result: MasterClaimReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/master-claims?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllTimeClaimsReport<TPromise = AllTimeClaimsReportResponse>(
    model: GetAllTimeClaimsReportRequest,
    handle: {
      200?: (result: AllTimeClaimsReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/all-time-claims?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClaimsByDayReport<TPromise = GetClaimsByDayReportResponse>(
    model: GetClaimsDayReportRequest,
    handle: {
      200?: (result: GetClaimsByDayReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/claims-by-day?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClaimsResultReport<TPromise = GetClaimsResultReportResponse>(
    model: GetClaimsReportRequest,
    handle: {
      200?: (result: GetClaimsResultReportResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/admin/reports/claims-result-report?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class AnalyticsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async appOpen<TPromise = VoidResponse>(
    model: AppOpenRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/app-open?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async logEvent<TPromise = VoidResponse>(
    model: LogEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/log-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async start<TPromise = VoidResponse>(
    model: TelemetryStartRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/analytics/start?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class DrugClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async price<TPromise = PricingResponse>(
    model: PriceRequest,
    handle: {
      200?: (result: PricingResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugDetails<TPromise = GetDrugDetailsResponse>(
    model: GetDrugDetailsRequest,
    handle: {
      200?: (result: GetDrugDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/drug-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = GetDrugStructureResponse>(
    model: GetDrugStructureRequest,
    handle: {
      200?: (result: GetDrugStructureResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = SearchResponse>(
    model: SearchRequest,
    handle: {200?: (result: SearchResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async legacySearch<TPromise = LegacySearchResponse>(
    model: SearchRequest,
    handle: {
      200?: (result: LegacySearchResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug/legacy-search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class DrugV2Client extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async price<TPromise = PricingV2Response>(
    model: PriceV2Request,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = HttpDrugResultV2>(
    model: GetDrugStructureV2Request,
    handle: {
      200?: (result: HttpDrugResultV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugInformation<TPromise = DrugInformationV2>(
    model: GetDrugInformationV2Request,
    handle: {
      200?: (result: DrugInformationV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/drug-information?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = SearchResponseV2>(
    model: SearchRequest,
    handle: {200?: (result: SearchResponseV2) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucher<TPromise = GetVoucherResponse>(
    model: GetVoucherRequest,
    handle: {200?: (result: GetVoucherResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/drug-v2/voucher?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class ExternalApiClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async authenticate<TPromise = AuthenticateResponse>(
    model: AuthenticateRequest,
    handle: {
      200?: (result: AuthenticateResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/authenticate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchDrugs<TPromise = SearchDrugsResponse>(
    model: SearchDrugsRequest,
    handle: {
      200?: (result: SearchDrugsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async drugStructure<TPromise = DrugStructureResponse>(
    model: DrugStructureRequest,
    handle: {
      200?: (result: DrugStructureResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async drugInfo<TPromise = DrugInfoResponse>(
    model: DrugInfoRequest,
    handle: {
      200?: (result: DrugInfoResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/drug-info?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async priceDrug<TPromise = PriceDrugResponse>(
    model: PriceDrugRequest,
    handle: {
      200?: (result: PriceDrugResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchDrugsV2<TPromise = SearchDrugsV2Response>(
    model: SearchDrugsV2Request,
    handle: {
      200?: (result: SearchDrugsV2Response) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/v2/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async drugStructureV2<TPromise = DrugStructureV2Response>(
    model: DrugStructureRequest,
    handle: {
      200?: (result: DrugStructureV2Response) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/v2/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async priceDrugV2<TPromise = PriceDrugV2Response>(
    model: PriceDrugV2Request,
    handle: {
      200?: (result: PriceDrugV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/external/v2/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PartnerClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = AccountLoginResponse>(
    model: AccountLoginRequest,
    handle: {
      200?: (result: AccountLoginResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = AccountResponse>(
    model: VoidRequest,
    handle: {200?: (result: AccountResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerExtended<TPromise = GetPartnerSimpleResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerSimpleResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/partner/extended/:partnerId?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'partnerId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':partnerId', model.partnerId).replace('{partnerId}', model.partnerId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setStripeBankAccount<TPromise = AccountResponse>(
    model: SetStripeBankAccountRequest,
    handle: {
      200?: (result: AccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/bank-account?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resendStripeLink<TPromise = VoidResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/resend-stripe-link?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestW9<TPromise = RequestW9AccountResponse>(
    model: RequestW9AccountRequest,
    handle: {
      200?: (result: RequestW9AccountResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/w9?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResultMonths<TPromise = AccountCommissionResultMonthsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: AccountCommissionResultMonthsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/commission-result-months?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCommissionResult<TPromise = AccountCommissionResultResponse>(
    model: AccountMonthReportRequest,
    handle: {
      200?: (result: AccountCommissionResultResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/commission-result?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async slugsByDay<TPromise = SlugsByDayResponse>(
    model: SlugsByDayRequest,
    handle: {200?: (result: SlugsByDayResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/slugs-by-day?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getLedger<TPromise = GetLedgerResponse>(
    model: PartnerRequest,
    handle: {200?: (result: GetLedgerResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/ledger?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDownloadsByPartner<TPromise = GetDownloadsByPartnerResponse>(
    model: AccountMonthReportRequest,
    handle: {
      200?: (result: GetDownloadsByPartnerResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reports/downloads?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async forgotPassword<TPromise = VoidResponse>(
    model: AccountForgotPasswordRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/forgot-password?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async contactBroker<TPromise = VoidResponse>(
    model: PartnerContactBrokerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/contact-broker?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestPayout<TPromise = SuccessResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: SuccessResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/request-payout?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async ledgerDebitReport<TPromise = AdminStringReportResponse>(
    model: PartnerLedgerDebitReportRequest,
    handle: {
      200?: (result: AdminStringReportResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/ledger-debit-report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async isPayoutViable<TPromise = PayoutViableResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: PayoutViableResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/payout-viable?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resetPassword<TPromise = VoidResponse>(
    model: AccountResetPasswordRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/reset-password?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateAccount<TPromise = AccountResponse>(
    model: PartnerUpdateAccountsRequest,
    handle: {
      200?: (result: AccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/update-account?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createCard<TPromise = AccountResponse>(
    model: AccountCreateCardRequest,
    handle: {
      200?: (result: AccountResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/create-card?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerRequests<TPromise = PartnerRequestsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: PartnerRequestsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/partner-requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async invitePartner<TPromise = VoidResponse>(
    model: AccountInvitePartnerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/invite-partner?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async downloadMaterial<TPromise = VoidResponse>(
    model: DownloadMaterialRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/download-material?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMarketingMaterials<TPromise = GetAllMarketingResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetAllMarketingResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/marketing-materials?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthConfig<TPromise = GetHealthConfigResponse>(
    model: GetPartnerAndCardRequest,
    handle: {
      200?: (result: GetHealthConfigResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/health-config?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReferrals<TPromise = GetPartnerReferralsResponse>(
    model: GetPartnerRequest,
    handle: {
      200?: (result: GetPartnerReferralsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/referrals?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReports<TPromise = GetPartnerHealthReportsResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsTopReasons<TPromise = GetPartnerHealthReportsTopReasonsResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsTopReasonsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/topReasons?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsTopDrugs<TPromise = GetPartnerHealthReportsTopDrugsResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsTopDrugsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/topDrugs?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsAppDownloads<TPromise = GetPartnerHealthReportsChartDataResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsChartDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/appDownloads?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsVisitsCompleted<TPromise = GetPartnerHealthReportsChartDataResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsChartDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/visitsCompleted?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReportsTopStates<TPromise = GetPartnerHealthReportsTopStatesResponse>(
    model: GetPartnerHealthReportsRequest,
    handle: {
      200?: (result: GetPartnerHealthReportsTopStatesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/topStates?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClients<TPromise = GetPartnerHealthClientsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetPartnerHealthClientsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/clients?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClient<TPromise = GetPartnerHealthClientResponse>(
    model: GetPartnerHealthClientRequest,
    handle: {
      200?: (result: GetPartnerHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updatePartnerHealthClient<TPromise = GetPartnerHealthClientResponse>(
    model: UpdatePartnerHealthClientRequest,
    handle: {
      200?: (result: GetPartnerHealthClientResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClientUsers<TPromise = GetPartnerHealthClientUsersResponse>(
    model: GetPartnerHealthClientUsersRequest,
    handle: {
      200?: (result: GetPartnerHealthClientUsersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client-users?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createPartnerHealthClientUser<TPromise = GetPartnerHealthClientUserResponse>(
    model: CreatePartnerHealthClientUserRequest,
    handle: {
      200?: (result: GetPartnerHealthClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async activatePartnerHealthClientUser<TPromise = GetPartnerHealthClientUserResponse>(
    model: ActivatePartnerHealthClientUserRequest,
    handle: {
      200?: (result: GetPartnerHealthClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/activate-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deactivatePartnerHealthClientUser<TPromise = GetPartnerHealthClientUserResponse>(
    model: ActivatePartnerHealthClientUserRequest,
    handle: {
      200?: (result: GetPartnerHealthClientUserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/deactivate-client-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetails<TPromise = WebBrokerDetailsResponse>(
    model: WebBrokerDetailsRequest,
    handle: {
      200?: (result: WebBrokerDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/web/brokerDetails?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitBrokerContactForm<TPromise = VoidResponse>(
    model: WebBrokerContactFormRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/web/brokerContactForm?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validateUrlSlugUnique<TPromise = ValidateUniqueResponse>(
    model: ValidateUrlSlugUniqueRequest,
    handle: {
      200?: (result: ValidateUniqueResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/validate-url-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitHealthGroupRequest<TPromise = SubmitHealthGroupRequestResponse>(
    model: SubmitHealthGroupRequestRequest,
    handle: {
      200?: (result: SubmitHealthGroupRequestResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/group-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerCards<TPromise = GetPartnerCardsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetPartnerCardsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/cards?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerTasks<TPromise = GetPartnerTasksResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetPartnerTasksResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/tasks?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async completePartnerTasks<TPromise = GetPartnerTasksResponse>(
    model: CompletePartnerTaskRequest,
    handle: {
      200?: (result: GetPartnerTasksResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/tasks?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async completePartnerTaskRank<TPromise = VoidResponse>(
    model: CompletePartnerTaskRankRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/tasks/complete-rank?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startCardOrderPayment<TPromise = StartCardOrderPaymentResponse>(
    model: StartCardOrderPaymentRequest,
    handle: {
      200?: (result: StartCardOrderPaymentResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/card-order/start?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async confirmCardOrderPayment<TPromise = ConfirmCardOrderPaymentResponse>(
    model: ConfirmCardOrderPaymentRequest,
    handle: {
      200?: (result: ConfirmCardOrderPaymentResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/card-order/confirm?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClientRequests<TPromise = GetPartnerHealthClientRequestsResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: GetPartnerHealthClientRequestsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client-requests?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPartnerHealthClientRequest<TPromise = GetPartnerHealthClientRequestResponse>(
    model: GetPartnerHealthClientRequestRequest,
    handle: {
      200?: (result: GetPartnerHealthClientRequestResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/client-request?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthPartnerDownloadsByCard<TPromise = GetHealthPartnerDownloadsByCardResponse>(
    model: GetHealthPartnerDownloadsByCardRequest,
    handle: {
      200?: (result: GetHealthPartnerDownloadsByCardResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/reports/downloadsByCard?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestCHContract<TPromise = RequestPartnerContractResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: RequestPartnerContractResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/contract/request-contract?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestCHW9Form<TPromise = RequestPartnerContractResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: RequestPartnerContractResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/health/contract/request-w9?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async checkPartnerContractsSigned<TPromise = CheckPartnerContractsSignedResponse>(
    model: PartnerRequest,
    handle: {
      200?: (result: CheckPartnerContractsSignedResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/partner/contracts/is-signed?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PharmacyClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getPharmacies<TPromise = GetPharmacyResponse>(
    model: GetPharmacyRequest,
    handle: {200?: (result: GetPharmacyResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/pharmacy/pharmacies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PortalAnalyticsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = VoidResponse>(
    model: PortalAnalyticsLoginRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal-analytics/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class PortalClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = PortalLoginResponse>(
    model: PortalLoginRequest,
    handle: {
      200?: (result: PortalLoginResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal/login?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientUser<TPromise = GetClientUserAndDetailsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetClientUserAndDetailsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal/client-user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async resetPassword<TPromise = VoidResponse>(
    model: AccountResetPasswordRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal/reset-password?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async forgotPassword<TPromise = VoidResponse>(
    model: PortalForgotPasswordRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/portal/forgot-password?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class ShareClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async messagePrice<TPromise = VoidResponse>(
    model: MessagePriceRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async emailPrice<TPromise = VoidResponse>(
    model: EmailPriceRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/email-price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async messageApp<TPromise = VoidResponse>(
    model: MessageAppRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-app?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitContactDetails<TPromise = VoidResponse>(
    model: SubmitContactDetailsRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/submit-contact-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async messageAppCustomized<TPromise = VoidResponse>(
    model: MessageAppCustomizedRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/message-app-customized?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async shareCHFlyer<TPromise = VoidResponse>(
    model: ShareCHFlyerRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/share/share-ch-flyer?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getBrokerDetails<TPromise = GetBrokerDetailsResponse>(
    model: GetBrokerDetailsRequest,
    handle: {
      200?: (result: GetBrokerDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/broker-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetailsBySlug<TPromise = GetBrokerDetailsResponse>(
    model: GetBrokerDetailsSlugRequest,
    handle: {
      200?: (result: GetBrokerDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/broker-details-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async register<TPromise = LightJwtResponse>(
    model: RegisterRequest,
    handle: {
      200?: (result: LightJwtResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/register?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validate<TPromise = JwtGetUserResponse>(
    model: ValidateRequest,
    handle: {
      200?: (result: JwtGetUserResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPushToken<TPromise = UserResponse>(
    model: SetPushTokenRequest,
    handle: {
      200?: (result: UserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/push-token?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPharmacyPreferences<TPromise = UserResponse>(
    model: SetPharmacyPreferencesRequest,
    handle: {
      200?: (result: UserResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/pharmacy-preferences?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = JwtGetUserResponse>(
    model: GetUserRequest,
    handle: {
      200?: (result: JwtGetUserResponse) => void;
      500?: (result: string) => void;
      410: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 410) {
        await handle[410](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async watchDrug<TPromise = WatchDrugResponse>(
    model: WatchDrugRequest,
    handle: {
      200?: (result: WatchDrugResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/watch-drug?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async geoPing<TPromise = VoidResponse>(
    model: RadarGeoRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/geo-ping?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async unwatchDrug<TPromise = UnwatchDrugResponse>(
    model: UnwatchDrugRequest,
    handle: {
      200?: (result: UnwatchDrugResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user/unwatch-drug?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserMailOrderClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async startMailOrderService<TPromise = StartMailOrderServiceResponse>(
    model: StartMailOrderServiceRequest,
    handle: {
      200?: (result: StartMailOrderServiceResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
      400: (result: {error: string}) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-mail-order/start-mail-order-service?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserV2Client extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getBrokerDetails<TPromise = GetBrokerDetailsV2Response>(
    model: GetBrokerDetailsV2Request,
    handle: {
      200?: (result: GetBrokerDetailsV2Response) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/broker-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetailsBySlug<TPromise = GetBrokerDetailsV2Response>(
    model: GetBrokerDetailsSlugV2Request,
    handle: {
      200?: (result: GetBrokerDetailsV2Response) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/broker-details-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = JwtGetUserV2Response>(
    model: VoidRequest,
    handle: {
      200?: (result: JwtGetUserV2Response) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async register<TPromise = LightJwtResponse>(
    model: RegisterV2Request,
    handle: {
      200?: (result: LightJwtResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/register?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async migrateUser<TPromise = JwtGetUserV2Response>(
    model: MigrateUserRequest,
    handle: {
      200?: (result: JwtGetUserV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/migrate-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPushToken<TPromise = VoidResponse>(
    model: SetPushTokenRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/push-token?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validate<TPromise = JwtGetUserV2Response>(
    model: ValidateRequest,
    handle: {
      200?: (result: JwtGetUserV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPharmacyPreferences<TPromise = UserV2Response>(
    model: SetPharmacyPreferencesRequest,
    handle: {200?: (result: UserV2Response) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/pharmacy-preferences?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCabinet<TPromise = GetCabinetResponse>(
    model: GetCabinetRequest,
    handle: {200?: (result: GetCabinetResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/cabinet?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async saveToCabinet<TPromise = GetCabinetResponse>(
    model: SaveToCabinetRequest,
    handle: {200?: (result: GetCabinetResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/cabinet?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteFromCabinet<TPromise = GetCabinetResponse>(
    model: DeleteFromCabinetRequest,
    handle: {200?: (result: GetCabinetResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/cabinet?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getShareCount<TPromise = GetShareCountResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetShareCountResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/share-count?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPrescyptiveDetails<TPromise = UserV2Response>(
    model: SetPrescyptiveDetailsRequest,
    handle: {200?: (result: UserV2Response) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2/set-prescyptive-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UserV2PharmacistClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async setPharmacistDetails<TPromise = UserV2Response>(
    model: SetPharmacistDetailsRequest,
    handle: {200?: (result: UserV2Response) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/setPharmacistDetails?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getRewardsReport<TPromise = PharmacistGetRewardsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: PharmacistGetRewardsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/rewards-report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getLedger<TPromise = GetLedgerResponse>(
    model: VoidRequest,
    handle: {200?: (result: GetLedgerResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/ledger?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async requestGiftCard<TPromise = GetLedgerResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetLedgerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/gift-card?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getStaticCards<TPromise = GetStaticCardsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetStaticCardsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/user-v2-pharmacist/static-cards?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class UtilsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getZipcode<TPromise = GetZipcodeResponse>(
    model: GetZipcodeRequest,
    handle: {
      200?: (result: GetZipcodeResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/zipcode?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createPartnerContact<TPromise = VoidResponse>(
    model: CreatePartnerContactRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/partner-contact?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getLatLng<TPromise = GetLatLngResponse>(
    model: GetLatLngRequest,
    handle: {
      200?: (result: GetLatLngResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/latlng?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getKrogerBrand<TPromise = GetKrogerBrandResponse>(
    model: GetKrogerBrandRequest,
    handle: {
      200?: (result: GetKrogerBrandResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/kroger?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async meta<TPromise = MetaResponse>(
    model: VoidRequest,
    handle: {200?: (result: MetaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/meta?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async partnerSlugRequest<TPromise = VoidResponse>(
    model: PartnerSlugRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/partner-slug-request?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitWebContactForm<TPromise = VoidResponse>(
    model: WebContactFormRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/web/contact?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitWebCalculatorAnalytics<TPromise = VoidResponse>(
    model: WebCalculatorRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/utils/web/calculator?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class WebhookClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async docusign<TPromise = VoidResponse>(
    model: DocusignWebhookPostRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/webhook/docusign?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthAnalyticsClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async appOpen<TPromise = VoidResponse>(
    model: HealthAppOpenRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/app-open?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async zipUrl<TPromise = HealthGetZipUrlResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: HealthGetZipUrlResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/zip-url?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async logEvent<TPromise = VoidResponse>(
    model: LogEventRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/log-event?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async start<TPromise = VoidResponse>(
    model: TelemetryStartRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/start?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async meta<TPromise = MetaResponse>(
    model: VoidRequest,
    handle: {200?: (result: MetaResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/analytics/meta?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthClientClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getMembersFiltered<TPromise = GetHealthClientMembersResponse>(
    model: GetHealthClientMembersRequest,
    handle: {
      200?: (result: GetHealthClientMembersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/members?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMembersAdvancedSearch<TPromise = GetHealthClientMembersResponse>(
    model: GetHealthClientMembersAdvancedSearchRequest,
    handle: {
      200?: (result: GetHealthClientMembersResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/members/advancedSearch?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMembersCSV<TPromise = GetHealthClientMembersCSVResponse>(
    model: GetHealthClientMembersCSVRequest,
    handle: {
      200?: (result: GetHealthClientMembersCSVResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/members/csv?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMember<TPromise = GetHealthClientMemberAndDependentsResponse>(
    model: GetHealthClientMemberRequest,
    handle: {
      200?: (result: GetHealthClientMemberAndDependentsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/member?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createMember<TPromise = GetHealthClientMemberResponse>(
    model: CreateHealthClientMemberRequest,
    handle: {
      200?: (result: GetHealthClientMemberResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/member?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createFamily<TPromise = VoidResponse>(
    model: CreateHealthClientFamilyRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/family?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateMember<TPromise = GetHealthClientMemberResponse>(
    model: UpdateHealthClientMemberRequest,
    handle: {
      200?: (result: GetHealthClientMemberResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/member?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteMember<TPromise = VoidResponse>(
    model: DeleteHealthClientMemberRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/member?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthReports<TPromise = GetHealthClientReportsResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsTopReasons<TPromise = GetHealthClientReportsTopReasonsResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsTopReasonsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/topReasons?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsTopDrugs<TPromise = GetHealthClientReportsTopDrugsResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsTopDrugsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/topDrugs?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsAppDownloads<TPromise = GetHealthClientReportsChartDataResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsChartDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/appDownloads?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsVisitsCompleted<TPromise = GetHealthClientReportsChartDataResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsChartDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/visitsCompleted?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsVisitsStarted<TPromise = GetHealthClientReportsChartDataResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsChartDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/visitsStarted?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsVisitsTimeCompleted<TPromise = GetHealthClientReportsChartDataResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsChartDataResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/visitsTimeCompleted?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getHealthClientReportsTopStates<TPromise = GetHealthClientReportsTopStatesResponse>(
    model: GetHealthClientReportsRequest,
    handle: {
      200?: (result: GetHealthClientReportsTopStatesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/reports/topStates?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendCorrespondence<TPromise = HealthClientSendCorrespondenceResponse>(
    model: HealthClientSendCorrespondenceRequest,
    handle: {
      200?: (result: HealthClientSendCorrespondenceResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/send-correspondence?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getImportReports<TPromise = GetHealthClientImportReportsResponse>(
    model: GetHealthClientImportReportsRequest,
    handle: {
      200?: (result: GetHealthClientImportReportsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/import-reports?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getImportReport<TPromise = GetHealthClientImportReportResponse>(
    model: GetHealthClientImportReportRequest,
    handle: {
      200?: (result: GetHealthClientImportReportResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/import-report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateClientUserDetails<TPromise = UpdateClientUserDetailsResponse>(
    model: UpdateClientUserDetailsRequest,
    handle: {
      200?: (result: UpdateClientUserDetailsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/client-user-details?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateClientBillingDetails<TPromise = UpdateClientBillingDetailsResponse>(
    model: UpdateClientBillingDetailsRequest,
    handle: {
      200?: (result: UpdateClientBillingDetailsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/client-billing?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientPaymentMethods<TPromise = GetClientPaymentMethodsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetClientPaymentMethodsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/client-payment-methods?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async verifyACHPaymentMethod<TPromise = VerifyACHPaymentMethodResponse>(
    model: VerifyACHPaymentMethodRequest,
    handle: {
      200?: (result: VerifyACHPaymentMethodResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/verify-ach-payment?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteCardPaymentMethod<TPromise = DeleteCardPaymentMethodRequest>(
    model: DeleteCardPaymentMethodRequest,
    handle: {
      200?: (result: DeleteCardPaymentMethodRequest) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/delete-card?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteBankAccountPaymentMethod<TPromise = DeleteBankAccountPaymentMethodRequest>(
    model: DeleteBankAccountPaymentMethodRequest,
    handle: {
      200?: (result: DeleteBankAccountPaymentMethodRequest) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/delete-bank-account?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createStripeCustomer<TPromise = CreateHealthClientStripeCustomerResponse>(
    model: CreateHealthClientStripeCustomerRequest,
    handle: {
      200?: (result: CreateHealthClientStripeCustomerResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/create-stripe-customer?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupCardPaymentMethod<TPromise = VoidResponse>(
    model: SetupClientPaymentMethodRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/card-payment-setup?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setupACHPaymentMethod<TPromise = VoidResponse>(
    model: SetupClientPaymentMethodRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/ach-payment-setup?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async payClientInvoice<TPromise = PayClientInvoiceResponse>(
    model: PayClientInvoiceRequest,
    handle: {
      200?: (result: PayClientInvoiceResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/pay-invoice?';

      const options = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientInvoices<TPromise = GetClientInvoicesResponse>(
    model: GetClientInvoicesRequest,
    handle: {
      200?: (result: GetClientInvoicesResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/invoices?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClientInvoice<TPromise = GetClientInvoiceResponse>(
    model: GetClientInvoiceRequest,
    handle: {
      200?: (result: GetClientInvoiceResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/invoice?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getSFTPCredentials<TPromise = GetSFTPCredentialsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetSFTPCredentialsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/sftp/credentials?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createSFTPCredentials<TPromise = CreateSFTPCredentialsResponse>(
    model: CreateSFTPCredentialsRequest,
    handle: {
      200?: (result: CreateSFTPCredentialsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/client/sftp/credentials?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthDrugV2Client extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async price<TPromise = PricingV2Response>(
    model: PriceV2Request,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/price?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async priceNoFilter<TPromise = PricingV2Response>(
    model: PriceNoFilterRequest,
    handle: {
      200?: (result: PricingV2Response) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/price-no-filter?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugStructure<TPromise = HttpDrugResultV2>(
    model: GetDrugStructureV2Request,
    handle: {
      200?: (result: HttpDrugResultV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/drug-structure?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugInformation<TPromise = DrugInformationV2>(
    model: GetDrugInformationV2Request,
    handle: {
      200?: (result: DrugInformationV2) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/drug-information?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async search<TPromise = SearchResponseV2>(
    model: SearchRequest,
    handle: {200?: (result: SearchResponseV2) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/search?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVoucher<TPromise = GetVoucherResponse>(
    model: GetVoucherRequest,
    handle: {200?: (result: GetVoucherResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/voucher?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDrugDetails<TPromise = GetDrugDetailsResponse>(
    model: GetDrugDetailsRequest,
    handle: {
      200?: (result: GetDrugDetailsResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/drug-v2/drug-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthIvrControllerClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async updateStatus<TPromise = VoidResponse>(
    model: HealthIvrUpdateStatusRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/ivr/update-status?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async testEligibility<TPromise = HealthIvrTestEligibilityResponse>(
    model: HealthIvrTestEligibilityRequest,
    handle: {
      200?: (result: HealthIvrTestEligibilityResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/ivr/test-eligibility?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async getBrokerDetails<TPromise = GetBrokerDetailsHealthResponse>(
    model: GetBrokerDetailsHealthRequest,
    handle: {
      200?: (result: GetBrokerDetailsHealthResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/broker-details?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async determineEligibility<TPromise = GetDetermineEligibilityResponse>(
    model: DetermineEligibilityRequest,
    handle: {
      200?: (result: GetDetermineEligibilityResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/determine-eligibility?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getBrokerDetailsBySlug<TPromise = GetBrokerDetailsHealthResponse>(
    model: GetBrokerDetailsSlugHealthRequest,
    handle: {
      200?: (result: GetBrokerDetailsHealthResponse) => void;
      500?: (result: string) => void;
      404: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/broker-details-slug?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 404) {
        await handle[404](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getUser<TPromise = JwtGetUserHealthResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: JwtGetUserHealthResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/user?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async register<TPromise = HealthLightJwtResponse>(
    model: RegisterHealthRequest,
    handle: {
      200?: (result: HealthLightJwtResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/register?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPushToken<TPromise = VoidResponse>(
    model: HealthSetPushTokenRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/push-token?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startPaymentSetup<TPromise = StartStripeCustomerResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StartStripeCustomerResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/start-payment-setup?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startSetupIntent<TPromise = StartSetupIntentResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StartSetupIntentResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/start-setup-intent?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async placeHold<TPromise = StripePlaceHoldResponse>(
    model: StripePlaceHoldRequest,
    handle: {
      200?: (result: StripePlaceHoldResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/place-hold?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async subsidizeVisit<TPromise = VoidRequest>(
    model: SubsidizeVisitRequest,
    handle: {200?: (result: VoidRequest) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/subsidize-visit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async listCards<TPromise = StripeListCardsResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StripeListCardsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/list-cards?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async listCharges<TPromise = StripeListChargesResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: StripeListChargesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/list-charges?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async validate<TPromise = JwtGetUserHealthResponse>(
    model: HealthValidateRequest,
    handle: {
      200?: (result: JwtGetUserHealthResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/validate?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setPharmacyPreferences<TPromise = UserHealthResponse>(
    model: HealthSetPharmacyPreferencesRequest,
    handle: {200?: (result: UserHealthResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/pharmacy-preferences?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getCabinet<TPromise = HealthGetCabinetResponse>(
    model: HealthGetCabinetRequest,
    handle: {
      200?: (result: HealthGetCabinetResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/cabinet?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async saveToCabinet<TPromise = HealthGetCabinetResponse>(
    model: HealthSaveToCabinetRequest,
    handle: {
      200?: (result: HealthGetCabinetResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/cabinet?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async deleteFromCabinet<TPromise = HealthGetCabinetResponse>(
    model: HealthDeleteFromCabinetRequest,
    handle: {
      200?: (result: HealthGetCabinetResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/cabinet?';

      const options = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDependents<TPromise = GetDependentsResponse>(
    model: GetDependentsRequest,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/dependents?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addDependents<TPromise = CreateDependentsResponse>(
    model: HealthAddDependentsRequest,
    handle: {
      200?: (result: CreateDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/dependents?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateUserDetails<TPromise = GetDependentsResponse>(
    model: HealthUpdateUserDetails,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/update-user-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async removeDependent<TPromise = GetDependentsResponse>(
    model: HealthRemoveDependentsRequest,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/remove-dependents?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDefaultVoucher<TPromise = GetDefaultVoucherResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetDefaultVoucherResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/default-voucher?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getNetworks<TPromise = GetNetworksResponse>(
    model: VoidRequest,
    handle: {
      200?: (result: GetNetworksResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/networks?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVisits<TPromise = GetVisitsResponse>(
    model: DependentRequest,
    handle: {200?: (result: GetVisitsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/visits?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setUserDetails<TPromise = GetDependentsResponse>(
    model: HealthSetUserDetailsRequest,
    handle: {
      200?: (result: GetDependentsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/set-user-details?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async avatarUrl<TPromise = VoidResponse>(
    model: HealthGetAvatarRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/avatar/:dependentId.png?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .filter((key) => key !== 'dependentId')

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      url = url.replace(':dependentId', model.dependentId).replace('{dependentId}', model.dependentId);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async updateCommunicationConfig<TPromise = VoidResponse>(
    model: HealthSetCommunicationRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user/update-communication-config?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserLOCClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async login<TPromise = LOCLoginResponse>(
    model: LOCLoginRequest,
    handle: {200?: (result: LOCLoginResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/loc/create-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserTMClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async sign<TPromise = TMDependentResponse>(
    model: TMSignRequest,
    handle: {200?: (result: TMDependentResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/sign?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getTerms<TPromise = GetTermsResponse>(
    model: VoidRequest,
    handle: {200?: (result: GetTermsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/terms?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async addDependent<TPromise = VoidResponse>(
    model: TMRequestPatientAddDependentRequest,
    handle: {200?: (result: VoidResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/addDependent?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchMedications<TPromise = SearchItemsResponse>(
    model: SearchItemsRequest,
    handle: {200?: (result: SearchItemsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/search-medications?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async searchAllergies<TPromise = SearchItemsResponse>(
    model: SearchItemsRequest,
    handle: {200?: (result: SearchItemsResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/search-allergies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getMedications<TPromise = GetMedicationsResponse>(
    model: DependentRequest,
    handle: {
      200?: (result: GetMedicationsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/medications?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createMedications<TPromise = GetMedicationsResponse>(
    model: TMRequestPatientCreateMedicationRequest,
    handle: {
      200?: (result: GetMedicationsResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/create-medications?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getAllergies<TPromise = GetAllergiesResponse>(
    model: DependentRequest,
    handle: {
      200?: (result: GetAllergiesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/allergies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async createAllergy<TPromise = GetAllergiesResponse>(
    model: TMRequestPatientCreateAllergyRequest,
    handle: {
      200?: (result: GetAllergiesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/create-allergy?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getPharmacies<TPromise = TMGetPharmaciesResponse>(
    model: TMGetPharmaciesRequest,
    handle: {
      200?: (result: TMGetPharmaciesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/pharmacies?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getZipPharmacies<TPromise = TMGetZipPharmaciesResponse>(
    model: TMGetZipPharmaciesRequest,
    handle: {
      200?: (result: TMGetZipPharmaciesResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/zip-pharmacies?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserTMVisitClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async startVisit<TPromise = TMVisitStartVisitResponse>(
    model: TMVisitStartVisitRequest,
    handle: {
      200?: (result: TMVisitStartVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/start-visit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getActiveVisit<TPromise = TMVisitGetVisitResponse>(
    model: TMVisitStartVisitRequest,
    handle: {
      200?: (result: TMVisitGetVisitResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/active-visit?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVisit<TPromise = TMVisitGetVisitResponse>(
    model: TMVisitGetVisitRequest,
    handle: {
      200?: (result: TMVisitGetVisitResponse) => void;
      500?: (result: string) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/visit?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getDisclaimer<TPromise = TMGetDisclaimerResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMGetDisclaimerResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/disclaimer?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async acceptDisclaimer<TPromise = TMVisitGetScreenOutResponse>(
    model: TMVisitAcceptDisclaimerRequest,
    handle: {
      200?: (result: TMVisitGetScreenOutResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/accept-disclaimer?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getScreenOut<TPromise = TMVisitGetScreenOutResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetScreenOutResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/screen-out?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async acceptScreenOut<TPromise = TMVisitWithFollowupResponse>(
    model: TMVisitAcceptScreenOutRequest,
    handle: {
      200?: (result: TMVisitWithFollowupResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/accept-screen-out?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReasons<TPromise = TMGetReasonsResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMGetReasonsResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/reason?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setReason<TPromise = TMVisitSetReasonResponse>(
    model: TMVisitSetReasonRequest,
    handle: {
      200?: (result: TMVisitSetReasonResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/reason?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async explainRepeatVisit<TPromise = TMVisitSetReasonResponse>(
    model: TMVisitExplainRepeatVisitRequest,
    handle: {
      200?: (result: TMVisitSetReasonResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/explain-repeat-visit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getModalities<TPromise = TMGetModalitiesResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMGetModalitiesResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/modalities?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async setModality<TPromise = TMVisitSetModalityResponse>(
    model: TMVisitSetModalityRequest,
    handle: {
      200?: (result: TMVisitSetModalityResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/modality?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async doctorStepUp<TPromise = TMVisitLightResponse>(
    model: TMVisitDoctorStepUpRequest,
    handle: {
      200?: (result: TMVisitLightResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/doctor-step-up?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async answerQuestion<TPromise = TMVisitGetQuestionResponse>(
    model: TMVisitAnswerQuestionRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/question?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async answerHeightWeight<TPromise = TMVisitGetQuestionResponse>(
    model: TMVisitAnswerHeightWeightRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/height-weight?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getChatMessages<TPromise = TMVisitChatMessagesResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitChatMessagesResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/chat-messages?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async enterVideoWaitingRoom<TPromise = TMVisitResponse>(
    model: EnterVideoWaitingRoomRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/enter-video-waiting-room?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getVideoToken<TPromise = TMVisitVideoResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitVideoResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/video-token?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async sendPrescription<TPromise = TMVisitResponse>(
    model: SendPrescriptionRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/send-prescription?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async startChat<TPromise = TMVisitStartChatResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitStartChatResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/start-chat?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async cancelVisit<TPromise = TMVisitResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/cancel-visit?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async declineTriage<TPromise = TMVisitResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/decline-triage?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async approveTriage<TPromise = TMVisitResponse>(
    model: ApproveTriageRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/approve-triage?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getClinic<TPromise = GetClinicResponse>(
    model: GetClinicRequest,
    handle: {
      200?: (result: GetClinicResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/clinic?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getReport<TPromise = TMVisitReportResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitReportResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/report?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async submitVisitAddendum<TPromise = TMVisitResponse>(
    model: TMVisitAddendumRequest,
    handle: {
      200?: (result: TMVisitResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/submit-addendum?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getQuestion<TPromise = TMVisitGetQuestionResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/question?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getFollowUp<TPromise = TMVisitGetQuestionResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/follow-up?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async previousQuestion<TPromise = TMVisitGetQuestionResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMVisitGetQuestionResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/previous-question?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getZipToken<TPromise = TMZipTokenResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMZipTokenResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/zip-token?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async getServerZipToken<TPromise = TMZipServerTokenResponse>(
    model: DependentVisitRequest,
    handle: {
      200?: (result: TMZipServerTokenResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-tm/visit/zip-server-token?';

      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      url += Object.keys(model)
        .filter((key) => !!(model as any)[key])

        .map((key) => `${key}=${encodeURIComponent((model as any)[key])}`)
        .join('&');

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthUserX2AIClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async createUser<TPromise = UserHealthResponse>(
    model: X2AICreateUser,
    handle: {200?: (result: UserHealthResponse) => void; 500?: (result: string) => void; 401?: (error: string) => void}
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/user-x2ai/create-user?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export class HealthWebhookClient extends BaseClient {
  constructor(options: ControllerOptions) {
    super(options);
  }

  async zipnosis<TPromise = VoidResponse>(
    model: WebhookZipnosisPostRequest,
    handle: {
      200?: (result: VoidResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/zipnosis?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }

  async zendesk<TPromise = WebhookZendeskPostResponse>(
    model: WebhookZendeskPostRequest,
    handle: {
      200?: (result: WebhookZendeskPostResponse) => void;
      500?: (result: string) => void;
      400: (result: {error: string}) => void;
      401?: (error: string) => void;
    }
  ): Promise<TPromise | undefined> {
    try {
      let url = this.options.baseUrl + '/health/webhook/zendesk?';

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      } as RequestInit;

      options.body = JSON.stringify(model);

      const response = await fetch(url, await this.transformOptions(options));

      const status = response.status;
      const headers: any = {};

      if (response.headers && response.headers.forEach) {
        response.headers.forEach((v: any, k: any) => (headers[k] = v));
      }

      const responseText = await response.text();

      if (status === 200) {
        if (handle[200]) {
          const val = responseText === '' ? null : JSON.parse(responseText);
          await handle[200](val);
          return val;
        }
        return JSON.parse(responseText);
      } else if (status === 400) {
        await handle[400](responseText === '' ? null : JSON.parse(responseText));
      } else if (status === 412) {
        (this.options as any).handleAlert(JSON.parse(responseText));
      } else if (status === 401) {
        if (!handle[401]) {
          this.options.handleUnauthorized(responseText);
        } else {
          await handle[401](responseText);
        }
      } else {
        try {
          const body = JSON.parse(responseText);
          if (!handle[500]) {
            this.options.handleError(body.error || responseText);
          } else {
            await handle[500](body.error || responseText);
          }
        } catch (ex) {
          if (!handle[500]) {
            this.options.handleError(responseText);
          } else {
            await handle[500](responseText);
          }
        }
      }
    } catch (ex) {
      if (!handle[500]) {
        this.options.handleError(ex.toString());
      } else {
        await handle[500](ex.toString());
      }
    }
  }
}

export interface UpdateAccountsRequest {
  accountId: string;
  email: string;
  phoneNumber: string;
  accountOwner: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  allowPromotionalCommunication: boolean;
  contactType: AccountContactTypes;
}

export type AccountContactTypes = 'nonProfit' | 'association' | 'healthcareProvider' | 'broker' | 'employer' | 'other';

export interface GetAccountResponse {
  account: HttpAccountExtended;
}

export interface HttpAccountExtended extends HttpAccount {
  cards: HttpCard[];
  partners: HttpPartner[];
}

export interface HttpAccount {
  id: string;
  email: string;
  primaryPartnerId?: string;
  phoneNumber?: string;
  accountSetUp: boolean;
  accountInvited: boolean;
  accountOwner?: string;

  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;

  contactType: AccountContactTypes;
  hubspotId?: number;

  allowPromotionalCommunication: boolean;
  createdDate: Date;
  updatedDate: Date;
  notes: HttpNote[];
}

export interface HttpNote {
  note: string;
  user: string;
  createdDate: Date;
}

export interface HttpCard {
  id: string;
  partnerId: string;
  accountId?: string;

  companyName?: string;
  groupCode: string;
  memberId: string;
  urlSlug?: string;
  shareUrlSlug?: string;

  deactivatedDate?: Date;
  requiresEligibility: boolean;
  usersCanAddTheirOwnDependents: boolean;
  zipEnv: 'DEV' | 'PROD';

  createdDate: Date;
  updatedDate: Date;
  notes: HttpNote[];
}

export interface HttpPartner {
  id: string;
  partnerName?: string;
  partnerType?: PartnerType;

  parentPartnerId?: string;
  contract?: HttpPartnerContract;
  commission?: HttpPartnerCommission;
  commissionReferrals?: HttpPartnerCommissionReferral[];
  w9?: HttpPartnerW9;
  startingGroupCode: string;
  startingMemberId: string;
  payoutStyle: PartnerPayoutStyle;

  createdDate: Date;
  updatedDate: Date;
  isPharmacist: boolean;
  notes: HttpNote[];
  health?: HttpPartnerHealthDetails;
  healthContract?: HttpPartnerHealthContract;
}

export type PartnerType = 'Master' | 'Tier 1' | 'Tier 2' | 'Tier 3';

export interface HttpPartnerContract {
  importStatus?: 'Docusign' | string;
  docusignEnvelopeId?: string;
  contractSigned?: Date;
  contractRequested?: Date;
}

export interface HttpPartnerCommission {
  minimumPayout: number;
  commissionAmount: number;
  stripeHasBankAccount: boolean;
  stripeLinkSent: boolean;
  stripeAccountSetup: boolean;
}

export interface HttpPartnerCommissionReferral {
  commissionAmount: number;
  partnerId: string;
  dateStarted: Date;
}

export interface HttpPartnerW9 {
  docusignEnvelopeId?: string;
  dateSent?: Date;
  dateSigned?: Date;
}

export type PartnerPayoutStyle = 'giftcard' | 'stripe';

export interface HttpPartnerHealthDetails {
  commission?: {
    commissionAmount: number;
  };
  commissionReferrals?: HttpPartnerCommissionReferral[];
  dateConverted: Date;
}

export interface HttpPartnerHealthContract {
  contractSigned?: Date;
  contractRequested?: Date;
  docusignEnvelopeId?: string;
}

export interface SearchAccountsRequest {
  query: string;
  page: string;
}

export interface SearchAccountsResponse {
  accounts: HttpAccountSearch[];
  page: number;
}

export interface HttpAccountSearch {
  id: string;
  email: string;
  accountOwner: string;
  cardsCount: number;
  partnersCount: number;
}

export interface GetAccountRequest {
  accountId: string;
}

export interface GetUnfoundClaimsRequest {
  page: number;
}

export interface GetUnfoundClaimsResponse {
  unfoundClaims: {
    groupCode: string;
    memberId: string;
    count: number;
  }[];
}

export interface ResolveClaimRequest {
  oldGroupCode: string;
  oldMemberId: string;
  newGroupCode: string;
  newMemberId: string;
}

export interface SuccessResponse {
  success: boolean;
}

export interface VoidRequest {}

export interface ResolveClaimResponse {
  stripePayoutRequests: HttpStripePayoutRequest[];
}

export interface HttpStripePayoutRequest {
  id: string;
  partner: HttpPartner;
  amount: number;
  dateRequested: Date;
}

export interface FulfillPayoutRequestRequest {
  requestId: string;
}

export interface AdminMonthReportMonthsResponse {
  months: string[];
}

export interface AdminMonthReportRequest {
  month: string;
}

export interface AdminMonthReportResponse {
  commission: AdminMonthReportCommission;
  lastMonthCommissions: AdminMonthReportCommission;
  commissionErrors: {
    type: 'below-zero';
    claimId: string;
    partnerId: string;
    cardId: string;
    groupCode: string;
    memberId: string;
  }[];
}

export interface AdminMonthReportCommission {
  dateRan: Date;
  monthRan: string; // '2019-08'
  numberOfClaims: number;
  totalAmountDue: number;
  totalAmountSaved: number;
  averagePercentSaved: number;
  final: boolean;
  claims: {
    groupCode: string;
    memberId: string;
    count: number;
    earned: number;
    saved: number;
  }[];
  claimsDays: {name: string; claims: number; revenue: string; savings: string}[];
}

export interface AdminLoginRequest {
  email: string;
  password: string;
}

export interface AdminLoginResponse {
  user: HttpAdminUser;
  jwt: string;
}

export interface HttpAdminUser {
  id: string;
  email: string;
}

export interface AdminReportResponse {
  report: AdminReport;
}

export interface AdminReport {
  partnerCount: number;
  accountCount: number;
  cardCount: number;
  userCount: number;
}

export interface AdminGenerateBinPcnReportRequest {
  month: string;
  type: 'singlecare' | 'rxedo';
}

export interface AdminStringReportResponse {
  report: string;
}

export interface GetClaimsByDayRequest {
  key: string;
  provider: 'rxedo' | 'singlecare';
}

export interface GetClaimsByDayResponse {}

export interface SlugsByDayResponse {
  slugs: {slug: string; count: number; date: string; cardId?: string}[];
}

export interface GetAllMarketingResponse {
  materials: HttpMarketingMaterial[];
}

export type HttpMarketingMaterial = {
  id: string;
  title: string;
  image: string;
  url: string;
  category: string;
  downloads: number;
  order: number;
};

export interface AddMaterialRequest {
  type: 'partner' | 'client';
  title: string;
  imageName: string;
  fileName: string;
  imageType: string;
  fileType: string;
  category: string;
  order: number;
}

export interface AddMaterialResponse {
  imageUpload: string;
  fileUpload: string;
}

export interface MaterialRequest {
  materialId: string;
}

export interface VoidResponse {}

export interface CreatePartnersRequest {
  parentPartnerId: string;
  partnerName: string;
  partnerType: PartnerType;
  startingGroupCode: string;
  startingMemberId: string;
  accountDetails: CreatePartnerAccountDetails;
  serviceType: 'cleverhealth' | 'cleverrx';
  commission: number;
  minimumPayout: number;
  commissionReferral: HttpPartnerCommissionReferral | null;
  sendToDocusign: boolean;

  card: {
    companyName?: string;
    urlSlug?: string;
  };

  sendEmailToParent: boolean;
}

export interface CreatePartnerAccountDetails {
  accountId?: string;
  firstName?: string;
  lastName?: string;
  contactType?: AccountContactTypes;
  email?: string;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  allowPromotionalCommunication?: boolean;
}

export interface GetPartnerResponse {
  partner: HttpPartnerExtended;
}

export interface HttpPartnerExtended extends HttpPartner {
  account: HttpAccountLight;
  groupTree: HttpPartnerGroupTreeItem;
}

export interface HttpAccountLight {
  id: string;
  email: string;
  primaryPartnerId?: string;
  accountOwner?: string;
  accountSetUp: boolean;
}

export interface HttpPartnerGroupTreeItem {
  startingGroupCode: string;
  startingMemberId: string;
  partnerId: string;
  partnerName: string;
  commissionAmount: number;
  partnerType: string;
  account: HttpAccountLight;
  children: HttpPartnerGroupTreeItem[];
  cards: HttpCardAndAccount[];
  parent?: HttpPartnerGroupTreeItem;
}

export interface HttpCardAndAccount extends HttpCardLight {
  account?: HttpAccountLight;
}

export interface HttpCardLight {
  partnerId: string;
  id: string;
  companyName?: string;
  groupCode: string;
  memberId: string;
  urlSlug?: string;
}

export interface BreakPartnerOffRequestRequest {
  partnerType: PartnerType;
  sendToDocusign: boolean;
  partnerId: string;
  commissionAmount: number;
  referralAmount?: number;
}

export interface UpdatePartnerRequest {
  partnerId: string;
  partnerName: string;
  parentPartnerId: string;
  startingGroupCode: string;
  startingMemberId: string;
  partnerType: PartnerType;
  commissionReferrals: HttpPartnerCommissionReferral[];
  commissionAmount: number;
  minimumPayout: number;
}

export interface GetAllAmountsDueResponse {
  partners: {amountDue: number; partnerId: string; partnerName: string}[];
}

export interface LinkStripeRequest {
  partnerId: string;
}

export interface LinkStripeResponse extends GetPartnerResponse {}

export interface PartnerRequest {
  partnerId: string;
}

export interface UpdateCardRequest extends CardRequest {
  cardId: string;
  partnerId: string;
  companyName: string;
  groupCode: string;
  memberId: string;
  requiresEligibility: boolean;
  usersCanAddTheirOwnDependents?: boolean;
  zipEnv?: 'DEV' | 'PROD';
  urlSlug: string;
}

export interface CardRequest {
  cardId: string;
}

export interface GetCardResponse {
  card: HttpCardExpanded;
  partner?: HttpPartner;
}

export interface HttpCardExpanded extends HttpCard {
  account?: HttpAccountLight;
  partner: HttpPartner;
}

export interface AddManualDebitRequest {
  partnerId: string;
  amount: string;
  note: string;
}

export interface GetLedgerResponse {
  ledger: HttpPartnerLedger;
  canRequestGiftCard: boolean;
}

export interface HttpPartnerLedger {
  lineItems: HttpPartnerLedgerLineItem[];
}

export type HttpPartnerLedgerLineItem =
  | {
      id: string;
      type: 'credit';
      amount: number;
      origin:
        | {
            type: 'commissionResult';
            commissionResultId: string;
            commissionMonth: string;
            dateAdded: Date;
          }
        | {
            type: 'manual';
            dateAdded: Date;
            note: string;
          };
      date: Date;
    }
  | {
      id: string;
      type: 'debit';
      date: Date;
      amount: number;
      origin: {reportApproximate: boolean; dateAdded: Date; note: string} & (
        | {
            type: 'stripe';
          }
        | {
            type: 'gift-card';
          }
        | {
            type: 'gift-card-request';
          }
        | {
            type: 'stripe-request';
          }
        | {
            type: 'manual';
          }
      );
    };

export interface CreateCardRequest {
  parentPartnerId: string;
  groupCode: string;
  memberId: string;
  accountDetails: CreateCardAccountDetails;
  card: {
    companyName?: string;
    urlSlug?: string;
  };
}

export type CreateCardAccountDetails = {
  accountId?: string;
  firstName?: string;
  lastName?: string;
  contactType?: AccountContactTypes;
  allowPromotionalCommunication?: boolean;
  email?: string;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
};

export interface StartCreatePartnersRequest {
  parentPartnerId: string;
}

export interface StartCreatePartnersResponse {
  parentPartner: HttpPartnerExtended;
  groupCode: string;
  memberId: string;
}

export interface ValidatePartnerNameUniqueRequest {
  partnerName: string;
}

export interface ValidateUniqueResponse {
  valid: boolean;
}

export interface ValidateUrlSlugUniqueRequest {
  urlSlug: string;
}

export interface SearchPartnersRequest {
  query: string;
  page: string;
  onlyMasters: string;
}

export interface SearchPartnersResponse {
  partners: HttpPartnerSearch[];
  page: number;
}

export interface HttpPartnerSearch {
  _id: string;
  partnerName: string;
  parentPartnerName: string;
  startingGroupCode: string;
  startingMemberId: string;
}

export interface GetPartnerRequest {
  partnerId: string;
}

export interface GetCardRequest {
  cardId: string;
}

export interface GetReferralPartnersResponse {
  referrals: HttpPartner[];
}

export interface GetPayoutsResponse {
  payouts: PartnerPayout[];
}

export interface PartnerPayout {
  cardId: string;
  groupCode: string;
  memberId: string;
  rate: string;
  amountDue: string;
}

export interface PageRequest {
  approvedPage: number;
}

export interface AdminPartnerRequestsResponse {
  requests: HttpPartnerRequestExtended[];
}

export interface HttpPartnerRequestExtended {
  _id: ObjectId;
  createdDate: Date;

  initiatingPartnerId: ObjectId;
  initiatingPartnerName: string;
  initiatingStartingGroupCode: string;
  initiatingStartingMemberId: string;

  parentPartnerId: ObjectId;
  parentPartnerName: string;
  parentStartingGroupCode: string;
  parentStartingMemberId: string;

  masterPartnerId: ObjectId;
  masterPartnerName: string;
  masterStartingGroupCode: string;
  masterStartingMemberId: string;

  request: DBPartnerRequestBody;
  approved: boolean;
  adjudicatedDate: Date;
  adjudicatedBy: string;
}

export type DBPartnerRequestBody =
  | {
      type: 'card-vanity-url';
      cardId: ObjectId;
      vanityUrl: string;
      overrideGroupCode?: string;
      overrideMemberId?: string;
    }
  | {type: 'invite-partner'; body: AccountInvitePartnerRequest};

export interface AccountInvitePartnerRequest extends PartnerRequest {
  partnerName?: string;
  emailAddress?: string;
  commissionAmount?: string;

  companyName?: string;
  vanityUrl?: string;

  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  contactType?: string;

  overrideGroupCode?: string;
  overrideMemberId?: string;

  sendEmailToParent: boolean;
  serviceType: 'cleverhealth' | 'cleverrx';
}

export interface AdminAdjudicatePartnerRequest {
  partnerRequestId: string;
  approved: boolean;
  denyReason?: string;

  request?: DBPartnerRequestBody;
}

export interface AdminAdjudicatePartnerResponse {
  resultId?: string;
  resultType?: 'partner' | 'card';
}

export interface GetClientUsersRequest {
  cardId: string;
}

export interface GetClientUsersResponse {
  clientUsers: HttpClientUser[];
}

export interface HttpClientUser {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  accountSetUp: boolean;
  cardId: string;
  partnerId: string;
  createdDate: Date;
  updatedDate: Date;
  clientId: string;
}

export interface GetHealthClientRequest {
  cardId: string;
  partnerId: string;
}

export interface GetHealthClientResponse {
  client: HttpClient & {groupCode: string; memberId: string; urlSlug: string};
}

export interface HttpClient {
  id: string;
  companyName: string;
  cardId: string;
  partnerId: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface CreateClientRequest {
  cardId: string;
  companyName: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

export interface GetClientResponse {
  client: HttpClient;
}

export interface CreateClientUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  cardId: string;
}

export interface GetClientUserResponse {
  clientUser: HttpClientUser;
}

export interface ActivateClientUserRequest {
  clientUserId: string;
}

export interface SetClaimReconcileRuleRequest {
  oldGroupCode: string;
  oldMemberId: string;
  newGroupCode: string;
  newMemberId: string;
}

export interface AutoSignDocumentRequest extends GetPartnerRequest {
  documentType: 'rxContract' | 'w9' | 'healthContract';
}

export interface CardOrderValidationRequest {
  password: string;
}

export interface CardOrderValidationResponse {
  isValid: boolean;
}

export interface GetAllCardOrdersRequest {
  filter: 'pending' | 'fulfilled';
  password?: string;
  page: string;
  take: string;
}

export interface GetAllCardOrdersResponse {
  cardOrders: HttpPartnerCardTransactionSearch[];
  count: number;
}

export type HttpPartnerCardTransactionSearch = {
  id: string;
  email: string;
  companyName: string;
  status: string;
  quantity: number;
  createdDate: string;
  shippingMethod: 'standard' | 'expedited' | 'rush';
};

export interface GetPartnerCardOrderRequest {
  password?: string;
  cardOrderId: string;
}

export interface GetPartnerCardOrderResponse {
  cardOrder: HttpPartnerCardTransaction;
}

export type HttpPartnerCardTransaction = {
  id: string;
  email: string;
  companyName: string;
  name: string;
  partnerId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  language: 'english' | 'spanish';
  cardType: 'generic' | 'co-branded' | 'logo';
  quantity: number;
  shippingMethod: 'standard' | 'expedited' | 'rush';
  qrCodeRequested: boolean;
  receiptNumber: string;
  receiptUrl: string;
  createdDate: string;
  updatedDate: string;
  requestedDeliveryDate?: string;
  stripeChargeId: string;
  amountCharged: number;
  status: 'pending' | 'fulfilled';
  logoUri?: string;
  qrCodeUri?: string;
  cardFrontProofUri?: string;
  cardBackProofUri?: string;
  cobrandedName?: string;
  trackingNumber?: string;
  carrier?: 'fedex' | 'ups' | '';
};

export interface UploadCardOrderProofRequest extends GetPartnerCardOrderRequest {
  type: 'front' | 'back';
  dataUri: string;
  fileType: 'image/jpeg' | 'image/png';
}

export interface GetCardOrderImageRequest extends GetPartnerCardOrderRequest {
  type: 'proof-front' | 'proof-back' | 'qrcode' | 'logo';
}

export interface UpdatePartnerCardOrderTrackingRequest extends GetPartnerCardOrderRequest {
  trackingNumber: string;
  carrier: 'fedex' | 'ups' | '';
}

export interface HealthConfigResponse {
  networks: HealthConfiguration[];
}

export type HealthConfiguration =
  | {
      type: 'telemedicine';
      asyncCost: number;
      phoneCost: number;
      videoCost: number;
      chatCost: number;
      subsidize: boolean;
      enabled: boolean;
    }
  | {type: 'prescription'; enabled: boolean}
  | {type: 'x2ai'; enabled: boolean}
  | {type: 'benjamin'; enabled: boolean}
  | {type: 'listeners'; enabled: boolean};

export interface UpdateHealthConfigRequest {
  cardId: string;
  networks: HealthConfiguration[];
}

export interface UpdatePartnerHealthCommissionRequest {
  partnerId: string;
  commissionReferrals: HttpPartnerCommissionReferral[];
  commissionAmount: number;
}

export interface AccountCommissionResultMonthsResponse {
  months: string[];
}

export interface AccountMonthReportRequest {
  month: string;
  partnerId: string;
}

export interface GetConsultResultResponse {
  partner: GetConsultResult;
  byState: ({state: string} & GetConsultResult)[];
  byCard: ({cardId: string; groupCode: string; memberId: string} & GetConsultResult)[];
  byDay: ({day: string} & GetConsultResult)[];
}

export interface GetConsultResult {
  total: number;
  totalGroupedReason: {reasonCode: string; count: number}[];
  totalHeld: number;
  totalNotStarted: number;
  totalCharged: number;
  totalGroupedModality: {modality: string; count: number}[];
}

export interface GetAdminHealthClientUsersRequest {
  page: string;
  offset: string;
  filter: string;
  cardId: string;
  partnerId: string;
}

export interface GetAdminHealthClientUsersResponse {
  clientUsers: HttpClientUserSearch[];
  count: number;
  clientExists: boolean;
}

export interface HttpClientUserSearch {
  id: string;
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  accountInvited: boolean;
  accountActive: boolean;
}

export interface GetHealthVisitsRequest {
  startDate: string;
  endDate: string;
  offset: string;
  count: string;
}

export interface GetHealthVisitsResponse {
  count: number;
  visits: HttpHealthVisit[];
}

export interface HttpHealthVisit {
  id: string;
  dateCreated: string;
  zipVisitId: number;
  reasonCode: string;
  status: ZipnosisVisitState;
  firstName: string;
}

export type ZipnosisVisitState =
  | 'patient_encounter' // the initial state of a Visit
  | 'modality_required' // the initial state of a Visit
  | 'repeat_explanation_required' // the initial state of a Visit
  | 'sms_opt_in' // the initial state of a Visit
  | 'kicked_out' // the state in which a refer out has occurred in the Clinical Protocol
  | 'deleted' // the state which occurs when a user cancels/stops a Zipnosis
  | 'expired' // If a visit has not been completed by the patient 24 hours after it was started, then it is automatically transitioned to this state.
  | 'payment_requested' // when a questionnaire is completed and requires payment
  | 'patient_complete' // payment has been accepted and enters the clinician's queue for the visit's clinic
  | 'doctor_encounter' // a clinician has opened a visit and has locked it from being accessed by other clinicians
  | 'doctor_response' // a clinician has completed the visit for review by the patient and enters the patient inbox
  | 'video_verification' // video upgrade
  | 'phone_verification' // phone upgrade
  | 'patient_reviewed' // the patient has clicked on and reviewed their visit
  | 'prescriptions_unsent' // patient has sent their prescriptions to a pharmacy via ERX
  | 'prescriptions_sent' // patient has sent their prescriptions to a pharmacy via ERX
  | 'confirm_browser_for_video' //
  | 'height_weight_needed' //
  | 'doctor_step_up_opt_in' //
  /**/
  | 'cancelled-via-clever';

export interface GetHealthUsersRequest {
  groupCode: string;
  memberId: string;
  offset: string;
  count: string;
  startDate: string;
  endDate: string;
}

export interface GetHealthUsersResponse {
  count: number;
  users: HttpAdminHealthUser[];
}

export interface HttpAdminHealthUser {
  id: string;
  numberOfDependents: number;
  createdDate: string;
  groupCode: string;
  memberId: string;
  firstName: string;
}

export interface GetHealthIvrAnalyticsRequest {
  type: 'forward-to-ucm' | 'send-text' | '';
  startDate: string;
  endDate: string;
  offset: string;
  count: string;
}

export interface GetHealthIvrAnalyticsResponse {
  count: number;
  ivrAnalytics: HttpHealthIvrAnalytics[];
}

export interface HttpHealthIvrAnalytics {
  id: string;
  type:
    | 'incoming-call'
    | 'start-eligibility'
    | 'send-text'
    | 'paid'
    | 'hang-up-on-payment'
    | 'forward-to-ucm'
    | 'eligibility' // status
    | 'eligibility-result';
  phoneNumber: string;
  zipCode: string;
  date: string;
}

export interface GetHealthReportsRequest {
  startDate: string;
  endDate: string;
  memberFilter: 'primary' | 'all';
}

export interface GetHealthReportsResponse {
  completedCalls: number;
  redirectedCalls: number;
  usersCreated: number;
  visitsCompleted: number;
  prescriptionsSent: number;
  visitsAsync: number;
  visitsPhone: number;
  visitsVideo: number;
  incomingCalls: number;
  bellaUtilization: number;
  percentCallsEligible: number;
}

export interface GetHealthReportsDataRequest {
  month: string;
  startDate: string;
  endDate: string;
  memberFilter: 'primary' | 'all';
}

export interface GetHealthReportsDataResponse {
  data: number[];
}

export interface GetHealthVisitDataRequest {
  zipVisitId: string;
}

export interface GetHealthVisitDataResponse {
  data: ZipnosisVisit | {};
}

export type ZipnosisVisit = {
  id: number;
  state: ZipnosisVisitState;
  sub_state: string;
  promo_code_id?: any;
  patient_id: number;
  patient_name: string;
  assessment_text?: any;
  icd_code: string;
  reason_for_visit?: string;
  serial: string;
  required_triage_option?: 'chat' | 'video';
  clinic: VisitClinic;
  interview_progress?: number;
  doctor?: ZipDoctor;
  prescriptions: ZipnosisVisitPrescription[];
  clinical_protocol?: any;
  tos_accepted?: any;
  global_screen_out_accepted?: any;
  created_at: string;
  updated_at: string;
  finished_at?: any;
  submitted_at?: any;
  readable_state?: string;
  patient_answers: VisitPatientAnswers;
  zip_triage?: ZipTriage;
  images?: ZipImage[];
  pharmacy?: ZipnosisVisitPharmacy;
};

export type VisitClinic = {
  id: number;
  name: string;
};

export type ZipDoctor = {
  id: number;
  first_name: string;
  last_name: string;
  title: string;
  name_with_title: string;
  full_name: string;
};

export interface ZipnosisVisitPrescription {
  id: number;
  pharmacy_id?: any;
  visit_id: number;
  erx_last_sent?: any;
  drug_name: string;
  sig: string;
  order_number: string;
  route?: any;
  quantity: string;
  quantity_qualifier: string;
  strength: string;
  strength_qualifier: string;
  refills_count: number;
  refill_as_needed: boolean;
  ndc_code: string;
  drug_form: string;
  days_supply: string;
  allow_substitution: boolean;
  state: string;
  drug_description: string;
  description: string;
}

export type VisitPatientAnswers = {
  patient_gender: string;
  patient_age: number;
  patient_name: string;
  patient_first_name: string;
  sick_member_id: number;
  patient_dob: string;
  preparers_name: string;
  patient_email: string;
  patient_phone: string;
};

export type ZipTriage =
  | {
      notes?: null;
      queue_order?: number;
      queued_at?: string;
      created_at?: string;
      updated_at?: string;
      platform?: null;
      patient_connection?: {
        connected_at: string;
        disconnected_at: string;
      };
      doctor_connection?: null;
      id: number;
      state: 'queued' | 'ready';
      type: 'ZipTriageChat';
    }
  | {
      id: number;
      type: 'ZipTriageVideo';
      state: 'ready';
      notes?: any;
      queue_order?: number;
      queued_at?: any;
      created_at: string;
      updated_at: string;
      platform: 'twilio';
      patient_connection?: null;
      doctor_connection?: null;
    };

export interface ZipImage {
  id: number;
  file_name: string;
  file_size: number;
  clinician_last_viewed_at?: string;
  picture_taken_on: string;
  uploaded_by: string;
  question_identifier: string;
  type: string;
}

export interface ZipnosisVisitPharmacy {
  id: number;
  name: string;
  fax: string;
  ncpdp_id: number;
  latitude: number;
  phone: string;
  longitude: number;
  preferred: boolean;
  display_url: string;
  display_url_title: string;
  service_level: string;
  created_at: Date;
  updated_at: Date;
  address: ZipnosisVisitPharmacyAddress;
  distance?: any;
}

export interface ZipnosisVisitPharmacyAddress {
  id: number;
  address1: string;
  address2?: any;
  city: string;
  country?: any;
  phone: string;
  state: string;
  zip: string;
  full_address: string;
  latitude?: any;
  longitude?: any;
}

export interface GetAdminEDIMembersRequest {
  page: string;
  count: string;
  groupCode: string;
  memberId: string;
  lastName?: string;
  phoneNumber?: string;
  dob?: string;
  zipCode?: string;
  firstName?: string;
  isActive?: string;
}

export interface GetAdminEDIMembersResponse {
  members: HttpAdminEDIMember[];
  count: number;
}

export interface HttpAdminEDIMember {
  id: string;
  firstName?: string;
  lastName: string;
  dob: FullDate;
  zipCode: string;
  phoneNumber?: string;
  employeeType: 'primary' | 'spouse' | 'dependent';
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  uniqueId: string;
  partnerId: string;
  cardId: string;
  groupCode: string;
  memberId: string;
  companyName: string;
}

export interface FullDate {
  year: number;
  month: number;
  day: number;
  stamp?: number | undefined;
}

export interface GetAdminEDIMemberRequest {
  id: string;
}

export interface GetAdminEDIMemberResponse {
  member: DBEligibleMember & {companyName: string};
}

export type DBEligibleMember = {
  _id: ObjectId;
  uniqueId: string;
  externalMemberId: string;
  dateOfBirth: FullDate;
  lastName: string;
  lastNameLower: string;
  firstName?: string;
  zipcode: string;
  otherFields: {[key: string]: string};
  partnerConfigId: ObjectId;
  cleverPartnerId: ObjectId;
  cleverCardId: ObjectId;
  cleverGroupCode: string;
  cleverMemberId: string;
  employeeType: EmployeeType;
  batchId: string;
  updatedBatchIds?: string[];
  createdDate: Date;
  updatedDate: Date;
  email?: string;
  state?: string;
  phoneNumber?: string;
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  deactivatedBatchId?: string;

  rowHash: number;
  manualUpdate?: {
    date: Date;
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
    lastNameLower: string;
  };
};

export type EmployeeType = 'primary' | 'spouse' | 'dependent';

export interface UpdateAdminEDIMemberRequest {
  id: string;
  firstName: string;
  lastName: string;
  dob: FullDate;
  zipCode: string;
}

export interface UpdateAdminEDIMemberResponse {
  member: DBEligibleMember;
}

export interface GetAdminEDIMemberTreeRequest {
  externalMemberId: string;
}

export interface GetAdminEDIMemberTreeResponse {
  members: DBEligibleMember[];
}

export interface GetHealthReportsMedicineCabinetRequest {
  startDate: string;
  endDate: string;
  userFilter: 'rx' | 'health';
}

export interface GetHealthReportsMedicineCabinetResponse {
  topMeds: {drugName: string; count: number}[];
}

export interface GetHealthReportsUsersByCardRequest {
  partnerId?: string;
  offset: string;
  count: string;
}

export interface GetHealthReportsUsersByCardResponse {
  usersByCard: {count: number; groupCode: string; memberId: string}[];
}

export interface GetHealthReportsChargesByCardResponse {
  chargesByCard: {charges: number; groupCode: string; memberId: string}[];
}

export interface GetHealthReportsTopPricedDrugsRequest {
  startDate?: string;
  endDate?: string;
}

export interface GetHealthReportsTopPricedDrugsResponse {
  topPricedDrugs: {drugName: string; ndc: string; count: number}[];
}

export interface GetHealthClientInvoicesRequest {
  startDate: string;
  endDate: string;
  offset: string;
  count: string;
}

export interface GetHealthClientInvoicesResponse {
  invoices: {
    createdDate: string;
    datePaid: string;
    dateSent: string;
    id: string;
    groupCode: string;
    memberId: string;
    companyName: string;
    total: number;
  }[];
  count: number;
}

export interface GetHealthClientInvoiceRequest {
  invoiceId: string;
}

export interface GetHealthClientInvoiceResponse {
  invoice: HttpClientInvoiceExtended;
  companyName: string;
}

export interface HttpClientInvoiceExtended {
  id: string;
  number: string;
  periodStart: number;
  periodEnd: number;
  accountName: string;
  amountDue: number;
  amountPaid: number;
  created: number;
  description: string;
  dueDate: number;
  email: string;
  phone: string;
  status: string;
  billingAddress: {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
  };
  items: {
    id: string;
    unitAmount: number;
    description: string;
    quantity: number;
  }[];
}

export interface GetHealthClientRequestsRequest {
  page: string;
  filter: 'pending' | 'approved' | 'denied';
}

export interface GetHealthClientRequestsResponse {
  count: number;
  requests: HttpClientRequestSearch[];
}

export type HttpClientRequestSearch = {
  id: string;
  dateCreated: string;
  urlSlug: string;
  startingDate: string;
  numberOfEmployees: number;
  companyName: string;
};

export interface GetHealthClientRequestRequest {
  requestId: string;
}

export interface GetHealthClientRequestResponse {
  request: HttpClientRequest;
}

export type HttpClientRequest = {
  id: string;
  partnerId: string;
  cardId?: string;
  clientId?: string;
  partnerName?: string;
  dateCreated: string;
  companyName: string;
  startingDate: string;
  numberOfEmployees: number;
  urlSlug: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  isSubsidized: boolean;
  requiresEligibility: boolean;
  notes: string;
  status: string;
  initialNetworks?: HealthConfiguration[];
};

export interface UpdateHealthClientRequestRequest {
  requestId: string;
  companyName: string;
  startingDate: string;
  numberOfEmployees: number;
  urlSlug: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  isSubsidized: boolean;
  requiresEligibility: boolean;
  initialNetworks: HealthConfiguration[];
}

export interface AdjudicateHealthClientRequestRequest extends UpdateHealthClientRequestRequest {
  approved: boolean;
  denyReason?: string;
}

export interface GetHealthPartnerKPIsPartnersRequest {
  startDate: string;
  endDate: string;
  offset: string;
  take: string;
  groupsPrefix: 'eq' | 'lt' | 'gt';
  groupsFilter: string;
  tasksPrefix: 'eq' | 'lt' | 'gt';
  tasksFilter: string;
  rank: '' | '0' | '1';
}

export interface GetHealthPartnerKPIsPartnersResponse {
  partners: {
    id: string;
    createdDate: string;
    partnerName: string;
    email: string;
    rank: 'private' | 'lieutenant';
    completedTasks: number;
    groups: number;
  }[];
  count: number;
}

export interface GetHealthPartnerKPIsPartnerResponse {
  partner: {
    email: string;
    createdDate: string;
    partnerName: string;
    numberOfGroups: number;
    loginCount: number;
    lastLogin: string;
    completedTasks: PartnerTask[];
    master: string;
  };
}

export type PartnerTask = {type: PartnerTaskType; dateCompleted: Date};

export type PartnerTaskType =
  | 'visitedPortal'
  | 'viewedNumbers'
  | 'downloadedApp'
  | 'visitedLandingPage'
  | 'watchedVideo'
  | 'signedDocs'
  | 'pricedRx'
  | 'sharedLink'
  | 'visitedProspectingPage'
  | 'sentFlyer'
  | 'orderedCards'
  | 'downloadedFlyers'
  | 'setupGroup';

export interface GetHealthPartnerKPIsReportsRequest {
  startDate: string;
  endDate: string;
}

export interface GetHealthPartnerKPIsReportsResponse {
  partners: {label: string; value: number}[];
  groups: {label: string; value: number}[];
  totalPartners: number;
  totalGroups: number;
  groupsNoUsers: number;
  partnersNoGroups: number;
  tasks: {task: PartnerTaskType; count: number}[];
  groupsOnboarding: number;
  numberOfLives: number;
}

export interface GetDownloadsByPartnerRequest {
  partnerId: string;
}

export interface GetDownloadsByPartnerResponse {
  breakdown: {groupCode: string; companyName: string; partnerName: string; memberId: string; count: number}[];
}

export interface GetClaimsReportRequest {
  startDate: FullDate;
  endDate: FullDate;
}

export interface ClaimsReportResponse {
  totalSavings: number;
  averageSavingPercent: number;
  numberOfSearches: number;
  claimOfThePeriod: ClaimOfTheDay;
  topPharmacies: {
    pharmacyName: string;
    count: number;
  }[];
  appClaims: number;
  nonAppClaims: number;
  singlecareClaims: number;
  rxedoClaims: number;
  topPartnersClaims: TopPartnerClaims[];
  // topPartnersUsers: TopPartnerUsers[];
}

export interface ClaimOfTheDay {
  date: string;
  drugName: string;
  totalSavings: number;
  percentSaved: number;
  pharmacy: string;
}

export interface TopPartnerClaims {
  partnerId: string;
  partnerName: string;
  groupCode: string;
  memberId: string;
  appClaims: number;
  nonAppClaims: number;
  totalSavings: number;
  averageSavingPercent: number;
}

export interface GetMasterClaimReportRequest {
  startDate: FullDate;
  endDate: FullDate;
}

export interface MasterClaimReportResponse {
  masters: MasterClaimReport[];
}

export interface MasterClaimReport {
  partnerName: string;
  partnerId: string;
  appClaims: number;
  nonAppClaims: number;
  totalSavings: number;
  averageSavingPercent: number;
  commissionEarned: number;
}

export interface GetAllTimeClaimsReportRequest {}

export interface AllTimeClaimsReportResponse {
  totalSavings: number;
  averageSavingPercent: number;
  appClaims: number;
  nonAppClaims: number;
  numberOfSearches: number;
}

export interface GetClaimsDayReportRequest {
  startDate: FullDate;
  endDate: FullDate;
  page: number;

  ndcOrPrescription?: string;
  pharmacy?: string;
  groupCode?: string;
  memberId?: string;
}

export interface GetClaimsByDayReportResponse {
  claims: ClaimItem[];
}

export interface ClaimItem {
  drugName: string;
  quantity: number;
  priceBase: number;
  amountPaid: number;
  amountSaved: number;
  pharmacy: string;

  groupCode: string;
  memberId: string;

  partnerId: string;

  date: FullDate;
  time: FullTime;
  ndc: string;

  fromApp: boolean;
  fromShare: boolean;

  type: 'singlecare' | 'rxedo';

  compensable: 1 | 0 | -1;
  rawRow: {[key: string]: any};
}

export interface FullTime {
  hour: number;
  minute: number;
  second: number;
  stamp: number;
}

export interface GetClaimsResultReportResponse {
  claimsByDate: ClaimsResultReportByDate[];
}

export interface ClaimsResultReportByDate {
  batchId: string;
  monthsEffected: string[];
  claimsInserted: number;
  unfoundCards: number;
  fileName: string;
  provider: 'singlecare' | 'rxedo';
  dateRan: Date;
  duration: number;
}

export interface AppOpenRequest {
  firstTime: boolean;
  fromShare: string;
  fromLink: boolean;
}

export interface LogEventRequest {
  eventName: string;
  sessionId: string;
  userId?: string;
  meta?: string;
}

export interface TelemetryStartRequest {
  sessionId: string;
  phoneDetails?: DBPhoneDetails;
}

export interface DBPhoneDetails {
  apiLevel?: number;
  brand?: string;
  model?: string;
  systemVersion?: string;
  systemName?: string;
  locale?: string;
  timezone?: string;
  lastAccess?: Date;
  error?: string;
  zipcode?: string;
  appVersion?: string;
}

export interface PriceRequest {
  ndc: string;
  zipcode?: string;
  quantity: string;
  forceBranded: boolean;
  pharmacy?: PharmacyPriceRequest;
  watchedDrugId?: string;
}

export interface PharmacyPriceRequest {
  address1: string;
  pharmacyType: PharmacyType;
  pharmacy: string;
  address2: string;
  postalCode: string;
  nabp: string;
}

export type PharmacyType = 'Walgreens' | KrogerPharmacyType | 'Walmart' | 'CVS' | 'Other';

export type KrogerPharmacyType =
  | 'Bakers'
  | 'City Market'
  | 'Dillons'
  | 'Food 4 Less'
  | 'Foods Co'
  | 'Fred Meyer'
  | "Fry's"
  | 'Gerbes'
  | 'Harris Teeter'
  | 'King Soopers'
  | 'Jay C Food Store'
  | 'Kroger'
  | 'Owens Market'
  | 'Pay-Less Super Markets'
  | 'QFC'
  | 'Ralphs'
  | 'Smiths';

export interface PricingResponse {
  watchedDrugId: string;
  pickedDrug: HttpPickedDrug;
  user: HttpUser;
  prices: DrugPrice[];
}

export interface HttpPickedDrug {
  zipcode: string;
  isGeneric: boolean;
  drugName: string;
  ndc: string;
  form: string;
  strengthItem: string;
  quantity: string;
  displayQuantity: string;
  forceBranded: boolean;
}

export interface HttpUser {
  id: string;
  watchedDrugs: HttpWatchedDrug[];
  createdDate: Date;
  brokerDetails: BrokerDetails;
  preferredPharmacyType?: PharmacyType;
  preferredPharmacy?: Pharmacy;
  pushToken: {platform: string; token: string};
}

export interface HttpWatchedDrug {
  watchedDrugId: string;
  prices: DrugPrice[];
  pickedDrug: HttpPickedDrug;
  savedCoupon?: DrugPrice;
  dateSaved: Date;
  lastRefreshed: Date;
}

export interface DrugPrice {
  pickedDrug: HttpPickedDrug;
  pharmacy: string;
  pharmacyType: PharmacyType;
  nabp?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  ndc: string;
  price: number;

  distance: number;

  latitude: number;
  longitude: number;

  closest: boolean;
  cheapest: boolean;
  preferredPharmacy: boolean;
  preferredPharmacyType: boolean;

  coupon: DrugCoupon;
}

export interface DrugCoupon {
  provider: 'rxedo' | 'singlecare';
  retrieved: boolean;
  groupNumber: string;
  memberId: string;
  rxBin: string;
  pcn: string;

  pharmacistPhoneNumber: string;
  memberPhoneNumber: string;

  user?: string;
  password?: string;
}

export interface BrokerDetails {
  id: string;
  member_id: string;
  company_name: string;
  url_slug: string;
  share_url_slug?: string;
  group_number: string;
  isDefault: boolean;
  uniqueMemberRegistration: boolean;
  externalMemberId?: string;
  externalMemberIdSuffix?: string;
  requireDateOfBirth?: boolean;
  externalProvider: 'direct-care' | 'test';
}

export interface Pharmacy {
  address1: string;
  address2: string;
  city: string;
  latitude: number;
  longitude: number;
  postalCode: string;
  state: string;
  distance: number;
  nabp: string;
  name: string;
  pharmacyType: PharmacyType;
}

export interface GetDrugDetailsRequest {
  ndc: string;
}

export interface GetDrugDetailsResponse {
  information: SinglecareMonoGraphData;
  faqs: SinglecareFAQ[];
  imageUrl: string;
}

export interface SinglecareMonoGraphData {
  BrandNames: string[];
  ConsumerForms: string[];
  ConsumerRoutes: string[];
  Contraindications: string[];
  DrugFoodAvoidings: string[];
  HowToUses: string[];
  Indications: string[];
  LessSeriousSideEffects: string[];
  MissedDoses: any[];
  NDCs: string[];
  Pronunciations: string[];
  SeriousSideEffects: string[];
  StorageDisposals: string[];
  TherapeuticClasses: string[];
  WarningCautions: string[];
}

export interface SinglecareFAQ {
  IsActive: boolean;
  IsRequired: boolean;
  Question: SinglecareQuestion;
  QuestionId: number;
  QuestionSetId: number;
  QuestionSetQuestionId: number;
  SortOrder: number;
}

export interface SinglecareQuestion {
  Answers: SinglecareAnswer[];
  Description: string;
  IsActive: boolean;
  QuestionId: number;
  Text: string;
}

export interface SinglecareAnswer {
  AnswerId: number;
  Description: string;
  IsActive: boolean;
  QuestionId: number;
  SortOrder: number;
  Text: string;
}

export interface GetDrugStructureRequest {
  name: string;
}

export interface GetDrugStructureResponse {
  drugResult: HttpDrugResult | null;
}

export interface HttpDrugResult {
  brand: HttpDrug;
  generic: HttpDrug;
}

export interface HttpDrug {
  name: string;
  doseForms: HttpDoseForm[];
}

export interface HttpDoseForm {
  form: string;
  dispensables: HttpDispensable[];
}

export interface HttpDispensable {
  strengthItem: string;
  dispensedQuantities: HttpDispensedQuantity[];
  ndcs: HttpNDC[];
}

export interface HttpDispensedQuantity {
  quantity: string;
  displayQuantity: string;
}

export interface HttpNDC {
  ndc: string;
  name: string;
  fullName: string;
  isGeneric: boolean;
}

export interface SearchRequest {
  query: string;
}

export interface SearchResponse {
  results: string[];
}

export interface LegacySearchResponse {
  results: {
    drug: {
      genericName: string;
      brandName: string;
      doseForms: {
        doseFormId: string;
        form: string;
        rank: number;
        dispensables: {
          dispensableId: string;
          strength: string;
          strengthUnitOfMeasure: string;
          strengthRank: number;
          quantities: {
            quantity: string;
          }[];
          ndcs: {
            ndc: string;
            drugName: string;
            rank: number;
          }[];
        }[];
      }[];
    };
    doseFormId: string;
    dispensableId: string;
    ndc: string;
  }[];
}

export interface PriceV2Request {
  ndc: string;
  zipcode?: string;
  quantity: number;
  pharmacy?: PharmacyPriceRequest;
}

export interface PricingV2Response {
  prices: DrugPriceV2[];
}

export interface DrugPriceV2 {
  pharmacy: string;
  pharmacyType: PharmacyType;
  nabp?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  ndc: string;
  price: number;
  uncPrice: number;
  percentSavings: string;

  distance: number;

  latitude: number;
  longitude: number;

  closest: boolean;
  cheapest: boolean;
  preferredPharmacy: boolean;
  preferredPharmacyType: boolean;

  coupon: DrugCoupon;
}

export interface GetDrugStructureV2Request {
  seoName: string;
}

export interface HttpDrugResultV2 {
  brands: HttpDrugV2[];
  generics: HttpDrugV2[];
}

export interface HttpDrugV2 {
  name: string;
  doseForms: HttpDoseFormV2[];
}

export interface HttpDoseFormV2 {
  form: string;
  dispensables: HttpDispensableV2[];
}

export interface HttpDispensableV2 {
  strengthItem: string;
  dispensedQuantities: HttpDispensedQuantityV2[];
}

export interface HttpDispensedQuantityV2 {
  quantity: number;
  ndc: HttpNDCV2;
}

export interface HttpNDCV2 {
  displayQuantity: number;
  dosage: string;
  form: string;
  fullName: string;
  gpi: string;
  inactive: boolean;
  isGeneric: boolean;
  marketingForm: string;
  marketingName: string;
  marketingSEOName: string;
  ndc: string;
  name: string;
  numScripts: number;
  packageQuantity: number;
  packageSize: number;
  packageSizeUOM: string;
  priceUsualAndCustomary: number;
  quantity: number;
  repack: boolean;
  rxNorm: string;
  seoName: string;
  strength: number;
  strengthString: string;
  strengthUnitOfMeasure: string;
}

export interface GetDrugInformationV2Request {
  ndc: string;
}

export interface DrugInformationV2 {
  Drug: DrugInformationV2Drug;
  FAQs: any[];
  ImageUrl: string;
  MonoGraphData: DrugInformationV2MonoGraphData;
  RelatedDrugs: DrugInformationV2RelatedDrug[];
}

export interface DrugInformationV2Drug {
  CanonicalURL: string;
  DeaClassCode: string;
  Description: string;
  DisplayQuantity: number;
  Dosage: string;
  Form: string;
  FullName: string;
  GPI: string;
  Inactive: boolean;
  IsGeneric: boolean;
  MarketingForm: string;
  MarketingName: string;
  MarketingSEOName: string;
  MetaDescription: string;
  MetaTitle: string;
  NDC: string;
  Name: string;
  NumScripts: number;
  PackageQuantity: number;
  PackageSize: number;
  PackageSizeUOM: string;
  PriceUsualAndCustomary: number;
  Quantity: number;
  Repack: boolean;
  RxNorm: string;
  SEOName: string;
  SEONoIndex: boolean;
  Strength: number;
  StrengthString: string;
  StrengthUnitOfMeasure: string;
  TopPrescriptionSortOrder: number;
  Treatment: string;
  UnitDoseUnitUsePkgCode: string;
}

export interface DrugInformationV2MonoGraphData {
  BrandNames: string[];
  ConsumerForms: string[];
  ConsumerRoutes: string[];
  Contraindications: string[];
  DrugFoodAvoidings: string[];
  HowToUses: string[];
  Indications: string[];
  LessSeriousSideEffects: string[];
  MissedDoses: any[];
  NDCs: string[];
  Pronunciations: string[];
  SeriousSideEffects: string[];
  StorageDisposals: string[];
  TherapeuticClasses: string[];
  WarningCautions: string[];
}

export interface DrugInformationV2RelatedDrug {
  DrugName: string;
  SEOName: string;
}

export interface SearchResponseV2 {
  results: SinglecareDrugSearchResult[];
}

export interface SinglecareDrugSearchResult {
  score: number;
  display_name: string;
  id: string;
  seo_name: string;
  ndc: string;
}

export interface GetVoucherRequest {
  price: DrugPriceV2;
}

export interface GetVoucherResponse {
  price: DrugPriceV2;
}

export interface AuthenticateRequest {
  apiKey: string;
}

export interface AuthenticateResponse {
  accessToken: string;
  expirationDate: string;
}

export interface SearchDrugsRequest {
  drugName: string;
}

export interface SearchDrugsResponse {
  results: string[];
}

export interface DrugStructureRequest {
  drugName: string;
}

export interface DrugStructureResponse {
  generic: DrugDetail;
  brand: DrugDetail;
}

export interface DrugDetail {
  name: string;
  doseForms: DrugDoseForm[];
}

export interface DrugDoseForm {
  form: string;
  dispensables: DrugDispensable[];
}

export interface DrugDispensable {
  strengthItem: string;
  dispensedQuantities: DrugDispensedQuantity[];
  ndcs: DrugNDC[];
}

export interface DrugDispensedQuantity {
  quantity: string;
}

export interface DrugNDC {
  ndc: string;
  name: string;
  fullName: string;
  isGeneric: boolean;
}

export interface DrugInfoRequest {
  ndc: string;
}

export interface DrugInfoResponse {
  information: DrugInformationV2;
}

export interface PriceDrugRequest {
  ndc: string;
  zipcode?: string;
  quantity: number;
  forceBranded: boolean;
}

export interface PriceDrugResponse {
  pickedDrug: HttpPickedDrug;
  prices: ExternalDrugPrice[];
}

export interface ExternalDrugPrice {
  pickedDrug: HttpPickedDrug;
  pharmacy: string;
  pharmacyType: PharmacyType;
  nabp?: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  zipCode: string;
  ndc: string;
  price: number;

  distance: number;

  latitude: number;
  longitude: number;

  closest: boolean;
  cheapest: boolean;
  coupon: ExternalDrugCoupon;
}

export interface ExternalDrugCoupon {
  groupNumber: string;
  memberId: string;
  rxBin: string;
  pcn: string;

  pharmacistPhoneNumber: string;
  memberPhoneNumber: string;
}

export interface SearchDrugsV2Request {
  drugName: string;
}

export interface SearchDrugsV2Response {
  results: SinglecareDrugSearchResult[];
}

export interface DrugStructureV2Response {
  brands: HttpDrugV2[];
  generics: HttpDrugV2[];
}

export interface PriceDrugV2Request {
  ndc: string;
  zipcode?: string;
  quantity: number;
  pharmacy?: PharmacyPriceRequest;
  forceBranded: boolean;
}

export interface PriceDrugV2Response {
  pickedDrug: HttpPickedDrug;
  prices: DrugPriceV2[];
}

export interface AccountLoginRequest {
  email: string;
  password: string;
}

export interface AccountLoginResponse extends AccountResponse {
  jwt: string;
  user: HttpAccountUser;
}

export interface AccountResponse {
  user: HttpAccountUser;
}

export interface HttpAccountUser {
  id: string;
  email: string;
  primaryPartnerId?: string;
  phoneNumber?: string;
  accountSetUp: boolean;
  accountOwner?: string;

  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;

  contactType: AccountContactTypes;
  hubspotId?: number;

  allowPromotionalCommunication: boolean;
  createdDate: Date;
  updatedDate: Date;
  notes: HttpNote[];

  cards: HttpCard[];
  partners: HttpPartner[];
}

export interface GetPartnerSimpleResponse {
  partner: HttpPartnerExtendedSimple;
}

export interface HttpPartnerExtendedSimple extends HttpPartner {
  account: HttpAccount;
  groupTree: HttpPartnerGroupTreeItemSimple;
}

export interface HttpPartnerGroupTreeItemSimple {
  startingGroupCode: string;
  startingMemberId: string;
  partnerId: string;
  partnerName: string;
  commissionAmount: number;
  partnerType: string;
  children: HttpPartnerGroupTreeItemSimple[];
  cards: (
    | HttpCard
    | {
        owned: false;
        companyName?: string;
        groupCode: string;
        memberId: string;
        urlSlug?: string;
      }
  )[];
  parent?: HttpPartnerGroupTreeItemSimple;
}

export interface SetStripeBankAccountRequest {
  partnerId: string;
  stripeBankAccountToken: string;
}

export interface RequestW9AccountRequest {
  partnerId: string;
}

export interface RequestW9AccountResponse {}

export interface AccountCommissionResultResponse {
  commissionResult: HttpCommissionResult;
  partnerOwnedCards: {groupCode: string; memberId: string}[];
  allCards: {_id: ObjectId; partnerName: string; partnerId: ObjectId; groupCode: string; memberId: string}[];
  partnerResults: {
    partnerName: string;
    groupCode: string;
    memberId: string;
    numberOfClaims: number;
    totalAmountDue: number;
    totalAmountSaved: number;
    averagePercentSaved: number;
  }[];
  lastCommissionResult: HttpCommissionResult;
}

export interface HttpCommissionResult {
  dateRan: Date;
  monthRan: string; // '2019-08'
  groupedClaims: {
    groupCode: string;
    memberId: string;
    totalAmountDue: number;
    totalAmountSaved: number;
    averagePercentSaved: number;
    compensable: number;
  }[];
  byDateClaims: {
    date: string;
    totalAmountDue: number;
    totalAmountSaved: number;
    averagePercentSaved: number;
    compensable: number;
  }[];
  numberOfClaims: number;
  totalAmountDue: number;
  totalAmountSaved: number;
  averagePercentSaved: number;
  final: boolean;
}

export interface SlugsByDayRequest {
  partnerId: string;
}

export interface AccountForgotPasswordRequest {
  email: string;
}

export interface PartnerContactBrokerRequest extends PartnerRequest {
  reason: 'new-contract' | 'stripe-account';
}

export interface PartnerLedgerDebitReportRequest {
  partnerId: string;
  debitLedgerId: string;
}

export interface PayoutViableResponse {
  viable: {
    contract: {[partnerId: string]: boolean};
    contractSigned: {[partnerId: string]: boolean};
    stripeCreated: boolean;
    stripeSetup: boolean;
    stripeBankAccount: boolean;
    w9Created: boolean;
    w9Signed: boolean;
    payoutThreshold: boolean;
    payoutThresholdAmount: number;
    payout: number;
    canPayout: boolean;
  };
}

export interface AccountResetPasswordRequest {
  email: string;
  code: string;
  newPassword: string;
}

export interface PartnerUpdateAccountsRequest {
  accountId: string;
  email: string;
  phoneNumber: string;
  accountOwner: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
}

export interface AccountCreateCardRequest extends PartnerRequest {
  companyName?: string;
  vanityUrl?: string;
}

export interface PartnerRequestsResponse {
  requests: HttpPartnerRequest[];
}

export interface HttpPartnerRequest {
  _id: ObjectId;
  createdDate: Date;
  initiatingPartnerId: ObjectId;
  parentPartnerId: ObjectId;
  masterPartnerId: ObjectId;
  request: DBPartnerRequestBody;
  approved: boolean;
  adjudicatedDate: Date;
  adjudicatedBy: string;
}

export interface DownloadMaterialRequest {
  title: string;
  partnerId: string;
}

export interface GetPartnerAndCardRequest {
  partnerId: string;
  cardId: string;
}

export interface GetHealthConfigResponse {
  networks: HealthConfiguration[];
}

export interface GetPartnerReferralsResponse {
  referrals: {
    commissionReferrals: DBPartnerCommissionReferral[];
    id: string;
    partnerName: string;
  }[];
}

export interface DBPartnerCommissionReferral {
  commissionAmount: number;
  partnerId: ObjectID;
  dateStarted: Date;
}

export interface GetPartnerHealthReportsRequest extends PartnerRequest {
  startDate: string;
  endDate: string;
  cardId: string;
}

export interface GetPartnerHealthReportsResponse {
  usersCreated: number;
  visitsCompleted: number;
  visitsAsync: number;
  visitsPhone: number;
  visitsVideo: number;
  bellaUtilization: number;
  prescriptionSavings: number;
  prescriptionCount: number;
}

export interface GetPartnerHealthReportsTopReasonsResponse {
  reasons: {reasonCode: string; value: number}[];
}

export interface GetPartnerHealthReportsTopDrugsResponse {
  topDrugs: {drugName: string; amountSaved: number}[];
}

export interface GetPartnerHealthReportsChartDataResponse {
  data: {label: string; value: number}[];
}

export interface GetPartnerHealthReportsTopStatesResponse {
  states: {name: string; value: number}[];
}

export interface GetPartnerHealthClientsResponse {
  clients: (HttpClient & {
    urlSlug?: string;
    numberOfEmployees?: number;
    startingDate?: string;
    isSubsidized?: boolean;
  })[];
}

export interface GetPartnerHealthClientRequest {
  clientId: string;
}

export interface GetPartnerHealthClientResponse {
  client: HttpClient & {
    groupCode: string;
    memberId: string;
    urlSlug: string;
    numberOfEmployees?: number;
    startingDate?: string;
    isSubsidized?: boolean;
  };
}

export interface UpdatePartnerHealthClientRequest {
  clientId: string;
  companyName: string;
  urlSlug: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  numberOfEmployees: number;
  startingDate: string;
  isSubsidized: boolean;
}

export interface GetPartnerHealthClientUsersRequest {
  page: string;
  offset: string;
  filter: string;
  cardId: string;
}

export interface GetPartnerHealthClientUsersResponse {
  clientUsers: HttpClientUserSearch[];
  count: number;
}

export interface CreatePartnerHealthClientUserRequest {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  cardId: string;
}

export interface GetPartnerHealthClientUserResponse {
  clientUser: HttpClientUser;
}

export interface ActivatePartnerHealthClientUserRequest {
  cardId: string;
  clientUserId: string;
}

export interface WebBrokerDetailsRequest {
  groupCode: string;
  memberId: string;
}

export interface WebBrokerDetailsResponse {
  brokerDetails: {
    name: string;
    groupCode: string;
    memberId: string;
    urlSlug: string;
  };
}

export interface WebBrokerContactFormRequest {
  groupCode: string;
  memberId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface SubmitHealthGroupRequestRequest {
  partnerId: string;
  companyName: string;
  startingDate: string;
  numberOfEmployees: number;
  urlSlug: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  isSubsidized: boolean;
  requiresEligibility: boolean;
  notes: string;
}

export interface SubmitHealthGroupRequestResponse {
  requestId: string;
}

export interface GetPartnerCardsResponse {
  cards: HttpCardLight[];
}

export interface GetPartnerTasksResponse {
  tasks: PartnerTask[];
}

export interface CompletePartnerTaskRequest extends PartnerRequest {
  type: PartnerTaskType;
}

export interface CompletePartnerTaskRankRequest extends PartnerRequest {
  rank: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  name: string;
}

export interface StartCardOrderPaymentRequest extends PartnerRequest {
  tokenId: string;
}

export interface StartCardOrderPaymentResponse {
  cardId: string;
  last4: string;
  exp_month: string;
  exp_year: string;
}

export interface ConfirmCardOrderPaymentRequest extends PartnerRequest {
  cardId: string;
  email: string;
  companyName: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
  language: 'english' | 'spanish';
  cardType: 'generic' | 'co-branded' | 'logo';
  quantity: number;
  shippingMethod: 'standard' | 'expedited' | 'rush';
  qrCodeRequested: boolean;
  requestedDeliveryDate: string;
  logoDataUri?: string;
  logoFileType?: string;
  qrCodeDataUri?: string;
  cobrandedName?: string;
}

export interface ConfirmCardOrderPaymentResponse {
  receipt_url: string;
}

export interface GetPartnerHealthClientRequestsResponse {
  requests: HttpClientRequestSearch[];
}

export interface GetPartnerHealthClientRequestRequest extends PartnerRequest {
  requestId: string;
}

export interface GetPartnerHealthClientRequestResponse {
  request: HttpClientRequest;
}

export interface GetHealthPartnerDownloadsByCardRequest {
  partnerId: string;
  offset: string;
  count: string;
}

export interface GetHealthPartnerDownloadsByCardResponse {
  usersByCard: {count: number; groupCode: string; memberId: string}[];
}

export interface RequestPartnerContractResponse {
  status: 'signed' | 'not-signed';
  embedUrl?: string;
}

export interface CheckPartnerContractsSignedResponse {
  chContractIsSigned: boolean;
  w9ContractIsSigned: boolean;
  tasks?: PartnerTask[];
}

export interface GetPharmacyRequest {
  zipcode?: string;
}

export interface GetPharmacyResponse {
  pharmacies: Pharmacy[];
}

export interface PortalAnalyticsLoginRequest {
  userType: 'admin' | 'partner' | 'client';
}

export interface PortalLoginRequest {
  email: string;
  password: string;
}

export interface PortalLoginResponse {
  accountType: 'partner' | 'admin' | 'client';
  jwt: string;
  user: HttpAccountUser | HttpAdminUser | HttpClientUser;
  clientDetails?: ClientUserCompanyDetails;
}

export interface ClientUserCompanyDetails {
  consultationCosts: {
    asyncCost: number;
    phoneCost: number;
    videoCost: number;
    chatCost: number;
  };
  billingAddress: {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  groupCode: string;
  memberId: string;
  urlSlug: string;
  companyName: string;
}

export interface GetClientUserAndDetailsResponse {
  user: HttpClientUser;
  clientDetails: ClientUserCompanyDetails;
}

export interface PortalForgotPasswordRequest {
  email: string;
}

export interface MessagePriceRequest {
  pharmacy: string;
  price: string;
  bin: string;
  group: string;
  pcn: string;
  memberId: string;
  phoneNumber?: string;
  questionsNumber: string;
  pharmacistPhoneNumber: string;
  drugName: string;
  provider?: 'rxedo' | 'singlecare';
}

export interface EmailPriceRequest {
  pharmacy: string;
  price: string;
  bin: string;
  group: string;
  pcn: string;
  memberId: string;
  email: string;
  questionsNumber: string;
  pharmacistPhoneNumber: string;
  drugName: string;
  provider?: 'rxedo' | 'singlecare';
}

export interface MessageAppRequest {
  urlSlug: string;
  phoneNumber: string;
  health?: boolean;
}

export interface SubmitContactDetailsRequest {
  name: string;
  phone: string;
  companyName: string;
  email: string;
}

export interface MessageAppCustomizedRequest {
  senderName: string;
  recipientName: string;
  recipientPhoneNumber: string;
  urlSlug: string;
  messageOption: '0' | '1' | '2' | '3';
}

export interface ShareCHFlyerRequest {
  flyers: {
    employer: boolean;
    voluntary: boolean;
    overview: boolean;
  };
  sender: {name: string; email: string};
  recipients: {name: string; email: string}[];
  qrCodeBase64?: string;
  companyNameBase64?: string;
}

export interface GetBrokerDetailsRequest {
  groupCode: string;
  memberId: string;
  dateOfBirth?: string;
}

export interface GetBrokerDetailsResponse {
  brokerDetails: BrokerDetails;
}

export interface GetBrokerDetailsSlugRequest {
  slug: string;
}

export interface RegisterRequest {
  phoneNumber: string;
  isLogin: boolean;
  fromShare?: boolean;
  brokerDetails: BrokerDetails;
  phoneDetails: DBPhoneDetails;
}

export interface LightJwtResponse {
  jwt: string;
}

export interface ValidateRequest {
  activationCode: string;
}

export interface JwtGetUserResponse extends UserResponse {
  jwt: string;
}

export interface UserResponse {
  user: HttpUser;
}

export interface SetPushTokenRequest {
  token: string;
  platform: string;
}

export interface SetPharmacyPreferencesRequest {
  preferredPharmacyType?: PharmacyType;
  preferredPharmacy?: Pharmacy;
}

export interface GetUserRequest {}

export interface WatchDrugRequest {
  watchedDrugId: string | null;
  prices: DrugPrice[];
  pickedDrug: HttpPickedDrug;
  saveCoupon: DrugPrice;
}

export interface WatchDrugResponse extends UserResponse {
  watchedDrugId: string;
}

export interface RadarGeoRequest {
  events: GeoEvent[];
  user: GeoUser;
}

export interface GeoEvent {
  _id: string;
  createdAt: string;
  live: boolean;
  type: string;
  user: GeoUserSmall;
  geofence?: Geofence;
  location: GeoLocation;
  locationAccuracy: number;
  confidence: number;
  place?: GeoPlace;
}

export interface GeoUserSmall {
  _id: string;
  userId: string;
  description: string;
  metadata: GeoMetadata;
  deviceId?: string;
}

export interface GeoMetadata {
  session: string;
}

export interface Geofence {
  _id: string;
  tag: string;
  externalId: string;
  description: string;
}

export interface GeoLocation {
  type: string;
  coordinates: number[];
}

export interface GeoPlace {
  _id: string;
  name: string;
  chain: Chain;
  location: GeoLocation;
  categories: string[];
}

export interface Chain {
  name: string;
  slug: string;
  domain: string;
}

export interface GeoUser {
  _id: string;
  live: boolean;
  userId: string;
  deviceId: string;
  description: string;
  metadata: GeoMetadata;
  updatedAt: string;
  createdAt: string;
  location: GeoLocation;
  locationAccuracy: number;
  stopped: boolean;
  foreground: boolean;
  deviceType: string;
  ip: string;
  geofences: Geofence[];
  place: GeoPlace;
  insights: GeoInsights;
}

export interface GeoInsights {
  state: GeoState;
}

export interface GeoState {
  home: boolean;
  office: boolean;
  traveling: boolean;
}

export interface UnwatchDrugRequest {
  watchedDrugId: string;
}

export interface UnwatchDrugResponse extends UserResponse {}

export interface StartMailOrderServiceRequest {
  personDetails: TpsPersonDetails;
  billingAddress: TpsBillingAddress;
  shippingAddress: TpsShippingAddress;
  pharmacy: TpsPharmacy;
  medicalDetails: TpsMedicalDetails;
  ccDetails: TpsCCDetails;
  drug: HttpPickedDrug;
}

export type TpsPersonDetails = {
  firstName: string;
  middleName: string;
  lastName: string;
  emailAddress: string;
  dateOfBirth: string;
  gender: string;
};

export type TpsBillingAddress = {
  streetAddress: string;
  otherAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
  homePhone: string;
};

export type TpsShippingAddress = {
  streetAddress: string;
  otherAddress: string;
  city: string;
  stateCode: string;
  zipCode: string;
};

export type TpsPharmacy = {
  pharmacyName: string;
  pharmacyPhone: string;
};

export type TpsMedicalDetails = {
  allergies: string[];
  medications: string[];
  conditions: string[];
};

export type TpsCCDetails = {
  card_number: string;
  card_cvc: string;
  exp_month: string;
  exp_year: string;
};

export interface StartMailOrderServiceResponse {
  result: StartMailOrderServiceResult;
}

export type StartMailOrderServiceResult =
  | {
      success: boolean;
    }
  | {errors: string[]};

export interface GetBrokerDetailsV2Request {
  groupCode: string;
  memberId: string;
  dateOfBirth?: string;
  noCode: boolean;
}

export interface GetBrokerDetailsV2Response {
  brokerDetails: BrokerDetailsV2;
}

export interface BrokerDetailsV2 {
  id: string;
  memberId: string;
  groupCode: string;
  companyName: string;
  urlSlug: string;
  shareUrlSlug: string;
  partnerId: string;
  cardId: string;
  isDefault: boolean;
  externalMemberIdSuffix?: string;
  externalMemberId?: string;
  uniqueMemberRegistration: boolean;
  externalProvider: string;
  requireDateOfBirth: boolean;
  pharmacist: boolean;
}

export interface GetBrokerDetailsSlugV2Request {
  slug: string;
}

export interface JwtGetUserV2Response extends UserV2Response {
  jwt: string;
}

export interface UserV2Response {
  user: HttpUserV2;
}

export interface HttpUserV2 {
  id: string;
  createdDate: string;
  phoneNumber: string;
  brokerDetails: BrokerDetailsV2;
  pushToken: {platform: string; token: string};
  pharmacistDetails?: HttpUserV2PharmacyDetails;
  prescryptiveDetails?: {carrierPCN: string; memberId: string; rxBin: string; rxGroup: string};
  shareCode?: string;
}

export type HttpUserV2PharmacyDetails = {
  pharmacy?: PharmacyDetails;
  emailAddress?: string;
  zipcode?: string;
};

export type PharmacyDetails = {
  address1: string;
  address2: string;
  postalCode: string;
  nabp: string;
  pharmacyType: PharmacyType;
  pharmacy: string;
};

export interface RegisterV2Request {
  phoneNumber: string;
  isLogin: boolean;
  fromShare?: boolean;
  fromShareCode?: string;
  brokerDetails: BrokerDetailsV2;
  phoneDetails: DBPhoneDetails;
}

export interface MigrateUserRequest {
  jwt: string;
}

export interface GetCabinetRequest {
  page: number;
}

export interface GetCabinetResponse {
  drugs: HttpUserCabinetDrug[];
  total: number;
}

export interface HttpUserCabinetDrug {
  userCabinetDrugId: string;
  price: DrugPriceV2;
  pickedDrug: HttpPickedDrug;
  dateSaved: string;
  lastRefreshed: string;
}

export interface SaveToCabinetRequest {
  pickedDrug: HttpPickedDrug;
  price: DrugPriceV2;
}

export interface DeleteFromCabinetRequest {
  userCabinetDrugId: string;
}

export interface GetShareCountResponse {
  count: number;
}

export interface SetPrescyptiveDetailsRequest {
  carrierPCN: string;
  memberId: string;
  rxBin: string;
  rxGroup: string;
}

export interface SetPharmacistDetailsRequest {
  pharmacy?: PharmacyDetails;
  emailAddress?: string;
  zipcode?: string;
}

export interface PharmacistGetRewardsResponse {
  amountPerStar: number;
  minPayout: number;
  pending: {numberOfClaims: number; totalAmountDue: number; totalAmountSaved: number; averagePercentSaved: number};
  active: {numberOfClaims: number; totalAmountDue: number; totalAmountSaved: number; averagePercentSaved: number};
}

export interface GetStaticCardsResponse {
  cards: StaticCard[];
}

export interface StaticCard {
  rxBin: string;
  pcn: string;
  pharmacyType: PharmacyType;
  provider: 'rxedo' | 'singlecare';
  pharmacyName: string;
}

export interface GetZipcodeRequest {
  lat: string;
  lng: string;
}

export interface GetZipcodeResponse {
  zipcode: string;
}

export interface CreatePartnerContactRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  notes: string;
  referredBy: string;
  partnerType: string;
}

export interface GetLatLngRequest {
  zipcode: string;
}

export interface GetLatLngResponse {
  lat: string;
  lng: string;
}

export interface GetKrogerBrandRequest {
  zipcode: string;
}

export interface GetKrogerBrandResponse {
  krogerPharmacyType: KrogerPharmacyType | null;
}

export interface MetaResponse {
  pushModels: PushModels;
}

export type PushModels =
  | {
      type: 'pharmacy-push';
      pharmacy: Pharmacy;
    }
  | {
      type: 'text';
    }
  | {
      type: 'visit';
      visitId: string;
    };

export interface PartnerSlugRequest {
  partnerId: string;
  isShare: string;
}

export interface WebContactFormRequest {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  referral: string;
  contactTypes: string[];
  message: string;
}

export interface WebCalculatorRequest {
  userId: string;
  step: string;
  value: string;
}

export interface DocusignWebhookPostRequest {
  partnerId: string;
  type: 'W9' | 'Contract' | 'CHContract';
}

export interface HealthAppOpenRequest {
  firstTime: boolean;
  fromShare: boolean;
  fromLink: boolean;
}

export interface HealthGetZipUrlResponse {
  url: string;
}

export interface GetHealthClientMembersRequest {
  count: string;
  offset: string;
  filter: string;
  active: 'active' | 'inactive' | '';
}

export interface GetHealthClientMembersResponse {
  members: HttpHealthMemberSearch[];
  count: number;
}

export interface HttpHealthMemberSearch {
  id: string;
  firstName?: string;
  lastName: string;
  dob: FullDate;
  zipCode: string;
  phoneNumber?: string;
  employeeType: 'primary' | 'spouse' | 'dependent';
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  uniqueId: string;
}

export interface GetHealthClientMembersAdvancedSearchRequest {
  count: string;
  page: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  phoneNumber: string;
  dateOfBirth: string;
  active: 'active' | 'inactive' | '';
  externalMemberId: string;
}

export interface GetHealthClientMembersCSVRequest {
  columns: {
    uniqueId: boolean;
    dateOfBirth: boolean;
    lastName: boolean;
    firstName: boolean;
    zipcode: boolean;
    employeeType: boolean;
    email: boolean;
    state: boolean;
    phoneNumber: boolean;
    activeDate: boolean;
    deactivatedDate: boolean;
  };
  filter: string;
  firstName: string;
  lastName: string;
  zipCode: string;
  phoneNumber: string;
  dateOfBirth: string;
  active: 'active' | 'inactive' | '';
  externalMemberId: string;
}

export interface GetHealthClientMembersCSVResponse {
  csv: string;
}

export interface GetHealthClientMemberRequest {
  id: string;
}

export interface GetHealthClientMemberAndDependentsResponse {
  member: HttpHealthMember;
  dependents: HttpHealthMember[];
}

export interface HttpHealthMember {
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: FullDate;
  zipcode: string;
  employeeType: EmployeeType;
  createdDate: Date;
  email?: string;
  state?: string;
  phoneNumber?: string;
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  externalMemberId: string;
  manualUpdate?: {
    date: Date;
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
  };
  uniqueId: string;
}

export interface CreateHealthClientMemberRequest {
  firstName: string;
  lastName: string;
  dateOfBirth: FullDate;
  zipcode: string;
  employeeType: EmployeeType;
  email?: string;
  state?: string;
  phoneNumber?: string;
  activeDate: FullDate;
  deactivatedDate: FullDate | null;
  externalMemberId: string;
  isPrimary: boolean;
  primaryEdiId?: string;
}

export interface GetHealthClientMemberResponse {
  member: HttpHealthMember;
}

export interface CreateHealthClientFamilyRequest {
  primary: {
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
    employeeType: EmployeeType;
    email?: string;
    state?: string;
    phoneNumber?: string;
    activeDate: FullDate;
    externalMemberId: string;
  };
  spouse?: {
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
    employeeType: EmployeeType;
    email?: string;
    state?: string;
    phoneNumber?: string;
    activeDate: FullDate;
  };
  dependents: {
    firstName: string;
    lastName: string;
    dateOfBirth: FullDate;
    zipcode: string;
    employeeType: EmployeeType;
    email?: string;
    state?: string;
    phoneNumber?: string;
    activeDate: FullDate;
  }[];
}

export interface UpdateHealthClientMemberRequest {
  id: string;

  firstName?: string;
  lastName?: string;
  dateOfBirth?: FullDate;
  zipcode?: string;
  employeeType?: EmployeeType;
  email?: string;
  state?: string;
  phoneNumber?: string;
  activeDate?: FullDate;
  deactivatedDate?: FullDate | null;
}

export interface DeleteHealthClientMemberRequest {
  id: string;
}

export interface GetHealthClientReportsRequest {
  startDate: string;
  endDate: string;
  memberFilter: 'primary' | 'all';
}

export interface GetHealthClientReportsResponse {
  usersCreated: {
    primaries: number;
    spouses: number;
    dependents: number;
    pets: number;
  };
  visitsCompleted: number;
  visitsAsync: number;
  visitsPhone: number;
  visitsVideo: number;
  bellaUtilization: number;
  prescriptionSavings: number;
  prescriptionCount: number;
}

export interface GetHealthClientReportsTopReasonsResponse {
  reasons: {reasonCode: string; value: number}[];
}

export interface GetHealthClientReportsTopDrugsResponse {
  topDrugs: {drugName: string; amountSaved: number}[];
}

export interface GetHealthClientReportsChartDataResponse {
  data: {label: string; value: number}[];
}

export interface GetHealthClientReportsTopStatesResponse {
  states: {name: string; value: number}[];
}

export interface HealthClientSendCorrespondenceRequest {
  replyEmail: string;
  subject: string;
  message: string;
}

export interface HealthClientSendCorrespondenceResponse {
  correspondence: HttpClientCorrespondence;
}

export interface HttpClientCorrespondence {
  id: string;
  replyEmail: string;
  cardId: string;
  partnerId: string;
  createdDate: Date;
  subject: string;
  message: string;
  groupCode: string;
  memberId: string;
  clientId: string;
}

export interface GetHealthClientImportReportsRequest {
  page: string;
  count: string;
}

export interface GetHealthClientImportReportsResponse {
  count: number;
  reports: HttpClientImportReportSimple[];
}

export interface HttpClientImportReportSimple {
  id: string;
  createdDate: string;
  batchId: string;
  fileName: string;
  details: {membersAdded: number; membersUpdated: number; membersRemoved: number};
  errorsLength: number;
}

export interface GetHealthClientImportReportRequest {
  reportId: string;
}

export interface GetHealthClientImportReportResponse {
  report: HttpClientImportReportExpanded;
}

export interface HttpClientImportReportExpanded {
  id: string;
  createdDate: string;
  batchId: string;
  fileName: string;
  details: {membersAdded: number; membersUpdated: number; membersRemoved: number};
  errors: {row: {[key: string]: string}; error: string}[];
}

export interface UpdateClientUserDetailsRequest {
  firstName: string;
  clientUserId: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

export interface UpdateClientUserDetailsResponse {
  clientUser: HttpClientUser;
}

export interface UpdateClientBillingDetailsRequest {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface UpdateClientBillingDetailsResponse {
  client: HttpClient;
}

export interface GetClientPaymentMethodsResponse {
  cards: ClientCardPaymentMethod[];
  bankAccounts: ClientBankAccountPaymentMethod[];
  isSetup: boolean;
}

export interface ClientCardPaymentMethod {
  id: string;
  brand: string;
  country: string | null;
  description?: string | null;
  exp_month: number;
  exp_year: number;
  last4: string;
}

export interface ClientBankAccountPaymentMethod {
  id: string;
  bankName: string;
  last4: string;
  status: 'new' | 'validated' | 'verified' | 'verification_failed' | 'errored';
}

export interface VerifyACHPaymentMethodRequest {
  bankAccountId: string;
  amounts: [number, number];
}

export interface VerifyACHPaymentMethodResponse {
  status: string;
}

export interface DeleteCardPaymentMethodRequest {
  cardId: string;
}

export interface DeleteBankAccountPaymentMethodRequest {
  bankAccountId: string;
}

export interface CreateHealthClientStripeCustomerRequest {
  clientUserId: string;
}

export interface CreateHealthClientStripeCustomerResponse {
  customerId: string;
}

export interface SetupClientPaymentMethodRequest {
  tokenId: string;
}

export interface PayClientInvoiceRequest {
  invoiceId: string;
  paymentMethodId: string;
  paymentMethodType: 'card' | 'ach';
}

export interface PayClientInvoiceResponse {
  invoiceId: string;
}

export interface GetClientInvoicesRequest {
  filter: 'invoiced' | 'due' | 'paid' | 'closed';
}

export interface GetClientInvoicesResponse {
  invoices: HttpClientInvoiceSimple[];
}

export interface HttpClientInvoiceSimple {
  id: string;
  amountDue: number;
  dueDate: number;
  number: string;
  periodStart: number;
  periodEnd: number;
}

export interface GetClientInvoiceRequest {
  invoiceId: string;
}

export interface GetClientInvoiceResponse {
  invoice: HttpClientInvoiceExtended;
}

export interface GetSFTPCredentialsResponse {
  username?: string;
}

export interface CreateSFTPCredentialsRequest {
  regeneratePassword: boolean;
}

export interface CreateSFTPCredentialsResponse {
  username: string;
  password?: string;
}

export interface PriceNoFilterRequest {
  ndc: string;
  lat: string;
  lng: string;
  quantity: number;
}

export interface HealthIvrUpdateStatusRequest {
  a: string; // authorization
  s: 'incoming-call' | 'start-eligibility' | 'send-text' | 'paid' | 'forward-to-ucm' | 'hang-up-on-payment'; // status
  p: string; // phone number
  z: string; // zip code
  ediId?: string; // ediId
}

export interface HealthIvrTestEligibilityRequest {
  a: string; // authorization
  p: string; // phone number
  lastName?: string;
  lastNameDigits?: string;
  dob: string;
  zipcode: string;
}

export interface HealthIvrTestEligibilityResponse {
  eligible: boolean;
  cost: number;
  ediId?: string;
}

export interface GetBrokerDetailsHealthRequest {
  groupCode: string;
  memberId: string;
  noCode: boolean;
}

export interface GetBrokerDetailsHealthResponse {
  brokerDetails: BrokerDetailsHealth;
}

export interface BrokerDetailsHealth {
  id: string;
  memberId: string;
  groupCode: string;
  companyName: string;
  urlSlug?: string;
  shareUrlSlug?: string;
  partnerId: string;
  cardId: string;
  isDefault: boolean;
  requiresEligibility: boolean;
}

export interface DetermineEligibilityRequest {
  groupCode?: string;
  memberId?: string;
  dateOfBirthStamp?: number;
  dateOfBirth?: string;
  lastName?: string;
  lastNameDigits?: string;
  zipCode: string;
}

export interface GetDetermineEligibilityResponse {
  brokerDetails: BrokerDetailsHealth;
  userDetails: HealthOnboardingUserDetails;
  networks: HealthConfiguration[];
}

export type HealthOnboardingUserDetails = {
  ediId: string;
  firstName?: string;
  lastName: string;
  email?: string;
  uniqueId: string;
  dateOfBirth: FullDate;
  zipcode: string;
  employeeType: EmployeeType;
  state?: string;
  phoneNumber?: string;
};

export interface GetBrokerDetailsSlugHealthRequest {
  slug: string;
}

export interface JwtGetUserHealthResponse extends UserHealthResponse {
  jwt: string;
}

export interface UserHealthResponse {
  user: HttpHealthUser;
  dependents: HttpUserDependent[];
}

export interface HttpHealthUser {
  id: string;
  createdDate: string;
  userDependentId: string;
  ediId: string;
  externalUniqueId: string;
  brokerDetails: BrokerDetailsHealth;
  pushToken: {platform: string; token: string};
  pushEnabled: boolean;
  preferredCommunication: 'text' | 'email';
}

export interface HttpUserDependent {
  id: string;
  relationship: 'primary' | 'spouse' | 'dependent' | 'pet';
  ediId?: string;
  uniqueId?: string;
  tm?: {
    termsSigned?: Date;
    medicareDisclaimerSigned?: Date;
  };
  x2ai?: {
    personId: string;
  };
  userDetails: HealthUserDetails;
}

export type HealthUserDetails = {
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  avatarUri?: string;
  avatarKey?: string;
  postal?: string;
  birthDate?: FullDate;
  email?: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  phone?: string;
};

export interface RegisterHealthRequest {
  phoneNumber: string;
  isLogin: boolean;
  fromShare: boolean;
  brokerDetails: BrokerDetailsHealth;
  phoneDetails: DBPhoneDetails;
  userDetails?: HealthOnboardingUserDetails;
}

export interface HealthLightJwtResponse {
  jwt: string;
}

export interface HealthSetPushTokenRequest {
  token: string;
  platform: string;
}

export interface StartStripeCustomerResponse {
  customerId: string;
  ephemeralKey: string;
}

export interface StartSetupIntentResponse {
  clientSecret: string;
}

export interface StripePlaceHoldRequest {
  paymentMethodId: string;
  visitId: string;
  dependentId: string;
  modality: ZipnosisVisitModality['slug'];
}

export type ZipModality = 'async' | 'direct-to-phone' | 'direct-to-video' | 'direct-to-chat';

export type ZipnosisVisitModality = {
  slug: ZipModality;
  price: number;
};

export interface StripePlaceHoldResponse {
  paymentIntentId: string;
  clientSecret: string;
}

export interface SubsidizeVisitRequest {
  visitId: string;
  dependentId: string;
  modality: ZipnosisVisitModality['slug'];
}

export interface StripeListCardsResponse {
  cards: StripeCard[];
}

export type StripeCard = {
  card: PaymentMethodCard;
  id: string;
};

export interface PaymentMethodCard {
  /**
   * Card brand. Can be `amex`, `diners`, `discover`, `jcb`, `mastercard`, `unionpay`, `visa`, or `unknown`.
   */
  brand: string;

  /**
   * Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you've collected.
   */
  country: string | null;

  /**
   * A high-level description of the type of cards issued in this range. (For internal use only and not typically available in standard API requests.)
   */
  description?: string | null;

  /**
   * Two-digit number representing the card's expiration month.
   */
  exp_month: number;

  /**
   * Four-digit number representing the card's expiration year.
   */
  exp_year: number;

  /**
   * Uniquely identifies this particular card number. You can use this attribute to check whether two customers who've signed up with you are using the same card number, for example. For payment methods that tokenize card information (Apple Pay, Google Pay), the tokenized number might be provided instead of the underlying card number.
   *
   * *Starting May 1, 2021, card fingerprint in India for Connect will change to allow two fingerprints for the same card --- one for India and one for the rest of the world.*
   */
  fingerprint?: string | null;

  /**
   * Card funding type. Can be `credit`, `debit`, `prepaid`, or `unknown`.
   */
  funding: string;

  /**
   * Issuer identification number of the card. (For internal use only and not typically available in standard API requests.)
   */
  iin?: string | null;

  /**
   * The name of the card's issuing bank. (For internal use only and not typically available in standard API requests.)
   */
  issuer?: string | null;

  /**
   * The last four digits of the card.
   */
  last4: string;
}

export interface StripeListChargesResponse {
  charges: StripeCharge[];
}

export interface StripeCharge {
  /**
   * Unique identifier for the object.
   */
  id: string;

  /**
   * String representing the object's type. Objects of the same type share the same value.
   */
  object: 'charge';

  /**
   * Amount intended to be collected by this payment. A positive integer representing how much to charge in the [smallest currency unit](https://stripe.com/docs/currencies#zero-decimal) (e.g., 100 cents to charge $1.00 or 100 to charge ¥100, a zero-decimal currency). The minimum amount is $0.50 US or [equivalent in charge currency](https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts). The amount value supports up to eight digits (e.g., a value of 99999999 for a USD charge of $999,999.99).
   */
  amount: number;

  /**
   * Amount in %s captured (can be less than the amount attribute on the charge if a partial capture was made).
   */
  amount_captured: number;

  /**
   * Amount in %s refunded (can be less than the amount attribute on the charge if a partial refund was issued).
   */
  amount_refunded: number;

  /**
   * The amount of the application fee (if any) requested for the charge. [See the Connect documentation](https://stripe.com/docs/connect/direct-charges#collecting-fees) for details.
   */
  application_fee_amount: number | null;

  /**
   * Authorization code on the charge.
   */
  authorization_code?: string;

  /**
   * The full statement descriptor that is passed to card networks, and that is displayed on your customers' credit card and bank statements. Allows you to see what the statement descriptor looks like after the static and dynamic portions are combined.
   */
  calculated_statement_descriptor: string | null;

  /**
   * If the charge was created without capturing, this Boolean represents whether it is still uncaptured or has since been captured.
   */
  captured: boolean;

  /**
   * Time at which the object was created. Measured in seconds since the Unix epoch.
   */
  created: number;

  /**
   * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies).
   */
  currency: string;

  /**
   * An arbitrary string attached to the object. Often useful for displaying to users.
   */
  description: string | null;

  /**
   * Whether the charge has been disputed.
   */
  disputed: boolean;

  /**
   * Error code explaining reason for charge failure if available (see [the errors section](https://stripe.com/docs/api#errors) for a list of codes).
   */
  failure_code: string | null;

  /**
   * Message to user further explaining reason for charge failure if available.
   */
  failure_message: string | null;

  /**
   * Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode.
   */
  livemode: boolean;

  /**
   * `true` if the charge succeeded, or was successfully authorized for later capture.
   */
  paid: boolean;

  /**
   * ID of the payment method used in this charge.
   */
  payment_method: string | null;

  /**
   * Details about the payment method at the time of the transaction.
   */
  payment_method_details: ChargePaymentMethodDetails | null;

  /**
   * This is the email address that the receipt for this charge was sent to.
   */
  receipt_email: string | null;

  /**
   * This is the transaction number that appears on email receipts sent for this charge. This attribute will be `null` until a receipt has been sent.
   */
  receipt_number: string | null;

  /**
   * This is the URL to view the receipt for this charge. The receipt is kept up-to-date to the latest state of the charge, including any refunds. If the charge is for an Invoice, the receipt will be stylized as an Invoice receipt.
   */
  receipt_url: string | null;

  /**
   * Whether the charge has been fully refunded. If the charge is only partially refunded, this attribute will still be false.
   */
  refunded: boolean;

  /**
   * For card charges, use `statement_descriptor_suffix` instead. Otherwise, you can use this value as the complete description of a charge on your customers' statements. Must contain at least one letter, maximum 22 characters.
   */
  statement_descriptor: string | null;

  /**
   * Provides information about the charge that customers see on their statements. Concatenated with the prefix (shortened descriptor) or statement descriptor that's set on the account to form the complete statement descriptor. Maximum 22 characters for the concatenated descriptor.
   */
  statement_descriptor_suffix: string | null;

  /**
   * The status of the payment is either `succeeded`, `pending`, or `failed`.
   */
  status: string;

  /**
   * A string that identifies this transaction as part of a group. See the [Connect documentation](https://stripe.com/docs/connect/charges-transfers#transfer-options) for details.
   */
  transfer_group: string | null;
}

export interface ChargePaymentMethodDetails {
  card?: PaymentMethodDetailsCard;
  /**
   * The type of transaction-specific details of the payment method used in the payment, one of `ach_credit_transfer`, `ach_debit`, `acss_debit`, `alipay`, `au_becs_debit`, `bancontact`, `card`, `card_present`, `eps`, `giropay`, `ideal`, `klarna`, `multibanco`, `p24`, `sepa_debit`, `sofort`, `stripe_account`, or `wechat`.
   * An additional hash is included on `payment_method_details` with a name matching this value.
   * It contains information specific to the payment method.
   */
  type: string;
}

export interface PaymentMethodDetailsCard {
  /**
   * Card brand. Can be `amex`, `diners`, `discover`, `jcb`, `mastercard`, `unionpay`, `visa`, or `unknown`.
   */
  brand: string | null;

  /**
   * Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you've collected.
   */
  country: string | null;

  /**
   * A high-level description of the type of cards issued in this range. (For internal use only and not typically available in standard API requests.)
   */
  description?: string | null;

  /**
   * Two-digit number representing the card's expiration month.
   */
  exp_month: number;

  /**
   * Four-digit number representing the card's expiration year.
   */
  exp_year: number;

  /**
   * Uniquely identifies this particular card number. You can use this attribute to check whether two customers who've signed up with you are using the same card number, for example. For payment methods that tokenize card information (Apple Pay, Google Pay), the tokenized number might be provided instead of the underlying card number.
   *
   * *Starting May 1, 2021, card fingerprint in India for Connect will change to allow two fingerprints for the same card --- one for India and one for the rest of the world.*
   */
  fingerprint?: string | null;

  /**
   * Card funding type. Can be `credit`, `debit`, `prepaid`, or `unknown`.
   */
  funding: string | null;

  /**
   * Issuer identification number of the card. (For internal use only and not typically available in standard API requests.)
   */
  iin?: string | null;

  /**
   * The name of the card's issuing bank. (For internal use only and not typically available in standard API requests.)
   */
  issuer?: string | null;

  /**
   * The last four digits of the card.
   */
  last4: string | null;

  /**
   * True if this payment was marked as MOTO and out of scope for SCA.
   */
  moto?: boolean | null;

  /**
   * Identifies which network this charge was processed on. Can be `amex`, `cartes_bancaires`, `diners`, `discover`, `interac`, `jcb`, `mastercard`, `unionpay`, `visa`, or `unknown`.
   */
  network: string | null;
}

export interface HealthValidateRequest {
  activationCode: string;
}

export interface HealthSetPharmacyPreferencesRequest {
  preferredPharmacyType?: PharmacyType;
  preferredPharmacy?: Pharmacy;
}

export interface HealthGetCabinetRequest {
  dependentId: string;
  page: number;
}

export interface HealthGetCabinetResponse {
  drugs: HttpUserCabinetDrug[];
  total: number;
}

export interface HealthSaveToCabinetRequest {
  dependentId: string;
  pickedDrug: HttpPickedDrug;
  price: DrugPriceV2;
}

export interface HealthDeleteFromCabinetRequest {
  dependentId: string;
  userCabinetDrugId: string;
}

export interface GetDependentsRequest {}

export interface GetDependentsResponse {
  dependents: HttpUserDependent[];
}

export interface HealthAddDependentsRequest {
  relationship: 'primary' | 'spouse' | 'dependent' | 'pet';
}

export interface CreateDependentsResponse {
  dependent: HttpUserDependent;
}

export interface HealthUpdateUserDetails {
  dependents: HealthUserDependents[];
}

export interface HealthUserDependents {
  dependentId: string;
  details: HealthUserDetails;
}

export interface HealthRemoveDependentsRequest {
  dependentId: string;
}

export interface GetDefaultVoucherResponse {
  voucher: DrugCoupon;
}

export interface GetNetworksResponse {
  networks: HealthConfiguration[];
}

export interface DependentRequest {
  dependentId: string;
}

export interface GetVisitsResponse {
  visits: HttpVisit[];
}

export type HttpVisit = {
  type: 'zipnosis';
  visitId: string;
  dependentId: string;
  date: string;
  zipVisit: ZipnosisVisit;
  status: 'active' | 'waiting' | 'finished';
};

export interface HealthSetUserDetailsRequest {
  dependentId: string;
  details: HealthUserDetails;
}

export interface HealthGetAvatarRequest {
  dependentId: string;
}

export interface HealthSetCommunicationRequest {
  preferredCommunication: 'text' | 'email';
  pushEnabled: boolean;
}

export interface LOCLoginRequest {
  dependentId: string;
}

export interface LOCLoginResponse {
  url: string;
  baseUrl: string;
}

export interface TMSignRequest {
  dependentId: string;
  medicareDisclaimerSigned: Date;
  termsSigned: Date;
}

export interface TMDependentResponse {
  dependent?: HttpUserDependent;
}

export interface GetTermsResponse {
  terms: string;
  privacy: string;
  medicalConsent: string;
}

export interface TMRequestPatientAddDependentRequest {
  patientId: number;
  request: AddDependentRequest;
}

export interface AddDependentRequest {
  family: {
    relationship: string;
    member_attributes: {
      first_name: string;
      last_name: string;
      birth_date: string;
      gender: string;
      sso_token: string;
    };
  };
}

export interface SearchItemsRequest {
  query: string;
}

export interface SearchItemsResponse {
  items: {id: number; name: string}[];
}

export interface GetMedicationsResponse {
  medications: ZipMedication[];
}

export type ZipMedication = {
  id: number;
  medication_id: number;
  medication_name: string;
  state: 'active' | 'inactive';
  external_medication_id?: string;
  external_code?: string;
};

export interface TMRequestPatientCreateMedicationRequest extends DependentRequest {
  medicationName: string;
  medicationId: number;
}

export interface GetAllergiesResponse {
  allergies: ZipAllergy[];
}

export type ZipAllergy = {
  id: number;
  allergy_id: number;
  allergy_name: string;
  state: 'active' | 'inactive';
  external_allergy_id?: string;
  external_code?: string;
};

export interface TMRequestPatientCreateAllergyRequest extends DependentRequest {
  allergyName: string;
  allergyId: number;
}

export interface TMGetPharmaciesRequest extends DependentRequest {
  pharmacyName: string;
  pharmacyType: PharmacyType;
  lat: string;
  lng: string;
}

export interface TMGetPharmaciesResponse {
  pharmacies: Pharmacy[];
}

export interface TMGetZipPharmaciesRequest extends DependentRequest {
  lat: string;
  lng: string;
  name?: string;
  page: number;
}

export interface TMGetZipPharmaciesResponse {
  pharmacies: ZipPharmacy[];
}

export interface ZipPharmacy {
  id: number;
  name: string;
  phone: string;
  fax: string;
  priority?: any;
  ncpdp_id: number;
  latitude: number;
  longitude: number;
  display_url: string;
  display_url_title: string;
  address: PharmacyAddress;
  created_at: string;
  updated_at: string;
  distance: number;
}

export interface PharmacyAddress {
  id: number;
  address1: string;
  address2?: any;
  city: string;
  country: string;
  phone?: any;
  state: string;
  zip: string;
  latitude?: any;
  longitude?: any;
}

export interface TMVisitStartVisitRequest {
  dependentId: string;
}

export interface TMVisitStartVisitResponse extends TMGetDisclaimerResponse {
  visitId: string;
  visit: ZipnosisVisit;
  disclaimer: {states: GetDisclaimerResponseState[]; medication_history_enabled: boolean};
}

export interface TMGetDisclaimerResponse {
  disclaimer: {states: GetDisclaimerResponseState[]; medication_history_enabled: boolean};
}

export type GetDisclaimerResponseState = {
  abbreviation: string;
  state: string;
  prompt_medicare: boolean;
  disclaimer_acknowledge_text: string;
  disclaimer: string;
  advance_beneficiary_notice?: string;
  medicare_prompt_questions: GetDisclaimerResponseMedicarePromptQuestion[];
  research_consent: GetDisclaimerResponseResearchConsent;
  patient_type: GetDisclaimerResponsePatientType;
};

export type GetDisclaimerResponseMedicarePromptQuestion = {
  text: string;
  value: boolean;
};

export type GetDisclaimerResponseResearchConsent = {
  text: string;
  required: boolean;
};

export type GetDisclaimerResponsePatientType = {
  required: boolean;
  text: string;
  options: {
    name: string;
    id: string;
    refer_out_if_chosen: boolean;
  }[];
};

export interface TMVisitGetVisitResponse {
  visit?: {visitId: string; zipVisit: ZipnosisVisit; zipTriageVideo?: ZipTriageVideo};
}

export interface ZipTriageVideo {
  id: number;
  state: string;
  reason: string;
  visit_id: number;
  created_at: Date;
  updated_at: Date;
  notes?: any;
  platform: string;
  queue_order?: number;
  queued_at?: any;
  current_queue_size: number;
  patient_phone_number?: any;
  next_valid_notification_time?: any;
  connection_successful: boolean;
  notification_enabled: boolean;
  users: ZipTriageVideoUser[];
  patient_connection?: any;
  doctor_connection?: any;
  clinic_id: number;
  allow_video_guest_invite?: any;
  actions: ZipTriageVideoActions;
}

export interface ZipTriageVideoUser {
  actions: {};
  full_name: string;
  preparer_full_name: string;
  id: number;
  role: string;
  role_description: string;
}

export interface ZipTriageVideoActions {
  invite: boolean;
}

export interface TMVisitGetVisitRequest {
  dependentId: string;
  visitId: string;
}

export interface DependentVisitRequest {
  dependentId: string;
  visitId: string;
}

export interface TMVisitAcceptDisclaimerRequest extends DependentVisitRequest {
  disclaimer: {
    visit: {
      patient_type?: string;
      medicare_status?: boolean;
    };
    disclaimer_acknowledged: boolean;
    research_consent: boolean;
    state: string;
  };
}

export interface TMVisitGetScreenOutResponse {
  visitId: string;
  visit: ZipnosisVisit;
  screenOut: string;
  screenOutQuestion: string;
}

export interface TMVisitAcceptScreenOutRequest extends DependentVisitRequest {
  accept: 'yes' | 'no';
}

export interface TMVisitWithFollowupResponse extends TMVisitResponse {
  followUp?: GetFollowUpCareResponse;
}

export interface TMVisitResponse {
  visitId: string;
  visit: ZipnosisVisit;
  charge?: HttpCHCharge;
  zipTriageVideo?: ZipTriageVideo;
}

export type HttpCHCharge = {
  id: string;
  payment: CHChargePayment;
};

export type CHChargePayment = {price: number} & (
  | {
      type: 'stripe';
      paymentIntentId: string;
      receipt?: any /*stripe.PaymentIntent*/;
      refundReceipt?: any /*stripe.PaymentIntent*/;
    }
  | {type: 'free'}
  | {type: 'subsidized'}
);

export type GetFollowUpCareResponse = {
  message: string;
  refer_outs: string;
  has_report: boolean;
  in_progress: boolean;
};

export interface TMGetReasonsResponse {
  reasons: GetReasonsResponse;
}

export type GetReasonsResponse = {
  reasons: Reason[];
  dont_see_option_settings: DontSeeOptionSettings;
};

export interface Reason {
  id: number;
  name: string;
  description?: string;
  icon?: string;
  conditions: Condition[];
}

export interface Condition {
  name: string;
  slug: string;
  cpid: number;
  label: string;
  description?: string;
  eligibility_settings: EligibilitySettings;
  eligible: boolean;
  ineligible_message: string;
}

export interface EligibilitySettings {
  patient_gender?: string;
  patient_max_age?: number;
  patient_min_age?: number;
  established_patients_only?: boolean;
}

export interface DontSeeOptionSettings {
  dont_see_option_header: string;
  dont_see_option_button_text: string;
  dont_see_option_to_top: boolean;
  dont_see_option_body: string;
}

export interface TMVisitSetReasonRequest extends DependentVisitRequest {
  reason: string;
}

export interface TMVisitSetReasonResponse extends TMVisitResponse {
  modalities: ZipnosisVisitModality[];
}

export interface TMVisitExplainRepeatVisitRequest extends DependentVisitRequest {
  reason: string;
}

export interface TMGetModalitiesResponse {
  modalities: ZipnosisVisitModality[];
}

export interface TMVisitSetModalityRequest extends DependentVisitRequest {
  modalitySlug: string;
}

export interface TMVisitSetModalityResponse extends TMVisitResponse {
  questions: QuestionOption[];
}

export interface QuestionOption {
  zid: number;
  value?: string;
  options: QuestionOption[];
  childs: any[];
  choice?: string;
  data_type?: string;
  layout?:
    | 'label'
    | 'checkbox'
    | 'date'
    | 'yes-no'
    | 'radio'
    | 'integer'
    | 'decimal'
    | 'single-line'
    | 'double-line'
    | 'radio-single'
    | 'image'
    | 'current-allergies'
    | 'current-medications';
  image?: string;
  label?: string;
}

export interface TMVisitDoctorStepUpRequest extends DependentVisitRequest {
  modalities: string[];
  phoneNumber: string;
}

export interface TMVisitLightResponse {
  visitId: string;
  visit: ZipVisitLight;
}

export type ZipVisitLight = {
  id: number;
  interview_progress?: number;
  state: ZipnosisVisitState;
  zip_triage?: ZipTriage;
};

export interface TMVisitAnswerQuestionRequest extends DependentVisitRequest {
  answer: ZipAnswer[];
}

export type ZipAnswer = {questionId: number | 'Medications' | 'Allergies'; answerId: string | string[] | number[]};

export interface TMVisitGetQuestionResponse extends TMVisitLightResponse {
  questions?: QuestionOption[];
  followUp?: GetFollowUpCareResponse;
  zipTriageVideo?: ZipTriageVideo;
}

export interface TMVisitAnswerHeightWeightRequest extends DependentVisitRequest {
  height?: number;
  weight?: number;
}

export interface TMVisitChatMessagesResponse extends TMVisitResponse {
  chatMessages: VisitChatResponse;
}

export type VisitChatResponse = {
  id: number;
  visit_id: number;
  state: string;
  messages: ZipMessage[];
  connections: any[];
  unread_message_count: number;
};

export interface ZipMessage {
  id: number;
  role: 'notification' | 'system' | 'patient' | 'doctor';
  image_id?: any;
  serial?: string;
  message: string;
  timestamp: string;
  sender_name: string;
  sender_id?: number;
}

export interface EnterVideoWaitingRoomRequest extends DependentVisitRequest {
  phoneNumber: string;
}

export interface TMVisitVideoResponse extends TMVisitResponse {
  videoToken: {session: string; token: string};
}

export interface SendPrescriptionRequest extends DependentVisitRequest {
  voucher: DrugCoupon;
  ncpdpId: number;
  prices?: any;
}

export interface TMVisitStartChatResponse extends TMVisitResponse {
  connection: VisitChatConnection;
  chatMessages: VisitChatResponse;
}

export type VisitChatConnection = {
  id: number;
  user_id: number;
  role: 'notification' | 'system' | 'patient' | 'doctor';
  connected_at: string;
  heartbeat_at: null;
  chat_id: number;
  user_name_with_title: string;
};

export interface ApproveTriageRequest extends DependentVisitRequest {
  phoneNumber?: string;
}

export interface GetClinicRequest extends DependentVisitRequest {
  clinicId: string;
}

export interface GetClinicResponse {
  clinic: ZipClinic;
}

export interface ZipClinic {
  id: number;
  before_payment: string;
  before_payment_sync: string;
  before_verification: string;
  business_line_description: string;
  business_line_name: string;
  chat_enabled: boolean;
  clinic_access: string;
  clinic_group_id: number;
  end_of_zip: string;
  exit_interview_header?: any;
  exit_interview_button_text?: any;
  exit_interview_body?: any;
  hours_this_week: ZipClinicHoursThisWeek[];
  name: string;
  next_open_time: string;
  phone_enabled: boolean;
  start_visit_button_text: string;
  video_enabled: boolean;
  medication_history_enabled: boolean;
  scheduled_visits_only: boolean;
  closed: boolean;
}

export interface ZipClinicHoursThisWeek {
  start: string;
  end: string;
  custom_text?: string;
  closed: boolean;
}

export interface TMVisitReportResponse extends TMVisitResponse {
  report: VisitReportResponse;
}

export type VisitReportResponse = {
  id: number;
  zip_triage_friendly_type: string;
  zip_triage_notes?: any;
  assessment_text: string;
  clinician_footer: string;
  created_at: string;
  icd_code: string;
  updated_at: string;
  patient_info_basic_text: string;
  doctor_summary_basic_text: string;
  medications: Medication[];
  allergies: Medication[];
  unable_to_diagnose: boolean;
  visit_zip_tickets: any[];
  order_transactions: OrderTransaction[];
  school_work_note?: SchoolWorkNote;
  allow_patient_comments?: any;
  async_summary: string;
  doctor_summary: string;
  patient_info?: any;
  able_to_diagnose_text: string;
  unable_to_diagnose_text?: any;
  additional_notes: string;
  addenda: any[];
  prescriptions: string[];
  addenda_and_comments?: ReportAddendum[];
};

export interface Medication {
  id: number;
  description: string;
}

export interface OrderTransaction {
  base_price: number;
  created_at: string;
  promo_code?: any;
  promo_code_amount?: any;
  amount: number;
  refund_amount?: any;
  insurance_billed_amount?: any;
  insurance_billed_at?: any;
  insurance_paid_amount?: any;
  insurance_paid_at?: any;
  description?: any;
  transaction_type: string;
  free_visit_reason?: any;
  zip_group_discount_amount?: any;
}

export interface SchoolWorkNote {
  ok_to_return: boolean;
  return_date: string;
  has_restrictions: boolean;
  restrictions: string;
  evaluation_date_copy: string;
  return_message_copy: string;
  evaluate_by_date?: any;
  notes: string;
}

export interface ReportAddendum {
  body: string;
  created_at: string;
  id: number;
  notify?: any;
  reviewed_by_clinician_at?: any;
  target_audience: string[];
  target_audience_display: string;
  type: string;
  user: string;
  user_id: number;
  user_role: string;
}

export interface TMVisitAddendumRequest {
  dependentId: string;
  visitId: string;
  addendum: string;
  phoneNumber: string;
}

export interface TMZipTokenResponse {
  token: string;
}

export interface TMZipServerTokenResponse {
  token: string;
  serverToken: string;
}

export interface X2AICreateUser {
  dependentId: string;
}

export interface WebhookZipnosisPostRequest {
  id: number;
  subscription_id: number;
  event_name:
    | 'visit.diagnosed'
    | 'visit.finished'
    | 'visit.created'
    | 'visit.referred'
    | 'video.provider_notified_patient'
    | 'visit.expired'
    | 'addendum.created';
  subject_type: string;
  subject_id: number;
  created_at: string;
  patient_id: number;
  external_patient_id: string;
  location: string;
}

export interface WebhookZendeskPostRequest {
  user_token: string;
}

export interface WebhookZendeskPostResponse {
  jwt: string;
}
