import moment from 'moment';
import React, {FC, useState} from 'react';
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap';
import {Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis} from 'recharts';
import Loading from '../../../../components/loading';
import {HttpPartnerExtended, SlugsByDayResponse} from '../../../../dataServices/app.generated';
import {AppService} from '../../../../dataServices/appService';
import {useEffectAsync} from '../../../../hooks/useEffectAsync';
import {Utils} from '../../../../utils/utils';

type DayData = any;

export const UrlSlugReport: FC<{partner: HttpPartnerExtended}> = (props) => {
  const [loadingReport, setLoadingReport] = useState(true);
  const [report, setReport] = useState<DayData[] | undefined>(undefined);
  const [rawReport, setRawReport] = useState<SlugsByDayResponse['slugs'] | undefined>(undefined);

  useEffectAsync(async () => {
    const result = await AppService.partnerClient.slugsByDay({partnerId: props.partner.id}, {});
    if (result) {
      if (result.slugs.length === 0) {
        setReport([]);
        setLoadingReport(false);
        return;
      }
      setRawReport(result.slugs);
      const allSlugs = Utils.groupBy(result.slugs, (a) => a.slug).map((a) => a.key);

      const slugDates = result.slugs.map((a) => moment(a.date).toDate()).sort((a, b) => +a - +b);
      const first = slugDates[0];
      const last = slugDates[slugDates.length - 1];

      const slugsByDay = Utils.groupBy(result.slugs, (a) => a.date);

      const daysData: DayData[] = [];

      for (const ind of Utils.range(moment(last).diff(first, 'days') + 1)) {
        const key = moment(first).add(ind, 'day').format('YYYY-MM-DD');
        const day = slugsByDay.find((a) => a.key === key);
        const dayData: DayData = {
          date: key,
        };

        if (!day) {
          for (const slug of allSlugs) {
            dayData[slug] = 0;
          }
        } else {
          for (const slug of allSlugs) {
            dayData[slug] = Utils.sum(
              day.items.filter((a) => a.slug === slug),
              (a) => a.count
            );
          }
          daysData.push(dayData);
        }
      }

      setReport(daysData);
      setLoadingReport(false);
    }
  }, []);

  return loadingReport ? (
    <Loading />
  ) : (
    <Row>
      <Col md="7">
        <Card className="main-card mb-3">
          <CardBody>
            <CardTitle>Url Slug Report</CardTitle>
            {report.length === 0 ? (
              <>There is no data yet.</>
            ) : (
              <BarChart
                width={500}
                height={300}
                data={report}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                {Utils.groupBy(rawReport, (a) => a.slug).map((a) => (
                  <Bar key={a.key} dataKey={a.key} stackId="a" fill="#8884d8" />
                ))}
              </BarChart>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
