import React, {useEffect, useRef} from 'react';

export const useClickOutside = (
  ref: React.MutableRefObject<any>,
  ref2: React.MutableRefObject<any>,
  show: boolean,
  onClose: () => void
) => {
  useEffect(() => {
    const clickHandler = (e: MouseEvent) => {
      if (!ref.current || !show || ref.current.contains(e.target) || ref2.current.contains(e.target)) {
        return;
      }
      onClose();
    };
    if (show) {
      document.addEventListener('click', clickHandler);
    }
    return () => document.removeEventListener('click', clickHandler);
  }, [show]);
};
