import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import {Card, CardBody, Col, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {HttpPartnerSearch} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';

type Props = RouteComponentProps<any> & {};
type State = {
  partners: HttpPartnerSearch[];
  loadingSearch: boolean;
};

export class PartnerMasters extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      loadingSearch: true,
      partners: [],
    };
  }

  async componentDidMount() {
    await this.search();
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle heading="Master Partners" subheading="" icon="pe-7s-medal icon-gradient bg-tempting-azure" />
        </div>
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <ReactTable
                  resizable={false}
                  loading={this.state.loadingSearch}
                  data={this.state.partners}
                  manual
                  getTrProps={(state: any, rowInfo: any) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: () => {
                          this.props.history.push(`/partner/details/${rowInfo.original._id}`);
                        },
                      };
                    } else {
                      return {};
                    }
                  }}
                  showPageSizeOptions={false}
                  defaultPageSize={this.state.partners.length}
                  sortable={false}
                  page={0}
                  showPagination={false}
                  columns={[
                    {
                      Header: 'Partner Name',
                      accessor: 'partnerName',
                      Cell: (e) => <Link to={`/partner/details/${e.original._id}`}>{e.value}</Link>,
                    },
                    {
                      id: 'startingCard',
                      Header: 'Starting Card',
                      accessor: (a) => a.startingGroupCode + '-' + a.startingMemberId,
                    },
                  ]}
                  style={{height: '600px'}}
                  className="-striped -highlight -fixed"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
    );
  }

  private search = async () => {
    this.setState({loadingSearch: true});
    const result = await AppService.adminPartnerClient.searchPartners(
      {
        page: '0',
        query: '',
        onlyMasters: 'true',
      },
      {}
    );
    if (result) {
      this.setState({
        partners: result.partners,
        loadingSearch: false,
      });
    }
  };
}
