import moment from 'moment';
import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {DateRangePicker} from 'react-dates';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import Loading from '../../../components/loading';
import {ClaimItem} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {useQueryString} from '../../../hooks/useQueryString';
import {CsvUtils} from '../../../utils/csvUtils';
import {FormatUtils} from '../../../utils/formatUtils';
import {FullDateUtils} from '../../../utils/fullDateUtils';

export const ClaimsReport: FC = () => {
  const [claimData, setClaimData] = useState<ClaimItem[] | undefined>(undefined);
  const [
    {ndcOrPrescription, pharmacy, groupCode, memberId, page, startDate, endDate},
    changed,
    setQuery,
  ] = useQueryString<{
    page: string;
    ndcOrPrescription: string;
    pharmacy: string;
    groupCode: string;
    memberId: string;
    startDate: string;
    endDate: string;
  }>({
    ndcOrPrescription: '',
    startDate: FullDateUtils.serverFormat(FullDateUtils.fromDate(moment().startOf('month').toDate())),
    endDate: FullDateUtils.serverFormat(FullDateUtils.fromDate(moment().toDate())),
    page: '0',
    pharmacy: '',
    groupCode: '',
    memberId: '',
  });

  const [searchNdcOrPrescription, setSearchNdcOrPrescription] = useState(ndcOrPrescription);
  const [searchPharmacy, setSearchPharmacy] = useState(pharmacy);
  const [searchGroupCode, setSearchGroupCode] = useState(groupCode);
  const [searchMemberId, setSearchMemberId] = useState(memberId);

  const [searchStartDate, setSearchStartDate] = useState(FullDateUtils.fromString(startDate));
  const [searchEndDate, setSearchEndDate] = useState(FullDateUtils.fromString(endDate));
  const [datePickerOpen, setDatePickerOpen] = useState<'startDate' | 'endDate'>();

  const [rawRow, setRawRow] = useState<{[key: string]: string}>();

  useEffect(() => {
    setSearchMemberId(memberId || '');
  }, [memberId]);
  useEffect(() => {
    setSearchPharmacy(pharmacy || '');
  }, [pharmacy]);
  useEffect(() => {
    setSearchGroupCode(groupCode || '');
  }, [groupCode]);
  useEffect(() => {
    setSearchNdcOrPrescription(ndcOrPrescription || '');
  }, [ndcOrPrescription]);
  useEffect(() => {
    setSearchStartDate(
      startDate ? FullDateUtils.fromString(startDate) : FullDateUtils.fromDate(moment().startOf('month').toDate())
    );
  }, [startDate]);
  useEffect(() => {
    setSearchEndDate(endDate ? FullDateUtils.fromString(endDate) : FullDateUtils.fromDate(moment().toDate()));
  }, [endDate]);

  const onSearch = useCallback(async () => {
    if (!changed) {
      return;
    }
    setClaimData(undefined);

    const response = await AppService.adminReportsClient.getClaimsByDayReport(
      {
        startDate: FullDateUtils.fromString(startDate),
        endDate: FullDateUtils.fromString(endDate),
        page: parseInt(page),

        pharmacy,
        ndcOrPrescription,
        groupCode,
        memberId,
      },
      {}
    );
    if (response) {
      setClaimData(response.claims);
    }
  }, [changed, page, pharmacy, ndcOrPrescription, groupCode, memberId, startDate, endDate]);

  useEffect(() => {
    onSearch();
  }, [changed, onSearch]);

  const onClear = useCallback(() => {
    setQuery({
      memberId: '',
      groupCode: '',
      ndcOrPrescription: '',
      pharmacy: '',
      page: '0',
    });
  }, [onSearch]);

  const table = useMemo(
    () => (
      <ReactTable
        data={claimData}
        showPagination={false}
        showPageSizeOptions={false}
        sortable={false}
        resizable={false}
        columns={[
          {
            Header: 'Drug',
            columns: [
              {
                Header: 'Drug Name',
                Cell: (e) => (
                  <Link
                    to={`/reports/claims?ndcOrPrescription=${e.original.drugName}&startDate=${startDate}&endDate=${endDate}`}
                  >
                    {e.original.drugName}
                  </Link>
                ),
              },
              {
                Header: 'Quantity',
                Cell: (e) => e.original.quantity,
              },
              {
                Header: 'NDC',
                Cell: (e) => (
                  <Link
                    to={`/reports/claims?ndcOrPrescription=${e.original.ndc}&startDate=${startDate}&endDate=${endDate}`}
                  >
                    {e.original.ndc}
                  </Link>
                ),
              },
              {
                Header: 'Pharmacy',
                Cell: (e) => (
                  <Link
                    to={`/reports/claims?pharmacy=${e.original.pharmacy}&startDate=${startDate}&endDate=${endDate}`}
                  >
                    {e.original.pharmacy}
                  </Link>
                ),
              },
            ],
          },
          {
            Header: 'Pricing',
            columns: [
              {
                Header: 'Base Price',
                Cell: (e) => FormatUtils.formatMoney(e.original.priceBase),
              },
              {
                Header: 'Amount Paid',
                Cell: (e) => FormatUtils.formatMoney(e.original.amountPaid),
              },
            ],
          },
          {
            Header: 'Details',
            columns: [
              {
                Header: 'Card',
                Cell: (e) => (
                  <Link
                    to={`/reports/claims?groupCode=${e.original.groupCode}&memberId=${e.original.memberId}&startDate=${startDate}&endDate=${endDate}`}
                  >
                    {e.original.groupCode + '-' + e.original.memberId}
                  </Link>
                ),
              },
              {
                Header: 'Provider',
                accessor: 'type',
                Cell: (e) => e.original.type.toUpperCase() + (e.original.reversed ? ' Reversed' : ''),
              },
              {
                Header: 'Date',
                Cell: (e) => moment(FullDateUtils.toDateUTC(e.original.date, e.original.time)).format('MMM Do'),
                width: 60,
              },
              {
                Header: 'From',
                Cell: (e) => (e.original.fromApp ? 'App' : 'Card'),
                width: 60,
              },
              {
                Header: 'Comp',
                accessor: 'type',
                Cell: (e) => e.original.compensable,
                width: 60,
              },
              {
                Header: '',
                width: 90,
                Cell: (e) => (
                  <Button color="primary" size="sm" onClick={() => setRawRow(e.original.rawRow)}>
                    CSV Row
                  </Button>
                ),
              },
            ],
          },
        ]}
        className="-striped -highlight"
      />
    ),
    [claimData]
  );

  return (
    <>
      <Card className="mb-3">
        <CardHeader className="card-header-tab z-index-7">
          <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
            <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
            Claims Report
          </div>
          <div className="btn-actions-pane-right">
            <DateRangePicker
              isDayBlocked={() => false}
              isOutsideRange={() => false}
              startDate={searchStartDate ? FullDateUtils.toMoment(searchStartDate) : null}
              startDateId="your_unique_start_date_id"
              endDate={searchEndDate ? FullDateUtils.toMoment(searchEndDate) : null}
              endDateId="your_unique_end_date_id"
              enableOutsideDays={true}
              onDatesChange={({startDate: sd, endDate: ed}) => {
                const sDate = sd ? FullDateUtils.fromDate(sd.toDate()) : null;
                const eDate = ed ? FullDateUtils.fromDate(ed.toDate()) : null;
                setSearchStartDate(sDate);
                setSearchEndDate(eDate);
                if (sDate !== searchStartDate && eDate !== searchEndDate) {
                  setQuery({
                    startDate: FullDateUtils.serverFormat(sDate),
                    endDate: FullDateUtils.serverFormat(eDate),
                    page: '0',
                  });
                } else if (eDate !== searchEndDate) {
                  setQuery({
                    endDate: FullDateUtils.serverFormat(eDate),
                    page: '0',
                  });
                } else if (sDate !== searchStartDate) {
                  setQuery({
                    startDate: FullDateUtils.serverFormat(sDate),
                    page: '0',
                  });
                }
              }}
              focusedInput={datePickerOpen}
              onFocusChange={(open) => {
                setDatePickerOpen(open);
              }}
            />
          </div>
        </CardHeader>
      </Card>
      <Row>
        <Col md="12">
          <Card className="main-card mb-3">
            <CardBody>
              <Form
                onSubmit={(e) => {
                  setQuery({
                    ndcOrPrescription: searchNdcOrPrescription,
                    pharmacy: searchPharmacy,
                    groupCode: searchGroupCode,
                    memberId: searchMemberId,
                    page: '0',
                  });
                  e.preventDefault();
                }}
              >
                <Row className={'py-2'}>
                  <Col md={4}>
                    <Input
                      placeholder={'NDC Or Prescription'}
                      className={'mr-2'}
                      type={'search'}
                      value={searchNdcOrPrescription}
                      onChange={(e) => setSearchNdcOrPrescription(e.target.value)}
                    />
                  </Col>
                  <Col md={2}>
                    <Input
                      placeholder={'Pharmacy'}
                      className={'mr-2'}
                      type={'search'}
                      value={searchPharmacy}
                      onChange={(e) => setSearchPharmacy(e.target.value)}
                    />
                  </Col>
                  <Col md={4}>
                    <Input
                      placeholder={'Group Code'}
                      className={'mr-2'}
                      type={'search'}
                      value={searchGroupCode}
                      onChange={(e) => setSearchGroupCode(e.target.value)}
                    />
                    <Input
                      placeholder={'Member ID'}
                      className={'mr-2'}
                      type={'search'}
                      value={searchMemberId}
                      onChange={(e) => setSearchMemberId(e.target.value)}
                    />
                  </Col>
                  <Col md={2}>
                    <Button color="primary" size="lg" type={'submit'}>
                      Search
                    </Button>
                    <Button color="secondary" size="lg" onClick={onClear}>
                      Clear
                    </Button>
                  </Col>
                </Row>
              </Form>

              {claimData === undefined ? (
                <div className={'pt-4 pb-4'}>
                  <Loading />
                </div>
              ) : (
                <>
                  {table}
                  <Row className={'pt-2'}>
                    <Col sm={'3'}>
                      <Button
                        color="primary"
                        size={'lg'}
                        disabled={parseInt(page) === 0}
                        onClick={() =>
                          setQuery({
                            page: (parseInt(page) - 1).toString(),
                          })
                        }
                      >
                        Previous
                      </Button>
                    </Col>
                    <Col
                      sm={'6'}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      Page {parseInt(page) + 1}
                    </Col>
                    <Col
                      sm={'3'}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        size={'lg'}
                        color="primary"
                        onClick={() =>
                          setQuery({
                            page: (parseInt(page) + 1).toString(),
                          })
                        }
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {rawRow && (
        <Modal isOpen>
          <ModalHeader toggle={() => setRawRow(undefined)}>{Row}</ModalHeader>
          <ModalBody>
            <pre style={{marginTop: 10, maxHeight: '50vh', fontSize: '1.5rem'}}>
              {Object.keys(rawRow).map((key) => (
                <div key={key}>
                  {key}: "{rawRow[key]}"
                </div>
              ))}
            </pre>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => CsvUtils.saveRawFile(`claim.csv`, CsvUtils.jsonToCsv(rawRow))} color={'success'}>
              Save as CSV
            </Button>
            <Button onClick={() => setRawRow(undefined)} color={'success'}>
              Done
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};
