import React, {useCallback, useEffect, useState} from 'react';
import {HealthContainer} from '../components/HealthContainer/healthContainer';
import {VisitsTable} from './visitsTable/visitsTable';
import {TablePagination} from '../components/TablePagination/TablePagination';
import {subDays, startOfDay, endOfDay, format} from 'date-fns';
import {AppService} from '../../../dataServices/appService';
import {toast} from 'react-toastify';
import {HttpHealthVisit} from '../../../dataServices/app.generated';
import {useHistory} from 'react-router';

export const HealthVisits = () => {
  const history = useHistory();
  const [visits, setVisits] = useState<HttpHealthVisit[]>([]);
  const [totCount, setTotCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [startDate, setStartDate] = useState(startOfDay(subDays(new Date(), 7)));
  const [endDate, setEndDate] = useState(endOfDay(new Date()));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const page = params.get('page');
    if (page) {
      setCurrPage(parseInt(page));
    }

    const start = params.get('startDate');
    const end = params.get('endDate');
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
    }
  }, [history.location.search]);

  const onQueryError = () => {
    setLoading(false);
    toast('There was an error while loading the visits.');
  };

  const queryVisits = useCallback(() => {
    setLoading(true);
    setVisits([]);
    AppService.adminPartnerHealthClient
      .getHealthVisits(
        {
          count: '30',
          offset: String(30 * currPage),
          startDate: String(startOfDay(startDate)),
          endDate: String(endOfDay(endDate)),
        },
        {'404': onQueryError}
      )
      .then((res) => {
        setLoading(false);
        if (!res) {
          return;
        }
        setTotCount(res.count);
        setVisits(res.visits);
      });
  }, [startDate, endDate, currPage]);

  useEffect(() => {
    queryVisits();
  }, [currPage, startDate, endDate]);

  const navPrevPage = () => {
    if (currPage === 0) {
      return;
    }
    history.push(
      `/health/visits?page=${currPage - 1}&startDate=${format(startDate, 'MM-dd-yyyy')}&endDate=${format(
        endDate,
        'MM-dd-yyyy'
      )}`
    );
  };

  const navNextPage = () => {
    history.push(
      `/health/visits?page=${currPage + 1}&startDate=${format(startDate, 'MM-dd-yyyy')}&endDate=${format(
        endDate,
        'MM-dd-yyyy'
      )}`
    );
  };

  const onSetStartDate = (date: Date) => {
    if (!date) {
      return;
    }
    const startParam = format(date, 'MM-dd-yyyy');
    const endParam = format(endDate, 'MM-dd-yyyy');
    history.push(`/health/visits?page=${currPage}&startDate=${startParam}&endDate=${endParam}`);
  };

  const onSetEndDate = (date: Date) => {
    if (!date) {
      return;
    }
    const startParam = format(startDate, 'MM-dd-yyyy');
    const endParam = format(date, 'MM-dd-yyyy');
    history.push(`/health/visits?page=${currPage}&startDate=${startParam}&endDate=${endParam}`);
  };

  return (
    <HealthContainer title={'Clever Health Visits'}>
      <VisitsTable
        visitList={visits}
        startDate={startDate}
        setStartDate={onSetStartDate}
        endDate={endDate}
        setEndDate={onSetEndDate}
        loading={loading}
      />
      <TablePagination
        totCount={totCount}
        currPage={currPage}
        itemsPerPage={30}
        onNavNext={navNextPage}
        onNavPrev={navPrevPage}
      />
    </HealthContainer>
  );
};
