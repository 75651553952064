import {inject, observer} from 'mobx-react';
import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {toast} from 'react-toastify';
import {Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Row} from 'reactstrap';
import Loading from '../../../../components/loading';
import {HttpPartnerExtended, PayoutViableResponse} from '../../../../dataServices/app.generated';
import {AppService} from '../../../../dataServices/appService';
import {MainStore, MainStoreName, MainStoreProps} from '../../../../store/main/store';
import {FormatUtils} from '../../../../utils/formatUtils';

type Props = {
  partner: HttpPartnerExtended;
} & RouteComponentProps<any> &
  MainStoreProps;
type State = {
  viable: PayoutViableResponse['viable'];
  modal?: {
    title: string;
    body: string;
    button: string;
    buttonAction?: () => Promise<any>;
  };
};

@inject(MainStoreName)
@observer
export class PayoutViableTab extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {viable: null};
  }

  async componentDidMount() {
    const result = await AppService.partnerClient.isPayoutViable({partnerId: this.props.partner.id}, {});
    if (result) {
      this.setState({viable: result.viable});
    }
  }
  private requestW9 = async () => {
    const result = await AppService.partnerClient.requestW9(
      {
        partnerId: this.props.partner.id,
      },
      {}
    );
    if (result) {
      toast('Your W9 request has been emailed to you.', {type: 'success'});
    }
  };

  render() {
    let items: {
      text: string;
      complete: boolean;
      buttonText?: string;
      buttonAction?: () => Promise<any>;
    }[] = [];

    if (this.state.viable) {
      const contractItems = [];

      for (const partnerId in this.state.viable.contract) {
        contractItems.push(
          ...[
            {
              text: `Contract for ${partnerId} Sent Out`,
              complete: this.state.viable.contract[partnerId],
              buttonText: 'FIX',
              buttonAction: async () => {
                this.setState({
                  modal: {
                    title: 'Request Contract',
                    body: 'Do you want to contact your Clever RX Broker to receive a new contract?',
                    button: 'Yes',
                    buttonAction: async () => {
                      const result = await AppService.partnerClient.contactBroker(
                        {
                          reason: 'new-contract',
                          partnerId,
                        },
                        {
                          ...MainStore.handleError(400),
                        }
                      );
                      if (result) {
                        toast('Your request has been sent!', {type: 'success'});
                      }
                    },
                  },
                });
              },
            },
            {
              text: `Contract for ${partnerId} Signed`,
              complete: this.state.viable.contractSigned[partnerId],
              buttonText: 'FIX',
              buttonAction: async () => {
                this.setState({
                  modal: {
                    title: 'Sign Contract',
                    body: 'Please check your email for a DocuSign contract and execute it.',
                    button: 'Okay',
                  },
                });
              },
            },
          ]
        );
      }

      items = [
        ...contractItems,

        {
          text: `Stripe Account Created`,
          complete: this.state.viable.stripeCreated,
          buttonText: 'FIX',
          buttonAction: async () => {
            this.setState({
              modal: {
                title: 'Stripe Account',
                body: 'Do you want to contact your Clever RX Broker to set up your stripe account?',
                button: 'Yes',
                buttonAction: async () => {
                  const result = await AppService.partnerClient.contactBroker(
                    {
                      reason: 'stripe-account',
                      partnerId: this.props.partner.id,
                    },
                    {
                      ...MainStore.handleError(400),
                    }
                  );
                  if (result) {
                    toast('Your request has been sent!', {type: 'success'});
                  }
                },
              },
            });
          },
        },
        {
          text: `Stripe Account Configured`,
          complete: this.state.viable.stripeSetup,
          buttonText: 'FIX',
          buttonAction: async () => {
            this.setState({
              modal: {
                title: 'Stripe Account',
                body: 'Please update your Stripe Account information',
                button: 'Okay',
                buttonAction: async () => {
                  this.props.history.push('/account/business');
                },
              },
            });
          },
        },
        {
          text: `Bank Account Configured`,
          complete: this.state.viable.stripeBankAccount,
          buttonText: 'FIX',
          buttonAction: async () => {
            this.setState({
              modal: {
                title: 'Stripe Account',
                body: 'Please update your Bank Account information',
                button: 'Okay',
                buttonAction: async () => {
                  this.props.history.push('/account/business');
                },
              },
            });
          },
        },
        {
          text: `W9 Form Requested`,
          complete: this.state.viable.w9Created,
          buttonText: 'REQUEST',
          buttonAction: async () => {
            this.setState({
              modal: {
                title: 'W9 Tax Form',
                body: 'Tap below to request your W9 Tax Form',
                button: 'Request',
                buttonAction: this.requestW9,
              },
            });
          },
        },
        {
          text: `W9 Form Signed`,
          complete: this.state.viable.w9Signed,
          buttonText: 'FIX',
          buttonAction: async () => {
            this.setState({
              modal: {
                title: 'W9 Tax Form',
                body: 'Please check your email for your DocuSign W9 Tax Form',
                button: 'Okay',
              },
            });
          },
        },
        {
          text: `Meets Minimum Payout Threshold Of ${FormatUtils.formatMoney(this.state.viable.payoutThresholdAmount)}`,
          complete: this.state.viable.payoutThreshold,
        },
      ];
    }

    return !this.state.viable ? (
      <Loading />
    ) : (
      <Row>
        <Col md="7">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Payout Checklist</CardTitle>
              <ListGroup>
                {items.map((item) => (
                  <ListGroupItem
                    key={item.text}
                    color={item.complete ? 'success' : 'danger'}
                    style={{flexDirection: 'row', display: 'flex', alignItems: 'center'}}
                  >
                    {item.text}
                    <div style={{flex: 1}} />
                  </ListGroupItem>
                ))}
                <ListGroupItem color={'success'}>
                  <b>Payout Amount Available {FormatUtils.formatMoney(this.state.viable.payout)}</b>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
