import React, {useEffect, useState} from 'react';
import {HealthContainer} from '../components/HealthContainer/healthContainer';
import {useLocation} from 'react-router';
import {AppService} from '../../../dataServices/appService';

export const HealthVisit = () => {
  const location = useLocation();
  const [visitData, setVisitData] = useState('');

  useEffect(() => {
    const zipVisitId = location.pathname.split('/')[3];
    AppService.adminPartnerHealthClient.getHealthVisitData({zipVisitId}, {}).then((res) => {
      setVisitData(JSON.stringify(res.data, null, 2));
    });
  }, []);

  return (
    <HealthContainer title={'Clever Health Visit'}>
      <pre>{visitData}</pre>
    </HealthContainer>
  );
};
