import React, {useState} from 'react';
import Select from 'react-select';
import {Button, ButtonGroup, Col, CustomInput, Label, Row} from 'reactstrap';
import {AccountSearchComponent} from '../../../../components/accountSearchComponent';
import {LInput, LSelect, LText} from '../../../../components/lInput';
import {AccountContactTypes} from '../../../../dataServices/app.generated';
import {PartnerUtils} from '../../../../utils/partnerUtils';

export interface PartnerAccountDetails {
  contactType?: AccountContactTypes;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  allowPromotionalCommunication?: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  accountId?: string;
}

export function PartnerCreateAccountDetailsStep(props: {
  next: () => void;
  accountDetails: PartnerAccountDetails;
  setAccountDetails: (accountDetails: PartnerAccountDetails, after?: () => void) => void;
}) {
  const {accountDetails, setAccountDetails} = props;
  const [findAccount, setFindAccount] = useState(!!accountDetails.accountId);

  return (
    <>
      <Row>
        <Col md={12}>
          <LText label={'Account Managed By:'}>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => {
                  setAccountDetails({accountId: null});
                  setFindAccount(false);
                }}
                active={!findAccount}
              >
                New Account
              </Button>
              <Button color="primary" onClick={() => setFindAccount(true)} active={findAccount}>
                Existing Account
              </Button>
            </ButtonGroup>
          </LText>
        </Col>
      </Row>
      {findAccount ? (
        <>
          <AccountSearchComponent
            onAccountSelect={(account) => {
              setAccountDetails(
                {
                  accountId: account.id,
                },
                props.next
              );
            }}
          />
        </>
      ) : (
        <>
          <Row>
            <Col md={6}>
              <LInput
                value={accountDetails.firstName}
                onChange={(e) => setAccountDetails({...accountDetails, firstName: e.target.value})}
                label={'First Name'}
                type={'text'}
                name={'firstname'}
                autoComplete={'disabled'}
              />
            </Col>
            <Col md={6}>
              <LInput
                value={accountDetails.lastName}
                onChange={(e) => setAccountDetails({...accountDetails, lastName: e.target.value})}
                label={'Last Name'}
                type={'text'}
                name={'lastname'}
                autoComplete={'disabled'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <LInput
                value={accountDetails.email}
                onChange={(e) => setAccountDetails({...accountDetails, email: e.target.value})}
                label={'Email'}
                type={'text'}
                name={'email'}
                autoComplete={'disabled'}
              />
            </Col>
            <Col md={3} style={{height: '90px', display: 'flex', alignItems: 'center'}}>
              <CustomInput
                type="checkbox"
                id={'allowPromoMaterials'}
                label="Allow Promotional Materials"
                checked={accountDetails.allowPromotionalCommunication}
                onChange={(e) =>
                  setAccountDetails({...accountDetails, allowPromotionalCommunication: e.target.checked})
                }
              />
            </Col>
            <Col md={3}>
              <LInput
                value={accountDetails.phoneNumber}
                onChange={(e) => setAccountDetails({...accountDetails, phoneNumber: e.target.value})}
                label={'Phone Number'}
                type={'text'}
                autoComplete={'disabled'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <LInput
                value={accountDetails.address1}
                onChange={(e) => setAccountDetails({...accountDetails, address1: e.target.value})}
                label={'Address 1'}
                type={'text'}
                name={'address1'}
                placeholder="Optional"
                autoComplete={'disabled'}
              />
            </Col>
            <Col md={6}>
              <LInput
                value={accountDetails.address2}
                onChange={(e) => setAccountDetails({...accountDetails, address2: e.target.value})}
                label={'Address 2'}
                type={'text'}
                name={'address2'}
                placeholder="Optional"
                autoComplete={'disabled'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <LInput
                value={accountDetails.city}
                onChange={(e) => setAccountDetails({...accountDetails, city: e.target.value})}
                label={'City'}
                type={'text'}
                name={'city'}
                placeholder="Optional"
                autoComplete={'disabled'}
              />
            </Col>
            <Col md={4}>
              <Label for={'stateSelect'}>State</Label>
              <Select
                menuPosition={'fixed'}
                id={'stateSelect'}
                onChange={(v: any) => setAccountDetails({...accountDetails, state: v.value})}
                value={PartnerUtils.getStates().find((a) => a.value === accountDetails.state)}
                options={PartnerUtils.getStates()}
                autoComplete={'disabled'}
              />
            </Col>
            <Col md={2}>
              <LInput
                value={accountDetails.zip}
                onChange={(e) => setAccountDetails({...accountDetails, zip: e.target.value})}
                label={'Zip'}
                type={'text'}
                name={'zip'}
                placeholder="Optional"
                autoComplete={'disabled'}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <LSelect
                menuPosition={'fixed'}
                label={'Contact Type'}
                value={PartnerUtils.getAccountContactsTypes().find((a) => a.value === accountDetails.contactType)}
                options={PartnerUtils.getAccountContactsTypes()}
                onChange={(e) => setAccountDetails({...accountDetails, contactType: (e as any).value})}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}
