import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {LInput, LSelect} from '../../../../components/lInput';
import {PartnerType} from '../../../../dataServices/app.generated';
import {AppService} from '../../../../dataServices/appService';
import {DebounceUtils} from '../../../../utils/debounceUtils';

export interface PartnerDetails {
  partnerName: string;
  partnerType: PartnerType;
  // paidBy: 'clever' | 'partner';
  startingGroupCode: string;
  startingMemberId: string;
  partnerNameValid: -1 | 0 | 1;
}

export class PartnerCreateDetailsStep extends Component<
  {
    partnerTypes: PartnerType[];
    partnerDetails: PartnerDetails;
    setPartnerDetails: (partnerDetails: PartnerDetails) => void;
  },
  {validating: boolean}
> {
  state = {
    validating: false,
  };

  validatePartnerName = () => {
    this.setState({validating: true});
    DebounceUtils.debounce('uniquePartner', 1000, async () => {
      const result = await AppService.adminPartnerClient.validatePartnerNameUnique(
        {partnerName: this.props.partnerDetails.partnerName},
        {}
      );
      this.props.setPartnerDetails({...this.props.partnerDetails, partnerNameValid: result.valid ? 1 : 0});
      this.setState({validating: false});
    });
  };

  render() {
    const {partnerDetails, setPartnerDetails, partnerTypes} = this.props;

    const options = partnerTypes.map((a) => ({label: a, value: a}));
    return (
      <>
        <Row>
          <Col md={6}>
            <LInput
              value={partnerDetails.partnerName}
              className={'no-highlight-input'}
              style={{
                borderWidth: partnerDetails.partnerNameValid === -1 && !this.state.validating ? null : 3,
                borderColor:
                  partnerDetails.partnerNameValid === 0
                    ? 'red'
                    : partnerDetails.partnerNameValid === 1
                    ? 'green'
                    : this.state.validating
                    ? '#c1a437'
                    : null,
              }}
              onChange={(e) => {
                const partnerName = e.target.value;
                setPartnerDetails({...partnerDetails, partnerName});
                this.validatePartnerName();
              }}
              label={'Partner Name'}
              type={'text'}
              placeholder={'Must be unique'}
            />
          </Col>
          <Col md={6}>
            <Row>
              <Col md={6}>
                <LInput
                  value={partnerDetails.startingGroupCode}
                  onChange={(e) => {
                    const startingGroupCode = e.target.value;
                    setPartnerDetails({...partnerDetails, startingGroupCode});
                  }}
                  label={'Group Code'}
                  type={'text'}
                />
              </Col>
              <Col md={6}>
                <LInput
                  value={partnerDetails.startingMemberId}
                  onChange={(e) => {
                    const startingMemberId = e.target.value;
                    setPartnerDetails({...partnerDetails, startingMemberId});
                  }}
                  label={'Member Id'}
                  type={'text'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          {partnerTypes.length > 0 && (
            <Col md={6}>
              <LSelect
                menuPosition={'fixed'}
                label={'Partner Type'}
                value={options.find((a) => a.value === partnerDetails.partnerType)}
                options={options}
                onChange={(e) => setPartnerDetails({...partnerDetails, partnerType: (e as any).value})}
              />
            </Col>
          )}
        </Row>
      </>
    );
  }
}
