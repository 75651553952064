import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';
import Loading from '../../../../components/loading';
import {HttpPartnerExtended, PartnerPayout} from '../../../../dataServices/app.generated';
import {AppService} from '../../../../dataServices/appService';
import {MainStore} from '../../../../store/main/store';

type Props = RouteComponentProps<any> & {partner?: HttpPartnerExtended};
type State = {
  gettingPayouts: boolean;
  payouts: PartnerPayout[];
};

export class PartnerPayoutReport extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {gettingPayouts: true, payouts: []};
  }

  componentDidMount(): void {
    this.getLedger();
  }

  private async getLedger() {
    this.setState({gettingPayouts: true});
    const result = await AppService.adminPartnerClient.getPayouts(
      {partnerId: this.props.partner.id},
      {
        ...MainStore.handleError(404),
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      this.setState({
        gettingPayouts: false,
        payouts: result.payouts,
      });
    }
  }

  render() {
    if (this.state.gettingPayouts) {
      return <Loading />;
    }
    const {payouts} = this.state;

    return (
      <>
        <Row>
          <Col md="12">
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                  Claim Payout Tracker
                </div>
              </CardHeader>
              <CardBody className="pt-2 pb-0">
                <Table hover striped className="mb-0">
                  <thead>
                    <tr>
                      <th>Card</th>
                      <th>Amount Due</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payouts.map((a) => (
                      <tr key={a.cardId}>
                        <td>
                          <Link to={`/card/details/${a.cardId}`}>
                            {a.groupCode}-{a.memberId}
                          </Link>
                        </td>
                        <td>{a.amountDue}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
