import moment from 'moment';
import React, {Component} from 'react';
import {DateRangePicker} from 'react-dates';
import {RouteComponentProps} from 'react-router';
import ReactTable from 'react-table';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import Loading from '../../../components/loading';
import {ClaimsResultReportByDate, FullDate} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {FullDateUtils} from '../../../utils/fullDateUtils';

type Props = RouteComponentProps<any> & {};
type State = {
  gettingReport: boolean;
  claimImportReport?: ClaimsResultReportByDate[];
  startDate: FullDate;
  endDate: FullDate;
  changedDates: boolean;
  page: number;
  datePickerOpen: 'startDate' | 'endDate';
};

export class ClaimsImportReport extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      gettingReport: true,
      datePickerOpen: null,
      changedDates: false,
      startDate: FullDateUtils.fromDate(moment().add(-1, 'day').toDate()),
      endDate: FullDateUtils.fromDate(moment().toDate()),
      page: 0,
    };
  }

  async componentDidMount() {
    this.getReport();
  }

  private async getReport() {
    this.setState({gettingReport: true});
    const result = await (this.state.page === 0
      ? AppService.adminReportsClient.getClaimsResultReport(
          {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
          },
          {}
        )
      : Promise.resolve({claimsByDate: this.state.claimImportReport}));
    if (result) {
      this.setState({
        gettingReport: false,
        claimImportReport: result.claimsByDate,
      });
    }
  }

  render() {
    if (this.state.gettingReport) {
      return <Loading />;
    }

    return (
      <>
        <Card className="mb-3">
          <CardHeader className="card-header-tab z-index-7">
            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
              <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
              Claims Import Report
            </div>
            <div className="btn-actions-pane-right">
              <DateRangePicker
                isDayBlocked={() => false}
                isOutsideRange={() => false}
                startDate={this.state.startDate ? FullDateUtils.toMoment(this.state.startDate) : null}
                startDateId="your_unique_start_date_id"
                endDate={this.state.endDate ? FullDateUtils.toMoment(this.state.endDate) : null}
                endDateId="your_unique_end_date_id"
                enableOutsideDays={true}
                onDatesChange={({startDate, endDate}) => {
                  this.setState({
                    startDate: startDate ? FullDateUtils.fromDate(startDate.toDate()) : null,
                    endDate: endDate ? FullDateUtils.fromDate(endDate.toDate()) : null,
                    changedDates: true,
                  });
                }}
                focusedInput={this.state.datePickerOpen} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(datePickerOpen) =>
                  this.setState({datePickerOpen}, () => {
                    if (!datePickerOpen && this.state.changedDates) {
                      this.setState({changedDates: false, page: 0});
                      this.getReport();
                    }
                  })
                } // PropTypes.func.isRequired,
              />
            </div>
          </CardHeader>
        </Card>
        {this.renderClaimImport()}
      </>
    );
  }

  renderClaimImport() {
    return (
      <>
        {this.state.gettingReport ? (
          <div className={'pt-4 pb-4'}>
            <Loading />
          </div>
        ) : (
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={this.state.claimImportReport}
                    showPagination={false}
                    showPageSizeOptions={false}
                    sortable={false}
                    pageSize={this.state.claimImportReport.length}
                    columns={[
                      {
                        Header: 'Months Effected',
                        accessor: 'monthsEffected',
                        Cell: (e) => e.original.monthsEffected.join(',  '),
                      },
                      {Header: 'Claims Inserted', accessor: 'claimsInserted'},
                      {Header: 'Unfound Cards', accessor: 'unfoundCards'},
                      {
                        Header: 'File Name',
                        accessor: 'fileName',
                        Cell: (e) => e.original.fileName.split('/').slice(-1)[0].replace(/_/g, ' ').replace(/-/g, ' '),
                      },
                      {Header: 'Provider', accessor: 'provider'},
                      {Header: 'Date Ran', accessor: 'dateRan'},
                      {Header: 'Duration', accessor: 'duration'},
                    ]}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </>
    );
  }
}
