import {
  addHours,
  differenceInHours,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  subDays,
  subHours,
  subMonths,
  subWeeks,
} from 'date-fns';

export const getTZOffset = (date: Date) => {
  const localDate = new Date();
  const PSTDate = new Date(new Date().toLocaleString('en-US', {timeZone: 'America/Los_Angeles'}));
  const offset = differenceInHours(PSTDate, localDate);
  return offset < 0 ? subHours(date, Math.abs(offset)) : offset > 0 ? addHours(date, Math.abs(offset)) : date;
};

export const getDateRange = (id: string) => {
  switch (id) {
    case 'today': {
      const start = getTZOffset(startOfDay(new Date()));
      const end = getTZOffset(endOfDay(new Date()));
      return [start, end];
    }
    case 'yesterday': {
      const start = getTZOffset(startOfDay(subDays(new Date(), 1)));
      const end = getTZOffset(endOfDay(subDays(new Date(), 1)));
      return [start, end];
    }
    case 'week': {
      const start = getTZOffset(startOfWeek(new Date()));
      const end = getTZOffset(new Date());
      return [start, end];
    }
    case 'lastweek': {
      const start = startOfWeek(subWeeks(new Date(), 1));
      const end = endOfWeek(start);
      return [start, end];
    }
    case 'month': {
      return [startOfMonth(new Date()), new Date()];
    }
    case 'lastmonth': {
      const start = startOfMonth(subMonths(new Date(), 1));
      const end = endOfMonth(start);
      return [start, end];
    }
    case 'quarter': {
      const start = startOfQuarter(new Date());
      const end = endOfQuarter(start);
      return [start, end];
    }
    default: {
      return null;
    }
  }
};
