import React, {useCallback, useEffect, useState} from 'react';
import {HealthContainer} from '../components/HealthContainer/healthContainer';
import {TablePagination} from '../components/TablePagination/TablePagination';
import {subDays, startOfDay, endOfDay, format} from 'date-fns';
import {AppService} from '../../../dataServices/appService';
import {toast} from 'react-toastify';
import {IVRsTable} from './IVRsTable/ivrsTable';
import {useHistory} from 'react-router';

export type HealthIVRFilter = 'forward-to-ucm' | 'send-text' | '';

export const HealthIVRs = () => {
  const history = useHistory();
  const [ivrs, setIvrs] = useState<any[]>([]);
  const [totCount, setTotCount] = useState(0);
  const [currPage, setCurrPage] = useState(0);
  const [startDate, setStartDate] = useState(subDays(new Date(), 7));
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<HealthIVRFilter>('');

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const page = params.get('page');
    if (page) {
      setCurrPage(parseInt(page));
    }

    const start = params.get('startDate');
    const end = params.get('endDate');
    if (start && end) {
      setStartDate(new Date(start));
      setEndDate(new Date(end));
    }
  }, [history.location.search]);

  const onQueryError = () => {
    setLoading(false);
    toast('There was an error while loading the IVR analytics.');
  };

  const queryIVRs = useCallback(() => {
    setLoading(true);
    setIvrs([]);
    AppService.adminPartnerHealthClient
      .getHealthIvrAnalytics(
        {
          count: '30',
          offset: String(30 * currPage),
          startDate: String(startOfDay(startDate)),
          endDate: String(endOfDay(endDate)),
          type: filter,
        },
        {'404': onQueryError}
      )
      .then((res) => {
        setLoading(false);
        if (!res) {
          return;
        }
        setTotCount(res.count);
        setIvrs(res.ivrAnalytics);
      });
  }, [startDate, endDate, currPage, filter]);

  useEffect(() => {
    queryIVRs();
  }, [currPage, startDate, endDate, filter]);

  const navPrevPage = () => {
    if (currPage === 0) {
      return;
    }
    history.push(
      `/health/ivr?page=${currPage - 1}&startDate=${format(startDate, 'MM-dd-yyyy')}&endDate=${format(
        endDate,
        'MM-dd-yyyy'
      )}`
    );
  };

  const navNextPage = () => {
    history.push(
      `/health/ivr?page=${currPage + 1}&startDate=${format(startDate, 'MM-dd-yyyy')}&endDate=${format(
        endDate,
        'MM-dd-yyyy'
      )}`
    );
  };

  const onSetStartDate = (date: Date) => {
    if (!date) {
      return;
    }
    const startParam = format(date, 'MM-dd-yyyy');
    const endParam = format(endDate, 'MM-dd-yyyy');
    history.push(`/health/ivr?page=${currPage}&startDate=${startParam}&endDate=${endParam}`);
  };

  const onSetEndDate = (date: Date) => {
    if (!date) {
      return;
    }
    const startParam = format(startDate, 'MM-dd-yyyy');
    const endParam = format(date, 'MM-dd-yyyy');
    history.push(`/health/ivr?page=${currPage}&startDate=${startParam}&endDate=${endParam}`);
  };

  return (
    <HealthContainer title={'Clever Health IVR Analytics'}>
      <IVRsTable
        ivrsList={ivrs}
        startDate={startDate}
        setStartDate={onSetStartDate}
        endDate={endDate}
        setEndDate={onSetEndDate}
        loading={loading}
        selectedFilter={filter}
        setSelectedFilter={setFilter}
      />
      <TablePagination
        totCount={totCount}
        currPage={currPage}
        itemsPerPage={30}
        onNavNext={navNextPage}
        onNavPrev={navPrevPage}
      />
    </HealthContainer>
  );
};
