import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, ButtonGroup, Col, CustomInput, Modal, ModalBody, ModalHeader, Row} from 'reactstrap';
import {LInput, LText} from '../../../../components/lInput';
import {PartnerSearchComponent} from '../../../../components/partnerSearchComponent';
import {HttpPartner, HttpPartnerCommissionReferral} from '../../../../dataServices/app.generated';
import {PartnerAccountDetails} from './accountDetailsStep';
import {PartnerDetails} from './partnerDetails';

export interface PartnerContract {
  commission: number;
  commissionReferral: HttpPartnerCommissionReferral | null;
  referredPartnerName: string | null;
  sendToDocusign: boolean;
}

export function PartnerCreateContractStep(props: {
  accountDetails: PartnerAccountDetails;
  partnerDetails: PartnerDetails;
  contract: PartnerContract;
  parentPartner: HttpPartner;
  setContract: (contract: PartnerContract) => void;
}) {
  const {contract, setContract, parentPartner, partnerDetails} = props;

  const [openReferralPartner, setOpenReferralPartner] = useState(false);

  return (
    <>
      <Row>
        <Col md={6}>
          <LInput
            value={contract.commission}
            onChange={(e) => setContract({...contract, commission: parseFloat(e.target.value)})}
            label={'Commission'}
            min={1}
            step={0.1}
            max={parentPartner.commission.commissionAmount}
            type={'number'}
          />
        </Col>
        <Col md={6} style={{height: '90px', display: 'flex', alignItems: 'center'}}>
          <CustomInput
            type="checkbox"
            id={'sendToDocusign'}
            label="Send Contract To Docusign"
            checked={contract.sendToDocusign}
            onChange={(e) => setContract({...contract, sendToDocusign: e.target.checked})}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ButtonGroup>
            <Button
              color="primary"
              onClick={() => {
                setContract({...contract, commissionReferral: null});
              }}
              active={contract.commissionReferral === null}
            >
              No Referred Partner
            </Button>
            <Button
              color="primary"
              onClick={() =>
                setContract({
                  ...contract,
                  commissionReferral: {dateStarted: new Date(), commissionAmount: 0.25, partnerId: null},
                })
              }
              active={contract.commissionReferral !== null}
            >
              Referred Partner
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      {contract.commissionReferral && (
        <>
          <Row>
            <Col md={6}>
              <LInput
                value={contract.commissionReferral.commissionAmount}
                onChange={(e) =>
                  setContract({
                    ...contract,
                    commissionReferral: {...contract.commissionReferral, commissionAmount: parseFloat(e.target.value)},
                  })
                }
                label={'Referral Commission'}
                min={0.01}
                step={0.01}
                max={contract.commission}
                type={'number'}
              />
            </Col>
            <Col md={6} style={{height: 90, display: 'flex', alignItems: 'center'}}>
              {contract.commissionReferral.partnerId ? (
                <LText label={'Referred Partner'} style={{fontWeight: 'bold'}}>
                  <Link to={`/partner/details/${contract.commissionReferral.partnerId}`}>
                    {contract.referredPartnerName}
                  </Link>
                  <Button style={{marginLeft: 10}} color={'primary'} onClick={() => setOpenReferralPartner(true)}>
                    Change Partner
                  </Button>
                </LText>
              ) : (
                <Button color={'primary'} onClick={() => setOpenReferralPartner(true)}>
                  Select Partner
                </Button>
              )}
            </Col>
          </Row>
        </>
      )}
      <Modal isOpen={openReferralPartner}>
        <ModalHeader toggle={() => setOpenReferralPartner(false)}>Select Referral Partner</ModalHeader>
        <ModalBody>
          <PartnerSearchComponent
            onPartnerSelect={(p) => {
              setOpenReferralPartner(false);
              setContract({
                ...contract,
                commissionReferral: {...contract.commissionReferral, partnerId: p._id},
                referredPartnerName: p.partnerName,
              });
            }}
          />
        </ModalBody>
      </Modal>
    </>
  );
}
