import cx from 'classnames';
import {inject, observer} from 'mobx-react';
import React, {Fragment} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {themeStoreDefaultProps, ThemeStoreName, ThemeStoreProps} from '../../../store/theme/store';
import {AppLogo} from '../appLogo';
import {AppNav} from '../appNav/appNav';

type Props = ThemeStoreProps;
type State = {
  active: boolean;
};

@inject(ThemeStoreName)
@observer
export class AppSidebar extends React.Component<Props, State> {
  static defaultProps = themeStoreDefaultProps;

  toggleMobileSidebar = () => {
    this.props.themeStore.setEnableMobileMenu(!this.props.themeStore.enableMobileMenu);
  };

  render() {
    const {backgroundColor, enableBackgroundImage, enableSidebarShadow} = this.props.themeStore;

    return (
      <Fragment>
        <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar} />
        <div className={cx('app-sidebar', backgroundColor, {'sidebar-shadow': enableSidebarShadow})}>
          <AppLogo />
          <PerfectScrollbar>
            <div className="app-sidebar__inner">
              <AppNav />
            </div>
          </PerfectScrollbar>
        </div>
      </Fragment>
    );
  }
}
