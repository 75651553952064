import moment from 'moment';
import React, {Component} from 'react';
import CountUp from 'react-countup';
import {DateRangePicker, Range} from 'react-date-range';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row} from 'reactstrap';
import Loading from '../../../components/loading';
import {ClaimsReportResponse, FullDate} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {FormatUtils} from '../../../utils/formatUtils';
import {FullDateUtils} from '../../../utils/fullDateUtils';

type Props = RouteComponentProps<any> & {};
type State = {
  gettingReport: boolean;
  report?: ClaimsReportResponse;
  startDate: FullDate;
  endDate: FullDate;
  showDate: boolean;
};

export class ExecutiveReport extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      gettingReport: true,
      showDate: false,
      startDate: FullDateUtils.fromDate(moment().startOf('month').toDate()),
      endDate: FullDateUtils.fromDate(moment().toDate()),
    };
  }

  async componentDidMount() {
    this.getReport();
  }
  private async getReport() {
    this.setState({gettingReport: true});
    const result = await AppService.adminReportsClient.getClaimsReport(
      {startDate: this.state.startDate, endDate: this.state.endDate},
      {}
    );
    if (result) {
      console.log(result);
      this.setState({gettingReport: false, report: result});
    }
  }

  render() {
    return (
      <>
        <Card className="mb-3">
          <CardHeader className="card-header-tab z-index-7">
            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
              <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
              Executive Report
            </div>

            <div className="btn-actions-pane-right">
              <div onClick={() => this.setState({showDate: true})} style={{display: 'flex', alignItems: 'center'}}>
                <div style={{border: 'solid 1px black', margin: 5, padding: 5}}>
                  {FullDateUtils.getString(this.state.startDate)}
                </div>
                -
                <div style={{border: 'solid 1px black', margin: 5, padding: 5}}>
                  {FullDateUtils.getString(this.state.endDate)}
                </div>
              </div>
            </div>
          </CardHeader>
          {this.state.gettingReport ? (
            <div className={'pt-4 pb-4'}>
              <Loading />
            </div>
          ) : (
            <Row className="no-gutters">
              <Col sm="6" md="4" lg="3" xl="4">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-warning" />
                    <i className="lnr-laptop-phone text-dark opacity-8" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Total Savings</div>
                    <div className="widget-numbers">
                      <CountUp
                        start={0}
                        end={this.state.report.totalSavings}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix="$"
                        useEasing={true}
                        suffix=""
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col sm="6" md="4" lg="3" xl="2">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-warning" />
                    <i className="lnr-laptop-phone text-dark opacity-8" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Percent Saved</div>
                    <div className="widget-numbers">
                      <CountUp
                        start={0}
                        end={Math.round(this.state.report.averageSavingPercent * 100)}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix="%"
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col sm="6" md="4" lg="3" xl="2">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-warning" />
                    <i className="lnr-laptop-phone text-dark opacity-8" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Prices</div>
                    <div className="widget-numbers">
                      <CountUp
                        start={0}
                        end={this.state.report.numberOfSearches}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=""
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col sm="6" md="4" lg="3" xl="2">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-warning" />
                    <i className="lnr-laptop-phone text-dark opacity-8" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">App Claims</div>
                    <div className="widget-numbers">
                      <CountUp
                        start={0}
                        end={this.state.report.appClaims}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=""
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
              <Col sm="6" md="4" lg="3" xl="2">
                <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                  <div className="icon-wrapper rounded-circle">
                    <div className="icon-wrapper-bg opacity-10 bg-warning" />
                    <i className="lnr-laptop-phone text-dark opacity-8" />
                  </div>
                  <div className="widget-chart-content">
                    <div className="widget-subheading">Non App Claims</div>
                    <div className="widget-numbers">
                      <CountUp
                        start={0}
                        end={this.state.report.nonAppClaims}
                        separator=","
                        decimals={0}
                        decimal="."
                        prefix=""
                        useEasing={true}
                        suffix=""
                        duration={4}
                      />
                    </div>
                  </div>
                </div>
                <div className="divider m-0 d-md-none d-sm-block" />
              </Col>
            </Row>
          )}
        </Card>
        {this.state.showDate && (
          <div style={{right: 0, position: 'absolute', zIndex: 100, border: 'solid'}}>
            <DateRangePicker
              ranges={[
                {
                  startDate: this.state.startDate ? FullDateUtils.toDate(this.state.startDate) : null,
                  endDate: this.state.endDate ? FullDateUtils.toDate(this.state.endDate) : null,
                },
              ]}
              onChange={(result) => {
                const range = (result as any).range1 as Range;
                if (!range) {
                  return;
                }
                this.setState(
                  {
                    startDate: range.startDate ? FullDateUtils.fromDate(range.startDate) : null,
                    endDate: range.endDate ? FullDateUtils.fromDate(range.endDate) : null,
                    showDate: false,
                  },
                  () => {
                    this.getReport();
                  }
                );
              }}
            />
          </div>
        )}

        {this.state.gettingReport ? (
          <div className={'pt-4 pb-4'}>
            <Loading />
          </div>
        ) : (
          <Row>
            {this.state.report.claimOfThePeriod && (
              <Col md="12" lg="6" xl="3">
                <Card className="card-hover-shadow profile-responsive card-border border-success mb-3">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner bg-success">
                      <div className="menu-header-content">
                        <div className="avatar-icon-wrapper btn-hover-shine mb-2 avatar-icon-xl" />
                        <div>
                          <h4 className="menu-header-title">{this.state.report.claimOfThePeriod.drugName}</h4>
                          <h6 className="menu-header-subtitle">
                            Top Claim {this.state.endDate ? 'Between' : 'For'}
                            <br />
                            {FullDateUtils.toMoment(this.state.startDate).format('MMM Do, YYYY')}
                            {this.state.endDate && (
                              <> and {FullDateUtils.toMoment(this.state.endDate).format('MMM Do, YYYY')}</>
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <CardBody>
                    <h5 className={'mt-2 mb-2'}>
                      Total Savings: <b>{FormatUtils.formatMoney(this.state.report.claimOfThePeriod.totalSavings)}</b>
                    </h5>
                    <h5 className={'mt-2 mb-2'}>
                      Percent Saved: <b>{Math.round(this.state.report.claimOfThePeriod.percentSaved * 100)}%</b>
                    </h5>
                    <h5 className={'mt-2 mb-2'}>
                      Pharmacy:{' '}
                      <b>
                        {this.state.report.claimOfThePeriod.pharmacy}
                        {', '}
                        {moment(this.state.report.claimOfThePeriod.date).format('MMM Do')}
                      </b>
                    </h5>
                  </CardBody>
                </Card>
                <Card className="card-shadow-primary profile-responsive card-border mb-3">
                  <div className="dropdown-menu-header">
                    <div className="dropdown-menu-header-inner" style={{backgroundColor: 'purple'}}>
                      <div className="menu-header-content btn-pane-right">
                        <div>
                          <h5 className="menu-header-title">Provider Breakdown</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ListGroup flush>
                    <ListGroupItem>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">Singlecare</div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers widget-numbers-sm text-primary">
                              <CountUp
                                start={0}
                                end={this.state.report.singlecareClaims}
                                separator=""
                                decimals={0}
                                decimal=","
                                prefix=""
                                suffix={' Claims'}
                                duration={3}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">RxEDO</div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers widget-numbers-sm text-primary">
                              <CountUp
                                start={0}
                                end={this.state.report.rxedoClaims}
                                separator=""
                                decimals={0}
                                decimal=","
                                prefix=""
                                suffix={' Claims'}
                                duration={3}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </Col>
            )}
            <Col md="12" lg="6" xl="3">
              <Card className="card-shadow-primary profile-responsive card-border mb-3">
                <div className="dropdown-menu-header">
                  <div className="dropdown-menu-header-inner bg-warning">
                    <div className="menu-header-content btn-pane-right">
                      <div>
                        <h5 className="menu-header-title">Top Pharmacies</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <ListGroup flush>
                  {this.state.report.topPharmacies.map((a) => (
                    <ListGroupItem key={a.pharmacyName}>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <div className="widget-heading">{a.pharmacyName}</div>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers widget-numbers-sm text-primary">
                              <CountUp
                                start={0}
                                end={a.count}
                                separator=""
                                decimals={0}
                                decimal=","
                                prefix=""
                                suffix={' Claims'}
                                duration={3}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Card>
            </Col>
            <Col md="12" lg="6" xl="6">
              <Card className="card-shadow-primary profile-responsive card-border mb-3">
                <div className="dropdown-menu-header">
                  <div className="dropdown-menu-header-inner bg-danger">
                    <div className="menu-header-content btn-pane-right">
                      <div>
                        <h5 className="menu-header-title">Top Partners</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <ListGroup flush>
                  {this.state.report.topPartnersClaims.map((p) => (
                    <ListGroupItem key={p.partnerId}>
                      <div className="widget-content p-0">
                        <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                            <h4 className="widget-heading">
                              <Link to={`/partner/details/${p.partnerId}`}>
                                {p.groupCode}-{p.memberId} {p.partnerName}
                              </Link>
                            </h4>
                          </div>
                          <div className="widget-content-right">
                            <div className="widget-numbers widget-numbers-sm text-primary">
                              <h5 className={'mt-2 mb-2'}>
                                Total Savings: <b>{FormatUtils.formatMoney(p.totalSavings)}</b>
                              </h5>
                              <h5 className={'mt-2 mb-2'}>
                                Average Percent Saved: <b>{Math.round(p.averageSavingPercent * 100)}%</b>
                              </h5>
                              <h5 className={'mt-2 mb-2'}>
                                App Claims: <b>{p.appClaims}</b>
                              </h5>
                              <h5 className={'mt-2 mb-2'}>
                                Non App Claims: <b>{p.nonAppClaims}</b>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Card>
            </Col>
          </Row>
        )}
      </>
    );
  }
}
