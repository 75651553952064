export class Utils {
  static groupBy<T, TKey>(items: T[], predicate: (pred: T) => TKey): {key: TKey; items: T[]}[] {
    const groups: {key: TKey; items: T[]}[] = [];
    for (const item of items) {
      const key = predicate(item);
      let group = groups.find((a) => a.key === key);
      if (!group) {
        groups.push((group = {key, items: []}));
      }
      group.items.push(item);
    }
    return groups;
  }
  static rangeC<T>(length: number, callback: (ind: number) => T): T[] {
    return this.range(length).map(callback);
  }

  static range(length: number) {
    const items = [];
    for (let i = 0; i < length; i++) {
      items.push(i);
    }
    return items;
  }
  static toDictionary<T, TKey extends string>(items: T[], predicate: (pred: T) => TKey): {[key in TKey]?: T} {
    const dict: {[key in TKey]?: T} = {};
    for (const item of items) {
      const key = predicate(item);
      dict[key] = item;
    }
    return dict;
  }

  static fromDictionary<T, TKey extends string>(dict: {[key in TKey]?: T}): T[] {
    const items: T[] = [];

    for (const key in dict) {
      if (dict.hasOwnProperty(key)) {
        items.push(dict[key]);
      }
    }
    return items;
  }

  static levenshteinDistance(a: string, b: string) {
    if (a.length === 0) {
      return b.length;
    }
    if (b.length === 0) {
      return a.length;
    }

    const matrix: number[][] = [];

    // increment along the first column of each row
    let i;
    for (i = 0; i <= b.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    let j;
    for (j = 0; j <= a.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
      for (j = 1; j <= a.length; j++) {
        if (b.charAt(i - 1) === a.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[b.length][a.length];
  }

  static timeoutPromise<T>(promise: Promise<T[]>, timeout: number): Promise<T[]> {
    return new Promise<T[]>((res) => {
      let resolved = false;
      setTimeout(() => {
        if (!resolved) {
          resolved = true;
          res([]);
        }
      }, timeout);

      promise.then((items) => {
        if (!resolved) {
          resolved = true;
          res(items);
        }
      });
    });
  }

  static sum<T>(items: T[], callback: (t: T) => number): number {
    let total = 0;
    for (const item of items) {
      total += callback(item);
    }
    return total;
  }

  static timeout(timeout: number): Promise<void> {
    return new Promise((res) => {
      setTimeout(() => {
        res();
      }, timeout);
    });
  }

  static generateForgotPasswordCode() {
    let text = 'S9';
    const possible = 'ABCDEFGHJKLMNPQRTUVWXYZ';

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static randomItem<T>(item: T[]): T {
    return item[Math.floor(Math.random() * item.length)];
  }

  static flat<T, T2>(items: T[], callback: (a: T) => T2[]) {
    const t2s: T2[] = [];
    for (const item of items) {
      t2s.push(...callback(item));
    }
    return t2s;
  }
}
