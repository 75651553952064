import React, {Component, Fragment} from 'react';
import MetisMenu from 'react-metismenu';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Navigation} from '../../../navItems';
import RouterLink from '../../routerLink';

type Props = RouteComponentProps<any>;

class Nav extends Component<Props> {
  render() {
    return (
      <Fragment>
        <MetisMenu
          content={Navigation}
          activeLinkFromLocation
          className="vertical-nav-menu"
          iconNamePrefix=""
          LinkComponent={RouterLink}
          classNameStateIcon="pe-7s-angle-down"
        />
      </Fragment>
    );
  }
}

export const AppNav = withRouter(Nav);
