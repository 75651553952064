import React, {FC} from 'react';
import {useClockCounter} from '../../../hooks/useClock';

export const BigLoading: FC<{loading: boolean; size?: number}> = ({loading, size}) => {
  const width = 62;
  const height = 61;

  const loadingSize = size ?? '9rem';
  const clock = useClockCounter(200);

  if (!loading) return <></>;

  return (
    <div style={{margin: '2rem'}}>
      <svg viewBox="0 0 288 328" width={loadingSize} height={loadingSize}>
        <path
          fill={'none'}
          d="M569.56,264.18v38.6H519.82a145.75,145.75,0,0,0,0,186.44h51v39.16a146,146,0,0,0,123.47-.56V427.48H570.8V365.75H694.27V264.18a146.05,146.05,0,0,0-124.71,0Z"
          transform="translate(-468 -232.09)"
        />
        <path
          fill={'#bbb'}
          d="M631.92,250.2a145.11,145.11,0,0,1,62.35,14V245h1.56a164.14,164.14,0,0,0-126.27-.64v19.78A145.3,145.3,0,0,1,631.92,250.2Z"
          transform="translate(-468 -232.09)"
        />
        <path
          fill={'#bbb'}
          d="M519.82,489.22a145.75,145.75,0,0,1,0-186.44h-12V289a163.76,163.76,0,0,0,1.23,215.5V489.22Z"
          transform="translate(-468 -232.09)"
        />
        <path
          fill={'#bbb'}
          d="M631.92,541.8a145.24,145.24,0,0,1-61.12-13.42v19.71a164,164,0,0,0,123.47-.49V527.82A145.11,145.11,0,0,1,631.92,541.8Z"
          transform="translate(-468 -232.09)"
        />

        <polygon
          fill={clock % 6 === 0 ? '#e64b38' : '#ff7f40'}
          points={`0 0 ${width} 0 ${width} ${height} 0 ${height}`}
          transform={'translate(40,12)'}
        />

        <polygon
          fill={clock % 6 === 1 || clock % 6 === 4 ? '#ff7f40' : '#e64b38'}
          points={`0 0 ${width} 0 ${width} ${height} 0 ${height}`}
          transform={`translate(${40 + width * 3},${12 + height * 0})`}
        />
        <polygon
          fill={clock % 6 === 2 || clock % 6 === 5 ? '#ff7f40' : '#e64b38'}
          points={`0 0 ${width} 0 ${width} ${height + 1} 0 ${height + 1}`}
          transform={`translate(${40 + width * 3},${12 + height * 1})`}
        />
        <polygon
          fill={clock % 6 === 3 || clock % 6 === 0 ? '#ff7f40' : '#e64b38'}
          points={`0 0 ${width} 0 ${width} ${height + 1} 0 ${height + 1}`}
          transform={`translate(${40 + width * 3},${12 + height * 2})`}
        />
        <polygon
          fill={clock % 6 === 3 || clock % 6 === 0 ? '#ff7f40' : '#e64b38'}
          points={`0 0 ${width + 1} 0 ${width + 1} ${height} 0 ${height}`}
          transform={`translate(${40 + width * 2},${12 + height * 2})`}
        />
        <polygon
          fill={clock % 6 === 3 || clock % 6 === 0 ? '#ff7f40' : '#e64b38'}
          points={`0 0 ${width + 1} 0 ${width + 1} ${height} 0 ${height}`}
          transform={`translate(${40 + width * 1},${12 + height * 2})`}
        />
        <polygon
          fill={clock % 6 === 1 || clock % 6 === 4 ? '#ff7f40' : '#e64b38'}
          points={`0 0 ${width} 0 ${width} ${height + 1} 0 ${height + 1}`}
          transform={`translate(${40 + width * 3},${12 + height * 3})`}
        />
        <polygon
          fill={clock % 6 === 2 || clock % 6 === 5 ? '#ff7f40' : '#e64b38'}
          points={`0 0 ${width} 0 ${width} ${height} 0 ${height}`}
          transform={`translate(${40 + width * 3},${12 + height * 4})`}
        />

        <polygon
          fill={clock % 6 === 3 ? '#e64b38' : '#ff7f40'}
          points={`0 0 ${width} 0 ${width} ${height} 0 ${height}`}
          transform={`translate(${40},${12 + height * 4})`}
        />
      </svg>
    </div>
  );
};
