import React, {FC} from 'react';
import {format} from 'date-fns';

interface UsersTableItemProps {
  onNavUser: () => void;
  id: string;
  numberOfDependents: number;
  createdDate: string;
  groupCode: string;
  memberId: string;
  firstName: string;
}

export const UsersTableItem: FC<UsersTableItemProps> = (props) => {
  return (
    <tr onClick={props.onNavUser}>
      <td style={{paddingLeft: '1.25rem'}}>
        <div>{props.firstName}</div>
      </td>
      <td>
        <div>{props.groupCode}</div>
      </td>
      <td>
        <div>{props.memberId}</div>
      </td>
      <td>
        <div>{format(new Date(props.createdDate), 'P')}</div>
      </td>
      <td style={{paddingRight: '1.25rem'}}>
        <div>{props.numberOfDependents}</div>
      </td>
    </tr>
  );
};
