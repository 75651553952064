import React, {Component} from 'react';
import CountUp from 'react-countup';
import {RouteComponentProps} from 'react-router';
import {Card, CardHeader, Col, Row} from 'reactstrap';
import Loading from '../../../components/loading';
import {AdminReport, AllTimeClaimsReportResponse} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';

type Props = RouteComponentProps<any> & {};
type State = {
  gettingReport: boolean;
  report?: AdminReport;
  claimsReport?: AllTimeClaimsReportResponse;
};

export class MainReport extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      gettingReport: true,
    };
  }

  async componentDidMount() {
    this.getReport();
  }
  private async getReport() {
    this.setState({gettingReport: true});
    const result = await Promise.all([
      AppService.adminClient.report({}, {}),
      AppService.adminReportsClient.getAllTimeClaimsReport({}, {}),
    ]);
    if (result) {
      this.setState({
        gettingReport: false,
        report: result[0].report,
        claimsReport: result[1],
      });
    }
  }

  render() {
    if (this.state.gettingReport) {
      return <Loading />;
    }

    return (
      <>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader className="card-header-tab z-index-6">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
                  All Time Account Numbers
                </div>
              </CardHeader>
              <Row className="no-gutters">
                <Col sm="6" md="4" lg="4" xl="3">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Users</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.report.userCount}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" lg="4" xl="3">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Partners</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.report.partnerCount}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" lg="4" xl="3">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Cards</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.report.cardCount}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" lg="4" xl="3">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Accounts</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.report.accountCount}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader className="card-header-tab z-index-6">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
                  All Times Claims Numbers
                </div>
              </CardHeader>
              <Row className="no-gutters">
                <Col sm="6" md="4" lg="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Total Savings</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.claimsReport.totalSavings}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix="$"
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" lg="4" xl="2">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Average Percent Saved</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={Math.round(this.state.claimsReport.averageSavingPercent * 100)}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix="%"
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" lg="4" xl="2">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Prices</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.claimsReport.numberOfSearches}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" lg="4" xl="2">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">App Claims</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.claimsReport.appClaims}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" lg="4" xl="2">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Non App Claims</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.claimsReport.nonAppClaims}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
