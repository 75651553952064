import moment from 'moment';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {toast} from 'react-toastify';
import {Card, CardBody, CardTitle, Col, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {HttpStripePayoutRequest} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore} from '../../../store/main/store';
import {FormatUtils} from '../../../utils/formatUtils';
import {Utils} from '../../../utils/utils';

type Props = RouteComponentProps<{}> & {};
type State = {stripePayoutRequests?: HttpStripePayoutRequest[]};

export class ClaimsPayoutRequests extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};
  }

  async componentDidMount() {
    const result = await AppService.adminClaimClient.getPayoutRequest({}, {});
    if (result) {
      this.setState({stripePayoutRequests: result.stripePayoutRequests});
    }
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {this.state.stripePayoutRequests ? this.renderBody() : <Loading />}
      </ReactCSSTransitionGroup>
    );
  }

  private renderBody() {
    return (
      <>
        <PageTitle heading={`Payout Requests`} subheading="" icon="pe-7s-medal icon-gradient bg-tempting-azure" />
        <Row>
          <Col md="9">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>
                  Partner Payout Requests (
                  {FormatUtils.formatMoney(Utils.sum(this.state.stripePayoutRequests, (a) => a.amount))})
                </CardTitle>
                {this.state.stripePayoutRequests.length === 0 ? (
                  <p>There are no more pending payout requests!</p>
                ) : (
                  <>
                    <Row>
                      <Col md={3}>Partner</Col>
                      <Col md={3}>Date Requested</Col>
                      <Col md={3}>Amount</Col>
                      <Col md={3} />
                    </Row>
                  </>
                )}

                {this.state.stripePayoutRequests
                  .sort((a, b) => +moment(a.dateRequested).toDate() - +moment(b.dateRequested).toDate())
                  .map((c) => (
                    <Row key={c.id}>
                      <Col md={3}>
                        {c.partner.partnerName}
                        {c.partner.payoutStyle === 'giftcard' ? ' (GIFT CARD)' : ''} ({c.partner.startingGroupCode}-
                        {c.partner.startingMemberId})
                      </Col>
                      <Col md={3}>{moment(c.dateRequested).format('YYYY-MM-DD')}</Col>
                      <Col md={3}>{FormatUtils.formatMoney(c.amount)}</Col>
                      <Col md={3}>
                        <PromiseButton color="primary" size="sm" onClick={() => this.payRequest(c)}>
                          Pay Out
                        </PromiseButton>
                      </Col>
                    </Row>
                  ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  private payRequest = async (c: HttpStripePayoutRequest) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to pay this invoice? Make sure there is enough money in stripe to cover this')) {
      const result = await AppService.adminClaimClient.fulfillPayoutRequest(
        {
          requestId: c.id,
        },
        {...MainStore.handleError(400)}
      );
      if (result.success) {
        toast('This request has been processed in stripe.', {type: 'success'});
        this.setState({stripePayoutRequests: this.state.stripePayoutRequests.filter((a) => a.id !== c.id)});
      }
    }
  };
}
