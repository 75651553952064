import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import {Button, Card, CardBody, Col, Form, Input, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {HttpPartnerSearch} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';

type Props = RouteComponentProps<{query?: string}> & {};
type State = {
  search: string;
  loadingSearch: boolean;
  partners: HttpPartnerSearch[];
  initialSearchDone: boolean;
  page: number;
};

export class PartnerSearch extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      search: props.match.params.query || '',
      loadingSearch: false,
      page: 0,
      initialSearchDone: !!props.match.params.query,
      partners: [],
    };
  }

  async componentDidMount() {
    if (this.state.search) {
      await this.search();
    }
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle heading="Partners" subheading="" icon="pe-7s-medal icon-gradient bg-tempting-azure" />
        </div>
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    this.setState({page: 0}, this.search);
                    e.preventDefault();
                  }}
                >
                  <Row className={'py-2'}>
                    <Col md={6}>
                      <Input
                        placeholder={'ex: Salvatore'}
                        className={'mr-2'}
                        type={'search'}
                        value={this.state.search}
                        onChange={(e) => this.setState({search: e.target.value})}
                      />
                    </Col>
                    <Col md={2}>
                      <Button color="primary" size="lg" type={'submit'}>
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {this.state.initialSearchDone && (
                  <>
                    <ReactTable
                      resizable={false}
                      loading={this.state.loadingSearch}
                      data={this.state.partners}
                      manual
                      getTrProps={(state: any, rowInfo: any) => {
                        if (rowInfo && rowInfo.row) {
                          return {
                            onClick: () => {
                              this.props.history.push(`/partner/details/${rowInfo.original._id}`);
                            },
                          };
                        } else {
                          return {};
                        }
                      }}
                      showPageSizeOptions={false}
                      defaultPageSize={10}
                      sortable={false}
                      page={this.state.page}
                      showPagination={false}
                      columns={[
                        {
                          Header: 'Partner Name',
                          accessor: 'partnerName',
                          Cell: (e) => (
                            <Link to={`/partner/details/${e.original._id}`} onClick={(c) => c.preventDefault()}>
                              {e.value}
                            </Link>
                          ),
                        },
                        {
                          id: 'startingCard',
                          Header: 'Starting Card',
                          accessor: (a) => a.startingGroupCode + '-' + a.startingMemberId,
                        },
                        {
                          id: 'parentPartnerName',
                          accessor: (a) => a.parentPartnerName || 'MASTER',
                          Header: 'Parent Partner Name',
                        },
                      ]}
                      style={{height: '600px'}}
                      className="-striped -highlight -fixed"
                    />
                    <Row>
                      <Col sm={'3'}>
                        <Button
                          color="primary"
                          size={'lg'}
                          disabled={this.state.page === 0}
                          onClick={() => this.setState({page: this.state.page - 1}, this.search)}
                        >
                          Previous
                        </Button>
                      </Col>
                      <Col sm={'6'} />
                      <Col sm={'3'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button
                          size={'lg'}
                          color="primary"
                          onClick={() => this.setState({page: this.state.page + 1}, this.search)}
                        >
                          Next
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
    );
  }

  private search = async () => {
    if (this.props.history.location.pathname !== `/partner/search/${this.state.search}`) {
      this.props.history.push(`/partner/search/${this.state.search}`);
    }
    this.setState({loadingSearch: true, initialSearchDone: true});
    const result = await AppService.adminPartnerClient.searchPartners(
      {
        page: this.state.page.toString(),
        query: this.state.search,
        onlyMasters: 'false',
      },
      {}
    );
    if (result) {
      this.setState({
        partners: result.partners,
        page: result.page,
        loadingSearch: false,
      });
    }
  };
}
