import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  CustomInput,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';
import {LInput, LText} from '../../../components/lInput';
import Loading from '../../../components/loading';
import {PartnerSearchComponent} from '../../../components/partnerSearchComponent';
import {PromiseButton} from '../../../components/promiseButton';
import {
  HealthConfiguration,
  HttpPartner,
  HttpPartnerExtended,
  HttpPartnerSearch,
  UpdatePartnerHealthCommissionRequest,
} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {useEffectAsync} from '../../../hooks/useEffectAsync';
import {MainStore} from '../../../store/main/store';

export function PartnerHealthDetails({
  partner,
  setPartner,
}: {
  partner: HttpPartnerExtended;
  setPartner: (partner: HttpPartnerExtended) => void;
}) {
  const history = useHistory();
  const [referralPartners, setReferralPartners] = useState<{[id: string]: HttpPartnerSearch}>();
  const [referrals, setReferrals] = useState<HttpPartner[]>([]);
  const [healthModified, setHealthModified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateCommission, setUpdateCommission] = useState<UpdatePartnerHealthCommissionRequest>();

  const [openReferralPartner, setOpenReferralPartner] = useState(false);
  const [openReferralPartnerIndex, setOpenReferralPartnerIndex] = useState(0);

  const updatePartnerDetails = async () => {
    if (healthModified) {
      const result = await AppService.adminPartnerHealthClient.updateCommission(updateCommission, {
        ...MainStore.handleError(400),
      });
      if (result) {
        setHealthModified(false);
        setPartner(result.partner);
        toast('Partner has been updated!', {type: 'success'});
      }
    }
  };

  useEffectAsync(async () => {
    for (const commissionReferral of partner.health.commissionReferrals) {
      if (commissionReferral.partnerId) {
        const referralResult = await AppService.adminPartnerClient.getPartner(
          {partnerId: commissionReferral.partnerId},
          {
            ...MainStore.handleError(404, () => {
              history.push('/');
            }),
          }
        );
        setReferralPartners({
          ...referralPartners,
          [referralResult.partner.id]: {
            _id: referralResult.partner.id,
            partnerName: referralResult.partner.partnerName,
            parentPartnerName: null,
            startingGroupCode: null,
            startingMemberId: null,
          },
        });
      }
    }

    const getReferralResult = await AppService.adminPartnerHealthClient.getReferrals(
      {
        partnerId: partner.id,
      },
      {
        ...MainStore.handleError(404, () => {
          history.push('/');
        }),
        ...MainStore.handleError(400, () => {
          history.push('/');
        }),
      }
    );
    if (getReferralResult) {
      setReferrals(getReferralResult.referrals);
    }
    setUpdateCommission({
      partnerId: partner.id,
      commissionReferrals: partner.health.commissionReferrals,
      commissionAmount: partner.health.commission.commissionAmount,
    });
    setLoading(false);
  }, []);

  return (
    <>
      <Modal isOpen={openReferralPartner}>
        <ModalHeader toggle={() => setOpenReferralPartner(false)}>Select Referral Partner</ModalHeader>
        <ModalBody>
          <PartnerSearchComponent
            onPartnerSelect={(p) => {
              const commissionReferrals = [...updateCommission.commissionReferrals];
              commissionReferrals[openReferralPartnerIndex] = {
                ...commissionReferrals[openReferralPartnerIndex],
                partnerId: p._id,
              };

              setHealthModified(true);
              setOpenReferralPartner(false);
              setUpdateCommission({
                ...updateCommission,
                commissionReferrals,
              });
              setReferralPartners({...referralPartners, [p._id]: p});
            }}
          />
        </ModalBody>
      </Modal>
      <Card className="main-card mb-3">
        <CardBody>
          <CardTitle>Health</CardTitle>
          {loading ? (
            <>
              <Loading />
            </>
          ) : (
            <>
              <Form>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Commission Amount'}
                      value={updateCommission.commissionAmount}
                      type={'number'}
                      min={0.1}
                      step={0.01}
                      max={2.5}
                      onChange={(e) => {
                        setHealthModified(true);
                        setUpdateCommission({...updateCommission, commissionAmount: parseFloat(e.target.value)});
                      }}
                    />
                  </Col>
                </Row>
              </Form>
              <Form>
                {updateCommission.commissionReferrals.map((c, ind) => (
                  <Row key={c.partnerId} style={{paddingTop: 10}}>
                    <Col md={6}>
                      <LInput
                        value={c.commissionAmount}
                        onChange={(e) => {
                          c.commissionAmount = parseFloat(e.target.value);
                          setHealthModified(true);
                          setUpdateCommission({
                            ...updateCommission,
                            commissionReferrals: [...updateCommission.commissionReferrals],
                          });
                        }}
                        label={'Referral Commission'}
                        min={0.01}
                        step={0.01}
                        max={2.5}
                        type={'number'}
                      />
                    </Col>
                    <Col md={4} style={{height: 90, display: 'flex', alignItems: 'center'}}>
                      {c.partnerId ? (
                        <LText label={'Referred Partner'} style={{fontWeight: 'bold'}}>
                          <Link to={`/partner/details/${c.partnerId}`}>
                            {!referralPartners[c.partnerId] ? 'Loading...' : referralPartners[c.partnerId].partnerName}
                          </Link>
                          <Button
                            style={{marginLeft: 10}}
                            color={'primary'}
                            onClick={() => {
                              setOpenReferralPartnerIndex(ind);
                              setOpenReferralPartner(true);
                            }}
                          >
                            Change Partner
                          </Button>
                        </LText>
                      ) : (
                        <Button
                          color={'primary'}
                          onClick={() => {
                            setOpenReferralPartnerIndex(ind);
                            setOpenReferralPartner(true);
                          }}
                        >
                          Select Partner
                        </Button>
                      )}
                    </Col>
                    <Col md={2} style={{height: 90, display: 'flex', alignItems: 'center'}}>
                      <Button
                        color="primary"
                        onClick={() => {
                          setHealthModified(true);
                          const commissionReferrals = [...updateCommission.commissionReferrals].filter(
                            (a) => a.partnerId !== c.partnerId
                          );
                          setUpdateCommission({
                            ...updateCommission,
                            commissionReferrals,
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button
                  color="primary"
                  onClick={() => {
                    setHealthModified(true);

                    setUpdateCommission({
                      ...updateCommission,
                      commissionReferrals: [
                        ...updateCommission.commissionReferrals,
                        {dateStarted: new Date(), commissionAmount: 0.25, partnerId: null},
                      ],
                    });
                  }}
                >
                  Add Referred Partner
                </Button>
              </Form>
            </>
          )}

          <CardTitle style={{paddingTop: 10}}>Referrals</CardTitle>
          <Form>
            {referrals.map((c, ind) => {
              const r = c.health.commissionReferrals.find((a) => a.partnerId === partner.id);
              return (
                <Row key={c.id} style={{paddingTop: 10}}>
                  <Col md={6}>
                    <LInput readOnly value={r.commissionAmount} label={'Referral Commission'} type={'number'} />
                  </Col>
                  <Col md={5} style={{height: 90, display: 'flex', alignItems: 'center'}}>
                    <LText label={'Referred Partner'} style={{fontWeight: 'bold'}}>
                      <Link to={`/partner/details/${c.id}`}>{c.partnerName}</Link>
                    </LText>
                  </Col>
                </Row>
              );
            })}
            {referrals.length === 0 && <span>This partner has no referrals</span>}
          </Form>

          <CardTitle style={{paddingTop: 10}}>Configuration</CardTitle>
          <b>
            Please visit the new portal at <a href={'https://client.cleverhealth.ai'}>client.cleverhealth.ai</a> to view
            and modify health configurations. Configurations are found on the card details page rather than the partner
            details page.
          </b>
          <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
            <PromiseButton
              disabled={!healthModified}
              onClick={updatePartnerDetails}
              className="btn-pill btn-shadow mr-3"
              color="primary"
            >
              Save Changes
            </PromiseButton>
          </Row>
        </CardBody>
      </Card>
    </>
  );
}
