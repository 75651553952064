import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import classnames from 'classnames';

class RouterLink extends React.Component {
  componentWillMount() {
    this.to = this.props.to;
    if (this.to[0] !== '/') {
      this.to = `/${this.to}`;
    }

    this.props.history.listen(this.onLocationChange.bind(this));
    this.onLocationChange(this.props.location);
  }

  onLocationChange(e) {
    if ((e.pathname || '/') === this.to) {
      this.props.activateMe();
    }
  }

  render() {
    const {
      className,
      classNameActive,
      classNameHasActiveChild,
      active,
      hasActiveChild,
      to,
      externalLink,
      hasSubMenu,
      toggleSubMenu,
      children,
    } = this.props;

    return hasSubMenu || externalLink ? (
      <a
        className={classnames(className, hasActiveChild && classNameHasActiveChild)}
        target={externalLink ? '_blank' : undefined}
        href={to}
        onClick={toggleSubMenu}
      >
        {children}
      </a>
    ) : (
      <Link className={classnames(className, active && classNameActive)} to={to}>
        {children}
      </Link>
    );
  }
}

export default withRouter(RouterLink);
