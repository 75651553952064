import React, {FC, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {HttpHealthVisit} from '../../../../dataServices/app.generated';
import {BigLoading} from '../../components/bigLoading';
import {Datepicker} from '../../components/DatePicker/DatePicker';
import '../../components/healthTable.css';
import {VisitsTableItem} from './visitsTableItem';

interface VisitsTableProps {
  visitList: HttpHealthVisit[];
  startDate: Date;
  endDate: Date;
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  loading: boolean;
}

export const VisitsTable: FC<VisitsTableProps> = (props) => {
  const history = useHistory();

  const onNavVisit = (id: number) => {
    history.push('/health/visit/' + id);
  };

  const visitList = useMemo(() => {
    return props.visitList.map((visit) => (
      <VisitsTableItem key={visit.id} {...visit} onNavVisit={() => onNavVisit(visit.zipVisitId)} />
    ));
  }, [props.visitList]);

  const onChangeDate = (selectedDates: Date[]) => {
    props.setStartDate(selectedDates[0]);
    selectedDates.length === 1 ? props.setEndDate(null) : props.setEndDate(selectedDates[1]);
  };

  return (
    <div className={'HealthTable'}>
      <header className={'HealthTable__header'}>
        <h2>Visits</h2>
        <Datepicker value={[props.startDate, props.endDate]} onChange={onChangeDate} />
      </header>
      <div className={'HealthTable__tableContainer'}>
        <table className={'HealthTable__table'}>
          <thead>
            <tr>
              <th style={{paddingLeft: '1.25rem'}}>
                <div>Zip Visit ID</div>
              </th>
              <th>
                <div>First Name</div>
              </th>
              <th>
                <div>Reason Code</div>
              </th>
              <th>
                <div>Status</div>
              </th>
              <th style={{paddingRight: '1.25rem'}}>
                <div>Date Created</div>
              </th>
            </tr>
          </thead>
          <tbody>{visitList}</tbody>
        </table>
        {visitList.length === 0 && !props.loading && (
          <p className={'HealthTable__empty'}>Sorry, no visits could be found.</p>
        )}
        {props.loading && (
          <div className={'HealthTable__loadingContainer'}>
            <BigLoading loading />
          </div>
        )}
      </div>
    </div>
  );
};
