import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Col, CustomInput, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row} from 'reactstrap';
import {LInput, LSelect, LText} from '../components/lInput';
import {PromiseButton} from '../components/promiseButton';
import {HttpPartnerExtended, PartnerType} from '../dataServices/app.generated';
import {AppService} from '../dataServices/appService';
import {MainStore} from '../store/main/store';
import {PartnerUtils} from '../utils/partnerUtils';

type Props = RouteComponentProps<{partnerId: string}> & {
  partner: HttpPartnerExtended;
  close: () => void;
};
type State = {
  referralAmount: number;
  commissionAmount: number;
  partnerType: PartnerType;
  sendToDocusign: boolean;
};

export class BreakOffModal extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      commissionAmount: 2,
      partnerType: 'Tier 1',
      referralAmount: 0.25,
      sendToDocusign: false,
    };
  }

  private breakOff = async () => {
    const result = await AppService.adminPartnerClient.breakOffPartner(
      {
        referralAmount: this.state.referralAmount,
        commissionAmount: this.state.commissionAmount,
        partnerId: this.props.partner.id,
        partnerType: this.state.partnerType,
        sendToDocusign: this.state.sendToDocusign,
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      this.props.history.push(`/partner/details/${result.partner.id}`);
    }
  };

  render() {
    const partnerTypeOptions = PartnerUtils.getAllPartnerTypes().map((a) => ({label: a, value: a}));
    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={this.props.close}>New Partner Details</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md={4}>
                <LText style={{fontWeight: 'bold'}} label={'Partner Name'}>
                  {this.props.partner.partnerName}
                </LText>
              </Col>
              <Col md={4}>
                <LSelect
                  label={'New Partner Type'}
                  value={partnerTypeOptions.find((a) => a.value === this.state.partnerType)}
                  options={partnerTypeOptions}
                  onChange={(e) =>
                    this.setState({
                      partnerType: (e as any).value,
                    })
                  }
                />
              </Col>
              <Col md={4} style={{height: '90px', display: 'flex', alignItems: 'center'}}>
                <CustomInput
                  type="checkbox"
                  id={'sendToDocusign'}
                  label="Send Contract To Docusign"
                  checked={this.state.sendToDocusign}
                  onChange={(e) => this.setState({sendToDocusign: e.target.checked})}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <LInput
                  type={'number'}
                  label={'Commission Amount'}
                  value={this.state.commissionAmount}
                  min={0.1}
                  step={0.01}
                  max={2.5}
                  onChange={(e) =>
                    this.setState({
                      commissionAmount: parseFloat(e.target.value),
                    })
                  }
                />
              </Col>
              <Col md={6}>
                <LInput
                  type={'number'}
                  label={'Referral Amount'}
                  value={this.state.referralAmount}
                  min={0.01}
                  step={0.01}
                  max={2.5}
                  onChange={(e) =>
                    this.setState({
                      referralAmount: parseFloat(e.target.value),
                    })
                  }
                />
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <PromiseButton onClick={this.breakOff} color={'primary'}>
            Break Partner Off
          </PromiseButton>
        </ModalFooter>
      </Modal>
    );
  }
}
