import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {toast} from 'react-toastify';
import {Card, CardBody, CardTitle, Col, Input, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {AppService} from '../../../dataServices/appService';
import {MainStore} from '../../../store/main/store';
import {Utils} from '../../../utils/utils';

type Props = RouteComponentProps<{}> & {};
type UnfoundClaim = {groupCode: string; memberId: string; count: number; newGroupCode: string; newMemberId: string};
type State = {
  unfoundClaims?: UnfoundClaim[];
};

export class ClaimsReconcile extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};
  }

  async componentDidMount() {
    const result = await AppService.adminClaimClient.getUnfoundClaims({page: 0}, {});
    if (result) {
      this.setState({unfoundClaims: result.unfoundClaims.map((a) => ({...a, newGroupCode: '', newMemberId: ''}))});
    }
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {this.state.unfoundClaims ? this.renderBody() : <Loading />}
      </ReactCSSTransitionGroup>
    );
  }

  private renderBody() {
    return (
      <>
        <PageTitle
          heading={`Reconcile Unmatched Claims`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
        />
        <Row>
          <Col md="9">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>Unfound Claims ({Utils.sum(this.state.unfoundClaims, (a) => a.count)})</CardTitle>
                {this.state.unfoundClaims.length === 0 ? (
                  <p>There are no more unfound claims!</p>
                ) : (
                  <Row>
                    <Col md={2}>Original Group Code</Col>
                    <Col md={2}>Original Member Id</Col>
                    <Col md={1}>Number Of Claims</Col>
                    <Col md={2}>Updated Group Code</Col>
                    <Col md={2}>Updated Member Id</Col>
                    <Col md={3} />
                  </Row>
                )}

                {this.state.unfoundClaims.map((c) => (
                  <Row key={c.groupCode + ' ' + c.memberId}>
                    <Col md={2}>{c.groupCode}</Col>
                    <Col md={2}>{c.memberId}</Col>
                    <Col md={1}>{c.count}</Col>
                    <Col md={2}>
                      <Input
                        placeholder={c.groupCode}
                        value={c.newGroupCode}
                        onChange={(e) => {
                          c.newGroupCode = e.target.value;
                          this.setState({unfoundClaims: [...this.state.unfoundClaims]});
                        }}
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        value={c.newMemberId}
                        placeholder={c.memberId}
                        onChange={(e) => {
                          c.newMemberId = e.target.value;
                          this.setState({unfoundClaims: [...this.state.unfoundClaims]});
                        }}
                      />
                    </Col>
                    <Col md={3}>
                      <PromiseButton color="primary" size="sm" onClick={() => this.updateClaim(c)}>
                        Update Claim
                      </PromiseButton>
                      <PromiseButton
                        style={{marginLeft: 10}}
                        color="primary"
                        size="sm"
                        onClick={async () => {
                          c.newGroupCode = '3170';
                          c.newMemberId = '999';
                          await this.updateClaim(c);
                        }}
                      >
                        Set To 3170-999
                      </PromiseButton>
                    </Col>
                  </Row>
                ))}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  private updateClaim = async (c: UnfoundClaim) => {
    if (!c.newMemberId || !c.newGroupCode) {
      toast('Missing group code and member id', {type: 'error'});
      return;
    }
    const result = await AppService.adminClaimClient.resolveClaim(
      {
        oldGroupCode: c.groupCode,
        oldMemberId: c.memberId,
        newMemberId: c.newMemberId,
        newGroupCode: c.newGroupCode,
      },
      {...MainStore.handleError(404)}
    );
    if (result.success) {
      const resultLeft = await AppService.adminClaimClient.getUnfoundClaims({page: 0}, {});
      if (resultLeft) {
        this.setState({
          unfoundClaims: resultLeft.unfoundClaims.map((a) => ({...a, newGroupCode: '', newMemberId: ''})),
        });
      }
      toast('This claim has been updated.', {type: 'success'});
      /*      this.setState({
        unfoundClaims: this.state.unfoundClaims.filter((a) => a.groupCode !== c.groupCode && a.memberId !== c.memberId),
      })*/
    }
  };
}
