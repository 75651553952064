import React, {Fragment} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {AdminRoute} from '../components/adminRoute';
import {AccountPages} from './account';
import {CardPages} from './card';
import {ClaimPages} from './claims';
import {HealthIVR} from './health/healthIVR/healthIVR';
import {HealthIVRs} from './health/healthIVRs/healthIVRs';
import {HealthUser} from './health/healthUser/healthUser';
import {HealthUsers} from './health/healthUsers/healthUsers';
import {HealthVisit} from './health/healthVisit/healthVisit';
import {HealthVisits} from './health/healthVisits/healthVisits';
import {ManagementPages} from './management';
import {PartnerPages} from './partner';
import {ReportPages} from './reports';
import {UserPages} from './user';
import {HealthReports} from './health/healthReports/healthReports';
import {HealthEligibilityMembers} from './health/healthEligibilityMembers/HealthEligibilityMembers';
import {HealthEligibilityMember} from './health/healthEligibilityMember/HealthEligibilityMember';

const AppMain = () => {
  return (
    <Fragment>
      <AdminRoute path="/partner" component={PartnerPages} />
      <AdminRoute path="/account" component={AccountPages} />
      <AdminRoute path="/card" component={CardPages} />
      <AdminRoute path="/claims" component={ClaimPages} />
      <AdminRoute path="/reports" component={ReportPages} />
      <AdminRoute path="/management" component={ManagementPages} />
      <Route path="/user" component={UserPages} />
      <Route exact path="/" render={() => <Redirect to="/partner/search" />} />
      <AdminRoute exact path={'/health/visits'} component={HealthVisits} />
      <AdminRoute exact path={'/health/visit/:id'} component={HealthVisit} />
      <AdminRoute exact path={'/health/users'} component={HealthUsers} />
      <AdminRoute exact path={'/health/user/:id'} component={HealthUser} />
      <AdminRoute exact path={'/health/ivr'} component={HealthIVRs} />
      <AdminRoute exact path={'/health/ivr/:id'} component={HealthIVR} />
      <AdminRoute exact path={'/health/reports'} component={HealthReports} />
      <AdminRoute exact path={'/health/eligibility/members'} component={HealthEligibilityMembers} />
      <AdminRoute exact path={'/health/eligibility/members/:id'} component={HealthEligibilityMember} />
      <ToastContainer position={'bottom-right'} />
    </Fragment>
  );
};

export default AppMain;
