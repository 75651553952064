import React, {FC, useCallback, useState} from 'react';
import {HealthContainer} from '../components/HealthContainer/healthContainer';
import {TablePagination} from '../components/TablePagination/TablePagination';
import {AppService} from '../../../dataServices/appService';
import {BigLoading} from '../components/bigLoading';
import '../components/healthTable.css';
import {TextFormatter} from '../../../utils/textFormatter';
import {HttpAdminEDIMember} from '../../../dataServices/app.generated';
import {format} from 'date-fns';
import {useHistory} from 'react-router';
import {FullDateUtils} from '../../../utils/fullDateUtils';

const EligibilityMemberRow: FC<HttpAdminEDIMember & {onNavUser: (id: string) => void}> = React.memo((props) => (
  <tr onClick={() => props.onNavUser(props.id)}>
    <td style={{paddingLeft: '1.25rem'}}>
      <div>{props.firstName}</div>
    </td>
    <td>
      <div>{props.lastName}</div>
    </td>
    <td>
      <div>{format(FullDateUtils.toDate(props.dob), 'P')}</div>
    </td>
    <td>
      <div>{props.zipCode}</div>
    </td>
    <td>
      <div>{props.employeeType}</div>
    </td>
    <td>
      <div>{format(FullDateUtils.toDate(props.activeDate), 'P')}</div>
    </td>
    <td>
      <div>{props.deactivatedDate ? format(FullDateUtils.toDate(props.deactivatedDate), 'P') : null}</div>
    </td>
    <td style={{paddingRight: '1.25rem'}}>
      <div>{props.uniqueId}</div>
    </td>
  </tr>
));

export const HealthEligibilityMembers = () => {
  const history = useHistory();
  const [currPage, setCurrPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState<HttpAdminEDIMember[]>([]);
  const [totCount, setTotCount] = useState(0);
  const [filters, setFilters] = useState({
    groupCode: '',
    memberId: '',
    dob: '',
    zipCode: '',
    firstName: '',
    lastName: '',
    isActive: false,
  });
  const [missingFields, setMissingFields] = useState({
    groupCode: false,
    memberId: false,
  });

  const onChangeGroupCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({...filters, groupCode: e.target.value});
    if (missingFields.groupCode) {
      setMissingFields((f) => ({...f, groupCode: false}));
    }
  };

  const onChangeMemberId = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({...filters, memberId: e.target.value});
    if (missingFields.memberId) {
      setMissingFields((f) => ({...f, memberId: false}));
    }
  };

  const queryMembers = async (page: number) => {
    if (!filters.groupCode || !filters.memberId) {
      return setMissingFields({groupCode: !filters.groupCode, memberId: !filters.memberId});
    }
    setLoading(true);
    setMembers([]);
    const res = await AppService.adminPartnerHealthClient.getAdminEDIMembers(
      {
        count: '30',
        page: page.toString(),
        groupCode: filters.groupCode,
        memberId: filters.memberId,
        lastName: filters.lastName,
        firstName: filters.firstName,
        zipCode: filters.zipCode,
        isActive: filters.isActive ? 'true' : 'false',
        dob: filters.dob,
      },
      {}
    );
    setLoading(false);

    if (res) {
      setTotCount(res.count);
      setMembers(res.members);
    }
  };

  const navNextPage = () => {
    setCurrPage((p) => p + 1);
    queryMembers(currPage + 1);
  };

  const navPrevPage = () => {
    if (currPage === 0) {
      return;
    }
    setCurrPage((p) => p - 1);
    queryMembers(currPage - 1);
  };

  const onNavUser = useCallback((id: string) => {
    history.push('/health/eligibility/members/' + id);
  }, []);

  return (
    <HealthContainer title={'Clever Health Eligibility Members'}>
      <div className={'HealthTable'}>
        <header className={'HealthTable__headerRows'}>
          <div className={'HealthTable__headerRowTitle'}>
            <span>Required Fields</span>
          </div>
          <div className={'HealthTable__headerRow'}>
            <label>
              Group Code
              <input
                className={`HealthTable__input ${missingFields.groupCode && 'HealthTable__inputError'}`}
                value={filters.groupCode}
                onChange={onChangeGroupCode}
              />
              {missingFields.groupCode && <div className={'HealthTable__inputErrorMsg'}>Required Field</div>}
            </label>
            <label>
              Member ID
              <input
                className={`HealthTable__input ${missingFields.memberId && 'HealthTable__inputError'}`}
                value={filters.memberId}
                onChange={onChangeMemberId}
              />
              {missingFields.memberId && <div className={'HealthTable__inputErrorMsg'}>Required Field</div>}
            </label>
          </div>
          <div className={'HealthTable__headerRowTitle'}>
            <span>Optional Filters</span>
          </div>
          <div className={'HealthTable__headerRow'}>
            <label>
              First Name
              <input
                className={'HealthTable__input'}
                value={filters.firstName}
                onChange={(e) => setFilters({...filters, firstName: e.target.value})}
              />
            </label>
            <label>
              Last Name
              <input
                className={'HealthTable__input'}
                value={filters.lastName}
                onChange={(e) => setFilters({...filters, lastName: e.target.value})}
              />
            </label>
            <label>
              Zip Code
              <input
                className={'HealthTable__input'}
                value={filters.zipCode}
                onChange={(e) =>
                  setFilters({...filters, zipCode: TextFormatter.format({format: '#####', prefix: ''}, e.target.value)})
                }
              />
            </label>
            <label>
              Date of Birth
              <input
                className={'HealthTable__input'}
                value={filters.dob}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    dob: TextFormatter.format({format: '##/##/####', prefix: ''}, e.target.value),
                  });
                }}
                placeholder={'MM/DD/YYYY'}
              />
            </label>
            <label>
              Member Is Active
              <div className={`HealthTable__checkbox ${filters.isActive && 'HealthTable__checkbox--active'}`}>
                <div onClick={() => setFilters({...filters, isActive: !filters.isActive})}>
                  {filters.isActive ? (
                    <svg fill="currentColor" viewBox="0 0 16 16">
                      <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                    </svg>
                  ) : null}
                </div>
              </div>
            </label>
          </div>
          <div className={'HealthTable__headerRow'}>
            <button className={'HealthTable__searchBtn'} onClick={() => queryMembers(currPage)}>
              <svg fill="currentColor" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
              Search
            </button>
          </div>
        </header>
        <div className={'HealthTable__tableContainer'}>
          <table className={'HealthTable__table'}>
            <thead>
              <tr>
                <th style={{paddingLeft: '1.25rem'}}>
                  <div>First Name</div>
                </th>
                <th>
                  <div>Last Name</div>
                </th>
                <th>
                  <div>Date of Birth</div>
                </th>
                <th>
                  <div>Zip Code</div>
                </th>
                <th>
                  <div>Employee Type</div>
                </th>
                <th>
                  <div>Active Date</div>
                </th>
                <th>
                  <div>Deactivated Date</div>
                </th>
                <th style={{paddingRight: '1.25rem'}}>
                  <div>Unique ID</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {members.map((m) => (
                <EligibilityMemberRow key={m.id} {...m} onNavUser={onNavUser} />
              ))}
            </tbody>
          </table>
          {members.length === 0 && !loading && (
            <p className={'HealthTable__empty'}>Sorry, no members could be found.</p>
          )}
          {loading && (
            <div className={'HealthTable__loadingContainer'}>
              <BigLoading loading />
            </div>
          )}
        </div>
      </div>
      <TablePagination
        totCount={totCount}
        currPage={currPage}
        itemsPerPage={30}
        onNavNext={navNextPage}
        onNavPrev={navPrevPage}
      />
    </HealthContainer>
  );
};
