import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {Card, CardBody, Col, Row} from 'reactstrap';

import {AccountSearchComponent} from '../../../components/accountSearchComponent';
import {PageTitle} from '../../../components/layout/pageTitle';

type Props = RouteComponentProps<{query?: string}> & {};
type State = {query: string};

export class AccountSearch extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      query: props.match.params.query || '',
    };
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle heading="Accounts" subheading="" icon="pe-7s-medal icon-gradient bg-tempting-azure" />
        </div>
        <Row>
          <Col md="12">
            <Card className="main-card mb-3">
              <CardBody>
                <AccountSearchComponent
                  query={this.state.query}
                  onSearch={(query) => {
                    if (this.props.history.location.pathname !== `/account/search/${query}`) {
                      this.props.history.push(`/account/search/${query}`);
                    }
                  }}
                  onAccountSelect={(account) => this.props.history.push(`/account/details/${account.id}`)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
    );
  }
}
