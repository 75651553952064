import {useEffect, useState} from 'react';

export function useClockCounter(duration: number) {
  const [clock, setClock] = useState(0);
  useEffect(() => {
    const id = setInterval(() => {
      setClock((c) => c + 1);
    }, duration);
    return () => {
      clearInterval(id);
    };
  }, [duration]);
  return clock;
}
