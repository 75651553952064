import React, {Fragment} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {PartnerAmountsDuePage} from './amountsDue';
import {PartnerCreate} from './create';
import {PartnerDetails} from './details';
import {PartnerMasters} from './masters';
import {PartnerSearch} from './search';

export const PartnerPages = ({match}: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/masters`} component={PartnerMasters} />
          <Route path={`${match.url}/search/:query?`} component={PartnerSearch} />
          <Route path={`${match.url}/create/:parentPartnerId`} component={PartnerCreate} />
          <Route path={`${match.url}/amounts-due`} component={PartnerAmountsDuePage} />
          <Route
            path={`${match.url}/details/:partnerId`}
            render={(props) => <PartnerDetails key={props.match.params.partnerId} {...props} />}
          />
        </div>
      </div>
    </div>
  </Fragment>
);
