import {AccountContactTypes, HttpPartner, PartnerType} from '../dataServices/app.generated';

export class PartnerUtils {
  static getAccountContactTypeName(a: AccountContactTypes) {
    switch (a) {
      case 'nonProfit':
        return 'Nonprofit';
      case 'association':
        return 'Association';
      case 'healthcareProvider':
        return 'Healthcare Provider';
      case 'broker':
        return 'Broker';
      case 'employer':
        return 'Employer';
      case 'other':
        return 'No group for me, but I am here to help people stay Clever by saving on their prescriptions!';
    }
  }

  static states = [
    {value: 'AL', label: 'Alabama'},
    {value: 'AK', label: 'Alaska'},
    {value: 'AZ', label: 'Arizona'},
    {value: 'AR', label: 'Arkansas'},
    {value: 'CA', label: 'California'},
    {value: 'CO', label: 'Colorado'},
    {value: 'CT', label: 'Connecticut'},
    {value: 'DE', label: 'Delaware'},
    {value: 'DC', label: 'District Of Columbia'},
    {value: 'FL', label: 'Florida'},
    {value: 'GA', label: 'Georgia'},
    {value: 'GU', label: 'Guam'},
    {value: 'HI', label: 'Hawaii'},
    {value: 'ID', label: 'Idaho'},
    {value: 'IL', label: 'Illinois'},
    {value: 'IN', label: 'Indiana'},
    {value: 'IA', label: 'Iowa'},
    {value: 'KS', label: 'Kansas'},
    {value: 'KY', label: 'Kentucky'},
    {value: 'LA', label: 'Louisiana'},
    {value: 'ME', label: 'Maine'},
    {value: 'MD', label: 'Maryland'},
    {value: 'MA', label: 'Massachusetts'},
    {value: 'MI', label: 'Michigan'},
    {value: 'MN', label: 'Minnesota'},
    {value: 'MS', label: 'Mississippi'},
    {value: 'MO', label: 'Missouri'},
    {value: 'MT', label: 'Montana'},
    {value: 'NE', label: 'Nebraska'},
    {value: 'NV', label: 'Nevada'},
    {value: 'NH', label: 'New Hampshire'},
    {value: 'NJ', label: 'New Jersey'},
    {value: 'NM', label: 'New Mexico'},
    {value: 'NY', label: 'New York'},
    {value: 'NC', label: 'North Carolina'},
    {value: 'ND', label: 'North Dakota'},
    {value: 'OH', label: 'Ohio'},
    {value: 'OK', label: 'Oklahoma'},
    {value: 'OR', label: 'Oregon'},
    {value: 'PA', label: 'Pennsylvania'},
    {value: 'PR', label: 'Puerto Rico'},
    {value: 'RI', label: 'Rhode Island'},
    {value: 'SC', label: 'South Carolina'},
    {value: 'SD', label: 'South Dakota'},
    {value: 'TN', label: 'Tennessee'},
    {value: 'TX', label: 'Texas'},
    {value: 'UT', label: 'Utah'},
    {value: 'VT', label: 'Vermont'},
    {value: 'VA', label: 'Virginia'},
    {value: 'WA', label: 'Washington'},
    {value: 'WV', label: 'West Virginia'},
    {value: 'WI', label: 'Wisconsin'},
    {value: 'WY', label: 'Wyoming'},
  ];
  static getStates() {
    return this.states;
  }

  static accountContactTypes: {label: string; value: string}[];
  static getAccountContactsTypes() {
    if (this.accountContactTypes) {
      return this.accountContactTypes;
    }
    this.accountContactTypes = ['nonProfit', 'association', 'healthcareProvider', 'broker', 'employer', 'other'].map(
      (a) => ({
        label: PartnerUtils.getAccountContactTypeName(a as AccountContactTypes),
        value: a,
      })
    );
    return this.accountContactTypes;
  }

  static getPartnerTypes(parentPartner: HttpPartner) {
    const types: PartnerType[] = [];
    switch (parentPartner.partnerType) {
      case 'Master':
        types.push('Tier 1');
      // eslint-disable-next-line no-fallthrough
      case 'Tier 1':
        types.push('Tier 2');
      // eslint-disable-next-line no-fallthrough
      case 'Tier 2':
        types.push('Tier 3');
    }
    return types;
  }

  static getAllPartnerTypes() {
    const types: PartnerType[] = [];
    types.push('Tier 1');
    types.push('Tier 2');
    types.push('Tier 3');
    return types;
  }
}
