import moment from 'moment';
import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import Loading from '../../../components/loading';

type Props = RouteComponentProps<any> & {};
type State = {
  gettingDownloadReport: boolean;
  viewAllClaims: boolean;
  month: string;
  noData: boolean;
};

export class DrugReport extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      noData: false,
      gettingDownloadReport: false,
      viewAllClaims: false,
      month: moment().format('YYYY-MM'),
    };
  }

  async componentDidMount() {
    this.getDownloadReport();
  }
  private async getDownloadReport() {
    this.setState({gettingDownloadReport: true});
    /* let result = await AppService.adminClaimClient.getCommissionResult(
      {month: this.state.month},
      {...MainStore.handleError(400)}
    );
    if (result && result.commission) {
      this.setState({
        gettingDownloadReport: false,
        commissionResult: result.commission,
        lastCommissionResult: result.lastMonthCommissions,
      });
    }*/
  }

  render() {
    if (this.state.noData) {
      return (
        <Row>
          <Col md="5">
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icosn lnr-laptop-phone mr-3 text-muted opacity-6" />
                  Claim Data
                </div>
              </CardHeader>
              <CardBody className="pt-2 pb-0">
                <div className="scroll-area-md">
                  <h3>Sorry, there were no claims found for this period</h3>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
    // if (this.state.gettingDownloadReport || !this.state.commissionResult) {
    return <Loading />;
    /*}
    const {commissionResult} = this.state;

    const claims = commissionResult.claims;

    return (
      <>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader className="card-header-tab z-index-6">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
                  Performance
                </div>
                <div className="btn-actions-pane-right">
                  <ButtonGroup size="sm">
                    {this.state.lastCommissionResult && (
                      <Button
                        caret="true"
                        color="dark"
                        className={'btn-shadow'}
                        onClick={() => {
                          this.setState({month: this.state.lastCommissionResult.monthRan}, () => {
                            this.getDownloadReport();
                          });
                        }}
                      >
                        {moment(this.state.lastCommissionResult.monthRan + '-01').format('MMMM')}
                      </Button>
                    )}
                    <Button color="dark" className={'btn-shadow active'}>
                      {moment(this.state.commissionResult.monthRan + '-01').format('MMMM')}
                    </Button>
                    {this.state.commissionResult.monthRan !==
                      moment()
                        .add(-1, 'month')
                        .format('YYYY-MM') && (
                      <Button
                        caret="true"
                        color="dark"
                        className={'btn-shadow'}
                        onClick={() => {
                          this.setState(
                            {
                              month: moment(this.state.commissionResult.monthRan + '-01')
                                .add(1, 'month')
                                .format('YYYY-MM'),
                            },
                            () => {
                              this.getDownloadReport();
                            }
                          );
                        }}
                      >
                        {moment(this.state.commissionResult.monthRan + '-01')
                          .add(1, 'month')
                          .format('MMMM')}
                      </Button>
                    )}
                  </ButtonGroup>
                </div>
              </CardHeader>
              <Row className="no-gutters">
                <Col sm="6" md="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Claim Revenue</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.commissionResult.totalAmountDue}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix="$"
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                      {this.state.lastCommissionResult && (
                        <div className="widget-description opacity-8 text-focus">
                          <div
                            className={
                              'd-inline pr-1 ' +
                              (this.state.commissionResult.totalAmountDue >
                              this.state.lastCommissionResult.totalAmountDue
                                ? 'text-success'
                                : 'text-danger')
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.commissionResult.totalAmountDue >
                                this.state.lastCommissionResult.totalAmountDue
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                            <span className="pl-1">
                              {Math.abs(
                                Math.round(
                                  (1 -
                                    this.state.commissionResult.totalAmountDue /
                                      this.state.lastCommissionResult.totalAmountDue) *
                                    100
                                )
                              )}
                              %
                            </span>
                          </div>
                          from the previous month
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-9 bg-danger" />
                      <i className="lnr-graduation-hat text-white" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Number Of Claims</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.commissionResult.claims.length}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={3}
                        />
                      </div>
                      {this.state.lastCommissionResult && (
                        <div className="widget-description opacity-8 text-focus">
                          <div
                            className={
                              'd-inline pr-1 ' +
                              (this.state.commissionResult.claims.length > this.state.lastCommissionResult.claims.length
                                ? 'text-success'
                                : 'text-danger')
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.commissionResult.claims.length >
                                this.state.lastCommissionResult.claims.length
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                            <span className="pl-1">
                              {Math.abs(
                                Math.round(
                                  (1 -
                                    this.state.commissionResult.claims.length /
                                      this.state.lastCommissionResult.claims.length) *
                                    100
                                )
                              )}
                              %
                            </span>
                          </div>
                          from the previous month
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="12" md="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-9 bg-success" />
                      <i className="lnr-apartment text-white" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Amount Saved</div>
                      <div className="widget-numbers text-success">
                        <CountUp
                          start={0}
                          end={this.state.commissionResult.totalAmountSaved}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix="$"
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                      {this.state.lastCommissionResult && (
                        <div className="widget-description opacity-8 text-focus">
                          <div
                            className={
                              'd-inline pr-1 ' +
                              (-this.state.commissionResult.totalAmountSaved >
                              -this.state.lastCommissionResult.totalAmountSaved
                                ? 'text-success'
                                : 'text-danger')
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                -this.state.commissionResult.totalAmountSaved >
                                -this.state.lastCommissionResult.totalAmountSaved
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                            <span className="pl-1">
                              {Math.abs(
                                Math.round(
                                  (1 -
                                    this.state.commissionResult.totalAmountSaved /
                                      this.state.lastCommissionResult.totalAmountSaved) *
                                    100
                                )
                              )}
                              %
                            </span>
                          </div>
                          from the previous month
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              {/!*
              <CardFooter className="text-center d-block p-3">
                <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                  <span className="mr-2 opacity-7">
                    <Ionicon color="#ffffff" icon="ios-analytics-outline" beat={true} />
                  </span>
                  <span className="mr-1">View Complete Report</span>
                </Button>
              </CardFooter>
*!/}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                  Top Performing Cards
                </div>
              </CardHeader>
              <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                    <ResponsiveContainer height="100%">
                      <AreaChart
                        data={daysData}
                        margin={{
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <Tooltip />
                        <defs>
                          <linearGradient id="colorRev" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="var(--primary)" stopOpacity={0.7} />
                            <stop offset="90%" stopColor="var(--primary)" stopOpacity={0} />
                          </linearGradient>
                          <linearGradient id="colorSavings" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="var(--secondary)" stopOpacity={0.7} />
                            <stop offset="90%" stopColor="var(--secondary)" stopOpacity={0} />
                          </linearGradient>
                          <linearGradient id="colorClaims" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="var(--secondary)" stopOpacity={0.7} />
                            <stop offset="90%" stopColor="var(--secondary)" stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="name" />
                        <Area
                          type="monotoneX"
                          dataKey="Claims"
                          stroke="var(--primary)"
                          strokeWidth="3"
                          fillOpacity={1}
                          fill="url(#colorClaims)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <CardBody className="pt-2 pb-0">
                <div className="scroll-area-md shadow-overflow">
                  <PerfectScrollbar>
                    <Table hover striped className="mb-0">
                      <thead>
                        <tr>
                          <th>Group Code</th>
                          <th>Member Id</th>
                          <th>Number Of Claims</th>
                          <th>Amount Earned</th>
                          <th>Amount Saved</th>
                        </tr>
                      </thead>
                      <tbody>
                        {claims.slice(0, this.state.viewAllClaims ? claims.length : 15).map((item, index) => (
                          <tr key={item.groupCode + '-' + item.memberId}>
                            <td>{item.groupCode}</td>
                            <td>{item.memberId}</td>
                            <td>{item.count}</td>
                            <td>{FormatUtils.formatMoney(item.earned)}</td>
                            <td>{FormatUtils.formatMoney(item.saved)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </PerfectScrollbar>
                </div>
              </CardBody>
              {this.state.commissionResult.claims.length > 15 && (
                <CardFooter className="d-block text-center rm-border">
                  <Button color="primary" onClick={() => this.setState({viewAllClaims: true})}>
                    View all Claims
                    <span className="text-white pl-2 opacity-3">
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </Button>
                </CardFooter>
              )}
            </Card>
          </Col>
          <Col md={'6'}>
            <Card className="main-card mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-briefcase mr-3 text-muted opacity-6" />
                  Claims In {moment(this.state.month + '-01').format('MMMM')}
                </div>
              </CardHeader>
              <div className="widget-chart widget-chart2 text-left p-0">
                <div className="widget-chat-wrapper-outer">
                  <div className="widget-chart-wrapper widget-chart-wrapper-lg opacity-10 m-0">
                    <ResponsiveContainer height="100%">
                      <AreaChart
                        data={daysData}
                        margin={{
                          top: 15,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      >
                        <Tooltip />
                        <defs>
                          <linearGradient id="colorRev" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="var(--primary)" stopOpacity={0.7} />
                            <stop offset="90%" stopColor="var(--primary)" stopOpacity={0} />
                          </linearGradient>
                          <linearGradient id="colorSavings" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="var(--secondary)" stopOpacity={0.7} />
                            <stop offset="90%" stopColor="var(--secondary)" stopOpacity={0} />
                          </linearGradient>
                          <linearGradient id="colorClaims" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="10%" stopColor="var(--secondary)" stopOpacity={0.7} />
                            <stop offset="90%" stopColor="var(--secondary)" stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey="name" />

                        <Area
                          type="monotoneX"
                          dataKey="$ Revenue"
                          stroke="var(--primary)"
                          strokeWidth="3"
                          fillOpacity={1}
                          fill="url(#colorRev)"
                        />
                        <Area
                          type="monotoneX"
                          dataKey="$ Savings"
                          stroke="var(--secondary)"
                          strokeWidth="3"
                          fillOpacity={1}
                          fill="url(#colorRev)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <CardBody>
                <Bar
                  data={{
                    labels: Utils.range(moment(this.state.month + '-01').daysInMonth()).map(a =>
                      moment(this.state.month + '-01')
                        .set('date', a + 1)
                        .format('Do')
                    ),
                    datasets: [dataset],
                  }}
                  width={100}
                  height={50}
                  options={{
                    tooltips: {
                      mode: 'index',
                      intersect: false,
                    },
                    legend: {
                      display: false,
                    },
                    responsive: true,
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                        },
                      ],
                    },
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );*/
  }
}
