import {addMonths, endOfMonth, format, startOfMonth, subMonths} from 'date-fns';
import moment from 'moment';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {ReactComponent as CHLogo} from '../../../assets/CHLogo.svg';
import {AppHeader} from '../../../components/layout/appHeader';
import {AppSidebar} from '../../../components/layout/appSidebar';
import {AppService} from '../../../dataServices/appService';
import {BigLoading} from '../components/bigLoading';
import {Datepicker} from '../components/DatePicker/DatePicker';
import {OptionSelect} from '../components/OptionSelect/OptionSelect';
import {getDateRange} from '../utils/healthDateUtils';
import {HealthReportChart} from './healthReportChart';
import {
  IVRCompletedIcon,
  IVREndedIcon,
  IVRIncomingIcon,
  IVRRedirectedIcon,
  PrescriptionsSentIcon,
  UsersCreatedIcon,
  VisitsAsyncIcon,
  VisitsIcon,
  VisitsPhoneIcon,
  VisitsVideoIcon,
} from './healthReportIcons';
import './healthReports.css';
import {HealthReportStat} from './healthReportStat';

const presetDateOptions = [
  {id: 'today', label: 'Today (PST)'},
  {id: 'yesterday', label: 'Yesterday (PST)'},
  {id: 'week', label: 'This Week'},
  {id: 'lastweek', label: 'Last Week'},
  {id: 'month', label: 'This Month'},
  {id: 'lastmonth', label: 'Last Month'},
  {id: 'quarter', label: 'This Quarter'},
  {id: '', label: 'Custom Range'},
];

const initialReportData = {
  completedCalls: 0,
  redirectedCalls: 0,
  usersCreated: 0,
  visitsStarted: 0,
  visitsCompleted: 0,
  prescriptionsSent: 0,
  visitsAsync: 0,
  visitsPhone: 0,
  visitsVideo: 0,
  incomingCalls: 0,
  hangupCalls: 0,
};

const initialChartData: Record<string, number[]> = {
  completedCalls: [],
  redirectedCalls: [],
  usersCreated: [],
  visitsStarted: [],
  visitsCompleted: [],
  prescriptionsSent: [],
  visitsTimeCompleted: [],
};

export const HealthReports = () => {
  const [dateRange, setDateRange] = useState([startOfMonth(new Date()), new Date()]);
  const [presetDate, setPresetDate] = useState('month');
  const [reportData, setReportData] = useState({
    ...initialReportData,
  });
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    ...initialChartData,
  });
  const [chartMonth, setChartMonth] = useState(moment().format('YYYY-MM'));
  const monthFormatted = useMemo(() => {
    return moment(chartMonth + '-01').format('MMMM');
  }, [chartMonth]);
  const [chartSelectDate, setChartSelectDate] = useState('0');
  const [chartLoading, setChartLoading] = useState(false);

  const chartDateOptions = useMemo(() => {
    const currDate = new Date();
    return [
      {id: '0', label: format(currDate, 'MMMM yyyy')},
      {id: '1', label: format(subMonths(currDate, 1), 'MMMM yyyy')},
      {id: '2', label: format(subMonths(currDate, 2), 'MMMM yyyy')},
      {id: '3', label: format(subMonths(currDate, 3), 'MMMM yyyy')},
      {id: '4', label: format(subMonths(currDate, 4), 'MMMM yyyy')},
      {id: '5', label: format(subMonths(currDate, 5), 'MMMM yyyy')},
      {id: '6', label: format(subMonths(currDate, 6), 'MMMM yyyy')},
    ];
  }, []);

  const onSetChartSelectDate = (id: string) => {
    setChartSelectDate(id);
    const startDate = subMonths(new Date(), parseInt(id));
    const newChartDate = moment(startDate).format('YYYY-MM');
    setChartMonth(newChartDate);
  };

  const resetData = () => {
    setReportData({
      ...initialReportData,
    });
  };

  const fetchChartData = useCallback(async () => {
    const startDate = String(addMonths(startOfMonth(new Date(chartMonth)), 1));
    const endDate = String(addMonths(endOfMonth(new Date(chartMonth)), 1));
    const [
      completedCalls,
      redirectedCalls,
      usersCreated,
      visitsStarted,
      visitsCompleted,
      prescriptionsSent,
      visitsTimeCompleted,
    ] = await Promise.all([
      AppService.adminPartnerHealthClient.getHealthReportsCompletedCalls({month: chartMonth, startDate, endDate}, {}),
      AppService.adminPartnerHealthClient.getHealthReportsRedirectedCalls({month: chartMonth, startDate, endDate}, {}),
      AppService.adminPartnerHealthClient.getHealthReportsUsersCreated({month: chartMonth, startDate, endDate}, {}),
      AppService.adminPartnerHealthClient.getHealthReportsVisitsStarted({month: chartMonth, startDate, endDate}, {}),
      AppService.adminPartnerHealthClient.getHealthReportsVisitsCompleted({month: chartMonth, startDate, endDate}, {}),
      AppService.adminPartnerHealthClient.getHealthReportsPrescriptionsSent(
        {month: chartMonth, startDate, endDate},
        {}
      ),
      AppService.adminPartnerHealthClient.getHealthReportsVisitsTimeCompleted(
        {month: chartMonth, startDate, endDate},
        {}
      ),
    ]);

    return {
      completedCalls,
      redirectedCalls,
      usersCreated,
      visitsStarted,
      visitsCompleted,
      prescriptionsSent,
      visitsTimeCompleted,
    };
  }, [chartMonth]);

  useEffect(() => {
    setChartLoading(true);
    fetchChartData().then((res) => {
      setChartLoading(false);
      setChartData({
        completedCalls: res.completedCalls?.data || [],
        redirectedCalls: res.redirectedCalls?.data || [],
        usersCreated: res.usersCreated?.data || [],
        visitsStarted: res.visitsStarted?.data || [],
        visitsCompleted: res.visitsCompleted?.data || [],
        prescriptionsSent: res.prescriptionsSent?.data || [],
        visitsTimeCompleted: res.visitsTimeCompleted?.data || [],
      });
    });
  }, [chartMonth]);

  useEffect(() => {
    setLoading(true);
    AppService.adminPartnerHealthClient
      .getHealthReports(
        {
          startDate: String(dateRange[0]),
          endDate: String(dateRange[1]),
        },
        {'404': resetData}
      )
      .then((res) => {
        setLoading(false);
        if (!res) {
          return;
        }
        setReportData({...res});
      });
  }, [dateRange]);

  const onChangeDate = (selectedDates: Date[]) => {
    setDateRange([selectedDates[0], selectedDates[1] || null]);
    setPresetDate('');
  };

  const onSetPresetDate = (id: string) => {
    setPresetDate(id);
    const newDateRange = getDateRange(id);
    if (newDateRange) {
      setDateRange(newDateRange);
    }
  };

  return (
    <>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="app-main__inner">
            <div className={'HealthReports'}>
              <div className={'HealthReports__card'}>
                <div className={'HealthReports__statsHeader'}>
                  <div className={'HealthReports__statsLogo'}>
                    <CHLogo />
                  </div>
                  <div className={'HealthReports__statsTitle'}>Clever Health Reports</div>
                  <OptionSelect
                    selectedFilter={presetDate}
                    setSelectedFilter={onSetPresetDate}
                    options={presetDateOptions}
                  />
                  <Datepicker value={dateRange} onChange={onChangeDate} />
                </div>
                {loading ? (
                  <div className={'HealthReports__loading'}>
                    <BigLoading loading />
                  </div>
                ) : (
                  <div className={'HealthReports__stats'}>
                    <HealthReportStat
                      icon={IVRIncomingIcon}
                      title={'Phone - Total Incoming Calls'}
                      value={reportData.incomingCalls}
                    />
                    <HealthReportStat
                      icon={IVRCompletedIcon}
                      title={'Phone - Total Completed Calls'}
                      value={reportData.completedCalls}
                    />
                    <HealthReportStat
                      icon={IVRRedirectedIcon}
                      title={'Phone - Total Redirected Calls'}
                      value={reportData.redirectedCalls}
                    />
                    <HealthReportStat icon={UsersCreatedIcon} title={'Users Created'} value={reportData.usersCreated} />
                    {/*<HealthReportStat icon={VisitsIcon} title={'Visits Started'} value={reportData.visitsStarted} />*/}
                    <HealthReportStat
                      icon={VisitsIcon}
                      title={'App Visits Completed'}
                      value={reportData.visitsCompleted}
                    />
                    <HealthReportStat
                      icon={VisitsAsyncIcon}
                      title={'App Visits - Async'}
                      value={reportData.visitsAsync}
                    />
                    <HealthReportStat
                      icon={VisitsPhoneIcon}
                      title={'App Visits - Phone'}
                      value={reportData.visitsPhone}
                    />
                    <HealthReportStat
                      icon={VisitsVideoIcon}
                      title={'App Visits - Video'}
                      value={reportData.visitsVideo}
                    />
                    <HealthReportStat
                      icon={PrescriptionsSentIcon}
                      title={'Total Prescriptions Saved To Cabinet'}
                      value={reportData.prescriptionsSent}
                    />
                  </div>
                )}
              </div>
              <div className={'HealthReports__chartsHeader'}>
                <div className={'HealthReports__statsLogo'}>
                  <CHLogo />
                </div>
                <h3>Clever Charts</h3>
                <OptionSelect
                  selectedFilter={chartSelectDate}
                  setSelectedFilter={onSetChartSelectDate}
                  options={chartDateOptions}
                />
                {chartLoading && <div className={'HealthReports__chartLoader'} />}
              </div>
              <div className={'HealthReports__charts'}>
                <div className={'HealthReports__card'}>
                  <div className={'HealthReports__chartTitle'}>
                    {IVRCompletedIcon}
                    IVR Completed Calls - {monthFormatted}
                  </div>
                  <HealthReportChart month={chartMonth} data={chartData.completedCalls} label={'Completed Calls'} />
                </div>
                <div className={'HealthReports__card'}>
                  <div className={'HealthReports__chartTitle'}>
                    {IVRRedirectedIcon}
                    IVR Redirected Calls - {monthFormatted}
                  </div>
                  <HealthReportChart month={chartMonth} data={chartData.redirectedCalls} label={'Redirected Calls'} />
                </div>
                <div className={'HealthReports__card'}>
                  <div className={'HealthReports__chartTitle'}>
                    {UsersCreatedIcon}
                    Users Created - {monthFormatted}
                  </div>
                  <HealthReportChart month={chartMonth} data={chartData.usersCreated} label={'Users Created'} />
                </div>
                <div className={'HealthReports__card'}>
                  <div className={'HealthReports__chartTitle'}>
                    {VisitsIcon}
                    Visits Started - {monthFormatted}
                  </div>
                  <HealthReportChart month={chartMonth} data={chartData.visitsStarted} label={'Visits Started'} />
                </div>
                <div className={'HealthReports__card'}>
                  <div className={'HealthReports__chartTitle'}>
                    {VisitsIcon}
                    Visits Completed - {monthFormatted}
                  </div>
                  <HealthReportChart month={chartMonth} data={chartData.visitsCompleted} label={'Visits Completed'} />
                </div>
                <div className={'HealthReports__card'}>
                  <div className={'HealthReports__chartTitle'}>
                    {PrescriptionsSentIcon}
                    Prescriptions Saved to Cabinet - {monthFormatted}
                  </div>
                  <HealthReportChart
                    month={chartMonth}
                    data={chartData.prescriptionsSent}
                    label={'Prescriptions Sent'}
                  />
                </div>
                <div className={'HealthReports__card'}>
                  <div className={'HealthReports__chartTitle'}>
                    {VisitsIcon}
                    Visits Completed by Hour of Day - {monthFormatted}
                  </div>
                  <HealthReportChart
                    labelByHour
                    month={chartMonth}
                    data={chartData.visitsTimeCompleted}
                    label={'Visits Completed'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
