import React from 'react';
import {ReactComponent as CHLogo} from '../../../../assets/CHLogo.svg';
import {AppHeader} from '../../../../components/layout/appHeader';
import {AppSidebar} from '../../../../components/layout/appSidebar';

export const HealthContainer = ({children, title}: {children: React.ReactNode; title: string}) => (
  <>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="app-page-title">
            <div className="page-title-wrapper">
              <div className={'page-title-heading'}>
                <div className={'page-title-icon'}>
                  <CHLogo />
                </div>
                <div>{title}</div>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
    </div>
  </>
);
