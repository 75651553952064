import {faAngleDown, faAngleUp} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, {Component} from 'react';
import {Bar} from 'react-chartjs-2';
import CountUp from 'react-countup';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {RouteComponentProps} from 'react-router';
import Select from 'react-select';
import {Card, CardBody, CardHeader, Col, Row, Table} from 'reactstrap';
import Loading from '../../../../components/loading';
import {HttpCommissionResult, HttpPartnerExtended} from '../../../../dataServices/app.generated';
import {AppService} from '../../../../dataServices/appService';
import {FormatUtils} from '../../../../utils/formatUtils';
import {Utils} from '../../../../utils/utils';

type Props = RouteComponentProps<any> & {partner?: HttpPartnerExtended};
type State = {
  commissionResult?: HttpCommissionResult;
  lastCommissionResult?: HttpCommissionResult;
  gettingDownloadReport: boolean;
  month: string;
  months?: string[];
  noData: boolean;
};

export class ClaimsReport extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      noData: false,
      gettingDownloadReport: false,
      commissionResult: null,
      month: moment().format('YYYY-MM'),
      months: [],
    };
  }

  async componentDidMount() {
    await this.startDownloadReport();
    await this.getDownloadReport();
  }

  private async startDownloadReport() {
    this.setState({gettingDownloadReport: true});

    const monthsResult = await AppService.partnerClient.getCommissionResultMonths(
      {partnerId: this.props.partner.id},
      {}
    );

    if (!monthsResult || monthsResult.months.length === 0) {
      this.setState({
        gettingDownloadReport: false,
        noData: true,
      });
      return;
    }

    await new Promise((res) =>
      this.setState({months: monthsResult.months, month: monthsResult.months[monthsResult.months.length - 1]}, res)
    );
  }

  private async getDownloadReport() {
    this.setState({gettingDownloadReport: true});

    const result = await AppService.partnerClient.getCommissionResult(
      {month: this.state.month, partnerId: this.props.partner.id},
      {}
    );
    if (result && result.commissionResult) {
      this.setState({
        gettingDownloadReport: false,
        commissionResult: result.commissionResult,
        lastCommissionResult: result.lastCommissionResult,
      });
    } else {
      this.setState({
        gettingDownloadReport: false,
        noData: true,
      });
    }
  }

  render() {
    if (this.state.noData) {
      return (
        <Row>
          <Col md="5">
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                  Claim Data
                </div>
                <div className="btn-actions-pane-right" style={{width: 200}}>
                  <Select
                    isSearchable={false}
                    placeholder={'Select Month'}
                    value={this.state.month}
                    getOptionLabel={(a) => moment(a + '-01').format('MMMM YYYY')}
                    options={this.state.months.reverse()}
                    onChange={(month: any) => this.setState({month}, this.getDownloadReport)}
                  />
                </div>
              </CardHeader>
              <CardBody className="pt-2 pb-0">
                <div className="scroll-area-md">
                  <h3>Sorry, there were no claims found for this period</h3>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      );
    }
    if (this.state.gettingDownloadReport || !this.state.commissionResult) {
      return <Loading />;
    }
    const {commissionResult} = this.state;

    const claims = commissionResult.groupedClaims
      .map((item) => ({
        groupCode: item.groupCode,
        memberId: item.memberId,
        earned: item.totalAmountDue,
        compensable: item.compensable,
        totalAmountSaved: item.totalAmountSaved,
      }))
      .sort((a, b) => b.earned - a.earned);

    const byDay = commissionResult.byDateClaims;
    const daysData: {name: string; Claims: number; '$ Revenue': string; '$ Savings': string}[] = [];
    for (const ind of Utils.range(moment(this.state.month + '-01').daysInMonth())) {
      const key = moment(this.state.month + '-01')
        .set('date', ind + 1)
        .format('YYYY-MM-DD');
      const day = commissionResult.byDateClaims.find((a) => a.date === key);
      if (!day) {
        daysData.push({
          name: moment(this.state.month + '-01')
            .set('date', ind + 1)
            .format('Do'),
          Claims: 0,
          '$ Savings': '0',
          '$ Revenue': '0',
        });
      } else {
        daysData.push({
          name: moment(this.state.month + '-01')
            .set('date', ind + 1)
            .format('Do'),
          Claims: day.compensable,
          '$ Savings': (-day.totalAmountSaved).toFixed(2),
          '$ Revenue': day.totalAmountDue.toFixed(2),
        });
      }
    }

    const dataset = {
      label: 'Claims',
      backgroundColor: '#ffa100',
      borderColor: '#ac6d00',
      borderWidth: 1,
      borderCapStyle: 'round' as const,
      data: daysData.map((a) => a.Claims),
    };

    /**/

    return (
      <>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader className="card-header-tab z-index-6">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
                  Performance {moment(this.state.month + '-01').format('MMMM YYYY')}
                </div>
                <div className="btn-actions-pane-right" style={{width: 200, zIndex: 100000000}}>
                  <Select
                    menuPosition={'absolute'}
                    value={this.state.month}
                    getOptionLabel={(a) => moment(a + '-01').format('MMMM YYYY')}
                    options={this.state.months.reverse()}
                    isSearchable={false}
                    placeholder={'Select Month'}
                    onChange={(month: any) => this.setState({month}, this.getDownloadReport)}
                  />
                </div>
              </CardHeader>
              <Row className="no-gutters">
                <Col sm="6" md="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-10 bg-warning" />
                      <i className="lnr-laptop-phone text-dark opacity-8" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Claim Revenue</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.commissionResult.totalAmountDue}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix="$"
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                      {this.state.lastCommissionResult && (
                        <div className="widget-description opacity-8 text-focus">
                          <div
                            className={
                              'd-inline pr-1 ' +
                              (this.state.commissionResult.totalAmountDue >
                              this.state.lastCommissionResult.totalAmountDue
                                ? 'text-success'
                                : 'text-danger')
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.commissionResult.totalAmountDue >
                                this.state.lastCommissionResult.totalAmountDue
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                            <span className="pl-1">
                              {Math.abs(
                                Math.round(
                                  (1 -
                                    this.state.commissionResult.totalAmountDue /
                                      this.state.lastCommissionResult.totalAmountDue) *
                                    100
                                )
                              )}
                              %
                            </span>
                          </div>
                          from the previous month
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="6" md="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-9 bg-danger" />
                      <i className="lnr-graduation-hat text-white" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Number Of Claims</div>
                      <div className="widget-numbers">
                        <CountUp
                          start={0}
                          end={this.state.commissionResult.numberOfClaims}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix=""
                          useEasing={true}
                          suffix=""
                          duration={3}
                        />
                      </div>
                      {this.state.lastCommissionResult && (
                        <div className="widget-description opacity-8 text-focus">
                          <div
                            className={
                              'd-inline pr-1 ' +
                              (this.state.commissionResult.numberOfClaims >
                              this.state.lastCommissionResult.numberOfClaims
                                ? 'text-success'
                                : 'text-danger')
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                this.state.commissionResult.numberOfClaims >
                                this.state.lastCommissionResult.numberOfClaims
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                            <span className="pl-1">
                              {Math.abs(
                                Math.round(
                                  (1 -
                                    this.state.commissionResult.numberOfClaims /
                                      this.state.lastCommissionResult.numberOfClaims) *
                                    100
                                )
                              )}
                              %
                            </span>
                          </div>
                          from the previous month
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="divider m-0 d-md-none d-sm-block" />
                </Col>
                <Col sm="12" md="4" xl="4">
                  <div className="card no-shadow rm-border bg-transparent widget-chart text-left">
                    <div className="icon-wrapper rounded-circle">
                      <div className="icon-wrapper-bg opacity-9 bg-success" />
                      <i className="lnr-apartment text-white" />
                    </div>
                    <div className="widget-chart-content">
                      <div className="widget-subheading">Amount Saved</div>
                      <div className="widget-numbers text-success">
                        <CountUp
                          start={0}
                          end={this.state.commissionResult.totalAmountSaved}
                          separator=","
                          decimals={0}
                          decimal="."
                          prefix="$"
                          useEasing={true}
                          suffix=""
                          duration={4}
                        />
                      </div>
                      {this.state.lastCommissionResult && (
                        <div className="widget-description opacity-8 text-focus">
                          <div
                            className={
                              'd-inline pr-1 ' +
                              (-this.state.commissionResult.totalAmountSaved >
                              -this.state.lastCommissionResult.totalAmountSaved
                                ? 'text-danger'
                                : 'text-success')
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                -this.state.commissionResult.totalAmountSaved >
                                -this.state.lastCommissionResult.totalAmountSaved
                                  ? faAngleDown
                                  : faAngleUp
                              }
                            />
                            <span className="pl-1">
                              {Math.abs(
                                Math.round(
                                  (1 -
                                    this.state.commissionResult.totalAmountSaved /
                                      this.state.lastCommissionResult.totalAmountSaved) *
                                    100
                                )
                              )}
                              %
                            </span>
                          </div>
                          from the previous month
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              {/*
              <CardFooter className="text-center d-block p-3">
                <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                  <span className="mr-2 opacity-7">
                    <Ionicon color="#ffffff" icon="ios-analytics-outline" beat={true} />
                  </span>
                  <span className="mr-1">View Complete Report</span>
                </Button>
              </CardFooter>
*/}
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <Card className="mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-laptop-phone mr-3 text-muted opacity-6" />
                  Top Performing Cards
                </div>
              </CardHeader>
              <CardBody className="pt-2 pb-0">
                <div className="scroll-area-md shadow-overflow">
                  <PerfectScrollbar>
                    <Table hover striped className="mb-0">
                      <thead>
                        <tr>
                          <th>Group Code</th>
                          <th>Member Id</th>
                          <th>Number Of Claims</th>
                          <th>Amount Earned</th>
                          <th>Amount Saved</th>
                        </tr>
                      </thead>
                      <tbody>
                        {claims.map((item, index) => (
                          <tr key={item.groupCode + ' ' + item.memberId}>
                            <td>{item.groupCode}</td>
                            <td>{item.memberId}</td>
                            <td>{item.compensable}</td>
                            <td>{FormatUtils.formatMoney(item.earned)}</td>
                            <td>{FormatUtils.formatMoney(item.totalAmountSaved)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </PerfectScrollbar>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={'6'}>
            <Card className="main-card mb-3">
              <CardHeader className="card-header-tab">
                <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                  <i className="header-icon lnr-briefcase mr-3 text-muted opacity-6" />
                  Claims In {moment(this.state.month + '-01').format('MMMM')}
                </div>
              </CardHeader>
              <CardBody>
                <Bar
                  data={{
                    labels: Utils.range(moment(this.state.month + '-01').daysInMonth()).map((a) => a + 1 + ''),
                    datasets: [dataset],
                  }}
                  width={100}
                  height={50}
                  options={{
                    tooltips: {
                      mode: 'index',
                      intersect: false,
                    },
                    legend: {
                      display: false,
                    },
                    responsive: true,
                    scales: {
                      yAxes: [
                        {
                          stacked: true,
                        },
                      ],
                    },
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
