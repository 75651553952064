import React from 'react';
import {Button, ButtonProps} from 'reactstrap';
import {useAsyncCallback} from './asyncHook';

type Props = {onClick: () => Promise<any>} & ButtonProps;

export function PromiseButton(props: Props) {
  const {onClick, disabled, children, ...rest} = props;
  const {loading, execute} = useAsyncCallback(onClick);

  return (
    <Button {...rest} onClick={execute} disabled={disabled || loading}>
      {loading ? (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        children
      )}
    </Button>
  );
}
