import CountUp from 'react-countup';
import React from 'react';
import './healthReports.css';

export const HealthReportStat = ({icon, title, value}: {icon: React.ReactNode; title: string; value: number}) => {
  return (
    <div className={'HealthReports__stat'}>
      <div className={'HealthReports__statIcon'}>{icon}</div>
      <div>
        <p>{title}</p>
        <h2>
          <CountUp
            start={0}
            end={value}
            separator=","
            decimals={0}
            decimal="."
            prefix=""
            useEasing={true}
            suffix=""
            duration={4}
          />
        </h2>
      </div>
    </div>
  );
};
