import {action, observable} from 'mobx';

export class ThemeStore {
  @observable backgroundColor: string = 'bg-dark sidebar-text-light';
  @observable headerBackgroundColor: string = 'bg-dark header-text-light';
  @observable enableMobileMenuSmall: boolean = false;
  @observable enableMobileMenu: boolean = false;
  @observable colorScheme: string = 'white';
  @observable enableBackgroundImage: boolean = false;
  @observable enableClosedSidebar: boolean = false;
  @observable enableFixedHeader: boolean = true;
  @observable enableHeaderShadow: boolean = true;
  @observable enableSidebarShadow: boolean = true;
  @observable enableFixedFooter: boolean = true;
  @observable enableFixedSidebar: boolean = true;
  @observable enablePageTitleIcon: boolean = true;
  @observable enablePageTitleSubheading: boolean = true;
  @observable enablePageTabsAlt: boolean = true;

  @action setEnableClosedSidebar(enableClosedSidebar: boolean) {
    this.enableClosedSidebar = enableClosedSidebar;
  }
  @action setEnableMobileMenu(enableMobileMenu: boolean) {
    this.enableMobileMenu = enableMobileMenu;
  }
  @action setEnableMobileMenuSmall(enableMobileMenuSmall: boolean) {
    this.enableMobileMenuSmall = enableMobileMenuSmall;
  }
}

export const ThemeStoreName = 'themeStore';
export const themeStore = new ThemeStore();
export type ThemeStoreProps = {[ThemeStoreName]?: ThemeStore};
export const themeStoreDefaultProps = {[ThemeStoreName]: (null as unknown) as ThemeStore};
