import React, {FC} from 'react';
import './TablePagination.css';

interface TablePaginationProps {
  totCount: number;
  onNavPrev: () => void;
  onNavNext: () => void;
  currPage: number;
  itemsPerPage: number;
}

export const TablePagination: FC<TablePaginationProps> = (props) => {
  return (
    <div className={'TablePagination'}>
      <div className={'TablePagination__info'}>
        Showing <span>{props.currPage * props.itemsPerPage + 1}</span> to{' '}
        <span>{(props.currPage + 1) * props.itemsPerPage}</span> of <span>{props.totCount}</span> results
      </div>
      <div className={'TablePagination__btns'}>
        <button
          className={'TablePagination__btn TablePagination__btn--left'}
          disabled={props.currPage === 0}
          onClick={props.onNavPrev}
        >
          <svg fill="currentColor" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
            />
          </svg>
          Previous
        </button>
        <button className={'TablePagination__btn TablePagination__btn--right'} onClick={props.onNavNext}>
          Next
          <svg fill="currentColor" viewBox="0 0 16 16">
            <path
              fillRule="evenodd"
              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
