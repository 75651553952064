import cx from 'classnames';
import {inject, observer} from 'mobx-react';
import React from 'react';
import {themeStoreDefaultProps, ThemeStoreName, ThemeStoreProps} from '../../../store/theme/store';

type Props = {heading: string; icon: string; subheading: string; actions?: React.ReactNode} & ThemeStoreProps;
type State = {};

@inject(ThemeStoreName)
@observer
export class PageTitle extends React.Component<Props, State> {
  static defaultProps = themeStoreDefaultProps;
  render() {
    const {heading, icon, subheading, actions} = this.props;
    const {enablePageTitleIcon, enablePageTitleSubheading} = this.props.themeStore;

    return (
      <div className="app-page-title">
        <div className="page-title-wrapper">
          <div className="page-title-heading">
            <div className={cx('page-title-icon', {'d-none': !enablePageTitleIcon})}>
              <i className={icon} />
            </div>
            <div>
              {heading}
              <div className={cx('page-title-subheading', {'d-none': !enablePageTitleSubheading})}>{subheading}</div>
            </div>
          </div>
          <div className="page-title-actions">{actions}</div>
        </div>
      </div>
    );
  }
}
