export class Config {
  static env: 'local' | 'dev' | 'prod' = process.env.REACT_APP_ENV as 'local' | 'dev' | 'prod';
  static get host() {
    const localHost = 'http://127.0.0.1:3003';
    const devHost = 'https://dev-api.cleverrx.com';
    const prodHost = 'https://api.cleverrx.com';
    switch (this.env) {
      case 'local':
        return localHost;
      case 'dev':
        return devHost;
      case 'prod':
        return prodHost;
    }
  }
  static start() {}
}
