import {inject, observer} from 'mobx-react';
import moment from 'moment';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import Select from 'react-select';
import {Card, CardBody, CardTitle, Col, Label, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {PromiseButton} from '../../../components/promiseButton';
import {AppService} from '../../../dataServices/appService';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';
import {CsvUtils} from '../../../utils/csvUtils';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {
  year: string;
  month: string;
};

const years = ['2019', '2020', '2021'].map((a) => ({value: a, label: a}));
const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'].map((a) => ({
  value: a,
  label: a,
}));

@inject(MainStoreName)
@observer
export class ManagementActionsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {year: moment().format('YYYY'), month: moment().format('MM')};
  }

  generateBinPCNReport = async (type: 'singlecare' | 'rxedo') => {
    const month = this.state.year + '-' + this.state.month;
    const result = await AppService.adminClient.generateBinPcnReport({type, month}, {});
    if (result) {
      CsvUtils.saveRawFile(`${type}-${month}-bin-pcn.csv`, result.report);
    }
  };

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle heading={`Clever Actions`} subheading="" icon="pe-7s-medal icon-gradient bg-tempting-azure" />

        <Row>
          <Col md="7">
            <Card className="main-card mb-3">
              <CardBody>
                <CardTitle>BIN PCN Report</CardTitle>
                <Row>
                  <Col md={3}>
                    <Label>Month</Label>
                    <Select
                      onChange={(v: any) => this.setState({month: v.value})}
                      value={months.find((a) => a.value === this.state.month)}
                      options={months}
                      autoComplete={'disabled'}
                    />
                  </Col>
                  <Col md={3}>
                    <Label>Year</Label>
                    <Select
                      onChange={(v: any) => this.setState({year: v.value})}
                      value={years.find((a) => a.value === this.state.year)}
                      options={years}
                      autoComplete={'disabled'}
                    />
                  </Col>
                  <Col md={6} style={{display: 'flex', alignItems: 'center'}}>
                    <PromiseButton
                      style={{margin: '0 auto'}}
                      color="primary"
                      onClick={() => this.generateBinPCNReport('singlecare')}
                    >
                      Generate Singlecare Report
                    </PromiseButton>
                    <PromiseButton
                      style={{margin: '0 auto'}}
                      color="primary"
                      onClick={() => this.generateBinPCNReport('rxedo')}
                    >
                      Generate RXEDO Report
                    </PromiseButton>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
    );
  }
}
