import React, {useRef, useState} from 'react';
import {useClickOutside} from '../../../../hooks/useClickOutside';
import {useEscKeyPressed} from '../../../../hooks/useEscKeyPressed';
import './OptionSelect.css';

interface OptionSelectProps<T> {
  options: {id: T; label: string}[];
  selectedFilter: T;
  setSelectedFilter: (val: T) => void;
}

export const OptionSelect = <T extends string>(props: OptionSelectProps<T>) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const trigger = useRef(null);
  const dropdown = useRef(null);

  useClickOutside(dropdown, trigger, showDropdown, () => setShowDropdown(false));
  useEscKeyPressed(showDropdown, () => setShowDropdown(false));

  return (
    <div className={'HealthOptionSelect'}>
      <button ref={trigger} className={'HealthOptionSelect__btn'} onClick={() => setShowDropdown(!showDropdown)}>
        <span>{props.options.find((opt) => opt.id === props.selectedFilter).label}</span>
        <svg fill={'currentColor'} width="11" height="7" viewBox="0 0 11 7">
          <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
        </svg>
      </button>
      {showDropdown && (
        <div
          ref={dropdown}
          className={'HealthOptionSelect__opts'}
          onFocus={() => setShowDropdown(true)}
          onBlur={() => setShowDropdown(false)}
        >
          {props.options.map((option) => {
            const isActive = option.id === props.selectedFilter;
            return (
              <div
                key={option.id}
                tabIndex={0}
                className={`HealthOptionSelect__opt ${isActive && 'HealthOptionSelect__opt--active'}`}
                onClick={() => {
                  props.setSelectedFilter(option.id);
                  setShowDropdown(false);
                }}
              >
                <span>{option.label}</span>
                {isActive && (
                  <svg fill={'currentColor'} width="12" height="9" viewBox="0 0 12 9">
                    <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
                  </svg>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
