import React, {Component} from 'react';
import ReactTable from 'react-table';
import {Button, Col, Form, Input, Row} from 'reactstrap';
import {HttpPartnerSearch} from '../dataServices/app.generated';
import {AppService} from '../dataServices/appService';

type Props = {
  onPartnerSelect: (partner: HttpPartnerSearch) => void;
};
type State = {
  search: string;
  loadingSearch: boolean;
  partners: HttpPartnerSearch[];
  page: number;
};

export class PartnerSearchComponent extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      search: '',
      loadingSearch: true,
      page: 0,
      partners: [],
    };
  }

  async componentDidMount() {
    await this.search();
  }

  private search = async () => {
    this.setState({loadingSearch: true});
    const result = await AppService.adminPartnerClient.searchPartners(
      {
        page: this.state.page.toString(),
        query: this.state.search,
        onlyMasters: 'false',
      },
      {}
    );
    if (result) {
      this.setState({
        partners: result.partners,
        page: result.page,
        loadingSearch: false,
      });
    }
  };

  render() {
    return (
      <>
        <Form
          onSubmit={(e) => {
            this.setState({page: 0}, this.search);
            e.preventDefault();
          }}
        >
          <Row className={'py-2'}>
            <Col md={6}>
              <Input
                placeholder={'ex: Salvatore'}
                className={'mr-2'}
                type={'search'}
                value={this.state.search}
                onChange={(e) => this.setState({search: e.target.value})}
              />
            </Col>
            <Col md={2}>
              <Button color="primary" size="lg" type={'submit'}>
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        <ReactTable
          resizable={false}
          loading={this.state.loadingSearch}
          data={this.state.partners}
          manual
          getTrProps={(state: any, rowInfo: any) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => {
                  this.props.onPartnerSelect(rowInfo.original);
                },
              };
            } else {
              return {};
            }
          }}
          showPageSizeOptions={false}
          defaultPageSize={10}
          sortable={false}
          page={this.state.page}
          showPagination={false}
          columns={[
            {
              Header: 'Partner Name',
              accessor: 'partnerName',
              Cell: (e) => (
                <a
                  href={'#'}
                  onClick={(ee) => {
                    this.props.onPartnerSelect(e.original);
                    ee.preventDefault();
                  }}
                >
                  {e.value}
                </a>
              ),
            },
            {
              id: 'startingCard',
              Header: 'Starting Card',
              accessor: (a) => a.startingGroupCode + '-' + a.startingMemberId,
            },
            /*  {
              accessor: 'parentPartnerName',
              Header: 'Parent Partner Name',
            },
            {
              Header: 'Number Of Partners',
              accessor: 'children',
            },
            {
              id: 'cardLength',
              Header: 'Number Of Cards',
              accessor: a => a.cards.length,
            },*/
          ]}
          style={{height: '600px'}}
          className="-striped -highlight -fixed"
        />
        <Row>
          <Col sm={'3'}>
            <Button
              color="primary"
              size={'lg'}
              disabled={this.state.page === 0}
              onClick={() => this.setState({page: this.state.page - 1}, this.search)}
            >
              Previous
            </Button>
          </Col>
          <Col sm={'6'} />
          <Col sm={'3'} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button size={'lg'} color="primary" onClick={() => this.setState({page: this.state.page + 1}, this.search)}>
              Next
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}
