import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-dates/lib/css/_datepicker.css';
// import {configure} from 'mobx';
import {create} from 'mobx-persist';
import {Provider} from 'mobx-react';
import React from 'react';
import 'react-dates/initialize';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import './assets/base.scss';
import Main from './pages';
import {MainStoreName} from './store/main/store';
import {stores} from './store/stores';
import {configure} from 'mobx';

configure({enforceActions: 'always'});

const rootElement = document.getElementById('root');

const hydrate = create({});
hydrate(MainStoreName, stores.mainStore).then(() => {
  ReactDOM.render(
    <Provider {...stores}>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </Provider>,
    rootElement
  );
});
