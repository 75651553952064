import {Utils} from '../../../utils/utils';
import moment from 'moment';
import {Bar} from 'react-chartjs-2';
import React from 'react';

const hourLabels = Utils.range(12)
  .map((a) => (a === 0 ? '12am' : `${a}am`))
  .concat(Utils.range(12).map((a) => (a === 0 ? '12pm' : `${a}pm`)));

export const HealthReportChart = React.memo(
  ({data, label, month, labelByHour}: {data: number[]; label: string; month: string; labelByHour?: boolean}) => {
    const dataset = {
      label,
      backgroundColor: '#ff7f40',
      borderColor: '#e64b38',
      borderWidth: 1,
      borderCapStyle: 'round' as const,
      data,
    };

    return (
      <Bar
        data={{
          labels: labelByHour
            ? hourLabels
            : Utils.range(moment(month + '-01').daysInMonth()).map((a) =>
                moment(month + '-01')
                  .set('date', a + 1)
                  .format('Do')
              ),
          datasets: [dataset],
        }}
        width={100}
        height={50}
        options={{
          tooltips: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            display: false,
          },
          responsive: true,
          scales: {
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        }}
      />
    );
  }
);
