import React, {useState} from 'react';
import Select from 'react-select';
import {Button, ButtonGroup, Col, CustomInput, Label, Row} from 'reactstrap';
import {AccountSearchComponent} from '../../../../components/accountSearchComponent';
import {LInput, LSelect, LText} from '../../../../components/lInput';
import {StateSwitch} from '../../../../components/stateSwitch';
import {AccountContactTypes, HttpPartnerExtended} from '../../../../dataServices/app.generated';
import {PartnerUtils} from '../../../../utils/partnerUtils';

export interface PartnerAccountDetails {
  contactType?: AccountContactTypes;
  firstName?: string;
  lastName?: string;
  email?: string;
  allowPromotionalCommunication?: boolean;
  phoneNumber?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  accountId?: string;
}

export function PartnerCreateAccountDetailsStep(props: {
  next: () => void;
  parentPartner: HttpPartnerExtended;
  accountDetails: PartnerAccountDetails;
  setAccountDetails: (accountDetails: PartnerAccountDetails) => void;
}) {
  const states = [
    {value: 'AL', label: 'Alabama'},
    {value: 'AK', label: 'Alaska'},
    {value: 'AZ', label: 'Arizona'},
    {value: 'AR', label: 'Arkansas'},
    {value: 'CA', label: 'California'},
    {value: 'CO', label: 'Colorado'},
    {value: 'CT', label: 'Connecticut'},
    {value: 'DE', label: 'Delaware'},
    {value: 'DC', label: 'District Of Columbia'},
    {value: 'FL', label: 'Florida'},
    {value: 'GA', label: 'Georgia'},
    {value: 'GU', label: 'Guam'},
    {value: 'HI', label: 'Hawaii'},
    {value: 'ID', label: 'Idaho'},
    {value: 'IL', label: 'Illinois'},
    {value: 'IN', label: 'Indiana'},
    {value: 'IA', label: 'Iowa'},
    {value: 'KS', label: 'Kansas'},
    {value: 'KY', label: 'Kentucky'},
    {value: 'LA', label: 'Louisiana'},
    {value: 'ME', label: 'Maine'},
    {value: 'MD', label: 'Maryland'},
    {value: 'MA', label: 'Massachusetts'},
    {value: 'MI', label: 'Michigan'},
    {value: 'MN', label: 'Minnesota'},
    {value: 'MS', label: 'Mississippi'},
    {value: 'MO', label: 'Missouri'},
    {value: 'MT', label: 'Montana'},
    {value: 'NE', label: 'Nebraska'},
    {value: 'NV', label: 'Nevada'},
    {value: 'NH', label: 'New Hampshire'},
    {value: 'NJ', label: 'New Jersey'},
    {value: 'NM', label: 'New Mexico'},
    {value: 'NY', label: 'New York'},
    {value: 'NC', label: 'North Carolina'},
    {value: 'ND', label: 'North Dakota'},
    {value: 'OH', label: 'Ohio'},
    {value: 'OK', label: 'Oklahoma'},
    {value: 'OR', label: 'Oregon'},
    {value: 'PA', label: 'Pennsylvania'},
    {value: 'PR', label: 'Puerto Rico'},
    {value: 'RI', label: 'Rhode Island'},
    {value: 'SC', label: 'South Carolina'},
    {value: 'SD', label: 'South Dakota'},
    {value: 'TN', label: 'Tennessee'},
    {value: 'TX', label: 'Texas'},
    {value: 'UT', label: 'Utah'},
    {value: 'VT', label: 'Vermont'},
    {value: 'VA', label: 'Virginia'},
    {value: 'WA', label: 'Washington'},
    {value: 'WV', label: 'West Virginia'},
    {value: 'WI', label: 'Wisconsin'},
    {value: 'WY', label: 'Wyoming'},
  ];
  const {accountDetails, setAccountDetails} = props;
  const [findAccount, setFindAccount] = useState<0 | 1 | 2>(0);
  const options = ['nonProfit', 'association', 'healthcareProvider', 'broker', 'employer', 'other'].map((a) => ({
    label: PartnerUtils.getAccountContactTypeName(a as AccountContactTypes),
    value: a,
  }));
  return (
    <>
      <Row>
        <Col md={12}>
          <LText label={'Account Managed By:'}>
            <ButtonGroup>
              <Button
                color="primary"
                onClick={() => {
                  setAccountDetails({accountId: null});
                  setFindAccount(1);
                }}
                active={findAccount === 1}
              >
                New Account
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setAccountDetails({accountId: null});
                  setFindAccount(2);
                }}
                active={findAccount === 2}
              >
                Existing Account
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setAccountDetails({accountId: props.parentPartner.account.id});
                  setFindAccount(0);
                }}
                active={findAccount === 0}
              >
                Tie Directly To <b>{props.parentPartner.partnerName}</b>
              </Button>
            </ButtonGroup>
          </LText>
        </Col>
      </Row>
      <StateSwitch state={() => findAccount}>
        {{
          0: () => <></>,
          1: () => (
            <>
              <Row>
                <Col md={6}>
                  <LInput
                    value={accountDetails.firstName}
                    onChange={(e) => setAccountDetails({...accountDetails, firstName: e.target.value})}
                    label={'First Name'}
                    type={'text'}
                    name={'firstname'}
                    autoComplete={'disabled'}
                  />
                </Col>
                <Col md={6}>
                  <LInput
                    value={accountDetails.lastName}
                    onChange={(e) => setAccountDetails({...accountDetails, lastName: e.target.value})}
                    label={'Last Name'}
                    type={'text'}
                    name={'lastname'}
                    autoComplete={'disabled'}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <LInput
                    value={accountDetails.email}
                    onChange={(e) => setAccountDetails({...accountDetails, email: e.target.value})}
                    label={'Email'}
                    type={'text'}
                    name={'email'}
                    autoComplete={'disabled'}
                  />
                </Col>
                <Col md={3} style={{height: '90px', display: 'flex', alignItems: 'center'}}>
                  <CustomInput
                    type="checkbox"
                    id={'allowPromoMaterials'}
                    label="Allow Promotional Materials"
                    checked={accountDetails.allowPromotionalCommunication}
                    onChange={(e) =>
                      setAccountDetails({...accountDetails, allowPromotionalCommunication: e.target.checked})
                    }
                  />
                </Col>
                <Col md={3}>
                  <LInput
                    value={accountDetails.phoneNumber}
                    onChange={(e) => setAccountDetails({...accountDetails, phoneNumber: e.target.value})}
                    label={'Phone Number'}
                    type={'text'}
                    autoComplete={'disabled'}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <LInput
                    value={accountDetails.address1}
                    onChange={(e) => setAccountDetails({...accountDetails, address1: e.target.value})}
                    label={'Address 1'}
                    type={'text'}
                    name={'address1'}
                    placeholder="Optional"
                    autoComplete={'disabled'}
                  />
                </Col>
                <Col md={6}>
                  <LInput
                    value={accountDetails.address2}
                    onChange={(e) => setAccountDetails({...accountDetails, address2: e.target.value})}
                    label={'Address 2'}
                    type={'text'}
                    name={'address2'}
                    placeholder="Optional"
                    autoComplete={'disabled'}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <LInput
                    value={accountDetails.city}
                    onChange={(e) => setAccountDetails({...accountDetails, city: e.target.value})}
                    label={'City'}
                    type={'text'}
                    name={'city'}
                    placeholder="Optional"
                    autoComplete={'disabled'}
                  />
                </Col>
                <Col md={4}>
                  <Label for={'stateSelect'}>State</Label>
                  <Select
                    menuPosition={'fixed'}
                    id={'stateSelect'}
                    onChange={(v: any) => setAccountDetails({...accountDetails, state: v.value})}
                    value={states.find((a) => a.value === accountDetails.state)}
                    options={states}
                    autoComplete={'disabled'}
                  />
                </Col>
                <Col md={2}>
                  <LInput
                    value={accountDetails.zip}
                    onChange={(e) => setAccountDetails({...accountDetails, zip: e.target.value})}
                    label={'Zip'}
                    type={'text'}
                    name={'zip'}
                    placeholder="Optional"
                    autoComplete={'disabled'}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <LSelect
                    menuPosition={'fixed'}
                    label={'Contact Type'}
                    value={options.find((a) => a.value === accountDetails.contactType)}
                    options={options}
                    onChange={(e) => setAccountDetails({...accountDetails, contactType: (e as any).value})}
                  />
                </Col>
              </Row>
            </>
          ),
          2: () => (
            <>
              <AccountSearchComponent
                onAccountSelect={(account) => {
                  setAccountDetails({
                    accountId: account.id,
                  });
                  props.next();
                }}
              />
            </>
          ),
        }}
      </StateSwitch>
    </>
  );
}
