import React, {Fragment} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {CardCreate} from './create';
import {CardDetails} from './details';
export const CardPages = ({match}: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/details/:cardId`} component={CardDetails} />
          <Route path={`${match.url}/create/:partnerId`} component={CardCreate} />
        </div>
      </div>
    </div>
  </Fragment>
);
