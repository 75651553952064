import {MobXProviderContext} from 'mobx-react';
import React from 'react';
import {mainStore, MainStoreProps} from './main/store';
import {themeStore, ThemeStoreProps} from './theme/store';

export type AppStore = MainStoreProps & ThemeStoreProps;
export const stores: AppStore = {mainStore, themeStore};
export function useStores(): AppStore {
  return React.useContext(MobXProviderContext);
}
