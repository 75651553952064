import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import {Button, Col, Form, Input, Row} from 'reactstrap';
import {HttpAccountSearch} from '../dataServices/app.generated';
import {AppService} from '../dataServices/appService';

type Props = {
  onAccountSelect: (account: HttpAccountSearch) => void;
  onSearch?: (query: string) => void;
  query?: string;
};
type State = {
  search: string;
  initialSearchDone: boolean;
  loadingSearch: boolean;
  accounts: HttpAccountSearch[];
  page: number;
};

export class AccountSearchComponent extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      search: props.query || '',
      initialSearchDone: !!props.query,
      loadingSearch: true,
      page: 0,
      accounts: [],
    };
  }

  async componentDidMount() {
    if (this.state.search) {
      await this.search();
    }
  }

  private search = async () => {
    this.props.onSearch && this.props.onSearch(this.state.search);
    this.setState({loadingSearch: true, initialSearchDone: true});
    const result = await AppService.adminAccountClient.searchAccounts(
      {
        page: this.state.page.toString(),
        query: this.state.search,
      },
      {}
    );
    if (result) {
      this.setState({
        accounts: result.accounts,
        page: result.page,
        loadingSearch: false,
      });
    }
  };

  render() {
    return (
      <>
        <Form
          onSubmit={(e) => {
            this.setState({page: 0}, this.search);
            e.preventDefault();
          }}
        >
          <Row className={'py-2'}>
            <Col md={6}>
              <Input
                placeholder={'ex: Salvatore'}
                className={'mr-2'}
                type={'search'}
                value={this.state.search}
                onChange={(e) => this.setState({search: e.target.value})}
              />
            </Col>
            <Col md={2}>
              <Button color="primary" size="lg" type={'submit'}>
                Search
              </Button>
            </Col>
          </Row>
        </Form>
        {this.state.initialSearchDone && (
          <>
            <ReactTable
              resizable={false}
              loading={this.state.loadingSearch}
              data={this.state.accounts}
              manual
              getTrProps={(state: any, rowInfo: any) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: () => {
                      this.props.onAccountSelect(rowInfo.original);
                    },
                  };
                } else {
                  return {};
                }
              }}
              showPageSizeOptions={false}
              showPagination={false}
              onPageChange={(p) => {
                this.setState({page: p}, this.search);
              }}
              sortable={false}
              defaultPageSize={10}
              page={this.state.page}
              columns={[
                {
                  accessor: 'accountOwner',
                  Header: 'Account Owner',
                  Cell: (e) => (
                    <Link
                      to={`/account/details/${e.original.id}`}
                      onClick={(ee) => {
                        this.props.onAccountSelect(e.original);
                        ee.preventDefault();
                      }}
                    >
                      {e.value}
                    </Link>
                  ),
                },
                {
                  Header: 'Email',
                  accessor: 'email',
                },
                {
                  id: 'partners',
                  Header: 'Number Of Partners',
                  accessor: (a) => a.partnersCount,
                },
                {
                  id: 'cards',
                  Header: 'Number Of Cards',
                  accessor: (a) => a.cardsCount,
                },
              ]}
              style={{height: '600px'}}
              className="-striped -highlight -fixed"
            />
            <Row>
              <Col sm={'3'}>
                <Button
                  color="primary"
                  size={'lg'}
                  disabled={this.state.page === 0}
                  onClick={() => this.setState({page: this.state.page - 1}, this.search)}
                >
                  Previous Page
                </Button>
              </Col>
              <Col sm={'6'} />
              <Col sm={'3'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  size={'lg'}
                  color="primary"
                  onClick={() => this.setState({page: this.state.page + 1}, this.search)}
                >
                  Next Page
                </Button>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}
