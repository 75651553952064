import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import ReactTable from 'react-table';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import Loading from '../../../components/loading';
import {GetAllAmountsDueResponse} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {FormatUtils} from '../../../utils/formatUtils';
import {Utils} from '../../../utils/utils';

type Props = RouteComponentProps<any> & {};
type State = {
  gettingReport: boolean;
  amountsDue?: GetAllAmountsDueResponse['partners'];
};

export class PartnerAmountsDuePage extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      gettingReport: true,
    };
  }

  async componentDidMount() {
    this.getReport();
  }

  private async getReport() {
    this.setState({gettingReport: true});
    const result = await AppService.adminPartnerClient.getAllAmountsDue({}, {});
    if (result) {
      console.log(result);
      this.setState({
        gettingReport: false,
        amountsDue: result.partners,
      });
    }
  }

  render() {
    if (this.state.gettingReport) {
      return <Loading />;
    }

    return (
      <>
        <Card className="mb-3">
          <CardHeader className="card-header-tab z-index-7">
            <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
              <i className="header-icon lnr-charts icon-gradient bg-happy-green" />
              Payouts Due (Total:{' '}
              <strong>{FormatUtils.formatMoney(Utils.sum(this.state.amountsDue, (a) => a.amountDue))}</strong>)
            </div>
          </CardHeader>
        </Card>
        {this.state.gettingReport ? (
          <div className={'pt-4 pb-4'}>
            <Loading />
          </div>
        ) : (
          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardBody>
                  <ReactTable
                    data={this.state.amountsDue}
                    showPagination={true}
                    showPageSizeOptions={true}
                    sortable={true}
                    columns={[
                      {
                        Header: 'Partner Name',
                        Cell: (e) =>
                          e.original.partnerId ? (
                            <Link to={`/partner/details/${e.original.partnerId}`}>{e.original.partnerName}</Link>
                          ) : (
                            e.original.partnerName
                          ),
                      },
                      {
                        Header: 'Amount Due',
                        Cell: (e) => FormatUtils.formatMoney(e.original.amountDue),
                      },
                    ]}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </>
    );
  }
}
