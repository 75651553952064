import {Config} from '../config';
import {mainStore} from '../store/main/store';
import {
  AdminAccountClient,
  AdminClaimClient,
  AdminClient,
  AdminDashboardClient,
  AdminPartnerClient,
  AdminReportsClient,
  DrugClient,
  PartnerClient,
  PharmacyClient,
  ShareClient,
  UserClient,
  UtilsClient,
  AdminMarketingClient,
  AdminPartnerHealthClient,
} from './app.generated';
import {ControllerOptions} from './baseClient';

const options: ControllerOptions = {
  baseUrl: Config.host,
  getJwt: () => {
    return mainStore.jwt;
  },
  handleError: (error: string) => {
    console.error(error);
    return mainStore.setError(error);
  },
  handleUnauthorized: (error: string) => {
    return mainStore.logout();
  },
};

export class AppService {
  static partnerClient = new PartnerClient(options);
  static adminClient = new AdminClient(options);
  static adminDashboardClient = new AdminDashboardClient(options);
  static adminPartnerClient = new AdminPartnerClient(options);
  static adminPartnerHealthClient = new AdminPartnerHealthClient(options);
  static adminMarketingClient = new AdminMarketingClient(options);
  static adminReportsClient = new AdminReportsClient(options);
  static adminAccountClient = new AdminAccountClient(options);
  static adminClaimClient = new AdminClaimClient(options);
  static drugClient = new DrugClient(options);
  static userClient = new UserClient(options);
  static utilsClient = new UtilsClient(options);
  static pharmacyClient = new PharmacyClient(options);
  static shareClient = new ShareClient(options);
}

// https://www.youtube.com/watch?v=jnKtICvvUko
