import {parse, stringify} from 'query-string';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router';

export type QueryStringResult<TQueryParams> = [Partial<TQueryParams>, boolean, (values: Partial<TQueryParams>) => void];

export function useQueryString<TQueryParams>(defaultValue: TQueryParams): QueryStringResult<TQueryParams> {
  const history = useHistory();

  const [search, setSearch] = useState<string>(window.location.search || stringify(defaultValue));
  const [changed, setChanged] = useState(true);

  useEffect(() => {
    setChanged(false);
  }, []);

  useEffect(() => {
    const l = history.listen((e) => {
      if (search !== e.search) {
        setSearch(e.search);
        setChanged(true);
      }
    });
    return () => {
      l();
    };
  }, [history, search]);

  function setQuery(values: Partial<TQueryParams>): void {
    const newQuery = {
      ...((parse(search) as unknown) as TQueryParams),
      ...values,
    };

    history.push({pathname: window.location.pathname, search: stringify(newQuery)});
  }

  return [{...defaultValue, ...((parse(search) as unknown) as TQueryParams)}, changed, setQuery];
}
