import {inject, observer, useObserver} from 'mobx-react';
import moment from 'moment';
import React, {Component, FC, useState} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import Select from 'react-select';
import {toast} from 'react-toastify';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {LInput, LSelect} from '../../../components/lInput';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {
  AccountInvitePartnerRequest,
  DBPartnerRequestBody,
  HttpPartnerRequestExtended,
} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore, MainStoreName, MainStoreProps} from '../../../store/main/store';
import {useStores} from '../../../store/stores';
import {PartnerUtils} from '../../../utils/partnerUtils';
import {Discriminate} from '../../../utils/typeUtils';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {
  requests?: HttpPartnerRequestExtended[];
  filter: 'pending' | 'approved' | 'denied';
  approvedPage: number;
  reviewRequest?: HttpPartnerRequestExtended;
};

@inject(MainStoreName)
@observer
export class ManagementPartnerRequestsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      filter: 'pending',
      approvedPage: 0,
    };
  }

  async componentDidMount() {
    await this.getRequests();
  }

  private async getRequests() {
    const result = await AppService.adminPartnerClient.getAllPartnerRequests(
      {approvedPage: this.state.approvedPage},
      {}
    );
    if (result) {
      this.setState({requests: result.requests});
    }
  }

  render() {
    const filteredRequests = this.state.requests
      ? this.state.requests.filter((a) => {
          switch (this.state.filter) {
            case 'pending':
              return a.approved === undefined || a.approved === null;
            case 'denied':
              return a.approved === false;
            case 'approved':
              return a.approved;
          }
        })
      : [];
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle heading={`Clever Requests`} subheading="" icon="pe-7s-medal icon-gradient bg-tempting-azure" />
        {!this.state.requests ? (
          <Loading />
        ) : (
          <Row>
            <Col md="7">
              <Card className="main-card mb-3">
                <CardBody>
                  <CardTitle>Pending Requests</CardTitle>
                  <ButtonGroup>
                    <Button
                      color="primary"
                      onClick={() => this.setState({filter: 'pending'})}
                      active={this.state.filter === 'pending'}
                    >
                      Pending
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.setState({filter: 'approved'})}
                      active={this.state.filter === 'approved'}
                    >
                      Approved
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => this.setState({filter: 'denied'})}
                      active={this.state.filter === 'denied'}
                    >
                      Denied
                    </Button>
                  </ButtonGroup>
                  <Table hover striped className="mb-0">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Master</th>
                        <th>Parent Partner</th>
                        <th>Partner</th>
                        <th>Request</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredRequests.length === 0 && <span>There are no requests.</span>}
                      {filteredRequests
                        .sort((a, b) => +b.createdDate - +a.createdDate)
                        .map((a) => (
                          <tr key={a._id}>
                            <td>{moment(a.createdDate).format('MM-DD-YYYY')}</td>
                            <td>
                              <Link to={`/partner/details/${a.masterPartnerId}`}>{a.masterPartnerName}</Link>
                            </td>
                            <td>
                              <Link to={`/partner/details/${a.parentPartnerId}`}>
                                {a.parentPartnerName} {a.parentStartingGroupCode}-{a.parentStartingMemberId}
                              </Link>
                            </td>
                            <td>
                              <Link to={`/partner/details/${a.initiatingPartnerId}`}>
                                {a.initiatingPartnerName} {a.initiatingStartingGroupCode}-{a.initiatingStartingMemberId}
                              </Link>
                            </td>

                            {a.request.type === 'card-vanity-url' && <td>Card Vanity URL: {a.request.vanityUrl}</td>}
                            {a.request.type === 'invite-partner' && (
                              <td>Invite Partner: {a.request.body.partnerName}</td>
                            )}
                            <td>
                              {a.approved === undefined || a.approved === null ? (
                                <Button
                                  onClick={() => {
                                    this.setState({reviewRequest: a});
                                  }}
                                  color={'primary'}
                                >
                                  Review
                                </Button>
                              ) : a.approved === true ? (
                                'Approved'
                              ) : (
                                'Denied'
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    {this.state.filter === 'approved' ? (
                      <tfoot>
                        <Row>
                          <Col sm={'3'}>
                            <Button
                              color="primary"
                              size={'lg'}
                              disabled={this.state.approvedPage === 0}
                              onClick={() =>
                                this.setState({approvedPage: this.state.approvedPage - 1}, () => this.getRequests())
                              }
                            >
                              Previous Page
                            </Button>
                          </Col>
                          <Col sm={'6'} />
                          <Col sm={'3'} style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                              size={'lg'}
                              color="primary"
                              onClick={() =>
                                this.setState({approvedPage: this.state.approvedPage + 1}, () => this.getRequests())
                              }
                            >
                              Next Page
                            </Button>
                          </Col>
                        </Row>
                      </tfoot>
                    ) : (
                      <></>
                    )}
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {this.state.reviewRequest && (
          <PartnerRequestModals
            request={this.state.reviewRequest}
            onDone={(result) => {
              switch (result) {
                case 'approved':
                  this.setState({reviewRequest: null});
                  this.getRequests();
                  break;
                case 'denied':
                  this.setState({reviewRequest: null});
                  this.getRequests();
                  break;
                case 'ignore':
                  this.setState({reviewRequest: null});
                  break;
              }
            }}
          />
        )}
      </ReactCSSTransitionGroup>
    );
  }
}

export const PartnerRequestModals: FC<{
  request: HttpPartnerRequestExtended;
  onDone: (done: 'approved' | 'denied' | 'ignore') => void;
}> = (props) => {
  const {mainStore} = useStores();
  const [denyReason, setDenyReason] = useState('');
  const [showCreateCard, setShowCreateCard] = useState(props.request.request.type === 'card-vanity-url');
  const [showInvitePartner, setShowInvitePartner] = useState(props.request.request.type === 'invite-partner');
  const [newCard, setNewCard] = useState<Discriminate<DBPartnerRequestBody, 'type', 'card-vanity-url'>>(
    props.request.request as any
  );
  const [newPartner, setNewPartner] = useState<AccountInvitePartnerRequest>(
    (props.request.request as Discriminate<DBPartnerRequestBody, 'type', 'invite-partner'>).body
  );

  async function denyRequest() {
    const result = await AppService.adminPartnerClient.adjudicatePartnerRequest(
      {
        partnerRequestId: props.request._id,
        approved: false,
        denyReason,
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      props.onDone('denied');
      toast('This request has been denied', {type: 'success'});
    }
  }

  async function createCard() {
    const result = await AppService.adminPartnerClient.adjudicatePartnerRequest(
      {
        partnerRequestId: props.request._id,
        approved: true,
        request: {...props.request.request, ...newCard},
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      props.onDone('approved');
      toast(
        <>
          This request has been approved. See <Link to={`/card/details/${result.resultId}`}>card</Link>.
        </>,
        {type: 'success'}
      );
    }
  }
  async function invitePartner() {
    const result = await AppService.adminPartnerClient.adjudicatePartnerRequest(
      {
        partnerRequestId: props.request._id,
        approved: true,
        request: {type: 'invite-partner', body: newPartner},
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      props.onDone('approved');
      toast(
        <>
          This request has been approved. See <Link to={`/partner/details/${result.resultId}`}>partner</Link>.
        </>,
        {type: 'success'}
      );
    }
  }

  return useObserver(() => (
    <>
      {showCreateCard && (
        <Modal isOpen={true}>
          <>
            <ModalHeader
              toggle={() => {
                setShowCreateCard(false);
                props.onDone('ignore');
              }}
            >
              New Card
            </ModalHeader>
            <ModalBody>
              <Form>
                <Row>
                  <Col md={12}>
                    <LInput
                      label={'Vanity URL'}
                      placeholder={'abc_inc'}
                      value={newCard.vanityUrl}
                      onChange={(e) => setNewCard({...newCard, vanityUrl: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Override Group Code'}
                      placeholder={'Leave Blank To Autogenerate'}
                      value={newCard.overrideGroupCode}
                      onChange={(e) => setNewCard({...newCard, overrideGroupCode: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Override Member Id'}
                      placeholder={'Leave Blank To Autogenerate'}
                      value={newCard.overrideMemberId}
                      onChange={(e) => setNewCard({...newCard, overrideMemberId: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row style={{backgroundColor: '#ffbfbf'}}>
                  <Col md={12}>
                    <LInput
                      label={'Deny Reason'}
                      placeholder={'Leave blank if approved'}
                      value={denyReason}
                      onChange={(e) => setDenyReason(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <PromiseButton onClick={denyRequest} color={'warning'}>
                Deny Request
              </PromiseButton>
              <PromiseButton onClick={createCard} color={'primary'}>
                Approve Vanity URL
              </PromiseButton>
            </ModalFooter>
          </>
        </Modal>
      )}
      {showInvitePartner && (
        <Modal isOpen={true}>
          <>
            <ModalHeader
              toggle={() => {
                setShowInvitePartner(false);
                props.onDone('ignore');
              }}
            >
              Invite Partner
            </ModalHeader>
            <ModalBody>
              <Form>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Partner Name'}
                      placeholder={'ABC Incorporated'}
                      value={newPartner.partnerName}
                      onChange={(e) => setNewPartner({...newPartner, partnerName: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      type={'number'}
                      label={'Commission Amount'}
                      value={newPartner.commissionAmount}
                      min={0.1}
                      step={0.01}
                      onChange={(e) => setNewPartner({...newPartner, commissionAmount: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Override Group Code'}
                      placeholder={'Leave Blank To Autogenerate'}
                      value={newPartner.overrideGroupCode}
                      onChange={(e) => setNewPartner({...newPartner, overrideGroupCode: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Override Member Id'}
                      placeholder={'Leave Blank To Autogenerate'}
                      value={newPartner.overrideMemberId}
                      onChange={(e) => setNewPartner({...newPartner, overrideMemberId: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Company Name'}
                      placeholder={'ABC Incorporated'}
                      value={newPartner.companyName}
                      onChange={(e) => setNewPartner({...newPartner, companyName: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Vanity URL'}
                      placeholder={'abc_inc'}
                      value={newPartner.vanityUrl}
                      onChange={(e) => setNewPartner({...newPartner, vanityUrl: e.target.value})}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <LInput
                      label={'First Name'}
                      placeholder={'Chris'}
                      value={newPartner.firstName}
                      onChange={(e) => setNewPartner({...newPartner, firstName: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Last Name'}
                      placeholder={'Jones'}
                      value={newPartner.lastName}
                      onChange={(e) => setNewPartner({...newPartner, lastName: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Email Address'}
                      placeholder={'abc@inc.com'}
                      value={newPartner.emailAddress}
                      onChange={(e) => setNewPartner({...newPartner, emailAddress: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Phone Number'}
                      placeholder={'888-555-1958'}
                      value={newPartner.phoneNumber}
                      onChange={(e) => setNewPartner({...newPartner, phoneNumber: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Address 1'}
                      placeholder={'5279 West 18th Street'}
                      value={newPartner.address1}
                      onChange={(e) => setNewPartner({...newPartner, address1: e.target.value})}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      label={'Address 2'}
                      placeholder={'Suite 105'}
                      value={newPartner.address2}
                      onChange={(e) => setNewPartner({...newPartner, address2: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <LInput
                      label={'City'}
                      placeholder={'Queens'}
                      value={newPartner.city}
                      onChange={(e) => setNewPartner({...newPartner, city: e.target.value})}
                    />
                  </Col>
                  <Col md={4}>
                    <Label for={'stateSelect'}>State</Label>
                    <Select
                      id={'stateSelect'}
                      onChange={(v: any) => setNewPartner({...newPartner, state: v.value})}
                      value={PartnerUtils.getStates().find((a) => a.value === newPartner.state)}
                      options={PartnerUtils.getStates()}
                    />
                  </Col>
                  <Col md={4}>
                    <LInput
                      label={'Postal Code'}
                      placeholder={'85847'}
                      value={newPartner.zip}
                      onChange={(e) => setNewPartner({...newPartner, zip: e.target.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <LSelect
                      menuPosition={'fixed'}
                      label={'Contact Type'}
                      value={PartnerUtils.getAccountContactsTypes().find((a) => a.value === newPartner.contactType)}
                      options={PartnerUtils.getAccountContactsTypes()}
                      onChange={(e) => setNewPartner({...newPartner, contactType: (e as any).value})}
                    />
                  </Col>
                </Row>

                <Row style={{backgroundColor: '#ffbfbf'}}>
                  <Col md={12}>
                    <LInput
                      label={'Deny Reason'}
                      placeholder={'Leave blank if approved'}
                      value={denyReason}
                      onChange={(e) => setDenyReason(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <PromiseButton onClick={denyRequest} color={'warning'}>
                Deny Request
              </PromiseButton>
              <PromiseButton onClick={invitePartner} color={'primary'}>
                Create Partner
              </PromiseButton>
            </ModalFooter>
          </>
        </Modal>
      )}
    </>
  ));
};
