import React, {Fragment} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {ManagementActionsPage} from './actions';
import {MarketingMaterialsPage} from './marketingMaterials';
import {ManagementPartnerRequestsPage} from './requests';

export const ManagementPages = ({match}: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/requests`} component={ManagementPartnerRequestsPage} />
          <Route path={`${match.url}/actions`} component={ManagementActionsPage} />
          <Route path={`${match.url}/marketing-materials`} component={MarketingMaterialsPage} />
        </div>
      </div>
    </div>
  </Fragment>
);
