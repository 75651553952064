import React, {Fragment} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {ClaimsImportReport} from './claimsImportReport';
import {ClaimsReport} from './claimsReport';
import {ExecutiveReport} from './executiveReport';
import {MainReport} from './mainReport';
import {MastersReport} from './mastersReport';
import {SlugsReport} from './slugsReport';

export const ReportPages = ({match}: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/main`} component={MainReport} />
          <Route path={`${match.url}/executive`} component={ExecutiveReport} />
          <Route path={`${match.url}/masters`} component={MastersReport} />
          <Route path={`${match.url}/slugs`} component={SlugsReport} />
          <Route path={`${match.url}/claims`} component={ClaimsReport} />
          <Route path={`${match.url}/claims-import`} component={ClaimsImportReport} />
        </div>
      </div>
    </div>
  </Fragment>
);
