import React, {Fragment} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {AppHeader} from '../../components/layout/appHeader';
import {AppSidebar} from '../../components/layout/appSidebar';
import {DrugReport} from './drugReport/claimsReport';
import {ClaimsPayoutRequests} from './payoutRequests';
import {ClaimsReconcile} from './reconcile';
import {ClaimsReport} from './reports/claimsReport';

export const ClaimPages = ({match}: RouteComponentProps<any>) => (
  <Fragment>
    <AppHeader />
    <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
        <div className="app-main__inner">
          <Route path={`${match.url}/reconcile`} component={ClaimsReconcile} />
          <Route path={`${match.url}/drugs`} component={DrugReport} />
          <Route path={`${match.url}/payout-requests`} component={ClaimsPayoutRequests} />
          <Route path={`${match.url}/reports`} component={ClaimsReport} />
        </div>
      </div>
    </div>
  </Fragment>
);
