import React, {Fragment} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {Login} from './login/';

export const UserPages = ({match}: RouteComponentProps<any>) => (
  <Fragment>
    <div className="app-container">
      <Route path={`${match.url}/login`} component={Login} />
    </div>
  </Fragment>
);
