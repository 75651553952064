import axios from 'axios';
import {inject, observer} from 'mobx-react';
import React, {Component, FC, useCallback, useEffect, useState} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {useDropzone} from 'react-dropzone';
import {RouteComponentProps} from 'react-router';
import {toast} from 'react-toastify';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Progress,
  Row,
} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {LInput} from '../../../components/lInput';
import {PromiseButton} from '../../../components/promiseButton';
import {
  HttpMarketingMaterial,

} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStoreName, MainStoreProps} from '../../../store/main/store';
import {Utils} from '../../../utils/utils';

type Props = {} & RouteComponentProps<any> & MainStoreProps;
type State = {
  materials: HttpMarketingMaterial[];
  loading: boolean;
};

@inject(MainStoreName)
@observer
export class MarketingMaterialsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {loading: true, materials: []};
  }

  async componentDidMount() {
    const result = await AppService.adminMarketingClient.allMaterials({}, {});
    if (result) {
      this.setState({materials: result.materials, loading: false});
    }
  }

  onReload = async () => {
    const result = await AppService.adminMarketingClient.allMaterials({}, {});
    if (result) {
      this.setState({materials: result.materials, loading: false});
    }
  };

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading={`Partner Marketing Materials`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
        />

        {Utils.groupBy(this.state.materials, (a) => a.category).map((a) => (
          <MaterialSection item={a} key={a.key} />
        ))}
        <FormUploadSection key={this.state.materials.length} reload={this.onReload} />
      </ReactCSSTransitionGroup>
    );
  }
}

export const MaterialSection: FC<{
  item: {key: string; items: HttpMarketingMaterial[]};
}> = ({item}) => {
  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          <CardTitle>{item.key}</CardTitle>
          {item.items.map((m) => (
            <Row key={m.id}>
              <Col>{m.title}</Col>
              <Col>
                <img alt={m.title} src={m.image} width={50} height={50} />
              </Col>
              <Col>
                <a href={m.image}>Download</a>
              </Col>
            </Row>
          ))}
        </CardBody>
      </Card>
    </>
  );
};

const FormUploadSection: FC<{
  reload: () => Promise<void>;
}> = ({reload}) => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);

  const [image, setImage] = useState<File | undefined>();
  const [file, setFile] = useState<File | undefined>();

  const onUpload = useCallback(async () => {
    if (!image || !title || !file || !category) {
      toast.error('Missing fields');
      return;
    }
    setLoading(true);
    const result = await AppService.adminMarketingClient.addMaterials(
      {
        title,
        category: category.toUpperCase(),
        imageName: image.name,
        fileName: file.name,
        imageType: image.type,
        fileType: file.type,
        order: 100,
      },
      {}
    );

    if (result) {
      await Promise.all([
        axios.put(result.imageUpload, image, {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': image.type,
          },
          onUploadProgress: (progressEvent) => {
            setImageUploadProgress(progressEvent.loaded / progressEvent.total);
          },
        }),
        axios.put(result.fileUpload, file, {
          headers: {
            'x-amz-acl': 'public-read',
            'Content-Type': file.type,
          },
          onUploadProgress: (progressEvent) => {
            setFileUploadProgress(progressEvent.loaded / progressEvent.total);
          },
        }),
      ]);
      await reload();
    }
    setLoading(false);
  }, [category, title, file, image]);

  return (
    <>
      <Card className="main-card mb-3">
        <CardBody>
          <CardTitle>Add New</CardTitle>
          <Row>
            <Col>
              <LInput
                label={'Category'}
                placeholder={''}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </Col>
            <Col>
              <LInput label={'Title'} placeholder={''} value={title} onChange={(e) => setTitle(e.target.value)} />
            </Col>
            <Col>
              Image
              <FileUpload loading={loading} setFile={setImage} uploadProgress={imageUploadProgress} />
            </Col>
            <Col>
              File
              <FileUpload loading={loading} setFile={setFile} uploadProgress={fileUploadProgress} />
            </Col>
            <Col>
              <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
                <PromiseButton onClick={onUpload} className="btn-pill btn-shadow mr-3" color="primary">
                  Upload Marketing
                </PromiseButton>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export const FileUpload: FC<{setFile: (c: File) => void; uploadProgress: number; loading: boolean}> = ({
  setFile,
  uploadProgress,
  loading,
}) => {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({multiple: false});
  const files = acceptedFiles.map((file) => (
    <React.Fragment key={file.name}>
      {file.name} - {Math.round(file.size / 1024)}KB
    </React.Fragment>
  ));

  useEffect(() => {
    setFile(acceptedFiles[0]);
  }, [acceptedFiles]);

  return (
    <>
      <div
        {...getRootProps({
          style: {
            borderRadius: '20px',
            border: 'solid 1px lightgray',
            padding: '20px',
            cursor: 'pointer',
          },
        })}
      >
        <input {...getInputProps({disabled: loading})} />
        {files.length > 0 ? (
          <span className={'description'}>{files[0]}</span>
        ) : (
          <span className={'description'}>Drag your files here, or click to select your files.</span>
        )}
        {uploadProgress > 0 && <Progress animated value={uploadProgress * 100} />}
      </div>
    </>
  );
};
