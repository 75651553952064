import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {RouteComponentProps} from 'react-router';
import {Button, Card, CardBody, Col, Row} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import Loading from '../../../components/loading';
import {PromiseButton} from '../../../components/promiseButton';
import {StateSwitch} from '../../../components/stateSwitch';
import {HttpPartnerExtended} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {MainStore} from '../../../store/main/store';
import {PartnerUtils} from '../../../utils/partnerUtils';
import {PartnerAccountDetails, PartnerCreateAccountDetailsStep} from './steps/accountDetailsStep';
import {CardDetails, CardDetailsStep} from './steps/cardDetailsStep';
import {PartnerContract, PartnerCreateContractStep} from './steps/contractStep';
import {PartnerCreateDetailsStep, PartnerDetails} from './steps/partnerDetails';

type Props = RouteComponentProps<{parentPartnerId: string}> & {};
type State = {
  steps: {
    type: 'partner' | 'account' | 'contract' | 'card';
    title: string;
  }[];
  step: number;

  accountDetails?: PartnerAccountDetails;
  contract?: PartnerContract;
  cardDetails?: CardDetails;
  partnerDetails?: PartnerDetails;

  sendEmailToParent: boolean;
  parentPartner: HttpPartnerExtended;
};

export class PartnerCreate extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      step: 0,
      steps: [
        {
          type: 'partner',
          title: 'Partner',
        },
        {
          type: 'account',
          title: 'Account',
        },
        {
          type: 'contract',
          title: 'Contract',
        },
        {
          type: 'card',
          title: 'Card',
        },
      ],
      parentPartner: null,
      sendEmailToParent: false,
    };
  }

  async componentDidMount() {
    await this.getPartner();
  }

  private async getPartner() {
    this.setState({parentPartner: null});
    const result = await AppService.adminPartnerClient.startCreatePartner(
      {parentPartnerId: this.props.match.params.parentPartnerId},
      {
        ...MainStore.handleError(404, () => {
          this.props.history.push('/');
        }),
      }
    );
    if (result) {
      this.setState({
        parentPartner: result.parentPartner,
        contract: {
          commission: Math.max(result.parentPartner.commission.commissionAmount - 0.5, 0),
          sendToDocusign: false,
          commissionReferral: null,
          referredPartnerName: null,
        },
        partnerDetails: {
          partnerName: '',
          partnerType: PartnerUtils.getPartnerTypes(result.parentPartner)[0],
          startingGroupCode: result.groupCode,
          startingMemberId: result.memberId,
          partnerNameValid: -1,
        },
        cardDetails: {
          companyName: '',
          urlSlug: '',
        },
        accountDetails: {
          firstName: '',
          lastName: '',
          phoneNumber: '',
          contactType: 'other',
          email: '',
          address1: '',
          address2: '',
          city: '',
          state: '',
          zip: '',
        },
      });
    }
  }

  private getWizardStep(index: number) {
    if (index === this.state.step) {
      return 'doing';
    }
    if (index < this.state.step) {
      return 'done';
    }
    return 'todo';
  }

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <PageTitle
          heading="Partner Creation"
          subheading="Easily create new partners"
          icon="lnr-magic-wand text-primary"
        />
        <Row>
          <Col md="12">
            {this.state.parentPartner ? (
              <Card className="main-card mb-3">
                <CardBody>
                  <div className="forms-wizard-vertical">
                    <ol className="forms-wizard">
                      {this.state.steps.map((s, i) => (
                        <li className={`form-wizard-step-${this.getWizardStep(i)}`} key={i} value={i}>
                          <em>{i + 1}</em>
                          <span>{s.title}</span>
                        </li>
                      ))}
                    </ol>
                    <div className="form-wizard-content">
                      <StateSwitch state={() => this.state.step}>
                        {{
                          0: () => (
                            <PartnerCreateDetailsStep
                              partnerTypes={PartnerUtils.getPartnerTypes(this.state.parentPartner)}
                              partnerDetails={this.state.partnerDetails}
                              setPartnerDetails={(partnerDetails) => this.setState({partnerDetails})}
                            />
                          ),
                          1: () => (
                            <PartnerCreateAccountDetailsStep
                              next={this.validateStep}
                              accountDetails={this.state.accountDetails}
                              setAccountDetails={(accountDetails, after) => this.setState({accountDetails}, after)}
                            />
                          ),
                          2: () => (
                            <PartnerCreateContractStep
                              partnerDetails={this.state.partnerDetails}
                              accountDetails={this.state.accountDetails}
                              parentPartner={this.state.parentPartner}
                              contract={this.state.contract}
                              setContract={(contract) => this.setState({contract})}
                            />
                          ),
                          3: () => (
                            <CardDetailsStep
                              partnerDetails={this.state.partnerDetails}
                              cardDetails={this.state.cardDetails}
                              setCardDetails={(cardDetails) => this.setState({cardDetails})}
                            />
                          ),
                        }}
                      </StateSwitch>
                    </div>
                    <div className="divider" />
                    <div className="clearfix">
                      <div>
                        <Button
                          color="secondary"
                          className="btn-shadow float-left btn-wide btn-pill"
                          outline
                          style={this.state.step > 0 ? {} : {display: 'none'}}
                          onClick={() => this.setState({step: this.state.step - 1})}
                        >
                          Previous
                        </Button>

                        <PromiseButton
                          color="primary"
                          className="btn-shadow btn-wide float-right btn-pill btn-hover-shine"
                          onClick={this.validateStep}
                        >
                          {this.state.step < this.state.steps.length - 1 ? 'Next' : 'Done'}
                        </PromiseButton>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
      </ReactCSSTransitionGroup>
    );
  }

  private validateStep = async () => {
    switch (this.state.step) {
      case 0:
        if (!this.state.partnerDetails.partnerName) {
          return false;
        }
        if (this.state.partnerDetails.partnerNameValid !== 1) {
          return false;
        }
        break;
      case 1:
        if (!this.state.accountDetails.accountId) {
          if (!this.state.accountDetails.firstName) {
            return false;
          }
          if (!this.state.accountDetails.lastName) {
            return false;
          }
          if (!this.state.accountDetails.email) {
            return false;
          }
        }
        break;
      case 2:
        if (this.state.contract.commission < 0) {
          return false;
        }
        if (this.state.contract.commission > this.state.parentPartner.commission.commissionAmount) {
          return false;
        }
        break;
      case 3:
        if (this.state.cardDetails.urlSlug.length > 0 && this.state.cardDetails.urlSlugValid !== 1) {
          return;
        }
        break;
    }
    if (this.state.step === 3) {
      const result = await AppService.adminPartnerClient.createPartner(
        {
          parentPartnerId: this.state.parentPartner.id,
          partnerName: this.state.partnerDetails.partnerName,
          partnerType: this.state.partnerDetails.partnerType,
          startingGroupCode: this.state.partnerDetails.startingGroupCode,
          startingMemberId: this.state.partnerDetails.startingMemberId,
          accountDetails: this.state.accountDetails,

          commission: this.state.contract.commission,
          sendToDocusign: this.state.contract.sendToDocusign,

          card: {
            companyName: this.state.cardDetails.companyName,
            urlSlug: this.state.cardDetails.urlSlug,
          },

          commissionReferral: this.state.contract.commissionReferral,
          minimumPayout: this.state.parentPartner.commission.minimumPayout,
          sendEmailToParent: this.state.sendEmailToParent,
        },
        {
          '400': (e) => {
            alert(e.error);
          },
        }
      );
      if (result) {
        this.props.history.push(`/partner/details/${result.partner.id}`);
      }
    } else {
      this.setState({step: this.state.step + 1});
    }
  };
}
