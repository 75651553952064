import {faMinus, faPlus} from '@fortawesome/free-solid-svg-icons';
import {faBusinessTime} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import Tabs, {TabPane} from 'rc-tabs';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import React, {Component} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import SweetAlert from 'react-bootstrap-sweetalert';
import {Prompt, RouteComponentProps} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownMenu,
  DropdownToggle,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import {PageTitle} from '../../../components/layout/pageTitle';
import {LInput, LSelect, LText} from '../../../components/lInput';
import Loading from '../../../components/loading';
import {PartnerSearchComponent} from '../../../components/partnerSearchComponent';
import {PromiseButton} from '../../../components/promiseButton';
import {StateSwitch} from '../../../components/stateSwitch';
import {
  HttpPartner,
  HttpPartnerExtended,
  HttpPartnerGroupTreeItem,
  HttpPartnerSearch,
} from '../../../dataServices/app.generated';
import {AppService} from '../../../dataServices/appService';
import {BreakOffModal} from '../../../modals/breakOffModal';
import {ConfirmModal} from '../../../modals/confirmModal';
import {DeleteModal} from '../../../modals/deleteModal';
import {NewParentModal} from '../../../modals/newParentModal';
import {MainStore} from '../../../store/main/store';
import {CsvUtils} from '../../../utils/csvUtils';
import {FormatUtils} from '../../../utils/formatUtils';
import {PartnerUtils} from '../../../utils/partnerUtils';
import {ClaimsReport} from './reports/claimsReport';
import {ConsultReport} from './reports/consultReport';
import {InstallReport} from './reports/installReport';
import {PartnerLedger} from './reports/partnerLedger';
import {PartnerPayoutReport} from './reports/partnerPayoutReport';
import {PayoutViableTab} from './reports/payoutViable';
import {UrlSlugReport} from './reports/urlSlugReport';
import {PartnerHealthDetails} from './partnerHealthDetails';

type Props = RouteComponentProps<{partnerId: string}> & {};
export type PartnerDetailsState = {
  partner?: HttpPartnerExtended;
  showCards: boolean;
  showCommissions: boolean;
  collapsedPartners: string[];
  uncollapsablePartners: string[];
  showSendContract?: (show: boolean) => void;
  isModified: boolean;

  referralPartners: {[id: string]: HttpPartnerSearch};
  openReferralPartner: boolean;
  openReferralPartnerIndex: number;
  showBreakOff: boolean;
  showNewPartnerModal: boolean;
  showDeletePartner: boolean;
  showStripeModal: boolean;
  showResendStripe: boolean;

  referrals: HttpPartner[];
};

export class PartnerDetails extends Component<Props, PartnerDetailsState> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      showCards: false,
      showCommissions: false,
      collapsedPartners: [],
      uncollapsablePartners: [],
      referralPartners: {},
      isModified: false,
      openReferralPartner: false,
      openReferralPartnerIndex: 0,
      showBreakOff: false,
      showNewPartnerModal: false,
      showDeletePartner: false,
      showStripeModal: false,
      showResendStripe: false,
      referrals: [],
    };
  }

  async componentDidMount() {
    await this.getPartner();
  }

  private makePharmacist = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to make this partner a pharmacist? This cannot be undone.')) {
      const result = await AppService.adminPartnerClient.makePharmacist(
        {
          partnerId: this.state.partner.id,
        },
        {...MainStore.handleError(400)}
      );
      if (result) {
        toast('This partner is now a pharmacist.', {type: 'success'});
        this.setState({partner: result.partner});
      }
    }
  };
  private makeHealthPartner = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to make this partner health enabled? This cannot be undone.')) {
      const result = await AppService.adminPartnerHealthClient.makeHealthPartner(
        {
          partnerId: this.state.partner.id,
        },
        {...MainStore.handleError(400)}
      );
      if (result) {
        toast('This partner is now health enabled.', {type: 'success'});
        this.setState({partner: result.partner});
      }
    }
  };

  render() {
    return (
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        {this.state.partner ? this.renderPartner() : <Loading />}
        {this.state.showBreakOff && (
          <BreakOffModal
            partner={this.state.partner}
            {...this.props}
            close={() => this.setState({showBreakOff: false})}
          />
        )}
        {this.state.showNewPartnerModal && (
          <NewParentModal
            partner={this.state.partner}
            {...this.props}
            close={() => this.setState({showNewPartnerModal: false})}
          />
        )}
        {this.state.showResendStripe && (
          <ConfirmModal
            text={'Are you sure you want to re-send the stripe activation link?.'}
            title={'Re-Send'}
            onSuccess={async () => {
              if (
                await AppService.adminPartnerClient.resendStripeLink(
                  {
                    partnerId: this.state.partner.id,
                  },
                  {
                    ...MainStore.handleError(400),
                    ...MainStore.handleError(404),
                  }
                )
              ) {
                toast('The stripe activation email has been re-sent', {type: 'success'});
              }
            }}
            close={() => this.setState({showResendStripe: false})}
          />
        )}
        {this.state.showDeletePartner && (
          <DeleteModal
            text={
              'Are you sure you want to delete this Partner? Please assure they have no children Partners or Cards associated with them.'
            }
            {...this.props}
            onSuccess={async () => {
              if (
                await AppService.adminPartnerClient.deletePartner(
                  {
                    partnerId: this.state.partner.id,
                  },
                  {
                    ...MainStore.handleError(400),
                  }
                )
              ) {
                this.props.history.push(`/partner/details/${this.state.partner.parentPartnerId}`);
              }
            }}
            close={() => this.setState({showDeletePartner: false})}
          />
        )}
        {this.state.showStripeModal && (
          <ConfirmModal
            title={'Stripe'}
            text={'Are you sure you want to send this partner a Stripe invitation?'}
            onSuccess={this.connectStripe}
            close={() => this.setState({showStripeModal: false})}
          />
        )}
        <Prompt
          when={this.state.isModified}
          message={(location) => `You have unsaved changes, are you sure you want leave?`}
        />
      </ReactCSSTransitionGroup>
    );
  }

  private renderPartner() {
    const partner = this.state.partner;
    const partnerTypeOptions = PartnerUtils.getAllPartnerTypes().map((a) => ({label: a, value: a}));
    return (
      <>
        <PageTitle
          heading={`${partner.partnerName} (${partner.startingGroupCode}-${partner.startingMemberId})`}
          subheading=""
          icon="pe-7s-medal icon-gradient bg-tempting-azure"
          actions={
            <UncontrolledDropdown className="d-inline-block">
              <DropdownToggle color="primary" className="btn-shadow" caret>
                <span className="btn-icon-wrapper pr-2 opacity-7">
                  <FontAwesomeIcon icon={faBusinessTime} />
                </span>
                Actions
              </DropdownToggle>
              <DropdownMenu right>
                <Nav vertical>
                  {partner.parentPartnerId && (
                    <NavItem>
                      <NavLink href="javascript:void(0);" onClick={() => this.setState({showNewPartnerModal: true})}>
                        <span>Change Parent Partner</span>
                      </NavLink>
                    </NavItem>
                  )}
                  {partner.parentPartnerId && (
                    <NavItem>
                      <NavLink href="javascript:void(0);" onClick={() => this.setState({showBreakOff: true})}>
                        <span>Break Off {partner.partnerName}</span>
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      href="javascript:void(0);"
                      onClick={() => {
                        this.props.history.push(`/partner/create/${partner.id}`);
                      }}
                    >
                      <span>Invite Partner Under {partner.partnerName}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="javascript:void(0);"
                      onClick={() => {
                        this.props.history.push(`/card/create/${partner.id}`);
                      }}
                    >
                      <span>Add Card Under {partner.partnerName}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink href="javascript:void(0);" onClick={() => this.exportPartnersAsCSV()}>
                      <span>Export Partners as CSV</span>
                    </NavLink>
                  </NavItem>
                  {partner.commission && partner.commission.stripeLinkSent && !partner.commission.stripeAccountSetup && (
                    <NavItem>
                      <NavLink href="javascript:void(0);" onClick={() => this.setState({showResendStripe: true})}>
                        <span>Re-Send Stripe Link</span>
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink href="javascript:void(0);" onClick={() => this.setState({showDeletePartner: true})}>
                      <span className={'text-danger'}>Delete {partner.partnerName}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
              </DropdownMenu>
            </UncontrolledDropdown>
          }
        />

        <Tabs
          destroyInactiveTabPane
          defaultActiveKey="1"
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent />}
        >
          <TabPane tab="Details" key="1">
            {this.renderDetails()}
          </TabPane>
          <TabPane tab="Install Report" key="2">
            <InstallReport partner={this.state.partner} {...this.props} />
          </TabPane>
          <TabPane tab="Card Slug Report" key="3">
            <UrlSlugReport partner={this.state.partner} {...this.props} />
          </TabPane>
          <TabPane tab="Claims Report" key="4">
            <ClaimsReport partner={this.state.partner} {...this.props} />
          </TabPane>
          {this.state.partner.health && (
            <TabPane tab="Consult Report" key="5">
              <ConsultReport partner={this.state.partner} {...this.props} />
            </TabPane>
          )}
          <TabPane tab="Partner Ledger" key="6">
            <PartnerLedger partner={this.state.partner} {...this.props} />
          </TabPane>
          <TabPane tab="Payout Viable" key="7">
            <PayoutViableTab partner={this.state.partner} {...this.props} />
          </TabPane>
          <TabPane tab="Claim Payout Tracker" key="8">
            <PartnerPayoutReport partner={this.state.partner} {...this.props} />
          </TabPane>
        </Tabs>
        <Modal isOpen={this.state.openReferralPartner}>
          <ModalHeader toggle={() => this.setState({openReferralPartner: false})}>Select Referral Partner</ModalHeader>
          <ModalBody>
            <PartnerSearchComponent
              onPartnerSelect={(p) => {
                const commissionReferrals = [...this.state.partner.commissionReferrals];
                commissionReferrals[this.state.openReferralPartnerIndex] = {
                  ...commissionReferrals[this.state.openReferralPartnerIndex],
                  partnerId: p._id,
                };

                this.setState({
                  isModified: true,
                  openReferralPartner: false,
                  partner: {
                    ...this.state.partner,
                    commissionReferrals,
                  },
                  referralPartners: {...this.state.referralPartners, [p._id]: p},
                });
              }}
            />
          </ModalBody>
        </Modal>
        {this.state.showSendContract && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Send Contract Now"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={`Are you sure you want to send the contract to ${this.state.partner.partnerName}?`}
            onConfirm={() => {
              this.state.showSendContract(true);
            }}
            onCancel={() => this.state.showSendContract(false)}
          >
            You will not be able to recover this imaginary file!
          </SweetAlert>
        )}
      </>
    );
  }

  private renderTree = (item: HttpPartnerGroupTreeItem): React.ReactNode => {
    const selected = item.partnerId === this.state.partner.id;
    const isCollapsed = this.isCollapsed(item.partnerId);
    const dontShowToggle =
      this.state.uncollapsablePartners.some((a) => a === item.partnerId) ||
      (!this.state.showCards && item.children.length === 0) ||
      (item.children.length === 0 && item.cards.length === 0);
    return (
      <div>
        {!dontShowToggle && (
          <a
            href={'#'}
            style={{marginLeft: 5, marginRight: 5}}
            onClick={(e) => {
              this.toggleCollapse(item.partnerId);
              e.preventDefault();
            }}
          >
            {isCollapsed ? <FontAwesomeIcon icon={faPlus} /> : <FontAwesomeIcon icon={faMinus} />}
          </a>
        )}
        <Link to={`/partner/details/${item.partnerId}`} style={{fontWeight: selected ? 'bold' : 'normal'}}>
          {item.startingGroupCode}-{item.startingMemberId} {item.partnerName}
        </Link>
        {this.state.showCommissions && item.commissionAmount >= 0 && (
          <span style={{paddingLeft: 5}} className={'money'}>
            {FormatUtils.formatMoney(item.commissionAmount)}
          </span>
        )}
        {/*{groupTree.account ? (
          <Link to={`/excel/account/${groupTree.account.id}`} className={'tree-account'}>
            ({groupTree.account.email})
          </Link>
        ) : (
          ''
        )}*/}
        {!isCollapsed && (
          <ul key={item.partnerId}>
            {item.children.map((a) => (
              <li key={a.partnerId}>{this.renderTree(a)}</li>
            ))}
            {this.state.showCards && item.cards.length > 0 && (
              <li style={{color: 'green'}}>
                Cards
                <ul>
                  {item.cards.map((card) => (
                    <li key={card.id}>
                      <Link to={`/card/details/${card.id}`} style={{color: 'green'}}>
                        {card.groupCode} - {card.memberId}{' '}
                        <b>{card.companyName || (card.urlSlug ? `/${card.urlSlug}` : '')}</b>
                      </Link>
                      {/*
              {card.account ? (
                <Link to={`/excel/account/${card.account.id}`} className={'tree-account'}>
                  ({card.account.email})
                </Link>
              ) : (
                ''
              )}
*/}
                    </li>
                  ))}
                </ul>
              </li>
            )}
          </ul>
        )}
      </div>
    );
  };

  private async getPartner() {
    this.setState({
      showCards: false,
      showCommissions: false,
      collapsedPartners: [],
      uncollapsablePartners: [],
      isModified: false,
      partner: null,
      referralPartners: {},
      showBreakOff: false,
      showNewPartnerModal: false,
      showDeletePartner: false,
      referrals: [],
    });
    const result = await AppService.adminPartnerClient.getPartner(
      {
        partnerId: this.props.match.params.partnerId,
      },
      {
        ...MainStore.handleError(404, () => {
          this.props.history.push('/');
        }),
      }
    );
    if (result) {
      this.setState({partner: result.partner, showCards: !!result.partner.parentPartnerId}, this.collapseAll);
      for (const commissionReferral of result.partner.commissionReferrals) {
        if (commissionReferral.partnerId) {
          const referralResult = await AppService.adminPartnerClient.getPartner(
            {
              partnerId: commissionReferral.partnerId,
            },
            {
              ...MainStore.handleError(404, () => {
                this.props.history.push('/');
              }),
            }
          );
          this.setState({
            referralPartners: {
              ...this.state.referralPartners,
              [referralResult.partner.id]: {
                _id: referralResult.partner.id,
                partnerName: referralResult.partner.partnerName,
                parentPartnerName: null,
                startingGroupCode: null,
                startingMemberId: null,
              },
            },
          });
        }
      }

      const getReferralResult = await AppService.adminPartnerClient.getReferrals(
        {
          partnerId: this.props.match.params.partnerId,
        },
        {
          ...MainStore.handleError(404, () => {
            this.props.history.push('/');
          }),
          ...MainStore.handleError(400, () => {
            this.props.history.push('/');
          }),
        }
      );
      if (getReferralResult) {
        this.setState({referrals: getReferralResult.referrals});
      }
    }
  }

  private isCollapsed(id: string) {
    return this.state.collapsedPartners.some((a) => a === id);
  }

  private toggleCollapse(id: string) {
    if (this.isCollapsed(id)) {
      this.setState({collapsedPartners: this.state.collapsedPartners.filter((a) => a !== id)});
    } else {
      this.setState({collapsedPartners: [...this.state.collapsedPartners, id]});
    }
  }

  private collapseAll() {
    const partnerId = this.state.partner.id;
    const uncollapsablePartners: string[] = [];
    function getChildren(groupTree: HttpPartnerGroupTreeItem, isTopYet: boolean): string[] {
      if (!isTopYet && groupTree.partnerId === partnerId) {
        isTopYet = true;
        uncollapsablePartners.push(groupTree.partnerId);
      }
      const partnerIds: string[] = [];
      if (isTopYet) {
        partnerIds.push(groupTree.partnerId);
      } else {
        uncollapsablePartners.push(groupTree.partnerId);
      }
      return partnerIds.concat(...groupTree.children.map((c) => getChildren(c, isTopYet)));
    }

    const ids = getChildren(this.state.partner.groupTree, false);
    this.setState({collapsedPartners: ids.filter((a) => a !== this.state.partner.id), uncollapsablePartners});
  }

  private expandAll = () => {
    this.setState({collapsedPartners: []});
  };

  private sendContract = async () => {
    return new Promise((res) => {
      this.setState({
        showSendContract: async (show) => {
          this.setState({showSendContract: undefined});
          if (!show) {
            res();
            return;
          }
          const result = await AppService.adminPartnerClient.sendToDocusign(
            {
              partnerId: this.state.partner.id,
            },
            {
              ...MainStore.handleError(400),
            }
          );
          if (result) {
            this.setState({partner: result.partner});
          }
          res();
        },
      });
    });
  };

  private connectStripe = async () => {
    try {
      const result = await AppService.adminPartnerClient.linkStripe(
        {
          partnerId: this.state.partner.id,
        },
        {
          ...MainStore.handleError(400),
          ...MainStore.handleError(404),
        }
      );
      if (result) {
        toast('Stripe setup information has been sent.', {type: 'success'});
        this.setState({partner: result.partner});
      }
      this.setState({showStripeModal: false});
    } catch (ex) {
      toast(ex.message, {type: 'error'});
    }
  };

  private updatePartnerDetails = async () => {
    const result = await AppService.adminPartnerClient.updatePartner(
      {
        partnerId: this.state.partner.id,
        partnerName: this.state.partner.partnerName,
        partnerType: this.state.partner.partnerType,
        startingGroupCode: this.state.partner.startingGroupCode,
        startingMemberId: this.state.partner.startingMemberId,
        commissionAmount: this.state.partner.commission.commissionAmount,
        commissionReferrals: this.state.partner.commissionReferrals,
        parentPartnerId: this.state.partner.parentPartnerId,
        minimumPayout: this.state.partner.commission.minimumPayout,
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      this.setState({isModified: false, partner: result.partner});
      toast('Partner has been updated!', {type: 'success'});
    }
  };

  private renderDetails() {
    const partner = this.state.partner;
    const partnerTypeOptions = PartnerUtils.getAllPartnerTypes().map((a) => ({label: a, value: a}));
    const isPrimaryPartner = !partner.account.primaryPartnerId || partner.account.primaryPartnerId === partner.id;
    return (
      <Row>
        <Col md="5">
          <Card className="main-card mb-3" style={{maxHeight: '100vh', overflowY: 'scroll'}}>
            <CardBody>
              <CardTitle>Partner Tree</CardTitle>
              <a
                href={'#'}
                onClick={(e) => {
                  this.expandAll();
                  e.preventDefault();
                }}
              >
                Expand All
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                href={'#'}
                onClick={(e) => {
                  this.collapseAll();
                  e.preventDefault();
                }}
              >
                Collapse All
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                href={'#'}
                onClick={(e) => {
                  this.setState({showCards: !this.state.showCards});
                  e.preventDefault();
                }}
              >
                {this.state.showCards ? 'Hide' : 'Show'} Cards
              </a>
              &nbsp;&nbsp;&nbsp;
              <a
                href={'#'}
                onClick={(e) => {
                  this.setState({showCommissions: !this.state.showCommissions});
                  e.preventDefault();
                }}
              >
                {this.state.showCommissions ? 'Hide' : 'Show'} Commissions
              </a>
              <div className={'partner-tree'} style={{paddingTop: 10}}>
                <ul>
                  <li>{this.renderTree(partner.groupTree)}</li>
                </ul>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col md="7">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Details</CardTitle>
              <Form>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Partner Name'}
                      value={partner.partnerName}
                      onChange={(e) =>
                        this.setState({
                          isModified: true,
                          partner: {...this.state.partner, partnerName: e.target.value},
                        })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <LText label={'Account Owner'} style={{fontWeight: 'bold'}}>
                      <Link to={`/account/details/${partner.account.id}`}>
                        {partner.account.accountOwner
                          ? `${partner.account.accountOwner} (${partner.account.email})`
                          : partner.account.email}
                      </Link>
                    </LText>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      value={partner.startingGroupCode}
                      onChange={(e) => {
                        this.setState({
                          isModified: true,
                          partner: {...this.state.partner, startingGroupCode: e.target.value},
                        });
                      }}
                      label={'Group Code'}
                      type={'text'}
                    />
                  </Col>
                  <Col md={6}>
                    <LInput
                      value={partner.startingMemberId}
                      onChange={(e) => {
                        this.setState({
                          isModified: true,
                          partner: {...this.state.partner, startingMemberId: e.target.value},
                        });
                      }}
                      label={'Member Id'}
                      type={'text'}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LSelect
                      label={'Partner Type'}
                      value={partnerTypeOptions.find((a) => a.value === this.state.partner.partnerType)}
                      options={partnerTypeOptions}
                      onChange={(e) =>
                        this.setState({
                          isModified: true,
                          partner: {...this.state.partner, partnerType: (e as any).value},
                        })
                      }
                    />
                  </Col>

                  <Col md={6}>
                    {!this.state.partner.health && (
                      <>
                        {this.state.partner.isPharmacist ? (
                          <>This partner is a pharmacist and will be paid out via giftcards.</>
                        ) : (
                          <PromiseButton
                            style={{marginLeft: 10}}
                            color="primary"
                            size="lg"
                            onClick={this.makePharmacist}
                          >
                            Mark Pharmacist
                          </PromiseButton>
                        )}
                      </>
                    )}

                    {this.state.partner.health ? (
                      <>This is a health enabled partner</>
                    ) : (
                      <PromiseButton
                        style={{marginLeft: 10}}
                        color="primary"
                        size="lg"
                        onClick={this.makeHealthPartner}
                      >
                        Mark Health Partner
                      </PromiseButton>
                    )}
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {partner.health && <PartnerHealthDetails partner={partner} setPartner={(p) => this.setState({partner: p})} />}
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Contract</CardTitle>
              <Form>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Commission Amount'}
                      value={partner.commission.commissionAmount}
                      type={'number'}
                      min={0.1}
                      step={0.01}
                      max={2.5}
                      onChange={(e) =>
                        this.setState({
                          isModified: true,
                          partner: {
                            ...this.state.partner,
                            commission: {
                              ...this.state.partner.commission,
                              commissionAmount: parseFloat(e.target.value),
                            },
                          },
                        })
                      }
                    />
                  </Col>
                  <Col md={6}>
                    <LText label={'Contract'} style={{fontWeight: 'bold'}}>
                      {partner.contract.docusignEnvelopeId ? (
                        <>
                          <a href={`https://app.docusign.com/documents/details/${partner.contract.docusignEnvelopeId}`}>
                            In Docusign (
                            <b>
                              {partner.contract.contractSigned
                                ? 'Signed ' + moment(partner.contract.contractSigned).format('YYYY-MM-DD')
                                : 'NOT SIGNED'}
                            </b>
                            )
                          </a>
                          <PromiseButton style={{marginLeft: 10}} color="primary" size="lg" onClick={this.sendContract}>
                            Resend Contract Now
                          </PromiseButton>
                        </>
                      ) : (
                        <>
                          No Contract On File
                          <PromiseButton style={{marginLeft: 10}} color="primary" size="lg" onClick={this.sendContract}>
                            Send Contract Now
                          </PromiseButton>
                        </>
                      )}
                    </LText>
                    <LText label={'W9'} style={{fontWeight: 'bold'}}>
                      {partner.w9?.docusignEnvelopeId ? (
                        <>
                          <a href={`https://app.docusign.com/documents/details/${partner.w9.docusignEnvelopeId}`}>
                            In Docusign (
                            <b>
                              {partner.w9.dateSigned
                                ? 'Signed ' + moment(partner.w9.dateSigned).format('YYYY-MM-DD')
                                : 'NOT SIGNED'}
                            </b>
                            )
                          </a>
                        </>
                      ) : (
                        <>No W9 On File</>
                      )}
                    </LText>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LInput
                      label={'Minimum Payout Amount'}
                      value={partner.commission.minimumPayout}
                      type={'number'}
                      min={10}
                      step={1}
                      max={1000}
                      onChange={(e) =>
                        this.setState({
                          isModified: true,
                          partner: {
                            ...this.state.partner,
                            commission: {
                              ...this.state.partner.commission,
                              minimumPayout: parseFloat(e.target.value),
                            },
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <LText label={'Bonus Tiers'} style={{fontWeight: 'bold'}}>
                      No Bonus On File
                    </LText>
                  </Col>
                  {isPrimaryPartner && (
                    <Col md={6}>
                      <LText label={'Stripe'} style={{fontWeight: 'bold'}}>
                        <StateSwitch
                          state={() => {
                            if (!partner.account.accountSetUp) {
                              return 'noAccount';
                            }
                            if (!partner.commission.stripeLinkSent) {
                              return 'notSetup';
                            }
                            if (!partner.commission.stripeAccountSetup) {
                              return 'accountNotSetup';
                            }
                            if (!partner.commission.stripeHasBankAccount) {
                              return 'noBankAccount';
                            }
                            return 'setup';
                          }}
                        >
                          {{
                            noAccount: () => (
                              <span>
                                Partner Portal Not Set Up
                                <Button style={{marginLeft: 10}} color={'primary'} onClick={this.activatePartnerPortal}>
                                  Activate Partner Portal
                                </Button>
                              </span>
                            ),
                            notSetup: () => (
                              <span>
                                No stripe account.{' '}
                                <Button
                                  style={{marginLeft: 10}}
                                  color={'primary'}
                                  onClick={() =>
                                    this.setState({
                                      showStripeModal: true,
                                    })
                                  }
                                >
                                  Send link to set up stripe now
                                </Button>
                              </span>
                            ),
                            accountNotSetup: () => <span>Waiting for partner to set up their stripe account</span>,
                            noBankAccount: () => <span>Waiting for partner to set up their bank account</span>,
                            setup: () => <span>This partner is fully set up</span>,
                          }}
                        </StateSwitch>
                      </LText>
                    </Col>
                  )}
                </Row>
                {partner.payoutStyle === 'giftcard' && (
                  <Row>
                    <Col md={6}>
                      <h4>THIS PARTNER IS PAID VIA GIFTCARD</h4>
                    </Col>
                  </Row>
                )}

                {partner.account.primaryPartnerId && (
                  <Row>
                    <Col md={6}>
                      <h4>
                        This Account has multiple partners tied to it.
                        <br />
                        {isPrimaryPartner ? (
                          <b>This is the Partner that requires a stripe account and W9.</b>
                        ) : (
                          <>
                            <b>This is NOT the Partner that requires a stripe account and W9.</b>{' '}
                            <Link to={`/partner/details/${partner.account.primaryPartnerId}`}>
                              Navigate to Primary Partner
                            </Link>
                          </>
                        )}
                      </h4>
                    </Col>
                  </Row>
                )}
              </Form>
            </CardBody>
          </Card>
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Referral Commission</CardTitle>
              <Form>
                {this.state.partner.commissionReferrals.map((c, ind) => (
                  <Row key={c.partnerId} style={{paddingTop: 10}}>
                    <Col md={6}>
                      <LInput
                        value={c.commissionAmount}
                        onChange={(e) => {
                          c.commissionAmount = parseFloat(e.target.value);
                          this.setState({
                            isModified: true,
                            partner: {
                              ...this.state.partner,
                              commissionReferrals: [...this.state.partner.commissionReferrals],
                            },
                          });
                        }}
                        label={'Referral Commission'}
                        min={0.01}
                        step={0.01}
                        max={partner.commission.commissionAmount}
                        type={'number'}
                      />
                    </Col>
                    <Col md={4} style={{height: 90, display: 'flex', alignItems: 'center'}}>
                      {c.partnerId ? (
                        <LText label={'Referred Partner'} style={{fontWeight: 'bold'}}>
                          <Link to={`/partner/details/${c.partnerId}`}>
                            {!this.state.referralPartners[c.partnerId]
                              ? 'Loading...'
                              : this.state.referralPartners[c.partnerId].partnerName}
                          </Link>
                          <Button
                            style={{marginLeft: 10}}
                            color={'primary'}
                            onClick={() => this.setState({openReferralPartner: true, openReferralPartnerIndex: ind})}
                          >
                            Change Partner
                          </Button>
                        </LText>
                      ) : (
                        <Button
                          color={'primary'}
                          onClick={() => this.setState({openReferralPartner: true, openReferralPartnerIndex: ind})}
                        >
                          Select Partner
                        </Button>
                      )}
                    </Col>
                    <Col md={2} style={{height: 90, display: 'flex', alignItems: 'center'}}>
                      <Button
                        color="primary"
                        onClick={() => {
                          const commissionReferrals = [...this.state.partner.commissionReferrals].filter(
                            (a) => a.partnerId !== c.partnerId
                          );
                          this.setState({
                            isModified: true,
                            partner: {
                              ...this.state.partner,
                              commissionReferrals,
                            },
                          });
                        }}
                      >
                        Remove
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Button
                  color="primary"
                  onClick={() =>
                    this.setState({
                      isModified: true,
                      partner: {
                        ...this.state.partner,
                        commissionReferrals: [
                          ...this.state.partner.commissionReferrals,
                          {dateStarted: new Date(), commissionAmount: 0.25, partnerId: null},
                        ],
                      },
                    })
                  }
                >
                  Add Referred Partner
                </Button>
              </Form>
            </CardBody>
          </Card>
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Referrals</CardTitle>
              <Form>
                {this.state.referrals.map((c, ind) => {
                  const r = c.commissionReferrals.find((a) => a.partnerId === this.state.partner.id);
                  return (
                    <Row key={c.id} style={{paddingTop: 10}}>
                      <Col md={6}>
                        <LInput readOnly value={r.commissionAmount} label={'Referral Commission'} type={'number'} />
                      </Col>
                      <Col md={5} style={{height: 90, display: 'flex', alignItems: 'center'}}>
                        <LText label={'Referred Partner'} style={{fontWeight: 'bold'}}>
                          <Link to={`/partner/details/${c.id}`}>{c.partnerName}</Link>
                        </LText>
                      </Col>
                    </Row>
                  );
                })}
                {this.state.referrals.length === 0 && <span>This partner has no referrals</span>}
              </Form>
            </CardBody>
          </Card>
          <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
            <PromiseButton
              disabled={!this.state.isModified}
              onClick={this.updatePartnerDetails}
              className="btn-pill btn-shadow mr-3"
              color="primary"
            >
              Save Changes
            </PromiseButton>
          </Row>
        </Col>
      </Row>
    );
  }

  private exportPartnersAsCSV() {
    const get = (p: HttpPartnerGroupTreeItem, isTopYet: boolean): HttpPartnerGroupTreeItem[] => {
      if (!isTopYet && p.partnerId === this.state.partner.id) {
        isTopYet = true;
      }

      const partners: HttpPartnerGroupTreeItem[] = [];
      if (isTopYet) {
        partners.push(p);
      }
      for (const child of p.children) {
        partners.push(...get(child, isTopYet));
      }
      return partners;
    };

    CsvUtils.saveFile(
      `partners-${this.state.partner.startingGroupCode}-${this.state.partner.startingMemberId}.csv`,
      get(this.state.partner.groupTree, false),
      [
        {
          display: 'Group Code',
          field: 'startingGroupCode',
        },
        {
          display: 'Member Id',
          field: 'startingMemberId',
        },
        {
          display: 'Partner Name',
          field: 'partnerName',
        },
        {
          display: 'Commission Amount',
          field: 'commissionAmount',
        },
      ]
    );
  }

  private activatePartnerPortal = async () => {
    // eslint-disable-next-line no-restricted-globals
    const j = confirm(
      `Are you sure you want to allow ${this.state.partner.account.accountOwner} to log into Partner Portal?`
    );
    if (j) {
      const result = await AppService.adminAccountClient.activateAccount(
        {
          accountId: this.state.partner.account.id,
        },
        {...MainStore.handleError(404)}
      );
      if (result) {
        toast('The activation email has been sent!', {type: 'success'});
        this.setState({partner: {...this.state.partner, account: result.account}});
      }
    }
  };
}
