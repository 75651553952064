import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import {LInput} from '../../../../components/lInput';
import {AppService} from '../../../../dataServices/appService';
import {DebounceUtils} from '../../../../utils/debounceUtils';

export interface CardDetails {
  companyName?: string;
  groupCode: string;
  memberId: string;
  urlSlug?: string;
  urlSlugValid?: -1 | 0 | 1;
}

export class CardDetailsStep extends Component<
  {
    cardDetails: CardDetails;
    setCardDetails: (cardDetails: CardDetails) => void;
  },
  {validating: boolean}
> {
  state = {
    validating: false,
  };

  validateUrlSlug = (urlSlug: string) => {
    if (urlSlug.length === 0) {
      this.setState({validating: false});
      this.props.setCardDetails({...this.props.cardDetails, urlSlugValid: -1});
    } else {
      this.setState({validating: true});
      DebounceUtils.debounce('urlSlug', 1000, async () => {
        if (!this.state.validating) {
          return;
        }
        const result = await AppService.adminPartnerClient.validateUrlSlugUnique({urlSlug}, {});
        if (!this.state.validating) {
          return;
        }
        this.props.setCardDetails({...this.props.cardDetails, urlSlugValid: result.valid ? 1 : 0});
        this.setState({validating: false});
      });
    }
  };

  render() {
    const {cardDetails, setCardDetails} = this.props;
    return (
      <>
        <Row>
          <Col md={6}>
            <LInput
              value={cardDetails.groupCode}
              onChange={(e) => {
                const groupCode = e.target.value;
                setCardDetails({...cardDetails, groupCode});
              }}
              label={'Group Code'}
              type={'text'}
            />
          </Col>
          <Col md={6}>
            <LInput
              value={cardDetails.memberId}
              onChange={(e) => {
                const memberId = e.target.value;
                setCardDetails({...cardDetails, memberId});
              }}
              label={'Member Id'}
              type={'text'}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <LInput
              value={cardDetails.companyName}
              onChange={(e) => setCardDetails({...cardDetails, companyName: e.target.value})}
              label={'Company Name'}
              placeholder={'optional'}
              type={'text'}
            />
          </Col>
          <Col md={6}>
            <LInput
              value={cardDetails.urlSlug}
              className={'no-highlight-input'}
              style={{
                borderWidth: cardDetails.urlSlugValid === -1 && !this.state.validating ? null : 3,
                borderColor:
                  cardDetails.urlSlugValid === 0
                    ? 'red'
                    : cardDetails.urlSlugValid === 1
                    ? 'green'
                    : this.state.validating
                    ? '#c1a437'
                    : null,
              }}
              onChange={(e) => {
                setCardDetails({...cardDetails, urlSlug: e.target.value});
                this.validateUrlSlug(e.target.value);
              }}
              label={'Url Slug'}
              placeholder={'optional'}
              type={'text'}
            />
          </Col>
        </Row>
      </>
    );
  }
}
