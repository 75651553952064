import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import React, {useRef, useState} from 'react';
import Flatpickr from 'react-flatpickr';
import './DatePicker.css';

const options: flatpickr.Options.Options = {
  mode: 'range',
  static: true,
  monthSelectorType: 'static',
  dateFormat: 'M j, Y',
  prevArrow:
    '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
  nextArrow:
    '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
};

export const Datepicker = ({value, onChange}: {value: any; onChange: any}) => {
  return (
    <div className={'DatePicker__container'}>
      <Flatpickr className={'DatePicker__flatpickr'} options={options} value={value} onChange={onChange} />
      <div className={'DatePicker__icon'}>
        <svg viewBox="0 0 16 16">
          <path d="M15 2h-2V0h-2v2H9V0H7v2H5V0H3v2H1a1 1 0 00-1 1v12a1 1 0 001 1h14a1 1 0 001-1V3a1 1 0 00-1-1zm-1 12H2V6h12v8z" />
        </svg>
      </div>
    </div>
  );
};
