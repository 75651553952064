import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Card, CardBody, CardTitle, Col, Row, Table} from 'reactstrap';
import Loading from '../../../../components/loading';
import {
  HttpCardLight,
  HttpPartnerExtended,
  HttpPartnerGroupTreeItem,
} from '../../../../dataServices/app.generated';
import {AppService} from '../../../../dataServices/appService';
import {MainStore} from '../../../../store/main/store';

type Props = RouteComponentProps<any> & {partner?: HttpPartnerExtended};
type State = {
  downloadReport?: {groupCode: string; memberId: string; count: number}[];
  gettingDownloadReport: boolean;
};

export class InstallReport extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {
      gettingDownloadReport: false,
      downloadReport: null,
    };
  }

  componentDidMount(): void {
    this.getDownloadReport();
  }

  render() {
    if (this.state.gettingDownloadReport || !this.state.downloadReport) {
      return <Loading />;
    }
    const partnerId = this.props.partner.id;
    const {downloadReport} = this.state;
    function getChildren(
      groupTree: HttpPartnerGroupTreeItem,
      isTopYet: boolean
    ): {card: HttpCardLight; partnerId: string; partnerName: string; downloads: number}[] {
      if (!isTopYet && groupTree.partnerId === partnerId) {
        isTopYet = true;
      }

      const innerCards: {card: HttpCardLight; partnerId: string; partnerName: string; downloads: number}[] = [];
      if (isTopYet) {
        for (const card of groupTree.cards) {
          const report = downloadReport.find((b) => b.groupCode === card.groupCode && b.memberId === card.memberId);
          innerCards.push({
            card,
            partnerId: groupTree.partnerId,
            partnerName: groupTree.partnerName,
            downloads: report ? report.count : 0,
          });
        }
      }
      return innerCards.concat(...groupTree.children.map((c) => getChildren(c, isTopYet)));
    }

    const cards = getChildren(this.props.partner.groupTree, false).sort((a, b) => b.downloads - a.downloads);

    return (
      <Row>
        <Col md="5">
          <Card className="main-card mb-3">
            <CardBody>
              <CardTitle>Member Installs</CardTitle>
              <Table hover striped className="mb-0">
                <thead>
                  <tr>
                    <th>Rank</th>
                    <th>Partner</th>
                    <th>Company Name</th>
                    <th>Group Code</th>
                    <th>Member Id</th>
                    <th>Installs</th>
                  </tr>
                </thead>
                <tbody>
                  {cards.map((item, index) => (
                    <tr key={item.card.id} onClick={() => this.props.history.push(`/card/details/${item.card.id}`)}>
                      <td>{index + 1}</td>
                      <td>{item.partnerName}</td>
                      <td>{item.card.companyName}</td>
                      <td>{item.card.groupCode}</td>
                      <td>{item.card.memberId}</td>
                      <td>{item.downloads}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  private async getDownloadReport() {
    this.setState({gettingDownloadReport: true});
    const result = await AppService.adminReportsClient.getDownloadsByPartner(
      {partnerId: this.props.partner.id},
      {...MainStore.handleError(404)}
    );
    if (result) {
      this.setState({gettingDownloadReport: false, downloadReport: result.breakdown});
    }
  }
}
