import React from 'react';
import {HealthContainer} from '../components/HealthContainer/healthContainer';

export const HealthIVR = () => {
  return (
    <HealthContainer title={'Clever Health IVR'}>
      <div />
    </HealthContainer>
  );
};
