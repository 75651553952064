import {useEffect} from 'react';

export const useEscKeyPressed = (show: boolean, onPress: () => void) => {
  return useEffect(() => {
    const keyHandler = ({keyCode}: {keyCode: number}) => {
      if (!show || keyCode !== 27) {
        return;
      }
      onPress();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [show]);
};
