import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {PartnerSearchComponent} from '../components/partnerSearchComponent';
import {PromiseButton} from '../components/promiseButton';
import {HttpPartnerExtended, HttpPartnerSearch} from '../dataServices/app.generated';
import {AppService} from '../dataServices/appService';
import {MainStore} from '../store/main/store';

type Props = RouteComponentProps<{partnerId: string}> & {
  partner: HttpPartnerExtended;
  close: () => void;
};
type State = {
  newParentPartner?: HttpPartnerSearch;
};

export class NewParentModal extends Component<Props, State> {
  constructor(props: Props, context: any) {
    super(props, context);

    this.state = {};
  }

  private setNewParent = async () => {
    const result = await AppService.adminPartnerClient.updatePartner(
      {
        partnerId: this.props.partner.id,
        partnerName: this.props.partner.partnerName,
        parentPartnerId: this.state.newParentPartner._id,
        startingGroupCode: this.props.partner.startingGroupCode,
        startingMemberId: this.props.partner.startingMemberId,
        partnerType: this.props.partner.partnerType,
        commissionReferrals: this.props.partner.commissionReferrals,
        commissionAmount: this.props.partner.commission.commissionAmount,
        minimumPayout: this.props.partner.commission.minimumPayout,
      },
      {
        ...MainStore.handleError(400),
      }
    );
    if (result) {
      window.location.reload();
    }
  };

  render() {
    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={this.props.close}>New Parent</ModalHeader>
        <ModalBody>
          <PartnerSearchComponent onPartnerSelect={(p) => this.setState({newParentPartner: p})} />

          {this.state.newParentPartner && (
            <div style={{marginTop: 10}}>
              Selected Parent Partner: <b>{this.state.newParentPartner.partnerName}</b>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <PromiseButton onClick={this.setNewParent} color={'primary'}>
            Update Parent
          </PromiseButton>
        </ModalFooter>
      </Modal>
    );
  }
}
